import React, { FC, useState } from "react";
import { ContractorResponse } from "lib/contracts/models/ProgrammingConsolidatedResponse";
import { Flex } from "shared/components/Flex/Flex";
import { Card, Collapse, Icon } from "@blueprintjs/core";
import { Box, Theme, makeStyles } from "@material-ui/core";
import ContractCollapse from "../ContractCollapse/ContractCollapse";

const useStyles = makeStyles((theme: Theme) => ({
  clickElement: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: theme.palette.detail.light,
    paddingLeft: "1em",
    transition: "color .5s",
    color: theme.palette.primary.main,
    userSelect: "none",
    cursor: "pointer",
    fontWeight: "bold",
  },
}));

const ContractorCollapse: FC<{ contractor: ContractorResponse }> = ({
  contractor,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(true);
  const handleClick = () => setIsOpen(!isOpen);

  return (
    <Flex>
      <Card style={{ padding: 0 }}>
        <Box onClick={handleClick} className={classes.clickElement} p={1}>
          <Flex alignItems="center" container>
            <Icon icon={isOpen ? "chevron-up" : "chevron-down"} />
            <Box paddingLeft={1}>{contractor.name}</Box>
          </Flex>
        </Box>
      </Card>
      <Collapse isOpen={isOpen}>
        {contractor.contracts && contractor.contracts.length ? (
          <Flex>
            {contractor.contracts.map((contract) => (
              <ContractCollapse contract={contract} />
            ))}
          </Flex>
        ) : null}
      </Collapse>
    </Flex>
  );
};

export default ContractorCollapse;
