import { useCallback, useEffect, useState } from "react";
import { TaskReason } from "../models/TaskReason";
import { TaskReasonService } from "../services/TaskReasonService";

type HookType = (onError?: () => void) => [TaskReason[], () => Promise<void>];

const useTaskReasons: HookType = (onError) => {
  const [taskReasons, setTaskReasons] = useState<TaskReason[]>([]);

  const fetch = useCallback(async () => {
    try {
      const service = new TaskReasonService();
      const { data } = await service.getReasons();
      setTaskReasons(data?.reasons);
    } catch (error) {
      alert(error);
      if (onError) onError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return [taskReasons, fetch];
};

export default useTaskReasons;
