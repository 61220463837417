import React, { ChangeEventHandler, FC } from "react";
import { Box, makeStyles } from "@material-ui/core";
import InputSearch from "shared/components/InputSearch/InputSearch";

const useStyles = makeStyles({
  root: {},
});

interface IContractorSearch {
  handleChangeSearch: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
}
const ContractorSearch: FC<IContractorSearch> = ({ handleChangeSearch }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <InputSearch onChange={handleChangeSearch} maxWidth="100%" />
    </Box>
  );
};

export default ContractorSearch;
