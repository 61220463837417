import { Divider, H4, H5 } from "@blueprintjs/core";
import { Box } from "@material-ui/core";
import AppList from "components/List/List";
import React, { FC } from "react";
import Summary from "components/Summary/Summary";
import { ContractResponse } from "lib/contracts/models/ConsolidatedEvaluationResponse";
import { ResolveVirtualWeek } from "lib/projects/utils/ResolveVirtualWeek";
import useTowerWeek from "./hooks/useTowerWeek";
import useContractorContractQualification from "./components/ContractorContractQualification/useContractorContractQualification";
import ContractorContractDialog from "./components/ContractorContractDialog/ContractorContractDialog";

interface Props {
  towerId: string;
  week: number;
}

const List = AppList<ContractResponse>();

const TowerWeek: FC<Props> = ({ towerId, week }) => {
  const { tower, contractor, contract, onSelectContract, onDialogClose } =
    useTowerWeek({
      towerId,
      week,
    });
  const { elementChildComponent, stylesProps } =
    useContractorContractQualification();

  return (
    <>
      {contract ? (
        <ContractorContractDialog contract={contract} onClose={onDialogClose} />
      ) : null}
      <Summary>
        <Box>
          <Box>
            <H4>{tower?.name}</H4>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box>Semana</Box>
            <Box>
              <b>{week + ResolveVirtualWeek(tower?.virtualWeek)}</b>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box>Cumplimiento</Box>
            <Box>
              <b>{parseFloat(`${contractor?.compliance || 0}`).toFixed(1)}%</b>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box>Calificación</Box>
            <Box>
              <b>
                {parseFloat(`${contractor?.qualification || 0}`).toFixed(1)}
              </b>
            </Box>
          </Box>
        </Box>
      </Summary>
      <Box paddingTop={2} justifyContent="center" display="flex">
        <H5>Actividades</H5>
      </Box>
      <Divider />
      <Box flexGrow={1} display="flex">
        <Box flexBasis="32%" />
        <Box flexGrow={1} style={{ textAlign: "center" }}>
          Cumplimiento
        </Box>
        <Box flexGrow={1} style={{ textAlign: "center" }}>
          Calificacion
        </Box>
      </Box>
      {contractor?.contracts?.length ? (
        <List
          key={contractor.id}
          elementChildComponent={elementChildComponent}
          elements={contractor.contracts}
          onSelectElement={onSelectContract}
          elementComponentStyles={stylesProps}
        />
      ) : null}
    </>
  );
};

export default TowerWeek;
