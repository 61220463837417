import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router";

import { Contractor } from "lib/contractors/models/Contractor";
import { ContractEval } from "lib/contracts/models/ContractEval";
import { RowEvaluation } from "lib/contracts/models/RowEvaluation";
import { Tower } from "lib/projects/models/Tower";
import { ContractService } from "lib/contracts/services/ContractService";

export interface UseEvalueateContractorParams {
  a: string;
}

export type openEvaluationContractorModal = (row: RowEvaluation) => void;
export type closeEvaluationContractorModal = () => void;

export interface UseEvalueateContractorResult {
  contractor?: Contractor;
  contracts: ContractEval[];
  tower?: Tower;
  isOpenModal: boolean;
  openModal: openEvaluationContractorModal;
  closeModal: closeEvaluationContractorModal;
  evaluation?: RowEvaluation;
  fetch: () => Promise<void>;
}

type HookType = (
  param?: UseEvalueateContractorParams
) => UseEvalueateContractorResult;

const useEvalueateContractor: HookType = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { contractorId, towerId } = useParams<{
    towerId: string;
    contractorId: string;
  }>();

  const [contractor, setContractor] = useState<Contractor>();
  const [contracts, setContracts] = useState<ContractEval[]>([]);
  const [tower, setTower] = useState<Tower>();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [evaluation, setEvaluation] = useState<RowEvaluation>();

  const fetch = useCallback(async () => {
    try {
      setContracts([]);
      const service = new ContractService();
      const { data } = await service.getContractsByContractorAndTower(
        contractorId,
        towerId
      );
      if (data.contracts.length) {
        setContractor(data.contractor);
        setContracts(data.contracts);
        setTower(data.tower);
      } else {
        history.push(path.replace("/:towerId/:contractorId", ""));
      }
    } catch (error) {
      alert(error);
    }
  }, [contractorId, towerId, setContractor, history, path]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const openModal: openEvaluationContractorModal = (row) => {
    setIsOpenModal(true);
    setEvaluation(row);
  };

  const closeModal: closeEvaluationContractorModal = () => {
    setIsOpenModal(false);
    setEvaluation(undefined);
  };

  return {
    contractor,
    contracts,
    tower,
    openModal,
    isOpenModal,
    evaluation,
    closeModal,
    fetch,
  };
};

export default useEvalueateContractor;
