import React, { FC } from "react";
import { Box, makeStyles } from "@material-ui/core";
import {
  Button,
  Card,
  ControlGroup,
  FormGroup,
  InputGroup,
} from "@blueprintjs/core";
import BoxTitle from "shared/components/BoxTitle/BoxTitle";
import useTaskReasonForm from "../customHooks/useTaskReasonForm";
import ReasonItem from "./ReasonItem";
import { usePACConfigContext } from "../context/PACConfigContext";

const useStyles = makeStyles({
  root: {},
});

const Reason: FC = () => {
  const classes = useStyles();
  const { taskReasons, onCreateReason, removeReason, asyncRefresh } =
    usePACConfigContext();
  const { data, errors, handleChange, handleSubmit, formLoading } =
    useTaskReasonForm(onCreateReason);
  return (
    <Box className={classes.root}>
      <BoxTitle>Motivos Evaluación</BoxTitle>
      <Card>
        <BoxTitle>Nuevo motivo</BoxTitle>
        <form onSubmit={handleSubmit}>
          <Box display="flex" alignItems="center">
            <ControlGroup>
              <InputGroup
                id="reason-input"
                placeholder="Nuevo motivo"
                value={data.name}
                onChange={handleChange("name")}
                intent={errors.name ? "danger" : "none"}
              />
              <Button
                icon="plus"
                type="submit"
                loading={formLoading}
                intent={errors.name ? "danger" : "none"}
                outlined
              >
                Agregar
              </Button>
            </ControlGroup>
            <FormGroup
              helperText={!!errors?.name && errors?.name}
              labelFor="reason-input"
              intent={errors.name ? "danger" : "none"}
            />
          </Box>
        </form>
      </Card>
      <Box marginY={1} />
      <Card>
        <Box display="flex" alignItems="baseline">
          <Box flexBasis="1" flexGrow="1" width="60%">
            {taskReasons.map((reason) => (
              <ReasonItem
                reason={reason}
                onConfirm={() => removeReason(reason)}
                onUpdateReason={asyncRefresh}
              />
            ))}
          </Box>
          <Box flexBasis="1" flexGrow="1" width="40%" />
        </Box>
      </Card>
    </Box>
  );
};

export default Reason;
