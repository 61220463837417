import React, { FC } from "react";
import AppRoute from "config/router/models/AppRoute";
import {
  Redirect,
  Route,
  RouteComponentProps,
  useLocation,
} from "react-router-dom";
import CustomSwitch from "shared/components/CustomSwitch/CustomSwitch";

const SubRoutePage: FC<RouteComponentProps & AppRoute> = ({
  name,
  routes,
  path,
}) => {
  const location = useLocation();
  return (
    <CustomSwitch>
      {Array.isArray(routes) && routes.length ? (
        <>
          {routes?.map((route) => {
            return (
              <Route
                key={`${name}/${route.name}`}
                path={`${path}${route.path}`}
                exact={!!route.exac}
                render={(props) => <route.component {...props} {...route} />}
              />
            );
          })}
          <Redirect
            exact
            from={path}
            to={
              location.pathname !== path
                ? location.pathname
                : `${path}${
                    routes.find((route) => route.initial)?.path ||
                    routes[0].path
                  }`
            }
          />
        </>
      ) : null}
    </CustomSwitch>
  );
};

export default SubRoutePage;
