import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {},
  title: {
    fontSize: "1.1em",
    fontWeight: "bold",
  },
  box: {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    "& > *": {
      margin: 0,
      marginTop: 2,
      marginBottom: 1,
    },
  },
  boxSizing: {
    boxSizing: "border-box",
  },
});

export default useStyles;
