import { AxiosResponse } from "axios";
import { HttpService } from "shared/services/HttpService";
import { Project, ProjectWithTowers } from "../models/Project";
import { Tower } from "../models/Tower";

export class ProjectService extends HttpService {
  constructor() {
    super("/projects");
  }

  getAllWithTowers = (): Promise<
    AxiosResponse<{ projects: ProjectWithTowers[] }>
  > => this.httpClient.get("/with-towers");

  getAll = (): Promise<AxiosResponse<{ projects: Project[] }>> =>
    this.httpClient.get("/");

  getById = (id: string): Promise<AxiosResponse<{ project: Project }>> =>
    this.httpClient.get(`/${id}`);

  create = (
    project: Partial<Project> & { towers: Partial<Tower>[] }
  ): Promise<AxiosResponse<{ project: Project }>> =>
    this.httpClient.post("/", project);

  update = (
    id: string,
    project: Partial<Project> & { towers: Partial<Tower>[] }
  ): Promise<AxiosResponse<{ updated: boolean }>> =>
    this.httpClient.put(`/${id}`, project);
}
