import { makeStyles, Theme } from "@material-ui/core";

const ProgramContractHeaderStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.highlighter.main,
  },
  dark: {
    color: theme.palette.highlighter.dark,
  },
  column: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      marginBottom: "10px",
    },
  },
  table: {
    "& table": {
      borderSpacing: 0,
      width: "100%",
    },
    "& thead": {
      background: theme.palette.detail.light,
      "& th": {
        paddingTop: 4,
        paddingBottom: 4,
      },
    },
    "& tbody tr": {
      cursor: "pointer",
      "&:hover": {
        background: theme.palette.activeHighlighter.main,
      },
      "& td": {
        padding: "15px 10px",
        textAlign: "center",
      },
    },
  },
}));

export default ProgramContractHeaderStyles;
