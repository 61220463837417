import React, { useCallback, useEffect, useState } from "react";
import { Table } from "@devexpress/dx-react-grid-material-ui";
import usePermision from "shared/customHooks/usePermision";
import ActivityContractorListCellActions, {
  ActivityContractorListCellActionsProps,
} from "../ActivityContractorListCellActions/ActivityContractorListCellActions";
import ActivityContractorListCellDate, {
  ActivityContractorListCellDateProps,
} from "../ActivityContractorListCellDate/ActivityContractorListCellDate";
import ActivityContractorListCellContractor, {
  ActivityContractorListCellContractorProps,
} from "../ActivityContractorListCellContractor/ActivityContractorListCellContractor";

type Props = ActivityContractorListCellContractorProps &
  ActivityContractorListCellDateProps &
  ActivityContractorListCellActionsProps;

const useActivityContractorListCell = ({
  handleClickAssignContractor,
  onDateSelect,
  handleRemoveClick,
}: Props) => {
  const { haveAccess } = usePermision();

  const [permissions, setPermissions] = useState({
    canDelete: false,
    canEditDate: false,
    canEditContractor: false,
  });

  useEffect(() => {
    const canDelete = haveAccess("pac.assignActivities", "canDelete");
    const canEditDate = haveAccess("pac.assignActivitiesDates", "canEdit");
    const canEditContractor = haveAccess(
      "pac.assignActivitiesContractor",
      "canEdit"
    );
    setPermissions({
      canDelete,
      canEditDate,
      canEditContractor,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Cell = useCallback(
    (props: Table.DataCellProps) => {
      const { column } = props;
      const { name } = column;

      switch (name) {
        case "contractor":
          return (
            <ActivityContractorListCellContractor
              canEdit={permissions.canEditContractor}
              handleClickAssignContractor={handleClickAssignContractor}
              {...props}
            />
          );
        case "startDate":
          return (
            <ActivityContractorListCellDate
              {...props}
              isStartDate
              canEdit={permissions.canEditDate}
              onDateSelect={onDateSelect}
            />
          );
        case "endDate":
          return (
            <ActivityContractorListCellDate
              {...props}
              onDateSelect={onDateSelect}
            />
          );
        case "":
          return (
            <ActivityContractorListCellActions
              {...props}
              canDelete={permissions.canDelete}
              handleRemoveClick={handleRemoveClick}
            />
          );

        default:
          return <Table.Cell {...props} />;
      }
    },
    [handleClickAssignContractor, onDateSelect, handleRemoveClick, permissions]
  );

  return {
    Cell,
  };
};

export default useActivityContractorListCell;
