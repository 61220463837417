import useTowerOfContractor from "modules/Contractor/hooks/useTowerOfContractor";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { TowerWeeksService } from "../services/TowerWeeksService";
import { TowerWeek } from "../types/TowerWeek";

interface Props {
  towerId: string;
}

const useTowerWeekList = ({ towerId }: Props) => {
  const tower = useTowerOfContractor(towerId);
  const { contractor } = useSelector((state: RootState) => state.auth);
  const [weeks, setWeeks] = useState<TowerWeek[]>([]);
  const [compliance, setCompliance] = useState<number>(0);
  const [qualification, setQualification] = useState<number>(0);

  const fetch = useCallback(async () => {
    if (contractor?.id) {
      try {
        const service = new TowerWeeksService();
        const { data } = await service.getTowerWeeks(contractor.id, towerId);
        setWeeks(data.weeks);
        setQualification(data.qualification || 0);
        setCompliance(data.compliance || 0);
      } catch (error) {
        alert(error);
      }
    }
  }, [towerId, contractor]);

  useEffect(() => {
    fetch();
  }, [contractor, fetch]);

  return {
    weeks,
    tower,
    qualification,
    compliance,
  };
};

export default useTowerWeekList;
