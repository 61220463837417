import React, { FC } from "react";
import { Box, makeStyles, Theme } from "@material-ui/core";
import { Popover2 } from "@blueprintjs/popover2";
import { Card } from "@blueprintjs/core";
import useProgramContract from "./hooks/useProgramContract";
import ProgramContractItem from "./componets/ProgramContractItem/ProgramContractItem";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
  },
  popper: {
    position: "absolute",
    right: 10,
    top: -50,
    "& .button": {
      zIndex: 2000,
      color: theme.palette.highlighter.dark,
      cursor: "pointer",
      padding: 4,
      display: "block",
    },
  },
  element: {
    height: 12,
    width: 12,
    background: "red",
    border: "1px solid gray",
    borderRadius: "30%",
  },
}));

const ProgramContract: FC = () => {
  const classes = useStyles();
  const { contracts, units, haveUser } = useProgramContract();
  return (
    <Box className={classes.root}>
      <div className={classes.popper}>
        <Popover2
          usePortal
          autoFocus
          content={
            <Card>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div
                        className={classes.element}
                        style={{
                          background: "#bdc3c7",
                        }}
                      />
                    </td>
                    <td>No disponible</td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        className={classes.element}
                        style={{
                          background: "#2980b9",
                        }}
                      />
                    </td>
                    <td>Pendiente programación</td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        className={classes.element}
                        style={{
                          background: "#f39c12",
                        }}
                      />
                    </td>
                    <td>Programado</td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        className={classes.element}
                        style={{
                          background: "#9b59b6",
                        }}
                      />
                    </td>
                    <td>Aprobación pendiente</td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        className={classes.element}
                        style={{
                          background: "#2ecc71",
                        }}
                      />
                    </td>
                    <td>Programación aprobada</td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        className={classes.element}
                        style={{
                          background: "#e74c3c",
                        }}
                      />
                    </td>
                    <td>Programación rechazada</td>
                  </tr>
                </tbody>
              </table>
            </Card>
          }
        >
          <span className="button">¿Estados de actividad?</span>
        </Popover2>
      </div>
      {contracts.map((contract) => {
        return (
          <Box key={`contract_${contract.id}`} marginY={2}>
            <ProgramContractItem
              contract={contract}
              units={units}
              haveUser={haveUser}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default ProgramContract;
