import React, { FC } from "react";
import {
  Table,
  TableFixedColumns,
} from "@devexpress/dx-react-grid-material-ui";
import { Column } from "@devexpress/dx-react-grid";
import NumberFormatString from "components/NumberFormatInputMaterialUI/NumberFormatString";
// import { InputBase } from "@material-ui/core";
// import useProgramFixedCellStyles from "./styles";
// import useProgramFixedCell from "./useProgramFixedCell";

const ProgramFixedCell: FC<TableFixedColumns.CellProps> = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const { tableRow, tableColumn } = props;
  const { column } = tableColumn;
  const { row } = tableRow;
  let value: string | React.ReactNode = "";
  let boxShadow = "none";

  if (column && typeof tableRow.rowId !== "undefined" && tableRow.row) {
    const rowValue = row[column.name];
    if (column.name === "unit") value = rowValue.name;
    else if (column.name === "total") {
      boxShadow = "-1px 0 red inset";
      value = (
        <div>
          <NumberFormatString value={rowValue} />
        </div>
      );
    } else value = row[column.name];
  } else {
    value = column?.title || "";
  }
  if (column?.name === "total") {
    boxShadow = "-2px 0 darkgray inset";
  }
  return (
    <TableFixedColumns.Cell
      {...props}
      style={{ boxShadow }}
      component={(childProps) => {
        return (
          <Table.Cell
            {...childProps}
            value={value}
            row={tableRow.row}
            column={column as Column}
          />
        );
      }}
    />
  );
};

export default ProgramFixedCell;
