import React, { FC } from "react";
import { Table } from "@devexpress/dx-react-grid-material-ui";
import moment from "moment";
import { Button, Icon } from "@blueprintjs/core";
import { Box } from "@material-ui/core";
import { ActivityDate } from "lib/projects/models/ActivityDate";
import { TaskRow } from "../../models/ActivityContractorListRow";

export interface ActivityContractorListCellDateProps {
  onDateSelect: (activityDate: ActivityDate) => void;
  isStartDate?: boolean;
  canEdit?: boolean;
}

const ActivityContractorListCellDate: FC<
  Table.DataCellProps & ActivityContractorListCellDateProps
> = (props) => {
  const { value, isStartDate, row, canEdit, onDateSelect } = props;
  const { startDate, endDate, id } = row as TaskRow;

  const onClick = () => {
    const activityDate: ActivityDate = {
      startDate: moment(startDate).toDate(),
      endDate: moment(endDate).toDate(),
      id,
    };
    onDateSelect(activityDate);
  };

  return (
    <Table.Cell {...props}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          {isStartDate && canEdit ? (
            <Button icon="edit" minimal onClick={onClick} />
          ) : (
            <Box />
          )}
          <span
            style={{
              flexGrow: 1,
              background: "",
              color: "#2980b9",
              paddingLeft: "4px",
              borderRadius: "4px",
            }}
          >
            {moment(value).format("DD MMM YYYY")}
          </span>
        </Box>
        {isStartDate ? (
          <Icon icon="arrow-right" style={{ color: "#2980b9" }} />
        ) : (
          <Box />
        )}
      </Box>
    </Table.Cell>
  );
};

export default ActivityContractorListCellDate;
