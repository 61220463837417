import { makeStyles } from "@material-ui/core";

const ProgramContractTableCellHeaderStyles = makeStyles(() => ({
  pass: {
    background: `#ecf0f1 !important`,
    color: "#27ae60",
  },
  current: {
    background: "white !important",
    color: "#2980b9",
  },
  next: {
    background: `#ecf0f1 !important`,
    color: "#bdc3c7",
  },
  eval: {
    // background: `${theme.palette.highlighter.main} !important`,
    background: `#f39c12 !important`,
    color: "white",
    "& *": {
      fill: "white !important",
    },
  },
  send: {
    background: `#9b59b6 !important`,
    color: "white",
  },
  passed: {
    background: `#2ecc71 !important`,
    color: "white",
  },
  reject: {
    background: `#FFFFFF !important`,
    color: "#e74c3c",
    fontWeight: "bold",
    boxShadow: "1px 1px #e74c3c inset, -1px -1px #e74c3c inset",
    "& *": {
      fill: "#e74c3c !important",
    },
  },
}));

export default ProgramContractTableCellHeaderStyles;
