import React, { FC, useEffect } from "react";
import { Switch, useHistory } from "react-router";

const NotFoundPage: FC = () => {
  const history = useHistory();

  useEffect(() => {
    history.push("/notfound");
  }, [history]);

  return null;
};

const CustomSwitch: FC = ({ children }) => (
  <Switch>
    {children}
    <NotFoundPage />
  </Switch>
);
export default CustomSwitch;
