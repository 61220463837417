import { AxiosResponse } from "axios";
import { HttpService } from "shared/services/HttpService";
import { Activity } from "../models/Activity";

export class ActivityService extends HttpService {
  constructor() {
    super("/activities");
  }

  all = (towerId: string): Promise<AxiosResponse<{ tasks: Activity[] }>> =>
    this.httpClient.get(`/${towerId}`);

  createBulk = (
    towerId: string,
    tasks: FormData
  ): Promise<AxiosResponse<{ created: boolean }>> =>
    this.httpClient.post(`/${towerId}/bulk`, tasks, {
      headers: { "Content-Type": "multipart/form-data" },
    });
}
