import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Project } from "lib/projects/models/Project";
import { Tower } from "lib/projects/models/Tower";

interface PACState {
  error: string;
  isLoading: boolean;
  initialized: boolean;
  listProjects: Project[];
  listTowers: Tower[];
  project: Project | null;
  tower: Tower | null;
}

const initialState: PACState = {
  error: "",
  initialized: false,
  isLoading: false,
  project: null,
  tower: null,
  listProjects: [],
  listTowers: [],
};

const PACSlice = createSlice({
  name: "@PAC",
  initialState,
  reducers: {
    setPACProjectList(state, action: PayloadAction<Project[]>) {
      state.listProjects = action.payload;
    },
    setPACTowerList(state, action: PayloadAction<Tower[]>) {
      state.listTowers = action.payload;
    },
    setPACProject(state, action: PayloadAction<Project | null>) {
      state.project = action.payload;
      if (!action.payload) {
        state.listTowers = [];
        state.tower = null;
      }
    },
    setPACTower(state, action: PayloadAction<Tower | null>) {
      state.tower = action.payload;
    },
  },
});

export const {
  setPACProject,
  setPACProjectList,
  setPACTower,
  setPACTowerList,
} = PACSlice.actions;

export const PACReducer = PACSlice.reducer;
