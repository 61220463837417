import React, { FC } from "react";
import TowerWeek from "modules/Contractor/features/TowerWeek/TowerWeek";
import { useParams } from "react-router";

const ContractorTowerWeekContainer: FC = () => {
  const { towerId, week } = useParams<{ towerId: string; week: string }>();
  return (
    <>
      <TowerWeek towerId={towerId} week={+week} />
    </>
  );
};

export default ContractorTowerWeekContainer;
