import React, { FC } from "react";
import { Button, Classes, Dialog } from "@blueprintjs/core";
import { Contractor } from "lib/contractors/models/Contractor";
import { Box } from "@material-ui/core";
import useContractorList from "lib/contractors/customHooks/useContractorList";
import useSelectContractor from "./hooks/useSelectContractor";
import ContractorList from "../ContractorList";
import ContractorSearch from "../ContractorSearch";

const SelectContractor: FC<{
  onSelectContractor: (contractor: Contractor) => void;
  contractorName?: string;
}> = ({ onSelectContractor, contractorName }) => {
  const {
    open,
    close,
    isOpen,
    contractors: OriginalContractors,
  } = useSelectContractor();
  const { handleChangeSearch, contractors, reset } =
    useContractorList(OriginalContractors);
  return (
    <>
      <Button
        icon={contractorName ? "third-party" : "plus"}
        fill
        large
        onClick={open}
      >
        {contractorName || "Seleccionar contratista"}
      </Button>
      <Dialog
        isOpen={isOpen}
        isCloseButtonShown
        onClose={() => {
          close();
          reset();
        }}
      >
        <div className={Classes.DIALOG_HEADER}>Seleccionar Contratista</div>
        <Box className={Classes.DIALOG_BODY}>
          <ContractorSearch handleChangeSearch={handleChangeSearch} />
          <ContractorList
            contractors={contractors}
            onClick={(contractor) => {
              onSelectContractor(contractor);
              close();
              reset();
            }}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default SelectContractor;
