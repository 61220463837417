import React, { FC } from "react";
import AppList from "components/List/List";
import { Tower } from "lib/projects/models/Tower";
import { Box } from "@material-ui/core";
import { Card, NonIdealState } from "@blueprintjs/core";
import useTowerList from "./hooks/useTowerList";

interface Props {
  projectId: string;
  onSelectTower: (tower: Tower) => void;
}

const List = AppList<Tower>();

const TowerList: FC<Props> = ({ projectId, onSelectTower }) => {
  const { towers } = useTowerList({ projectId });
  return (
    <>
      {towers.length ? (
        <List
          elementChildComponent={(tower) => (
            <>
              <Box>{tower.name}</Box>
            </>
          )}
          elements={towers}
          onSelectElement={onSelectTower}
        />
      ) : (
        <Card>
          <NonIdealState
            title="Aún no hay calificaciones"
            icon="warning-sign"
          />
        </Card>
      )}
    </>
  );
};
export default TowerList;
