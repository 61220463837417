import React, { FC } from "react";
import { Box, makeStyles, Theme, Typography } from "@material-ui/core";
import moment from "moment";
import {
  EvalueateContractorProvider,
  useEvalueateContractorContext,
} from "./context/EvalueateContractorContext";
import EvaluateContractorTableContainer from "./containers/EvaluateContractorTable/EvaluateContractorTable";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.highlighter.main,
    padding: "18px 36px",
    borderRadius: 10,
  },
  dark: {
    color: theme.palette.highlighter.dark,
  },
}));

const EvalueateContractor: FC = () => {
  const classes = useStyles();
  const { contractor, tower, contracts, fetch } =
    useEvalueateContractorContext();
  return (
    <Box>
      <Box mt={2} mb={3} display="flex" className={classes.root}>
        <Box flexBasis="1" flexGrow="1">
          <Box>
            <Typography variant="h2">Contratista</Typography>
            <Typography>{contractor?.name}</Typography>
          </Box>
          {tower?.week && tower.week.length ? (
            <Box mt={2}>
              <Typography variant="h2">Tiempo a evaluar</Typography>
              <Typography className={classes.dark}>
                {moment(`${tower?.week[0]}`.replace("T00", "T10")).format(
                  "DD MMM"
                )}{" "}
                -{moment(tower?.week[1]).format("DD MMM")} (Semana{" "}
                {(tower.currentWeek || 0) + tower.virtualWeek})
              </Typography>
            </Box>
          ) : null}
        </Box>
        <Box flexBasis="1" flexGrow="1">
          <Box>
            <Typography variant="h2">Proyecto</Typography>
            <Typography>{tower?.project?.name}</Typography>
          </Box>
          {tower?.startDate ? (
            <Box mt={2}>
              <Typography variant="h2">Tiempo de Construcción</Typography>
              <Typography>
                {moment(tower?.startDate).format("DD MMM YYYY")} -{" "}
                {moment(tower?.endDate).format("DD MMM YYYY")}
              </Typography>
            </Box>
          ) : null}
        </Box>
        <Box flexBasis="1" flexGrow="1">
          <Box>
            <Typography variant="h2">Etapa</Typography>
            <Typography>{tower?.name}</Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        {contracts.map((contract) => (
          <EvaluateContractorTableContainer
            key={contract.id}
            contract={contract}
            tower={tower}
            fetch={fetch}
          />
        ))}
      </Box>
    </Box>
  );
};

const EvalueateContractorContainer: FC = () => {
  return (
    <EvalueateContractorProvider>
      <EvalueateContractor />
    </EvalueateContractorProvider>
  );
};

export default EvalueateContractorContainer;
