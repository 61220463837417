import React, { FC } from "react";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { Classes } from "@blueprintjs/core";
import clsx from "clsx";
import { DatePicker } from "@blueprintjs/datetime";
import MomentLocaleUtils from "react-day-picker/moment";
import { Tower } from "lib/projects/models/Tower";
import useTowerForm from "pages/Configuration/containers/Projects/customHooks/useTowerForm";
import moment from "moment";
import TowerFormStyles from "./styles";

interface Props {
  tower: Partial<Tower> | undefined;
  back: () => void;
  handleSave: (tower: Partial<Tower>, updated?: boolean) => void;
}

const TowerForm: FC<Props> = ({ tower, back, handleSave }) => {
  const classes = TowerFormStyles();
  const {
    data,
    errors,
    handleChange,
    handleSubmit,
    handleChangeValue,
    handleChangeStartDate,
  } = useTowerForm({
    tower,
    handleSave,
  });

  const setClosingDayClass = (index: number): string => {
    return clsx(
      classes.closingDay,
      data?.closingDay && +data.closingDay === index ? classes.active : ""
    );
  };

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <IconButton onClick={back}>
          <ArrowBack />
        </IconButton>
        {tower?.name ? "Actualizar etapa" : "Agregar etapa"}
        <form id="tower_form" noValidate onSubmit={handleSubmit} />
        <div className={classes.formItem}>
          <div className={classes.label}>
            <Typography>Nombre</Typography>
          </div>
          <TextField
            value={data.name}
            fullWidth
            required
            label="Ingresa"
            name="name"
            autoFocus
            error={!!errors.name}
            helperText={errors.name}
            onChange={handleChange("name")}
          />
        </div>
        <div className={classes.formItem}>
          <div className={classes.label}>
            <Typography>Código ERP</Typography>
          </div>
          <TextField
            value={data.erp}
            fullWidth
            required
            label="Ingresa"
            name="erp"
            error={!!errors.erp}
            helperText={errors.erp}
            onChange={handleChange("erp")}
          />
        </div>
        <div className={classes.formItem}>
          <div className={classes.label}>
            <Typography>Semana de inicio</Typography>
          </div>
          <TextField
            label="Semana de inicio"
            fullWidth
            required
            name=""
            value={data?.virtualWeek}
            type="number"
            error={!!errors.virtualWeek}
            onChange={handleChange("virtualWeek")}
            InputLabelProps={{
              shrink: true,
            }}
            helperText={errors.virtualWeek}
            InputProps={{
              inputProps: {
                min: 0,
                max: 2000,
              },
            }}
          />
        </div>
        <div className={classes.formItem}>
          <div className={classes.label}>
            <Typography>Fecha inicio de etapa</Typography>
          </div>
          <Box style={{ position: "relative" }} flexGrow={1}>
            {tower?.haveTasksInProgess ? (
              <Box
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  background: "rgba(0,0,0,.2)",
                  zIndex: 2,
                  borderRadius: 5,
                }}
              />
            ) : (
              ""
            )}
            <Box style={{ textAlign: "center" }}>
              {tower?.haveTasksInProgess ? "Etapa en curso" : ""}
            </Box>
            <DatePicker
              className={classes.dataPicker}
              defaultValue={undefined}
              value={moment(data?.startDate).toDate()}
              locale="es"
              onChange={handleChangeStartDate}
              localeUtils={MomentLocaleUtils}
            />
            {errors.startDate ? (
              <span style={{ color: "red" }}>{errors.startDate}</span>
            ) : (
              ""
            )}
          </Box>
        </div>
        <div className={classes.formItem}>
          <div className={classes.label}>
            <Typography>Día de corte PAC</Typography>
          </div>
          <Box flexBasis="1" flexGrow="1">
            <Box display="flex" flexWrap="wrap">
              <Box
                className={setClosingDayClass(1)}
                onClick={() => handleChangeValue("closingDay", "1")}
              >
                lun.
              </Box>
              <Box
                className={setClosingDayClass(2)}
                onClick={() => handleChangeValue("closingDay", "2")}
              >
                mart.
              </Box>
              <Box
                className={setClosingDayClass(3)}
                onClick={() => handleChangeValue("closingDay", "3")}
              >
                miérc.
              </Box>
              <Box
                className={setClosingDayClass(4)}
                onClick={() => handleChangeValue("closingDay", "4")}
              >
                juev.
              </Box>
              <Box
                className={setClosingDayClass(5)}
                onClick={() => handleChangeValue("closingDay", "5")}
              >
                vier.
              </Box>
              <Box
                className={setClosingDayClass(6)}
                onClick={() => handleChangeValue("closingDay", "6")}
              >
                sáb.
              </Box>
              <Box
                className={setClosingDayClass(7)}
                onClick={() => handleChangeValue("closingDay", "7")}
              >
                dom.
              </Box>
            </Box>
            {errors.closingDay ? (
              <span style={{ color: "red" }}>{errors.closingDay}</span>
            ) : (
              ""
            )}
          </Box>
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          form="tower_form"
        >
          {tower?.name ? "Actualizar" : "Agregar"}
        </Button>
      </div>
    </>
  );
};

export default TowerForm;
