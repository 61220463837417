import { useParams } from "react-router";
import { useCallback, useEffect, useState } from "react";
import { ContractService } from "lib/contracts/services/ContractService";
import { Contract } from "lib/contracts/models/Contract";
import { useSelector } from "react-redux";
import { RootState } from "store";

const useListTaskEvaluations = () => {
  const contractor = useSelector((state: RootState) => state.auth.contractor);
  const { contractId } = useParams<{ contractId: string }>();
  const [data, setData] = useState<{
    contract: Contract;
    rejectedWeek?: number;
    approvalWeek?: number;
    lastPassedWeek?: number;
    towerWeek?: number;
    virtualWeek?: number;
    week: {
      startDate: string;
      endDate: string;
    };
  }>();

  const [isOpenApprove, setIsOpenApprove] = useState(false);
  const [isOpenReject, setIsOpenReject] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetch = useCallback(async () => {
    if (contractor?.id && contractId) {
      try {
        const service = new ContractService();
        const { data: response } = await service.getContractsApproval(
          contractId
        );
        setData(response);
      } catch (error) {
        alert(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractor]);

  useEffect(() => {
    if (contractor?.id) {
      fetch();
      setIsLoading(false);
    }
  }, [contractor, fetch]);

  const openApprove = () => setIsOpenApprove(true);
  const closeApprove = () => setIsOpenApprove(false);

  const openReject = () => setIsOpenReject(true);
  const closeReject = () => setIsOpenReject(false);

  return {
    contract: data?.contract,
    rejectedWeek: data?.rejectedWeek,
    approvalWeek: data?.approvalWeek,
    lastPassedWeek: data?.lastPassedWeek,
    towerWeek: data?.towerWeek,
    virtualWeek: data?.virtualWeek,
    week: data?.week,
    isOpenApprove,
    isOpenReject,
    isLoading,
    openApprove,
    closeApprove,
    openReject,
    closeReject,
    fetch,
  };
};

export default useListTaskEvaluations;
