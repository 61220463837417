import React, { FC } from "react";
import { IconButton, Menu, MenuItem, Typography } from "@material-ui/core";
import MoreVert from "@material-ui/icons/MoreVert";
import { Edit } from "@material-ui/icons";

import ListItemElement from "shared/components/ListItem/ListItemElement";
import { User } from "lib/users/models/User";
import useAuth from "features/Authentication/core/hooks/useAuth";

const PersonItem: FC<
  User & {
    onClickEdit: (person: User) => void;
    onClickDelete: (id: string) => Promise<void>;
    onClickChangeRole: (person: User) => void;
  }
> = ({ onClickEdit, onClickDelete, onClickChangeRole, ...person }) => {
  const { user } = useAuth();
  const { name, lastName, role } = person;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleClickActionClose = (
    action: "edit" | "changeRole" | "eliminate"
  ): (() => void) => {
    switch (action) {
      case "edit":
        return (): void => {
          onClickEdit(person);
          handleClose();
        };
      case "changeRole":
        return (): void => {
          onClickChangeRole(person);
          handleClose();
        };
      case "eliminate":
        return (): void => {
          onClickDelete(person.id);
          handleClose();
        };
      default:
        return handleClose;
    }
  };

  return (
    <ListItemElement title={`${name} ${lastName || ""}`} subTitle={role?.name}>
      {user?.id !== person.id ? (
        <div>
          <IconButton aria-label="MoreVert" onClick={handleClick}>
            <MoreVert />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClickActionClose("edit")}>Editar</MenuItem>
            <MenuItem onClick={handleClickActionClose("eliminate")}>
              <Typography color="secondary">Eliminar perfil</Typography>
            </MenuItem>
          </Menu>
        </div>
      ) : (
        <div>
          <IconButton
            aria-label="MoreVert"
            onClick={handleClickActionClose("edit")}
          >
            <Edit />
          </IconButton>
        </div>
      )}
    </ListItemElement>
  );
};

export default PersonItem;
