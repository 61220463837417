import React, { createContext, FC, useContext } from "react";
import useProgram, { UseProgramResult } from "../customHooks/useProgram";

type ProgramContextType = UseProgramResult;

const ProgramContext = createContext<ProgramContextType | null>(null);

export const useProgramContext = (): ProgramContextType =>
  useContext(ProgramContext) as ProgramContextType;

export const ProgramProvider: FC = ({ children }) => {
  const value = useProgram();

  return (
    <ProgramContext.Provider value={{ ...value }}>
      {children}
    </ProgramContext.Provider>
  );
};
