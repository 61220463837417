import { useCallback } from "react";
import { PlatformConfigService } from "lib/platform/services/PlatformConfig.Service";
import { PlatformConfig } from "lib/platform/models/PlatformConfig";

const usePlatformConfig = () => {
  const getPublicConfig = useCallback(async () => {
    let result: PlatformConfig = {};
    try {
      const service = new PlatformConfigService();
      const { data } = await service.getPublicConfig();
      result = data.config;
    } catch (error) {
      alert(error);
    }
    return result;
  }, []);
  return {
    getPublicConfig,
  };
};

export default usePlatformConfig;
