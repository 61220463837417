import React, { FC } from "react";
import {
  Button,
  Card,
  Divider,
  H3,
  H4,
  NonIdealState,
} from "@blueprintjs/core";
import { Box, Typography } from "@material-ui/core";
import { useHistory, useLocation } from "react-router";
import useConsolidatedEvaluationSelectProject from "../../CORE/hooks/useConsolidatedEvaluationSelectProject";

const ConsolidatedEvaluationSelectProject: FC = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { projects } = useConsolidatedEvaluationSelectProject();
  return (
    <div>
      <Box mt={4}>
        <h1>
          <Typography variant="h1">Proyectos</Typography>
        </h1>
      </Box>
      <Divider />
      <Box display="flex" flexWrap="wrap">
        {projects?.length ? (
          <>
            {projects
              .filter((project) => project.towers.length)
              .map((project) => {
                return (
                  <Box p={1} key={project.id}>
                    <Box>
                      <H3>{project.name}</H3>
                    </Box>
                    <Box paddingX={1} margin={1}>
                      <H4>Etapas</H4>
                      {project.towers.map((tower) => {
                        return (
                          <Box
                            key={tower.id}
                            display="flex"
                            maxWidth={500}
                            paddingY={1}
                          >
                            <Card about="qwdw">
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                              >
                                <Box
                                  marginRight={2}
                                  style={{ fontSize: "1.2em" }}
                                >
                                  {tower.name}
                                </Box>
                                <Box>
                                  <Button
                                    icon="comparison"
                                    intent="primary"
                                    onClick={() => {
                                      history.push(
                                        `${pathname}/${tower.id}/consolidated`
                                      );
                                    }}
                                  >
                                    Consolidado de Evaluación
                                  </Button>
                                </Box>
                              </Box>
                            </Card>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                );
              })}
          </>
        ) : (
          <NonIdealState icon="new-grid-item" title="Sin proyectos" />
        )}
      </Box>
    </div>
  );
};

export default ConsolidatedEvaluationSelectProject;
