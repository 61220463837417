import { Permission } from "lib/users/models/Permission";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import usePermision from "shared/customHooks/usePermision";
import Side, { SideElement } from "../config/Side";

interface SidePermission extends Permission {
  isChild: boolean;
  childs: SidePermission[];
}

const useSidebar = () => {
  const location = useLocation();
  const { permissions, initializedPermissions } = usePermision();
  const [currentSide, setCurrentSide] = useState<SideElement>();
  const [sidePermission, setSidePermissions] = useState<SideElement[]>([]);

  const inizialize = () => {
    if (permissions) {
      const sidePermissions: { [key: string]: SidePermission } = {};
      const permissionsMap = Object.values(permissions).filter(
        (permission) => permission.canView
      );

      permissionsMap
        .filter((permission) => !permission.name.includes("."))
        .forEach((permission) => {
          sidePermissions[permission.name] = {
            ...permission,
            isChild: false,
            childs: [],
          };
        });
      permissionsMap
        .filter((permission) => permission.name.includes("."))
        .forEach((permission) => {
          const nameSplit = permission.name.split(".");
          const parentName = nameSplit[0];
          if (sidePermissions[parentName]) {
            sidePermissions[parentName].childs = sidePermissions[
              parentName
            ].childs.concat({
              ...permission,
              isChild: true,
              name: nameSplit[1],
              childs: [],
            });
          }
        });

      const sidePermissionsArray = Object.values(sidePermissions);
      let sidebarPermission: SideElement[] = [];
      if (sidePermissionsArray.length) {
        sidebarPermission = sidePermissionsArray.reduce((init, permission) => {
          const sideElement = Side.find(
            (side) => side.name === permission.name
          );
          if (sideElement) {
            if (!sideElement.matchs) sideElement.matchs = [sideElement.path];
            let childs: SideElement[] = [];
            if (sideElement.childs?.length && permission.childs.length) {
              const sideChilds = sideElement.childs;
              childs = permission.childs.reduce((childInit, child) => {
                const childElement = sideChilds.find(
                  (sideChild) => sideChild.name === child.name
                );
                if (childElement) {
                  if (
                    sideElement.matchs &&
                    !sideElement.matchs.includes(
                      sideElement.path + childElement.path
                    )
                  )
                    sideElement.matchs = sideElement.matchs.concat(
                      sideElement.path + childElement.path
                    );
                  return childInit.concat(childElement);
                }
                return childInit;
              }, [] as SideElement[]);
            }
            return init.concat({ ...sideElement, childs });
          }
          return init;
        }, [] as SideElement[]);
      }
      setSidePermissions(sidebarPermission);
    }
  };

  useEffect(() => {
    if (initializedPermissions) inizialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializedPermissions]);

  useEffect(() => {
    if (sidePermission.length) {
      const side = sidePermission.find((permission) =>
        permission.matchs?.some((el) => location.pathname.includes(el))
      );
      setCurrentSide(side);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, sidePermission]);

  return {
    permissions: sidePermission,
    currentSide,
  };
};
export default useSidebar;
