import { AxiosResponse } from "axios";
import { User } from "lib/users/models/User";
import { HttpService } from "shared/services/HttpService";
import AuthErrors from "../errors/Auth.Error";
import { UserAuth } from "../models/UserAuth";
import { UserCredentials } from "../models/UserCredentials";

export class AuthService extends HttpService {
  constructor() {
    super("/auth", AuthErrors);
  }

  login(
    credentials: UserCredentials
  ): Promise<AxiosResponse<{ user: UserAuth }>> {
    return this.httpClient.post("/signin", credentials);
  }

  verify(hash: string): Promise<AxiosResponse<{ user: User }>> {
    return this.httpClient.get(`/verify/${hash}`);
  }

  verifyRecovery(hash: string): Promise<AxiosResponse<{ user: User }>> {
    return this.httpClient.get(`/recovery/${hash}`);
  }

  changePassword(
    id: string,
    password: string
  ): Promise<AxiosResponse<{ user: User }>> {
    return this.httpClient.put(`/changepassword/${id}`, { password });
  }

  resetPassword(email: string): Promise<AxiosResponse<{ user: User }>> {
    return this.httpClient.get(`/resetpassword/${email}`);
  }
}
