import { useEffect } from "react";
import useConsolidatedEvaluationAPI from "lib/contracts/hooks/useConsolidatedEvaluationAPI";

const useConsolidatedEvaluation = ({ towerId }: { towerId: string }) => {
  const { response, isFetching, fetch } = useConsolidatedEvaluationAPI({
    towerId,
  });

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    ...response,
    isFetching,
    fetch,
  };
};
export default useConsolidatedEvaluation;
