import useContractors from "lib/contractors/customHooks/useContractors";
import { Contractor } from "lib/contractors/models/Contractor";
import { useState } from "react";

export interface UseContractorParams {
  a: string;
}

export interface UseContractorResult {
  contractors: Contractor[];
  currentContractor?: Contractor;
  selectCurrentContractor: (contractor?: Contractor) => void;
  handleCreateContractor: (contractor: Contractor) => void;
  handleUpdateContractor: (
    contractorId: string,
    contractor: Record<string, unknown>
  ) => void;
  fetchContractos: () => Promise<void>;
}

type HookType = (param?: UseContractorParams) => UseContractorResult;

const useContractor: HookType = () => {
  const [contractors, setContractors, fetchContractos] = useContractors();
  const [currentContractor, setCurrentContractor] = useState<Contractor>();

  const selectCurrentContractor = (contractor?: Contractor): void => {
    setCurrentContractor(contractor);
  };

  const handleCreateContractor = (contractor: Contractor): void => {
    setContractors(contractors.concat(contractor));
    alert({ message: "Contratista creado con éxito", severity: "success" });
  };

  const handleUpdateContractor = (
    contractorId: string,
    contractorUpdated: Record<string, unknown>
  ): void => {
    setContractors(
      contractors.map((contractor) => {
        if (contractor.id === contractorId)
          return {
            ...contractor,
            ...contractorUpdated,
          };
        return contractor;
      })
    );
    alert({
      message: "Contratista actualizado con éxito",
      severity: "success",
    });
  };

  return {
    contractors,
    selectCurrentContractor,
    currentContractor,
    handleCreateContractor,
    handleUpdateContractor,
    fetchContractos,
  };
};

export default useContractor;
