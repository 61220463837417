import React, { FC } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { Button, Card, H1, NonIdealState, Tag } from "@blueprintjs/core";
import { useHistory } from "react-router";
import useListTowersPage from "./hooks/useListTowersPage";

const useStyles = makeStyles({
  root: {},
});

const ListTowersPage: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { contracts, selectContract } = useListTowersPage();

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <Box marginRight={2}>
          <Button
            icon="arrow-left"
            onClick={() => history.push("/contractor/projects")}
          />
        </Box>
        <H1>Actividades</H1>
      </Box>
      {contracts.length ? (
        <Box>
          {contracts.map((contract) => (
            <Box marginY={1} key={contract.id}>
              <Card interactive onClick={() => selectContract(contract)}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Box>Tarea: {contract.activity.name}</Box>
                    <Box>Etapa: {contract.activity.tower?.name}</Box>
                  </Box>
                  <Box>
                    {contract.isRequiredApprove ? (
                      <Tag intent="warning">Se requiere la aprobación</Tag>
                    ) : (
                      <>
                        {contract.isRejected ? (
                          <Tag intent="danger">Rechazado</Tag>
                        ) : (
                          <>
                            {contract.isApprove ? (
                              <Tag intent="success">Aprobado</Tag>
                            ) : null}
                          </>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              </Card>
            </Box>
          ))}
        </Box>
      ) : (
        <Box>
          <Card>
            <NonIdealState icon="warning-sign" title="Sin aprobaciones" />
          </Card>
        </Box>
      )}
    </Box>
  );
};

export default ListTowersPage;
