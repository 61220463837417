import React, { FC } from "react";
import { Contract } from "lib/contracts/models/Contract";
import { TaskUnit } from "lib/contracts/models/TaskUnit";
import ProgramContractTable from "./ProgramContractTable/ProgramContractTable";

interface ProgramContractItemProps {
  contract: Contract;
  units: TaskUnit[];
  haveUser: boolean;
}

const ProgramContractItem: FC<ProgramContractItemProps> = ({
  contract,
  units,
  haveUser,
}) => {
  return (
    <>
      <ProgramContractTable
        contract={contract}
        units={units}
        haveUser={haveUser}
      />
    </>
  );
};

export default ProgramContractItem;
