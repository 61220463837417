import React, { FC } from "react";
import { Alignment, Button, Navbar, Tab, Tabs } from "@blueprintjs/core";
import { Box, Paper } from "@material-ui/core";
import useStyles from "./styles";
import { useContractorContext } from "../../context/ContractorContext";
import useContractorInformation from "./hooks/useContractorInformation";
import ContractorForm from "./components/ContractorForm/ContractorForm";
import ContractorUsers from "./components/ContractorUsers/ContractorUsers";

const ContractorInformation: FC<{ onClose: () => void }> = ({ onClose }) => {
  const classes = useStyles();
  const { currentContractor } = useContractorContext();
  const { tab, setTab } = useContractorInformation();
  return (
    <Paper elevation={2}>
      <Navbar>
        {currentContractor?.id ? (
          <Navbar.Group align={Alignment.LEFT}>
            <Tabs
              animate
              id="navbar"
              large
              onChange={(id) => setTab(id.toString())}
              selectedTabId={tab}
            >
              <Tab id="Home" title="Información" />
              <Tab id="Users" title="Usuarios" disabled />
            </Tabs>
          </Navbar.Group>
        ) : null}
        <Navbar.Group
          align={currentContractor?.id ? Alignment.RIGHT : Alignment.LEFT}
        >
          <Navbar.Heading>
            <Box display="flex" alignItems="center">
              <span className={classes.title}>
                {currentContractor?.name || "Nuevo contratista"}
              </span>
              {currentContractor?.id ? (
                <Box ml={2}>
                  <Button
                    icon="cross"
                    onClick={() => {
                      setTab("Home");
                      onClose();
                    }}
                  />
                </Box>
              ) : null}
            </Box>
          </Navbar.Heading>
        </Navbar.Group>
      </Navbar>
      {tab === "Home" ? <ContractorForm /> : null}
      {tab === "Users" && currentContractor ? (
        <ContractorUsers contractor={currentContractor} />
      ) : null}
    </Paper>
  );
};

export default ContractorInformation;
