import React, { FC } from "react";
import { Box } from "@material-ui/core";
import AppList from "components/List/List";
import { Divider, H4 } from "@blueprintjs/core";
import Summary from "components/Summary/Summary";
import useTowerWeekList from "./hooks/useTowerWeekList";
import { TowerWeek } from "./types/TowerWeek";
import useTowerWeekRow from "./hooks/useTowerWeekRow";

const List = AppList<TowerWeek>();

interface Props {
  towerId: string;
  onSelectWeek: (week: TowerWeek) => void;
}

const TowerWeekList: FC<Props> = ({ towerId, onSelectWeek }) => {
  const { tower, weeks, compliance, qualification } = useTowerWeekList({
    towerId,
  });
  const { towerWeekElement } = useTowerWeekRow({ tower });
  return (
    <>
      <Summary>
        <Box>
          <Box>
            <H4>{tower?.name}</H4>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box>Cumplimiento</Box>
            <Box>
              <b>{parseFloat(`${compliance || 0}`).toFixed(0)}%</b>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box>Calificación</Box>
            <Box>
              <b>{parseFloat(`${qualification || 0}`).toFixed(1)}</b>
            </Box>
          </Box>
        </Box>
      </Summary>
      <Divider />
      <Box flexGrow={1} display="flex">
        <Box flexBasis="32%" />
        <Box flexGrow={1} style={{ textAlign: "center" }}>
          Cumplimiento
        </Box>
        <Box flexGrow={1} style={{ textAlign: "center" }}>
          Calificacion
        </Box>
      </Box>
      <List
        elementChildComponent={towerWeekElement}
        onSelectElement={onSelectWeek}
        elements={weeks}
      />
    </>
  );
};

export default TowerWeekList;
