import { H1, Icon, NonIdealState } from "@blueprintjs/core";
import { Box } from "@material-ui/core";
import React from "react";
import { RouteComponentProps } from "react-router-dom";

const NotFoundPage: React.FunctionComponent<RouteComponentProps> = () => {
  return (
    <Box
      minHeight="50vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <NonIdealState
        icon={<Icon icon="warning-sign" size={80} />}
        title={<H1>404</H1>}
        description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, totam, doloremque omnis quibusdam numquam labore eaque corporis quisquam soluta ipsam rerum, vero eveniet eos laboriosam. Ut numquam minima tempore quaerat."
      />
    </Box>
  );
};

export default NotFoundPage;
