import React, { FC } from "react";
import { Box } from "@material-ui/core";
import {
  Button,
  Callout,
  Classes,
  Dialog,
  FileInput,
  // NumericInput,
} from "@blueprintjs/core";
import useUploadActivitiesForm from "../../hooks/useUploadActivitiesForm";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  actions: React.ReactNode;
  uploadTasks: (file: File) => Promise<void>;
}

const UploadActivitiesModal: FC<Props> = ({ isOpen, onClose, uploadTasks }) => {
  const {
    // initialWeek,
    file,
    handleUploadFile,
    // handleNumericInputChange,
    onSubmit,
  } = useUploadActivitiesForm({
    uploadTasks,
    onClose,
  });

  return (
    <>
      <Dialog
        usePortal
        isOpen={isOpen}
        onClose={onClose}
        title="Subir actividades"
      >
        <Box className={Classes.DIALOG_BODY}>
          <Callout intent="warning">
            Solo se guardarán las actividades posteriores a la fecha de inicio
            de la etapa
          </Callout>
          <Box paddingY={2}>
            Actividades
            <FileInput
              text={file?.name || "Archivo actividades"}
              onInputChange={handleUploadFile}
              fill
              large
              buttonText="Seleccionar"
              inputProps={{
                accept:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
              }}
            />
          </Box>
        </Box>
        <Box className={Classes.DIALOG_FOOTER}>
          <Box className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button>Cancelar</Button>
            <Button
              intent="primary"
              icon="cloud-upload"
              onClick={onSubmit}
              disabled={!file?.name}
            >
              Subir archivo
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default UploadActivitiesModal;
