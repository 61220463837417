import React, { FC } from "react";
import TowerList from "modules/Contractor/features/TowerList/TowerList";
import { useHistory, useLocation, useParams } from "react-router";

const ContractorTowerListContainer: FC = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { projectId } = useParams<{ projectId: string }>();
  return (
    <>
      <TowerList
        projectId={projectId}
        onSelectTower={(tower) => push(`${pathname}/weeks/${tower.id}`)}
      />
    </>
  );
};
export default ContractorTowerListContainer;
