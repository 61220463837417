import { useCallback, useEffect, useState } from "react";
import { Tower } from "../models/Tower";
import { TowerService } from "../services/TowerService";

export interface UseTowerParams {
  id: string;
  onError: () => void;
}

export interface UseTowerResult {
  tower?: Tower;
}

type HookType = (param: UseTowerParams) => UseTowerResult;

const useTower: HookType = ({ id, onError }) => {
  const [tower, setTower] = useState<Tower>();

  const fetch = useCallback(async () => {
    try {
      const service = new TowerService();
      const { data } = await service.find(id);
      setTower(data?.tower);
    } catch (error) {
      if (error instanceof Error) {
        if (error.message.includes("invalid input synta")) {
          alert({ message: "No existe esta etapa", severity: "warning" });
        } else alert(error.message);
      }
      if (onError) onError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    tower,
  };
};

export default useTower;
