import { ContractorService } from "lib/contractors/services/ContractorService";
import { Tower } from "lib/projects/models/Tower";

export class TowerListService extends ContractorService {
  constructor() {
    super();
    this.errors = {};
  }

  getTowerListByContractorIdAndProjectId = (
    contractorId: string,
    projectId: string
  ) =>
    this.httpClient.get<{ towers: Tower[] }>(
      `/${contractorId}/project/${projectId}`
    );
}
