import { makeStyles, Theme } from "@material-ui/core";

const useLoginPageStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: "1 1 ",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondaryDark.main,
  },
}));

export default useLoginPageStyles;
