import { useCallback, useEffect, useState } from "react";
import { DateRange } from "@blueprintjs/datetime";
import { Contract } from "lib/contracts/models/Contract";
import { ActivityDate } from "lib/projects/models/ActivityDate";
import useBoolean from "shared/customHooks/useBoolean";
import { Modifier } from "react-day-picker";
import { ContractActivityDateService } from "../services/ContractActivityDateService";
import { ChangeActivityDateService } from "../services/ChangeActivityDateService";

export interface ChangeDateChildNode {
  activityDate: ActivityDate;
  handleDateChange: (activityDate?: ActivityDate) => void;
}

const useChangeActivityDate = ({
  activityDate,
  handleDateChange,
}: ChangeDateChildNode) => {
  const [contract, setContract] = useState<Contract>();
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    activityDate.startDate,
    activityDate.endDate,
  ]);

  const [disabledDays, setDisabledDays] = useState<Modifier>();
  const [initialDisabledDays, setInitialDisabledDays] = useState(true);
  const [minDate, setMinDate] = useState<Date>();
  const { value: isOpen, setTrue, setFalse } = useBoolean();
  const [canEditStartDate, setCanEditStartDate] = useState(false);

  const fetch = useCallback(async () => {
    try {
      const service = new ContractActivityDateService();
      const { data } = await service.getContractActivityDate(activityDate.id);
      setContract(data?.contract);
      if (data.minDate) setMinDate(new Date(data.minDate));
      if (data.disabledDays?.before) {
        setDisabledDays({
          before: new Date(data.disabledDays.before),
        });
      } else {
        setInitialDisabledDays(false);
      }
    } catch (error) {
      alert(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityDate]);

  useEffect(() => {
    if (activityDate.id) setTrue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityDate]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const close = () => {
    setFalse();
    setTimeout(handleDateChange, 100);
  };

  const onChangeRage = (dateRangeChanged: DateRange) => {
    if (!canEditStartDate && dateRangeChanged[1])
      setDateRange(dateRangeChanged);
    if (canEditStartDate && dateRangeChanged[0]) setDateRange(dateRangeChanged);
  };

  useEffect(() => {
    if (!initialDisabledDays) {
      if (dateRange[1] && canEditStartDate) {
        setDisabledDays({
          after: dateRange[1],
        });
      } else if (dateRange[0] && !canEditStartDate) {
        setDisabledDays({
          before: dateRange[0],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canEditStartDate, initialDisabledDays]);

  const toggleCanEditStartDay = () => {
    setCanEditStartDate(!canEditStartDate);
  };

  const onSaveClick = async () => {
    try {
      const service = new ChangeActivityDateService();
      await service.changeActivityDates(activityDate.id, dateRange);
      setFalse();
      alert({
        message: "La acción se ejecutó con éxito",
        severity: "success",
      });
      setTimeout(() => {
        handleDateChange({
          id: activityDate.id,
          startDate: dateRange[0],
          endDate: dateRange[1],
        });
      }, 100);
    } catch (error) {
      alert(error);
    }
  };

  return {
    dateRange,
    contract,
    isOpen,
    minDate,
    disabledDays,
    canEditStartDate,
    initialDisabledDays,
    close,
    onChangeRage,
    toggleCanEditStartDay,
    onSaveClick,
  };
};
export default useChangeActivityDate;
