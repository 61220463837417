import React, { FC } from "react";
import MomentLocaleUtils from "react-day-picker/moment";
import { Boundary, Button, Classes, Dialog } from "@blueprintjs/core";
import { DateRangePicker } from "@blueprintjs/datetime";
import { Box } from "@material-ui/core";
import useChangeActivityDate, {
  ChangeDateChildNode,
} from "./hooks/useChangeActivityDate";

const ChangeActivityDate: FC<ChangeDateChildNode> = ({
  activityDate,
  handleDateChange,
}) => {
  const {
    dateRange,
    isOpen,
    minDate,
    disabledDays,
    canEditStartDate,
    initialDisabledDays,
    close,
    onChangeRage,
    toggleCanEditStartDay,
    onSaveClick,
  } = useChangeActivityDate({
    activityDate,
    handleDateChange,
  });
  return (
    <>
      <Dialog
        title="Cambio de fechas"
        isOpen={isOpen}
        usePortal
        icon="calendar"
        onClose={close}
      >
        <Box className={Classes.DIALOG_BODY}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingBottom={1}
          >
            <Box display="flex" justifyContent="center" flexGrow={1}>
              {!initialDisabledDays ? (
                <Button
                  intent={canEditStartDate ? "primary" : "none"}
                  onClick={toggleCanEditStartDay}
                >
                  <span>Fecha de inicio</span>
                </Button>
              ) : (
                <span>Fecha de inicio</span>
              )}
            </Box>
            <Box display="flex" justifyContent="center" flexGrow={1}>
              {!initialDisabledDays ? (
                <Button
                  intent={!canEditStartDate ? "primary" : "none"}
                  onClick={toggleCanEditStartDay}
                >
                  <span>Fecha de fin</span>
                </Button>
              ) : (
                <span>Fecha de fin</span>
              )}
            </Box>
          </Box>
          <DateRangePicker
            localeUtils={MomentLocaleUtils}
            value={dateRange}
            shortcuts={false}
            locale="es"
            minDate={minDate}
            boundaryToModify={
              !initialDisabledDays && canEditStartDate
                ? Boundary.START
                : Boundary.END
            }
            onChange={onChangeRage}
            contiguousCalendarMonths={initialDisabledDays}
            allowSingleDayRange
            dayPickerProps={{
              disabledDays,
            }}
          />
        </Box>
        <Box className={Classes.DIALOG_FOOTER}>
          <Box className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={close}>Cerrar</Button>
            <Button intent="primary" onClick={onSaveClick}>
              Guardar
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default ChangeActivityDate;
