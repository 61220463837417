import React, { FC } from "react";
import { Box, Container } from "@material-ui/core";

import { Icon, Tab, Tabs } from "@blueprintjs/core";
import Reason from "./components/Reason";
import Units from "./components/Units";
import { PACConfigContextProvider } from "./context/PACConfigContext";
import PACConfigStyles from "./styles";

const PACConfig: FC = () => {
  const classes = PACConfigStyles();
  return (
    <Container maxWidth="md" className={classes.root}>
      <Tabs animate id="navbar" vertical large>
        <Tab
          id="Home"
          title={
            <Box display="flex" alignItems="center">
              <Icon icon="array-string" size={20} />
              <Box marginLeft={1}>Motivos Evaluación</Box>
            </Box>
          }
          panel={<Reason />}
          panelClassName={classes.panel}
        />
        <Tab
          id="Files"
          title={
            <Box display="flex" alignItems="center">
              <Icon icon="array-numeric" size={20} />
              <Box marginLeft={1}>Unidades de medida</Box>
            </Box>
          }
          panel={<Units />}
          panelClassName={classes.panel}
        />
      </Tabs>
    </Container>
  );
};

const PACConfigPage: FC = () => {
  return (
    <PACConfigContextProvider>
      <PACConfig />
    </PACConfigContextProvider>
  );
};

export default PACConfigPage;
