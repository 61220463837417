import { makeStyles, Theme } from "@material-ui/core";
import { green } from "@material-ui/core/colors";

const SelectRolePersonModalStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 480,
    position: "relative",
  },
  formItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  label: {
    minWidth: "50%",
  },
  labelSubtitle: {
    color: theme.palette.detail.main,
  },
  formControl: {
    width: "100%",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  rootCheck: {
    position: "relative",
    top: 3,
    paddingTop: 0,
    paddingBottom: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.secondaryDark.main,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.secondaryDark.main,
    },
  },
  radioRoot: {
    marginBottom: "1em",
    display: "flex",
    position: "relative",
    alignItems: "baseline",
  },
}));

export default SelectRolePersonModalStyles;
