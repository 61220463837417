import React, { FC } from "react";
import { Box, Container } from "@material-ui/core";

import {
  Breadcrumb,
  Breadcrumbs,
  BreadcrumbProps,
  Icon,
  Divider,
  Card,
  Button,
  NonIdealState,
} from "@blueprintjs/core";
import { useHistory, useRouteMatch } from "react-router";
import BoxTitle from "shared/components/BoxTitle/BoxTitle";

import AssignTowerStyles from "./styles";
import {
  AssignTowerProvider,
  useAssignTowerContext,
} from "./context/AssignTowerContext";

const AssignTower: FC = () => {
  const classes = AssignTowerStyles();
  const {
    tower,
    project,
    usersOutTower,
    usersInTower,
    assignUser,
    removeUser,
  } = useAssignTowerContext();

  const { path } = useRouteMatch();
  const history = useHistory();

  return (
    <Container className={classes.root}>
      <Box>
        <Box>
          <Breadcrumbs
            breadcrumbRenderer={({ text, href, ...rest }: BreadcrumbProps) => {
              return (
                <Breadcrumb
                  {...rest}
                  onClick={() => {
                    if (href) history.push(href);
                  }}
                >
                  {text}
                  <Icon icon="caret-right" />
                </Breadcrumb>
              );
            }}
            currentBreadcrumbRenderer={({
              text,
              href,
              ...rest
            }: BreadcrumbProps) => {
              return (
                <Breadcrumb
                  {...rest}
                  onClick={() => {
                    if (href) history.push(href);
                  }}
                >
                  {text}
                </Breadcrumb>
              );
            }}
            items={[
              {
                href: path.replace("/tower/assign/:towerId", ""),
                icon: "folder-close",
                text: "Projectos",
              },
              {
                href: "",
                disabled: true,
                icon: "folder-close",
                text: tower?.name,
              },
            ]}
          />
        </Box>
      </Box>
      <Box paddingTop={2}>
        <Card>
          <BoxTitle>
            Asignar usuarios <span>{tower?.name}</span>
            <div style={{ fontSize: ".8em", fontWeight: 400 }}>
              {project?.name}
            </div>
          </BoxTitle>
          <Divider />
          <Box display="flex" marginTop={3} alignItems="baseline">
            <Box flexGrow="1" paddingRight={1} className={classes.column}>
              <BoxTitle small center>
                Usuarios Disponibles
              </BoxTitle>
              {usersOutTower.length ? (
                <div>
                  {usersOutTower.map((user) => (
                    <Box key={user.id} paddingY={0.5}>
                      <Card>
                        <Box display="flex" alignItems="center">
                          <Box
                            display="flex"
                            flexDirection="column"
                            flexGrow="1"
                          >
                            <b>{user.name}</b>
                            <span>{user.email}</span>
                          </Box>
                          <Box>
                            <Button
                              icon="arrow-right"
                              onClick={() => assignUser(user)}
                            />
                          </Box>
                        </Box>
                      </Card>
                    </Box>
                  ))}
                </div>
              ) : (
                <NonIdealState
                  icon="people"
                  title="No hay usuarios disponibles"
                />
              )}
            </Box>
            <Box flexGrow="1" paddingLeft={1} className={classes.column}>
              <BoxTitle small center>
                Usuario Asignados
              </BoxTitle>
              {usersInTower.length ? (
                <div>
                  {usersInTower.map((userTower) => (
                    <Box key={userTower.userId} paddingY={0.5}>
                      <Card>
                        <Box display="flex" alignItems="center">
                          <Box>
                            <Button
                              icon="arrow-left"
                              onClick={() => removeUser(userTower)}
                            />
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            flexGrow="1"
                            ml={1}
                          >
                            <b>{userTower.user.name}</b>
                            <span>{userTower.user.email}</span>
                          </Box>
                        </Box>
                      </Card>
                    </Box>
                  ))}
                </div>
              ) : (
                <NonIdealState
                  icon="warning-sign"
                  title="Sin usuarios asignados"
                  description="Seleccione un usuario disponible,"
                />
              )}
            </Box>
          </Box>
        </Card>
      </Box>
    </Container>
  );
};

const AssignTowerContainer: FC = () => {
  return (
    <AssignTowerProvider>
      <AssignTower />
    </AssignTowerProvider>
  );
};

export default AssignTowerContainer;
