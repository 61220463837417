import React, { createContext, FC, useContext } from "react";
import useSelectContractor, {
  UseSelectContractorResult,
} from "../customHooks/useSelectContractor";

type SelectContractorContextType = UseSelectContractorResult;

const SelectContractorContext =
  createContext<SelectContractorContextType | null>(null);

export const useSelectContractorContext = (): SelectContractorContextType =>
  useContext(SelectContractorContext) as SelectContractorContextType;

export const SelectContractorProvider: FC = ({ children }) => {
  const value = useSelectContractor({ a: "1" });

  return (
    <SelectContractorContext.Provider value={{ ...value }}>
      {children}
    </SelectContractorContext.Provider>
  );
};
