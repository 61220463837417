import React, { FC } from "react";
import { Table } from "@devexpress/dx-react-grid-material-ui";
import { InputBase } from "@material-ui/core";
import NumberFormat from "react-number-format";
import useProgramCellStyles from "./styles";
import useProgramCell from "./useProgramCell";

type Props = Table.DataCellProps & {
  onChangeValue: (row: string, value: string) => void;
};

const ProgramCell: FC<Props> = (props) => {
  const { row, value, onChangeValue } = props;
  const { root, hight } = useProgramCellStyles();
  const { data, handleInputChange } = useProgramCell(
    row.id,
    value.value as number
  );
  return (
    <Table.Cell {...props} className={hight}>
      <NumberFormat
        customInput={InputBase}
        className={root}
        value={data.value}
        allowNegative={false}
        onBlur={() => onChangeValue(row.id, `${data.value}`)}
        onChange={handleInputChange("value")}
        thousandSeparator
        isNumericString
      />
      {/* <InputBase
        value={data.value}
        className={root}
        inputProps={{
          min: "0",
        }}
        type="number"
        onChange={handleInputChange("value")}
        onBlur={() => onChangeValue(row.id, data.value as number)}
      /> */}
    </Table.Cell>
  );
};

export default ProgramCell;
