import { AxiosResponse } from "axios";
import { Contract } from "lib/contracts/models/Contract";
import { ContractService } from "lib/contracts/services/ContractService";

export class AssignContractorToActivityService extends ContractService {
  constructor() {
    super();
    this.errors = {
      HAVE_TASKS: "El contratista ya inicio una programación",
    };
  }

  assignContractorToActivity = (
    contractorId: string,
    activityId: string
  ): Promise<AxiosResponse<{ contract: Contract }>> =>
    this.httpClient.post(
      `/assign/contractor/${contractorId}/activity/${activityId}`
    );
}
