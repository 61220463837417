import React, { FC } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import TowerWeekList from "modules/Contractor/features/TowerWeekList/TowerWeekList";

const ContractorTowerWeeksContainer: FC = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { towerId } = useParams<{ towerId: string }>();
  return (
    <>
      <TowerWeekList
        towerId={towerId}
        onSelectWeek={(week) => push(`${pathname}/week/${week.towerWeek}`)}
      />
    </>
  );
};
export default ContractorTowerWeeksContainer;
