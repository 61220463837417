import useProjectsWithTowers from "lib/projects/cutomHooks/useProjectsWithTowers";

const useConsolidatedEvaluationSelectProject = () => {
  const [projects] = useProjectsWithTowers();
  return {
    projects,
  };
};

export default useConsolidatedEvaluationSelectProject;
