import { UserAuth } from "lib/auth/models/UserAuth";
import { useDispatch } from "react-redux";
import {
  setAuthContractor,
  setAuthMinSide,
} from "../redux/AuthenticationSlice";

const useAuthContractor = () => {
  const dispatch = useDispatch();
  const loginContractor = (user: UserAuth) => {
    if (user) {
      if (Array.isArray(user.contractors) && user.contractors.length) {
        dispatch(setAuthMinSide(!(user?.role && !user.role.requireContractor)));
        dispatch(setAuthContractor(user.contractors[0]));
      }
    }
  };

  return { loginContractor };
};

export default useAuthContractor;
