import { lighten } from "@material-ui/core/styles";
import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";
import { TypographyStyleOptions } from "@material-ui/core/styles/createTypography";
import ProximaNovaAWoff2 from "./fonts/ProximaNovaA-Regular.woff2";

const ProximaNovaA = {
  fontFamily: "ProximaNovaA",
  fontStyle: "normal",
  fontWeight: 500,
  src: `
    local('ProximaNovaA'),
    local('ProximaNovaA-Regular'),
    url(${ProximaNovaAWoff2}) format('woff2')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    black: Palette["primary"];
    active: Palette["primary"];
    activeHighlighter: Palette["primary"];
    detail: Palette["primary"];
    negative: Palette["primary"];
    highlighter: Palette["primary"];
    lightBackground: Palette["primary"];

    white: Palette["primary"];
    secondaryDark: Palette["primary"];
    tertiary: Palette["primary"];
    tertiaryText: Palette["primary"];
  }
  interface PaletteOptions {
    black: PaletteOptions["primary"];
    active: PaletteOptions["primary"];
    activeHighlighter: PaletteOptions["primary"];
    detail: PaletteOptions["primary"];
    negative: PaletteOptions["primary"];
    highlighter: PaletteOptions["primary"];
    lightBackground: PaletteOptions["primary"];

    white: PaletteOptions["primary"];
    secondaryDark: PaletteOptions["primary"];
    tertiary: PaletteOptions["primary"];
    tertiaryText: PaletteOptions["primary"];
  }
}

declare module "@material-ui/core/styles" {
  interface TypographyVariants {
    poster: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    poster?: React.CSSProperties;
  }
}

declare module "@material-ui/core/Typography" {
  interface TypographyPropsVariantOverrides {
    poster: true;
    h3: false;
  }
}

declare module "@material-ui/core/styles/createTypography" {
  interface Typography {
    sectionTitle: TypographyStyleOptions;
    poster: TypographyStyleOptions;
  }

  interface TypographyOptions {
    sectionTitle?: TypographyStyleOptions;
    poster?: TypographyStyleOptions;
  }
}

const colors = {
  black: "#2E2F30",
  active: "#FFB800",
  detail: "#7D848B",
  negative: "#FF4900",
  highlighter: lighten("#00BEFF", 0.1),
  lightBackground: "#FAFAFA",
};

export const theme = createMuiTheme({
  typography: {
    h1: {
      fontWeight: "bold",
      fontSize: 28,
      color: colors.black,
    },
    h2: {
      fontWeight: "normal",
      fontSize: 14,
      color: colors.detail,
    },
    sectionTitle: {
      fontWeight: "normal",
      fontSize: 16,
      color: colors.black,
    },
    poster: {
      fontSize: "5.8rem",
    },
    fontFamily: "ProximaNovaA, Roboto, sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    button: {
      fontFamily: "ProximaNovaA, Roboto, sans-serif",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [ProximaNovaA],
      },
    },
  },
  palette: {
    primary: {
      main: colors.black,
    },
    white: {
      main: colors.lightBackground,
    },
    secondaryDark: {
      main: "#FFB800",
    },
    tertiary: {
      main: "#E6E6E6",
    },
    tertiaryText: {
      main: "#7D848B",
    },

    black: {
      main: colors.black,
    },
    active: {
      main: colors.active,
    },
    activeHighlighter: {
      main: lighten(colors.active, 0.8),
      dark: colors.active,
    },
    detail: {
      main: colors.detail,
      light: "#F5F5F5",
    },
    negative: {
      main: colors.negative,
    },
    highlighter: {
      main: lighten(colors.highlighter, 0.9),
      dark: colors.highlighter,
    },
    lightBackground: {
      main: colors.lightBackground,
    },
  },
});
