import React, { FC } from "react";
import NumberFormat from "react-number-format";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NumberFormatString: FC<{ value: any }> = ({ value }) => {
  return (
    <NumberFormat
      value={`${value}`}
      displayType="text"
      thousandSeparator
      thousandsGroupStyle="thousand"
    />
  );
};

export default NumberFormatString;
