import Joi from "joi";
import { AuthService } from "lib/auth/services/Auth.Service";
import { useMutation } from "react-query";
import useBoolean from "shared/customHooks/useBoolean";
import { useForm } from "shared/customHooks/useForm";
import { LogicError } from "shared/services/LogicError";

const useForgotPassword = () => {
  const { value: isSuccess, setTrue } = useBoolean();

  const { mutate, isLoading } = useMutation(
    async (email: string) => {
      const service = new AuthService();
      return service.resetPassword(email);
    },
    {
      onError: (error) => {
        if (error instanceof LogicError && error.logic === "EMAIL_NOT_FOUND")
          alert({
            message: "No existe un usuario asociado al correo",
            severity: "warning",
          });
      },
      onSuccess: () => setTrue(),
    }
  );

  const form = useForm<{ email: string }>({
    initialValues: {
      email: "",
    },
    validations: {
      email: {
        custom: {
          message: "El correo electrónico no es válido",
          isValid: (value) =>
            !Joi.string()
              .email({ tlds: { allow: false } })
              .validate(value).error,
        },
      },
    },
    onSubmit: async (values) => {
      await mutate(values.email);
    },
  });

  return { ...form, isLoading, isSuccess };
};

export default useForgotPassword;
