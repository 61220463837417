import React, { FC } from "react";
import { Box, makeStyles, Theme } from "@material-ui/core";

interface Props {
  small?: boolean;
  center?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: "1em",
    textAlign: (props: Props) => (props.center ? "center" : "initial"),
    fontSize: (props: Props) => (props.small ? "1em" : "1.5em"),
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
}));

const BoxTitle: FC<Props> = ({ children, small, center }) => {
  const classes = useStyles({ small, center });
  return <Box className={classes.root}>{children}</Box>;
};

export default BoxTitle;
