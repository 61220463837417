import moment, { Moment } from "moment";
import { EvaluateContractorsFilterValues } from "lib/contracts/models/Evaluation";
import { ContractorEvalHistory } from "lib/contracts/models/TaskEvaluation";
import { ContractService } from "lib/contracts/services/ContractService";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

type Col = {
  name: string;
  title: string;
};

export type UseEvaluateContractorsResult = {
  startWeek: Moment;
  endWeek: Moment;
  contractorHistory: ContractorEvalHistory[];
  cols: Col[];
  rows: ContractorEvalHistory[];
  contractorsEvaluated: number[];
  selectContractor: (data: ContractorEvalHistory | undefined) => void;
  onFilter: (filters: EvaluateContractorsFilterValues) => void;
};

const useEvaluateContractors = (): UseEvaluateContractorsResult => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [startWeek, setStartWeek] = useState(moment());
  const [endWeek, setEndWeek] = useState(moment());

  const [cols] = useState<Col[]>([
    { name: "contractorName", title: "Contratista" },
    { name: "projectName", title: "Proyecto" },
    { name: "towerName", title: "Etapa" },
    { name: "pacEvaluate", title: "Evaluado" },
    { name: "lastQualification", title: "Última Calificación" },
    { name: "qualification", title: "Promedio" },
    { name: "compliance", title: "% Cumplimiento" },
  ]);

  const [rows, setRows] = useState<ContractorEvalHistory[]>([]);
  const [contractorHistory, setContractorHistory] = useState<
    ContractorEvalHistory[]
  >([]);
  const [contractorsEvaluated, setContractorsEvaluated] = useState<number[]>(
    []
  );

  const fetch = useCallback(
    async (filters?: EvaluateContractorsFilterValues) => {
      try {
        const service = new ContractService();
        const { data } = await service.getEvalHistory(filters);
        setContractorHistory(data.contracts);
        setContractorsEvaluated(data.contractors || [0, 0]);
      } catch (error) {
        alert(error);
      }
    },
    [setContractorHistory]
  );

  useEffect(() => {
    const today = moment();
    if (moment().day("Wednesday").isAfter(today)) {
      setEndWeek(moment().day("Wednesday").subtract(2, "week"));
      setStartWeek(
        moment().day("Wednesday").subtract(1, "week").subtract(1, "day")
      );
    } else {
      setEndWeek(moment().day("Wednesday").subtract(10, "week"));
      setStartWeek(moment().day("Wednesday").subtract(1, "day"));
    }
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    setRows(
      contractorHistory.map((contract) => {
        return {
          ...contract,
          contractorName: contract.contractor.name,
          projectName: contract.activity.tower?.project?.name,
          towerName: contract.activity.tower?.name,
        };
      })
    );
  }, [contractorHistory]);

  const selectContractor = (data: ContractorEvalHistory | undefined): void => {
    if (data?.contractor.id) {
      const contractorId = data?.contractor.id;
      const towerId = data?.activity.tower?.id;
      history.push(`${pathname}/${towerId}/${contractorId}`);
    }
  };

  const onFilter = (filters: EvaluateContractorsFilterValues) => {
    fetch(filters);
  };

  return {
    startWeek,
    endWeek,
    contractorHistory,
    cols,
    rows,
    contractorsEvaluated,
    selectContractor,
    onFilter,
  };
};

export default useEvaluateContractors;
