import React, { FC } from "react";
import { Box } from "@material-ui/core";
import { Card, HTMLTable, Icon, Tag } from "@blueprintjs/core";
import { useHistory, useLocation } from "react-router";
import { Tooltip2 } from "@blueprintjs/popover2";
import useContracts from "./customHooks/useContracts";

const ListContractors: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { contracts } = useContracts();

  return (
    <Box mt={2}>
      <Card style={{ padding: 0 }}>
        <HTMLTable interactive condensed bordered width="100%">
          <thead>
            <tr>
              <th style={{ width: "12px" }}>
                <span />
              </th>
              <th>Contratista</th>
            </tr>
          </thead>
          <tbody>
            {contracts.map((a) => (
              <tr
                key={a.id}
                onClick={() =>
                  history.push(`${location.pathname}/contractor/${a.id}`)
                }
              >
                <td style={{ verticalAlign: "middle" }}>
                  {a.valid ? (
                    <Icon icon="endorsed" intent="success" />
                  ) : (
                    <Tooltip2
                      content="Programación incompleta"
                      position="top-right"
                    >
                      <Icon icon="endorsed" intent="warning" />
                    </Tooltip2>
                  )}
                </td>
                <td>
                  <Box>{a.name}</Box>
                  <Box>{a.documentNumber || "No hay número de documento"}</Box>
                  {a.emptySubtask ? (
                    <Box paddingBottom={1}>
                      <Tag intent="warning">Sin actividades</Tag>
                    </Box>
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </HTMLTable>
      </Card>
    </Box>
  );
};

export default ListContractors;
