export default {
  staging: {
    serverUrl: "http://localhost:8080/v1",
  },
  development: {
    serverUrl: "http://localhost:8080/v1",
  },
  production: {
    serverUrl: `${window.location.origin}/api`,
  },
};
