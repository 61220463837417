export enum EvaluationState {
  PENDING = "PENDING",
  SCHEDULED = "SCHEDULED",
  SCHEDULED_SENDING = "SCHEDULED_SENDING",
  SCHEDULED_PASSED = "SCHEDULED_PASSED",
  SCHEDULED_REJECTED = "SCHEDULED_REJECTED",
  SCHEDULED_EVALUATED = "SCHEDULED_EVALUATED",
}

export interface Evaluation {
  id?: string;
  name?: string;
  towerWeek: number;
  currentProgramation: boolean;
  isPrediction: boolean;
  isEvaluation: boolean;
  isProgramation: boolean;
  value: number;
  state: EvaluationState;
  isFuture: boolean;
  week: number;
  reject: boolean;
  comment: string;
  inUse?: boolean;
  virtualWeek?: number;
  requireSend?: boolean;
}

export interface IEvaluationFilterValue {
  id: string;
  name: string;
}

export interface IEvaluationProjectFilter extends IEvaluationFilterValue {
  towers?: IEvaluationFilterValue[];
  contractors?: IEvaluationFilterValue[];
}

export interface IEvaluationTowerFilter extends IEvaluationFilterValue {
  project?: IEvaluationFilterValue;
  contractors?: IEvaluationFilterValue[];
}

export interface IEvaluationContractorFilter extends IEvaluationFilterValue {
  towers?: IEvaluationFilterValue[];
  projects?: IEvaluationFilterValue[];
}

export interface IEvaluationFilters {
  projects: IEvaluationProjectFilter[];
  contractors: IEvaluationContractorFilter[];
  towers: IEvaluationTowerFilter[];
}

export interface EvaluateContractorsFilterValues {
  projectId?: string;
  contractorId?: string;
  towerId?: string;
}
