import { AxiosResponse } from "axios";
import { UserAuth } from "lib/auth/models/UserAuth";
import { HttpService } from "shared/services/HttpService";

export class UserService extends HttpService {
  constructor() {
    super("/users");
  }

  getCurrentUser(): Promise<AxiosResponse<{ user: UserAuth }>> {
    return this.httpClient.get("/current");
  }
}
