import Cookies from "universal-cookie";
import { v4 } from "uuid";

export class CookieService {
  getSessionId = (): string => {
    const key = "__did";
    const cookies = new Cookies();
    const session = cookies.get(key);
    if (session) return session;
    cookies.set(key, v4());
    return cookies.get(key);
  };
}
