import React, { FC } from "react";
import {
  darken,
  lighten,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: lighten(theme.palette.highlighter.dark, 0.7),
      color: darken(theme.palette.highlighter.dark, 0.1),
      padding: "4px 10px",
      maxWidth: 60,
      textAlign: "center",
      borderRadius: 4,
    },
  })
);

const Tag: FC<{ text: string }> = ({ text }) => {
  const classes = useStyles();
  return <div className={classes.root}>{text}</div>;
};

export default Tag;
