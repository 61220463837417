import React, { FC } from "react";
import { RouteComponentProps } from "react-router";
import {
  Box,
  Button,
  Container,
  Divider,
  List,
  Typography,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import AsyncConfirmModal from "shared/components/AsyncModal/AsyncConfirmModal";
import InputSearch from "shared/components/InputSearch/InputSearch";
import useRoleList from "./customHooks/useRoleList";
import PersonItem from "./components/PersonItem";
import usePersons from "./customHooks/usePersons";
import useRemovePerson from "./customHooks/useRemovePerson";
import PersonModal from "./components/PersonModal/PersonModal";
import SelectRolePersonModal from "./components/SelectRolePersonModal/SelectRolePersonModal";
import PersonsPageStyles from "./styles";

const PersonsPage: FC<RouteComponentProps> = () => {
  const classes = PersonsPageStyles();
  const { roles } = useRoleList();
  const {
    currentPerson,
    persons,
    isOpenModalPerson,
    isOpenModalRolePerson,
    openModalPerson,
    handleCreateUser,
    handleUserUpdated,
    closeModalPerson,
    handleClickEdit,
    handleUserRemove,
    handleClickSelectRole,
    closeModalRolePerson,
    handleChangeRolePerson,
    handleChangeSearch,
  } = usePersons();

  const { modalRef, handleClickDelete } = useRemovePerson({
    onRemovePerson: handleUserRemove,
  });

  return (
    <Container maxWidth="md" className={classes.root}>
      <AsyncConfirmModal title="Borrar elemento" ref={modalRef} />
      {[Math.random()].map((rand) => (
        <PersonModal
          roles={roles}
          key={rand}
          open={isOpenModalPerson}
          handleUserCreated={handleCreateUser}
          handleUserUpdated={handleUserUpdated}
          handleClose={closeModalPerson}
          person={currentPerson}
        />
      ))}
      {[Math.random()].map((rand) => (
        <SelectRolePersonModal
          roles={roles}
          key={rand}
          open={isOpenModalRolePerson}
          handleChangeRolePerson={handleChangeRolePerson}
          handleClose={closeModalRolePerson}
          person={currentPerson}
        />
      ))}
      <Box display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between">
          <InputSearch onChange={handleChangeSearch} />
          <Button
            color="primary"
            variant="contained"
            startIcon={<Add />}
            onClick={openModalPerson}
          >
            <Typography style={{ fontSize: "1em" }}>Nuevo miembro</Typography>
          </Button>
        </Box>
        <Box mt={4}>
          <h1>
            <Typography variant="h1">Personas</Typography>
          </h1>
        </Box>
        <Divider />
        <Box>
          <List>
            {persons.map((person) => (
              <PersonItem
                key={person.id}
                {...person}
                onClickEdit={handleClickEdit}
                onClickDelete={handleClickDelete}
                onClickChangeRole={handleClickSelectRole}
              />
            ))}
          </List>
        </Box>
      </Box>
    </Container>
  );
};

export default PersonsPage;
