/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";

import { Contract } from "lib/contracts/models/Contract";
import { ContractService } from "lib/contracts/services/ContractService";
import useUnits from "lib/contracts/hooks/useUnits";

import { TaskUnit } from "lib/contracts/models/TaskUnit";
import { ContractorService } from "lib/contractors/services/ContractorService";
import { useSelectContractorContext } from "../../../context/SelectContractorContext";

export interface UseProgramContractParams {
  a: string;
}

export interface UseProgramContractResult {
  contracts: Contract[];
  units: TaskUnit[];
  haveUser: boolean;
}

type HookType = (param?: UseProgramContractParams) => UseProgramContractResult;

const useProgramContract: HookType = () => {
  const { tower, selectContract } = useSelectContractorContext();
  const [units] = useUnits();
  const { contractorId } = useParams<{ contractorId: string }>();
  const [haveUser, setHaveUser] = useState(false);
  const [contracts, setContracts] = useState<Contract[]>([]);

  const fetch = useCallback(async () => {
    try {
      if (tower?.id) {
        const contractorService = new ContractorService();
        const { data: contractorData } = await contractorService.haveUser(
          contractorId
        );
        setHaveUser(!!contractorData?.exists);

        const service = new ContractService();
        const { data } = await service.listContractsByTowerIdAndContractorId(
          tower.id,
          contractorId
        );
        setContracts(data.contracts);
        if (data.contracts.length) selectContract(data.contracts[0]);
      }
    } catch (error) {
      alert(error);
    }
  }, [contractorId, tower]);

  useEffect(() => {
    fetch();
    return () => {
      selectContract(undefined);
    };
  }, [fetch]);

  return {
    contracts,
    units,
    haveUser,
  };
};

export default useProgramContract;
