import { makeStyles, Theme } from "@material-ui/core";

const useProgramContractTableStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
    position: "relative",
    minHeight: "40px",
    "& *": {
      boxSizing: "border-box",
      "&::-webkit-scrollbar": {
        height: ".7em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 1px rgba(0,0,0,1)",
        webkitBoxShadow: "inset 0 0 1px rgba(0,0,0,1)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,.2)",
        borderRadius: 8,
      },
    },
    "& table": {
      borderSpacing: "1px",
      paddingBottom: "10px",
    },
    "& thead": {
      background: theme.palette.detail.light,
      "& tr:first-child": {
        "& th": {
          background: theme.palette.detail.light,
          padding: "0 10px",
          border: "0.5px solid",
          borderColor: theme.palette.detail.light,
        },
      },
    },
    "& tbody tr": {
      "&:hover": {
        "& td": {
          background: theme.palette.highlighter.main,
        },
      },
      "& td": {
        border: "0.5px solid",
        borderColor: theme.palette.detail.light,
        paddingTop: 2,
        paddingBottom: 2,
        background: "white",
      },
    },
  },
  dark: {
    color: theme.palette.highlighter.dark,
  },
  bold: {
    fontWeight: "bold",
  },
  header: {
    paddingBottom: 0,
    paddingTop: 0,
  },
}));

export default useProgramContractTableStyles;
