import { Box } from "@material-ui/core";
import { Tower } from "lib/projects/models/Tower";
import { ResolveVirtualWeek } from "lib/projects/utils/ResolveVirtualWeek";
import React, { useCallback } from "react";
import { TowerWeek } from "../types/TowerWeek";

const useTowerWeekRow = ({ tower }: { tower: Tower | null }) => {
  const getColor = (qualification: number) => {
    const isDanger = qualification === 0;
    const isWarning = qualification < 5;
    if (isDanger) return "red";
    if (isWarning) return "orange";
    return "green";
  };

  const towerWeekElement = useCallback(
    (week: TowerWeek) => {
      const { compliance, qualification, towerWeek } = week;
      const color = getColor(qualification);
      return (
        <Box flexGrow={1} display="flex" justifyContent="space-between">
          <Box flexBasis="32%">
            Semana {towerWeek + ResolveVirtualWeek(tower?.virtualWeek)}
          </Box>
          <Box
            flexGrow={1}
            style={{
              textAlign: "center",
              color,
            }}
          >
            {parseFloat(`${compliance || 0}`).toFixed(1)}%
          </Box>
          <Box
            flexGrow={1}
            style={{
              textAlign: "center",
              color,
            }}
          >
            {parseFloat(`${qualification || 0}`).toFixed(1)}
          </Box>
        </Box>
      );
    },
    [tower]
  );

  return {
    towerWeekElement,
  };
};

export default useTowerWeekRow;
