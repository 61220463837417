import React, { createContext, FC, useContext } from "react";

interface Return {
  name: string;
}

type ProjectContextType = Return;

const ProjectContext = createContext<ProjectContextType | null>(null);

export const useProjectContext = (): ProjectContextType =>
  useContext(ProjectContext) as ProjectContextType;

export const ProjectProvider: FC = ({ children }) => {
  return (
    <ProjectContext.Provider value={{ name: "Project" }}>
      {children}
    </ProjectContext.Provider>
  );
};
