import { useHistory, useRouteMatch } from "react-router";

import useProjects from "lib/projects/cutomHooks/useProjects";
import { Project } from "lib/projects/models/Project";

export interface UseProgramProjectsParams {
  a: string;
}

export interface UseProgramProjectsResult {
  projects: Project[];
  selectProject: (id: string, numberOfTowers?: string) => void;
}

type HookType = (param?: UseProgramProjectsParams) => UseProgramProjectsResult;

const useProgramProjects: HookType = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const [projects] = useProjects();

  const selectProject = (id: string, numberOfTowers?: string): void => {
    if (numberOfTowers && +numberOfTowers > 0) history.push(`${path}/${id}`);
    else alert("Este proyecto no tiene etapas");
  };

  return {
    projects,
    selectProject,
  };
};

export default useProgramProjects;
