import { Project } from "lib/projects/models/Project";
import { Tower } from "lib/projects/models/Tower";
import { ProjectService } from "lib/projects/services/ProjectService";
import { TowerService } from "lib/projects/services/TowerService";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { IUseFormResult, useForm } from "shared/customHooks/useForm";
import { useProjectHomeContext } from "../context/ProjectHomeContext";

interface IUseProjectFormResult {
  towers: Partial<Tower>[];
  currentTower: Partial<Tower> | undefined;
  formIndex: number;
  closeFormModal: () => void;
  toggleFormIndex: () => void;
  addTower: (tower: Partial<Tower>, updated?: boolean) => Promise<void>;
  createEmptyTower: () => void;
  selectTower: (tower: Partial<Tower>) => void;
}

type result = IUseFormResult<Project> & IUseProjectFormResult;

const useProjectForm = (): result => {
  const { handleCreateProject, closeModal, currentProject } =
    useProjectHomeContext();
  const [towers, setTowers] = useState<Partial<Tower>[]>([]);
  const [formIndex, setFormIndex] = useState(0);
  const [currentTower, setCurrentTower] = useState<Partial<Tower>>();

  const fetch = useCallback(async () => {
    if (currentProject?.numberOfTowers) {
      const service = new TowerService();
      const { data } = await service.all(currentProject.id);
      setTowers(data.towers || []);
    }
  }, [currentProject]);

  const addTower = async (
    tower: Partial<Tower>,
    updated?: boolean
  ): Promise<void> => {
    if (updated) {
      setTowers(towers.map((el) => (el?.id === tower.id ? tower : el)));
    } else if (currentProject?.id) {
      const service = new ProjectService();
      await service.update(currentProject.id, {
        towers: [tower],
      });
      await fetch();
    } else {
      setTowers(towers.concat({ ...tower, id: `temp_${Math.random()}` }));
    }
    setCurrentTower(undefined);
    setFormIndex(0);
  };

  const selectTower = (tower: Partial<Tower>): void => {
    setCurrentTower(tower);
    setFormIndex(1);
  };

  const createEmptyTower = (): void => {
    setTowers(
      towers.concat({
        name: "",
        startDate: moment(),
        endDate: moment(),
      })
    );
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  const toggleFormIndex = (): void => {
    if (formIndex) setCurrentTower(undefined);
    setFormIndex(formIndex ? 0 : 1);
  };

  const resetModal = (): void => {
    setCurrentTower(undefined);
    setFormIndex(0);
    setTowers([]);
    closeModal();
  };

  const form = useForm<Project>({
    validations: {
      name: {
        required: {
          message: "El nombre del proyecto es requerido",
          value: true,
        },
      },
    },
    initialValues: {
      name: currentProject?.name || "",
    },
    async onSubmit(projectData, reset) {
      const service = new ProjectService();
      const towerForSave = towers
        .filter((tower) => tower.id?.includes("temp_"))
        .map((tower) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { id, ...data } = tower;
          return data;
        });
      try {
        if (currentProject?.id) {
          const updateData = {} as Record<string, unknown>;

          Object.keys(projectData).forEach((keyString) => {
            const key = keyString as keyof Partial<Project>;
            if (projectData[key] !== currentProject[key])
              updateData[key] = projectData[key];
          });

          if (Object.keys(updateData).length || towerForSave.length) {
            await service.update(currentProject.id, {
              ...updateData,
              towers: towerForSave,
            });
            handleCreateProject(
              {
                ...currentProject,
                ...updateData,
                numberOfTowers:
                  +(currentProject?.numberOfTowers || 0) + towerForSave.length,
              },
              true
            );
          }
        } else {
          const { data } = await service.create({
            ...projectData,
            towers: towerForSave,
          });
          handleCreateProject({
            ...data.project,
            numberOfTowers: towers.length,
          });
        }
        resetModal();
        reset();
      } catch (error) {
        alert(error);
      }
    },
  });

  const closeFormModal = (): void => {
    form.resetData();
    resetModal();
  };

  return {
    ...form,
    createEmptyTower,
    towers,
    closeFormModal,
    formIndex,
    toggleFormIndex,
    addTower,
    currentTower,
    selectTower,
  };
};

export default useProjectForm;
