import { makeStyles, Theme } from "@material-ui/core";

const SelectContractorStyles = makeStyles((theme: Theme) => ({
  root: {},
  box: {
    cursor: "pointer",
  },
  paper: {
    "&:hover": {
      boxShadow: theme.shadows[3],
    },
  },
}));

export default SelectContractorStyles;
