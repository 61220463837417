import { Tower } from "lib/projects/models/Tower";
import { TowerService } from "lib/projects/services/TowerService";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";

const useAssignContractorPage = () => {
  const { towerId } = useParams<{ projectId: string; towerId: string }>();
  const [tower, setTower] = useState<Tower>();

  const fetch = useCallback(async () => {
    const towerService = new TowerService();
    const { data: dataTower } = await towerService.find(towerId);
    setTower(dataTower.tower);
  }, [towerId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    tower,
  };
};
export default useAssignContractorPage;
