import { IntegratedSorting, SortingState } from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";
import { Activity } from "lib/projects/models/Activity";
import { ActivityDate } from "lib/projects/models/ActivityDate";
import React, { FC } from "react";
import useActivityContractorListTable from "../../hooks/useActivityContractorListTable";
import { TaskRow } from "../../models/ActivityContractorListRow";
import useActivityContractorListCell from "../ActivityContractorListCell/useActivityContractorListCell";

interface Props {
  activities: Activity[];
  onClickAssignContractor: (activity: TaskRow) => void;
  onDateSelect: (activityDate: ActivityDate) => void;
  handleRemoveClick: (activityId: string) => void;
}

const ActivityContractorListTable: FC<Props> = ({
  activities,
  onClickAssignContractor,
  onDateSelect,
  handleRemoveClick,
}) => {
  const { rows, cols } = useActivityContractorListTable({
    activities,
  });

  const { Cell } = useActivityContractorListCell({
    handleClickAssignContractor: onClickAssignContractor,
    onDateSelect,
    handleRemoveClick,
  });

  return (
    <>
      <Grid rows={rows} columns={cols}>
        <SortingState
          defaultSorting={[{ columnName: "contractor", direction: "asc" }]}
          columnExtensions={[
            { columnName: "numberOfContractors", sortingEnabled: false },
          ]}
        />
        <IntegratedSorting
        // columnExtensions={[
        //   {
        //     columnName: "startDate",
        //     compare: compareDate,
        //   },
        //   {
        //     columnName: "endDate",
        //     compare: compareDate,
        //   },
        // ]}
        />
        <Table
          cellComponent={Cell}
          columnExtensions={[
            {
              columnName: "",
              width: "40px",
            },
            {
              columnName: "name",
              width: "50%",
            },
            {
              columnName: "startDate",
              width: "160px",
            },
            {
              columnName: "endDate",
              width: "140px",
            },
          ]}
        />
        <TableHeaderRow showSortingControls />
      </Grid>
    </>
  );
};
export default ActivityContractorListTable;
