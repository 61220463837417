import React, { FC } from "react";
import { Box, Divider, makeStyles, Paper } from "@material-ui/core";
import {
  ProgramAssignmentProvider,
  useProgramAssignmentContext,
} from "./context/ProgramAssignmentContext";
import AssignTaskTable from "./components/AssignTaskTable";
import DrawerAssignContractor from "./components/DrawerAssignContractor";

const useStyles = makeStyles(() => {
  return {
    root: {},
    table: {
      borderSpacing: "10px",
    },
  };
});

const ProgramAssignment: FC = () => {
  const classes = useStyles();
  const { tower } = useProgramAssignmentContext();
  return (
    <Paper elevation={0}>
      <Box p={2} className={classes.root}>
        <Box mb={2}>
          <h2>Asignación de contratistas: {tower?.name}</h2>
        </Box>
        <Divider />
        <DrawerAssignContractor />
        <AssignTaskTable />
      </Box>
    </Paper>
  );
};

const ProgramAssignmentContainer: FC = () => {
  return (
    <ProgramAssignmentProvider>
      <ProgramAssignment />
    </ProgramAssignmentProvider>
  );
};

export default ProgramAssignmentContainer;
