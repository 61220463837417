import React, { FC } from "react";
import { Button, Icon } from "@blueprintjs/core";
import { Table } from "@devexpress/dx-react-grid-material-ui";
import { Box } from "@material-ui/core";
import { TaskRow } from "../../models/ActivityContractorListRow";

export interface ActivityContractorListCellContractorProps {
  handleClickAssignContractor: (activity: TaskRow) => void;
  canEdit?: boolean;
}

const ActivityContractorListCellContractor: FC<
  Table.DataCellProps & ActivityContractorListCellContractorProps
> = (props) => {
  const {
    value: contractor,
    row,
    canEdit,
    handleClickAssignContractor,
  } = props;
  const { lastScheduledTowerWeek } = row;

  return (
    <Table.Cell {...props}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {lastScheduledTowerWeek <= 0 && canEdit ? (
          <Button
            small
            icon={contractor?.id ? "follower" : "inherited-group"}
            minimal={!!contractor?.id}
            onClick={() => handleClickAssignContractor(row)}
          >
            {contractor?.id ? contractor.name : "Asignar contratista"}
          </Button>
        ) : (
          <Box paddingX={1} display="flex" alignItems="center">
            <Icon icon="person" size={15} />
            <span style={{ marginLeft: 8 }}>
              {contractor?.id ? contractor.name : "Sin contratista"}
            </span>
          </Box>
        )}
      </Box>
    </Table.Cell>
  );
};

export default ActivityContractorListCellContractor;
