import { AxiosResponse } from "axios";
import { User, UserTower } from "../models/User";
import { UserService } from "./UserService";

export class PersonsService extends UserService {
  getAllPersons = (): Promise<AxiosResponse<{ users: User[] }>> => {
    return this.httpClient.get("/");
  };

  getAllPersonsInTower = (
    towerId: string
  ): Promise<AxiosResponse<{ in: UserTower[]; out: User[] }>> => {
    return this.httpClient.get(`/tower/${towerId}`);
  };

  createPerson = (
    roleId: string,
    user: Partial<User>
  ): Promise<AxiosResponse<{ user: User }>> => {
    return this.httpClient.post(`/${roleId}`, user);
  };

  updatePerson = (
    userId: User["id"],
    user: Partial<User>
  ): Promise<AxiosResponse<{ user: User }>> => {
    return this.httpClient.put(`/${userId}`, user);
  };

  assignUser = (
    towerId: string,
    userId: string
  ): Promise<AxiosResponse<{ success: boolean }>> => {
    return this.httpClient.patch(`/tower/${towerId}/user/${userId}`);
  };

  removeUser = (
    towerId: string,
    userId: string
  ): Promise<AxiosResponse<{ success: boolean }>> => {
    return this.httpClient.delete(`/tower/${towerId}/user/${userId}`);
  };
}
