import { ActivityService } from "lib/projects/services/ActivityService";

export class RemoveActivityService extends ActivityService {
  constructor() {
    super();
    this.errors = {
      ACTIVITY_HAVE_ACTIONS: "La actividad ya se encuenta en uso",
      ACTIVITY_NOT_FOUND: "La actividad no existe",
    };
  }

  remove = (id: string) => this.httpClient.delete(`/${id}`);
}
