import { AxiosResponse } from "axios";
import { ContractService } from "lib/contracts/services/ContractService";
import { ConsolidatedEvaluationResponse } from "lib/contracts/models/ConsolidatedEvaluationResponse";

export class ConsolidatedEvaluationService extends ContractService {
  getConsolidatedEvaluation = (
    towerId: string,
    week: number,
    params: {
      contractorId?: string;
    }
  ): Promise<AxiosResponse<ConsolidatedEvaluationResponse>> =>
    this.httpClient.get(`/consolidated/tower/${towerId}/week/${week}`, {
      params,
    });
}
