import React, { FC } from "react";
import { Alert, Card, H5, Intent } from "@blueprintjs/core";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFixedColumns,
} from "@devexpress/dx-react-grid-material-ui";
import { Box } from "@material-ui/core";
import moment from "moment";
import { ProgramContractTableProps } from "./interfaces/ProgramContractTableProps";
import {
  ProgramContractTableProvider,
  useProgramContractTableContext,
} from "./ProgramContractTableContext";
import useProgramContractTableStyles from "./styles";
import ProgramContractTableCellHeader from "./components/ProgramContractTableCellHeader/ProgramContractTableCellHeader";
import ProgramContractTableCell from "./components/ProgramContractTableCell/ProgramContractTableCell";
import ProgramContractIncidenceForm from "./components/ProgramContractIncidenceForm/ProgramContractIncidenceForm";
import ProgramContractForm from "./components/ProgramContractForm/ProgramContractForm";
import useTableCellHeaderActions, {
  ScheduleActions,
} from "./customHooks/useTableCellHeaderActions";
import ProgramFixedCell from "./components/ProgramFixedCell/ProgramFixedCell";

const ProgramContractTableComponent: FC = () => {
  const classes = useProgramContractTableStyles();
  const {
    contract,
    cols,
    rows,
    isTaskInProgress,
    lastEvaluationHaveState,
    taskEvaluations,
    requireProgramation,
    haveUser,
    onChangeValue,
    removeRow,
    selectTask,
    fetch,
  } = useProgramContractTableContext();

  const { state, actionsDispatch, onCancel, onConfirm } =
    useTableCellHeaderActions({ contract, fetch });

  return (
    <Card className={classes.root}>
      <Alert
        cancelButtonText="Cancelar"
        confirmButtonText="Confirmar"
        icon="edit"
        intent={Intent.PRIMARY}
        isOpen={
          state.isOpen && state.action === ScheduleActions.REMOVE_SCHEDULE
        }
        loading={state.isLoading}
        onCancel={onCancel}
        onConfirm={onConfirm}
      >
        <H5>Editar programación</H5>
        <p style={{ width: "250px" }}>
          ¿Está seguro de que desea editar esta programación?
          <br />
          Se eliminaran las programaciones posteriores y no podrá recuperarlas.
        </p>
      </Alert>
      <Alert
        {...{
          cancelButtonText: haveUser ? "Cancelar" : undefined,
          confirmButtonText: haveUser ? "Enviar" : "Cerrar",
        }}
        icon={haveUser ? "send-to-graph" : "warning-sign"}
        intent={Intent.PRIMARY}
        isOpen={state.isOpen && state.action === ScheduleActions.SEND_SCHEDULE}
        loading={state.isLoading}
        onCancel={onCancel}
        onConfirm={haveUser ? onConfirm : onCancel}
      >
        {haveUser ? (
          <p>
            Se enviará la programación al contratista, por lo que no podrá
            modificarla amenos que el contratista rechace la programación
          </p>
        ) : (
          <p>
            El contratista no tiene un usuario que pueda recibir la programación
          </p>
        )}
      </Alert>
      <Alert
        {...{
          confirmButtonText: "Cerrar",
        }}
        icon="label"
        intent={Intent.PRIMARY}
        isOpen={state.isOpen && state.action === ScheduleActions.VIEW_COMMENT}
        loading={state.isLoading}
        onConfirm={onCancel}
      >
        <p>{state.comment}</p>
      </Alert>
      <Box>
        {" "}
        <Box
          padding={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box p={1}>
            <Box>
              <span className={classes.bold}>
                <span className={classes.dark}>
                  (
                  {moment.utc(contract.activity.startDate).format("DD-MM-YYYY")}
                  {" - "}
                  {moment.utc(contract.activity.endDate).format("DD-MM-YYYY")})
                </span>
              </span>{" "}
              - {contract.activity.name}
            </Box>
          </Box>

          <Box display="flex">
            <Box paddingX={1}>
              {isTaskInProgress &&
              requireProgramation &&
              taskEvaluations.length ? (
                <ProgramContractIncidenceForm />
              ) : null}
            </Box>
            <Box paddingX={1}>
              {!lastEvaluationHaveState ? <ProgramContractForm /> : null}
            </Box>
          </Box>
        </Box>
        <Box>
          {taskEvaluations.length ? (
            <Grid rows={rows} columns={cols}>
              <Table
                columnExtensions={[
                  {
                    columnName: "inUse",
                    width: "70px",
                    align: "right",
                  },
                ]}
                cellComponent={(props) => (
                  <ProgramContractTableCell
                    {...props}
                    onChangeValue={onChangeValue}
                    removeRow={removeRow}
                    selectRow={selectTask}
                  />
                )}
              />
              <TableHeaderRow
                cellComponent={(props) => (
                  <ProgramContractTableCellHeader
                    {...props}
                    actionsDispatch={actionsDispatch}
                  />
                )}
              />
              <TableFixedColumns
                leftColumns={["name", "unit", "total"]}
                cellComponent={ProgramFixedCell}
              />
            </Grid>
          ) : null}
        </Box>
      </Box>
    </Card>
  );
};

const ProgramContractTable: FC<ProgramContractTableProps> = ({
  units,
  contract,
  haveUser,
}) => {
  return (
    <ProgramContractTableProvider
      units={units}
      contract={contract}
      haveUser={haveUser}
    >
      <ProgramContractTableComponent />
    </ProgramContractTableProvider>
  );
};

export default ProgramContractTable;
