import { TaskUnit } from "lib/contracts/models/TaskUnit";
import { TaskUnitService } from "lib/contracts/services/TaskUnitService";
import { IUseFormResult, useForm } from "shared/customHooks/useForm";

export type UseSubTaskUnitFormResult = IUseFormResult<TaskUnit>;

type HookType = (
  handleCreate: (unit: TaskUnit) => void
) => UseSubTaskUnitFormResult;

const useSubTaskUnitForm: HookType = (handleCreate) => {
  const form = useForm<TaskUnit>({
    validations: {
      name: {
        required: {
          value: true,
          message: "Debe asignar un motivo",
        },
      },
    },
    initialValues: {
      name: "",
    },
    async onSubmit(formData, reset) {
      try {
        const service = new TaskUnitService();
        const { data } = await service.createUnit(formData);
        if (data.unit.id) {
          handleCreate(data.unit);
          reset();
        }
      } catch (error) {
        alert(error);
      }
    },
  });

  return {
    ...form,
  };
};

export default useSubTaskUnitForm;
