import React, { FC } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import { Button, Classes, Dialog, Tag } from "@blueprintjs/core";
import NumberFormat from "react-number-format";
import useProgramContractForm from "./useProgramContractForm";

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ProgramContractForm: FC = () => {
  const componentClasses = useStyles();
  const {
    open,
    errors,
    data,
    units,
    contract,
    taskSelected,
    handleClick,
    handleClose,
    handleChange,
    handleSubmit,
    handleChangeValue,
    handleChangeSelect,
  } = useProgramContractForm();

  return (
    <span>
      {units.length ? (
        <Button
          disabled={!units.length}
          small
          onClick={handleClick}
          icon="plus"
        >
          Agregar actividad
        </Button>
      ) : (
        <Tag>No hay unidades configuradas</Tag>
      )}
      {open ? (
        <Dialog
          isOpen={open}
          title={taskSelected?.id ? "Actualizar actividad" : "Nueva actividad"}
          onClose={handleClose}
        >
          <div className={Classes.DIALOG_BODY}>
            <Box minWidth="400px">
              <table style={{ borderSpacing: 10 }}>
                <tbody>
                  <tr>
                    <td>Nombre actividad</td>
                    <td>
                      <b>{contract.activity.name}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Contratista</td>
                    <td>
                      <b>{contract.contractor.name}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
              <form id="form-subtask" onSubmit={handleSubmit} />
              <TextField
                value={data?.name || ""}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="activityname"
                label="Nombre de la actividad"
                name="activityname"
                autoComplete="activityname"
                autoFocus
                error={!!errors?.name}
                helperText={errors?.name}
                onChange={handleChange("name")}
              />
              <FormControl
                variant="outlined"
                className={componentClasses.formControl}
                required
                error={!!errors.unitId}
                fullWidth
              >
                <InputLabel htmlFor="outlined-age-native-simple">
                  Unidad de medida
                </InputLabel>
                <Select
                  inputProps={{
                    name: "age",
                    id: "outlined-age-native-simple",
                  }}
                  fullWidth
                  label="Unidad de medida"
                  value={data.unitId}
                  onChange={handleChangeSelect("unitId")}
                >
                  {units.map((unit) => (
                    <MenuItem
                      key={`unit_${unit.id}`}
                      value={unit.id}
                      selected={unit.id === data.unitId}
                    >
                      {unit.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.unitId ? (
                  <FormHelperText>{errors.unitId}</FormHelperText>
                ) : null}
              </FormControl>
              <NumberFormat
                customInput={TextField}
                label="Total"
                margin="normal"
                required
                fullWidth
                value={data?.total}
                onValueChange={({ value }) => {
                  handleChangeValue("total", value);
                }}
                name="numberformat"
                id="formatted-numberformat-input"
                error={!!errors?.total}
                helperText={errors?.total}
                variant="outlined"
                allowNegative={false}
                thousandSeparator
                isNumericString
              />
            </Box>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <Box display="flex">
              <Box width="40%" paddingRight={2}>
                <Button color="primary" onClick={handleClose} fill>
                  Cerrar
                </Button>
              </Box>
              <Button
                intent="primary"
                type="submit"
                fill
                form="form-subtask"
                disabled={
                  !!(
                    taskSelected?.id &&
                    taskSelected.name === data.name &&
                    taskSelected.unit.id === data.unitId &&
                    taskSelected.total === data.total
                  )
                }
              >
                {taskSelected?.id ? "Actualizar" : "Guardar"}
              </Button>
            </Box>
          </div>
        </Dialog>
      ) : null}
    </span>
  );
};

export default ProgramContractForm;
