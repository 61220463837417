import { Contract } from "lib/contracts/models/Contract";
import { TaskEvaluationsDTO } from "lib/contracts/models/TaskEvaluationsDTO";
import { ContractService } from "lib/contracts/services/ContractService";
import { useState } from "react";
import { IUseFormResult, useForm } from "shared/customHooks/useForm";
import { useProgramContractTableContext } from "../../ProgramContractTableContext";

export interface UseProgramContractIncidenceFormResult
  extends IUseFormResult<{ [key: string]: number }> {
  open: boolean;
  handleClick: () => void;
  handleClose: () => void;
  value: () => number;
  contract: Contract;
  tasks: TaskEvaluationsDTO[];
}

type HookType = () => UseProgramContractIncidenceFormResult;

const useProgramContractIncidenceForm: HookType = () => {
  const [open, setOpen] = useState(false);
  const { contract, taskEvaluations, fetch } = useProgramContractTableContext();

  const handleClick = (): void => {
    setOpen(true);
  };

  const resolvePercentage = (
    data: Partial<{ [key: string]: number }>
  ): number => {
    return (
      (Object.keys(data).length
        ? Object.values(data).reduce((a, b) => +(a || 0) + +(b || 0))
        : 0) || 0
    );
  };

  const form = useForm<{ [key: string]: number }>({
    initialValues:
      Array.isArray(taskEvaluations) && taskEvaluations.length
        ? taskEvaluations.reduce((prev, task) => {
            const currentPrev = prev;
            if (task.id)
              currentPrev[task?.id] = Math.trunc(100 / taskEvaluations.length);
            return currentPrev;
          }, {} as { [key: string]: number })
        : {},
    async onSubmit(newData, reset) {
      try {
        if (`${resolvePercentage(newData)}` !== `${100}`)
          alert({
            message: "El porcentaje final debe ser del 100%",
            severity: "warning",
          });
        else if (newData) {
          const body = Object.keys(newData).reduce((prev, key) => {
            const task = taskEvaluations.find((el) => `${el.id}` === `${key}`);
            const week = task?.evaluations
              ? task.evaluations.find((el) => el.currentProgramation)
              : null;

            if (task && task.id && week)
              return prev.concat({
                id: task.id,
                value: week.value.toFixed(2),
                week: week.week,
                towerWeek: week.towerWeek,
                currentWeek: 0,
                percentage: +newData[key],
              });
            return prev;
          }, [] as { id: string; value: string; percentage: number; week: number; currentWeek: number; towerWeek: number }[]);
          const service = new ContractService();
          await service.savePercentageTasks(contract.id, body);
          reset();
          fetch();
          alert({ message: "Incidencia guardada", severity: "success" });
          setOpen(false);
        }
      } catch (error) {
        alert(error);
      }
    },
  });

  const handleClose = (): void => {
    setOpen(false);
    form.resetData();
  };

  const value = (): number => resolvePercentage(form.data);

  return {
    open,
    handleClick,
    handleClose,
    value,
    contract,
    tasks: taskEvaluations,
    ...form,
  };
};

export default useProgramContractIncidenceForm;
