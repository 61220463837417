import { useState } from "react";

const useBoolean = (defaultValue = false) => {
  const [value, setValue] = useState(defaultValue);
  const setTrue = () => setValue(true);
  const setFalse = () => setValue(false);

  return {
    value,
    setFalse,
    setTrue,
  };
};

export default useBoolean;
