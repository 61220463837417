import { Activity } from "lib/projects/models/Activity";
import { ActivityService } from "lib/projects/services/ActivityService";
import { CreationActivityModel } from "../models/CreationActivityModel";

export class CreateActivityService extends ActivityService {
  constructor() {
    super();
    this.errors = {
      START_DATE_GREATER_END_DATE:
        "La fecha de inicio no puede ser mayor a la fecha de fin",
      END_DATE_LESS_END_DATE:
        "La fecha de fin no puede ser menor a la fecha de inicio",
      HAVE_EXISTS: "Ya existe una actividad con el mismo nombre",
    };
  }

  create = (towerId: string, data: CreationActivityModel) =>
    this.httpClient.post<{ activity: Activity }>(`/${towerId}`, data);
}
