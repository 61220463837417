import { Contract } from "lib/contracts/models/Contract";
import { Task } from "lib/contracts/models/Task";
import { TaskEvaluationsDTO } from "lib/contracts/models/TaskEvaluationsDTO";
import { TaskUnit } from "lib/contracts/models/TaskUnit";
import { ContractService } from "lib/contracts/services/ContractService";
import { TaskService } from "lib/contracts/services/TaskService";
import { useEffect, useState } from "react";
import { IUseFormResult, useForm } from "shared/customHooks/useForm";
import { useProgramContractTableContext } from "../../ProgramContractTableContext";

export interface UseProgramContractFormResult extends IUseFormResult<Task> {
  open: boolean;
  handleClick: () => void;
  handleClose: () => void;
  contract: Contract;
  units: TaskUnit[];
  taskSelected?: TaskEvaluationsDTO;
}

type HookType = () => UseProgramContractFormResult;

const useProgramContractForm: HookType = () => {
  const [open, setOpen] = useState(false);

  const { contract, units, fetch, taskSelected, selectTask } =
    useProgramContractTableContext();

  const handleClick = (): void => {
    setOpen(true);
  };

  const form = useForm<Task>(
    {
      validations: {
        name: {
          required: {
            value: true,
            message: "Se requiere el nombre de la actividad",
          },
        },
        unitId: {
          required: {
            value: true,
            message: "Se asignar una unidad de medida",
          },
        },
        total: {
          required: {
            value: true,
            message: "Se agregar un total",
          },
          custom: {
            isValid: (value) => {
              try {
                const numValue = +value;
                if (typeof taskSelected?.prevTotal === "number") {
                  if (taskSelected?.prevTotal === taskSelected?.total)
                    return true;
                  return numValue > taskSelected.prevTotal;
                }
                if (typeof numValue === "number") return numValue > 0;
              } catch (error) {
                return false;
              }
              return false;
            },
            message: `Se requiere de un número suberior a ${
              typeof taskSelected?.prevTotal === "number"
                ? `${taskSelected.prevTotal}`
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0
            }`,
          },
        },
      },
      initialValues: {
        name: "" || taskSelected?.name,
        unitId: "" || taskSelected?.unit.id,
        total: 0 || taskSelected?.total,
      },
      async onSubmit(newData, reset) {
        try {
          if (taskSelected?.id) {
            const updatedData = {} as Record<string, unknown>;
            const task: Partial<Task> = {
              unitId: taskSelected.unit.id,
              name: taskSelected.name,
              total: taskSelected.total,
            };

            Object.keys(task).forEach((keyString) => {
              const key = keyString as keyof Partial<Task>;
              if (newData[key] !== task[key]) updatedData[key] = newData[key];
            });
            const taskService = new TaskService();
            await taskService.update(taskSelected.id, updatedData);
            fetch();
            reset();
            setOpen(false);
            selectTask(undefined);
            alert({ message: "Actividad guardada", severity: "success" });
          } else if (newData) {
            const service = new ContractService();
            await service.contratorCreateSubtask(contract.id, newData);
            alert({ message: "Actividad guardada", severity: "success" });
            fetch();
            reset();
            setOpen(false);
          }
        } catch (error) {
          alert(error);
        }
      },
    },
    [taskSelected?.id]
  );

  const handleClose = (): void => {
    setOpen(false);
    selectTask(undefined);
    form.resetData();
  };

  useEffect(() => {
    if (taskSelected?.id) {
      handleClick();
    }
  }, [taskSelected?.id]);

  return {
    open,
    handleClick,
    handleClose,
    contract,
    units,
    taskSelected,
    ...form,
  };
};

export default useProgramContractForm;
