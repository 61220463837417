import React, { FC } from "react";
import { Divider, H3 } from "@blueprintjs/core";
import { Box } from "@material-ui/core";
import Summary from "components/Summary/Summary";
import ActivityContractorList from "modules/PAC/features/ActivityContractorList/ActivityContractorList";
import AssignContractorToActivity from "modules/PAC/features/AssignContractorToActivity/AssignContractorToActivity";
import ChangeActivityDate from "modules/PAC/features/ChangeActivityDate/ChangeActivityDate";
import CreateActivity from "modules/PAC/features/CreateActivity/CreateActivity";
import RemoveActivity from "modules/PAC/features/RemoveActivity/RemoveActivity";
import HavePermission from "components/HavePermission/HavePermission";
import useAssignContractorPage from "./hooks/useAssignContractorPage";

const AssignContractorPage: FC = () => {
  const { tower } = useAssignContractorPage();
  return (
    <>
      {tower?.id ? (
        <>
          <ActivityContractorList
            tower={tower}
            assignContractorComponent={AssignContractorToActivity}
            changeActivityDateComponent={ChangeActivityDate}
            removeActivityComponent={RemoveActivity}
            tableHeader={({ handleAddActivity }) => {
              return (
                <>
                  <Summary>
                    <Box display="flex" justifyContent="space-between">
                      <Box>
                        <H3 style={{ margin: 0 }}>Actividades: {tower.name}</H3>
                      </Box>
                      <HavePermission
                        resourceName="pac.assignActivities"
                        action="canAdd"
                      >
                        <CreateActivity
                          tower={tower}
                          onActivityCreated={handleAddActivity}
                        />
                      </HavePermission>
                    </Box>
                  </Summary>
                  <Box marginY={2}>
                    <Divider />
                  </Box>
                </>
              );
            }}
          />
        </>
      ) : null}
    </>
  );
};

export default AssignContractorPage;
