import { configureStore } from "@reduxjs/toolkit";
import { APPReducer } from "App/store";
import { PACReducer } from "modules/PAC/store";
import { AuthenticationReducer } from "features/Authentication/core/redux/AuthenticationSlice";
import { ContractorReducer } from "modules/Contractor/store";

export const store = configureStore({
  reducer: {
    app: APPReducer,
    auth: AuthenticationReducer,
    PAC: PACReducer,
    Contractor: ContractorReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
