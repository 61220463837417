import React, { FC } from "react";
import {
  Box,
  Button,
  Divider,
  List,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import InputSearch from "shared/components/InputSearch/InputSearch";
import ListItemElement from "shared/components/ListItem/ListItemElement";
import {
  ProjectHomeProvider,
  useProjectHomeContext,
} from "./context/ProjectHomeContext";
import ProjectModal from "./components/ProjectModal/ProjectModal";

const useStyles = makeStyles({
  root: {},
});

const ProjectHome: FC = () => {
  const classes = useStyles();
  const { projects, selectProject, open, openModal, handleChangeSearch } =
    useProjectHomeContext();
  return (
    <Box className={classes.root}>
      {open ? <ProjectModal /> : null}
      <Box display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between">
          <InputSearch onChange={handleChangeSearch} />
          <Button
            color="primary"
            variant="contained"
            startIcon={<Add />}
            onClick={openModal}
          >
            <Typography style={{ fontSize: "1em" }}>Nuevo proyecto</Typography>
          </Button>
        </Box>
        <Box mt={4}>
          <h1>
            <Typography variant="h1">Proyectos</Typography>
          </h1>
        </Box>
        <Divider />
        <Box>
          <List>
            {projects.map((project) => (
              <ListItemElement
                key={project.id}
                title={project.name}
                subTitle={`Etapas creadas ${project?.numberOfTowers}`}
              >
                <div>
                  <Button
                    variant="outlined"
                    aria-label="MoreVert"
                    onClick={() => selectProject(project)}
                  >
                    Editar
                  </Button>
                </div>
              </ListItemElement>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

const ProjectHomeContainer: FC = () => {
  return (
    <ProjectHomeProvider>
      <ProjectHome />
    </ProjectHomeProvider>
  );
};

export default ProjectHomeContainer;
