import React, { FC } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import { TextField } from "@material-ui/core";
import useInvite from "./customHooks/useInvite";

const Copyright: FC = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      {new Date().getFullYear()}.
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flex: "1 1 ",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondaryDark.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const InvitePage: FC = () => {
  const classes = useStyles();
  const {
    user,
    data,
    isRecovery,
    handleSubmit,
    handleChange,
    errors,
    formLoading,
  } = useInvite();

  return (
    <Container component="main" maxWidth="xs" className={classes.root}>
      <CssBaseline />
      {user?.id ? (
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <EmojiPeopleIcon />
          </Avatar>
          {!isRecovery && (
            <>
              <Typography component="h1" variant="h1">
                Bienvenido
              </Typography>
            </>
          )}
          <Typography component="h1" variant="h1">
            {user?.name} {user?.lastName || ""}
          </Typography>
          <Typography component="h2" variant="h2">
            Debe asignar una contraseña
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              value={data?.password || ""}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Contraseña"
              type="password"
              name="password"
              autoFocus
              error={!!errors?.password}
              helperText={errors?.password}
              onChange={handleChange("password")}
            />
            <TextField
              value={data?.confirmPassword || ""}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Confirmar contraseña"
              type="password"
              name="confirmPassword"
              error={!!errors?.confirmPassword}
              helperText={errors?.confirmPassword}
              onChange={handleChange("confirmPassword")}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={formLoading}
              className={classes.submit}
            >
              <Typography>Asignar contraseña</Typography>
            </Button>
          </form>
        </div>
      ) : null}
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default InvitePage;
