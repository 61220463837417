import { makeStyles, Theme } from "@material-ui/core";

const EvaluateContractorsFilterStyles = makeStyles((theme: Theme) => ({
  root: {},
  filterLabel: {
    padding: 10,
    fontSize: "1.2em",
    fontWeight: "bold",
    color: theme.palette.detail.main,
  },
  label: {
    color: theme.palette.detail.main,
  },
}));

export default EvaluateContractorsFilterStyles;
