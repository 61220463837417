import React, { FC } from "react";
import { Card, Classes, H1, NonIdealState } from "@blueprintjs/core";
import { Flex } from "shared/components/Flex/Flex";
import moment from "moment";
import { Box } from "@material-ui/core";
import { ResolveVirtualWeek } from "lib/projects/utils/ResolveVirtualWeek";
import useProgrammingConsolidated from "./hooks/useProgrammingConsolidated";
import ContractorCollapse from "./components/ContractorCollapse/ContractorCollapse";
import Header from "./components/Header/Header";
import Filter from "./components/Filter/Filter";

const ProgrammingConsolidated: FC = () => {
  const {
    week,
    contractors,
    tower,
    currentProgrammingWeek,
    currentWeek,
    isFetching,
    virtualWeek,
    fetch,
  } = useProgrammingConsolidated();
  return (
    <div>
      <Flex justifyContent="space-between" container alignItems="center">
        <Flex>
          <H1>Informe Consolidado de Programación</H1>
        </Flex>
      </Flex>
      {tower && tower.id && Array.isArray(week) ? (
        <Header
          tower={tower}
          currentWeek={week}
          week={(currentProgrammingWeek || 0) + ResolveVirtualWeek(virtualWeek)}
        />
      ) : null}
      <Flex container justifyContent="space-between" alignItems="center">
        <Filter
          week={currentWeek || 1}
          programmingWeek={currentProgrammingWeek || 1}
          onSubmit={fetch}
          virtualWeek={ResolveVirtualWeek(virtualWeek)}
        />
        <Flex>
          {week && week.length ? (
            <Flex
              style={{
                fontSize: "1.2em",
                fontWeight: "bold",
                color: "darkgray",
              }}
            >
              <Flex container justifyContent="space-between">
                <span>Inicia:</span>{" "}
                <span style={{ marginLeft: "1em" }}>
                  {moment(week[0]).format("DD MMM YYYY")}
                </span>
              </Flex>
              <Flex container justifyContent="space-between">
                <span>Finaliza:</span>{" "}
                <span style={{ marginLeft: "1em" }}>
                  {moment(week[1]).format("DD MMM YYYY")}
                </span>
              </Flex>
            </Flex>
          ) : null}
        </Flex>
      </Flex>

      {!isFetching ? (
        <div>
          {contractors && contractors.length ? (
            <Flex>
              {contractors.map((contractor) => (
                <ContractorCollapse contractor={contractor} />
              ))}
            </Flex>
          ) : (
            <Card>
              <NonIdealState
                icon="warning-sign"
                title="Sin resultados"
                description="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año"
              />
            </Card>
          )}
        </div>
      ) : (
        <Box
          height={200}
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={Classes.SKELETON}
        />
      )}
    </div>
  );
};

export default ProgrammingConsolidated;
