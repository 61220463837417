import { useEffect, useState } from "react";
import { Activity } from "lib/projects/models/Activity";
import { Column } from "@devexpress/dx-react-grid";
import { TaskRow } from "../models/ActivityContractorListRow";

interface Props {
  activities: Activity[];
}

const useActivityContractorListTable = ({ activities }: Props) => {
  const [rows, setRows] = useState<TaskRow[]>([]);
  const [cols] = useState<Column[]>([
    { name: "name", title: "Nombre" },
    { name: "contractor", title: "Contratista" },
    { name: "startDate", title: "Fecha Inicio" },
    { name: "endDate", title: "Fecha Fin" },
    { name: "", title: "" },
  ]);

  useEffect(() => {
    setRows(
      activities.map((activity) => {
        const { contracts, ...extraTask } = activity;
        const contractor = contracts?.length
          ? contracts[0].contractor
          : undefined;
        const lastScheduledTowerWeek = contracts?.length
          ? contracts[0].lastScheduledTowerWeek || 0
          : 0;

        return {
          ...extraTask,
          contractor,
          lastScheduledTowerWeek,
          numberOfContractors: contracts?.length || 0,
        };
      })
    );
  }, [activities]);

  return {
    rows,
    cols,
  };
};
export default useActivityContractorListTable;
