import React, { FC } from "react";
import { Container, makeStyles } from "@material-ui/core";
import { Route, useHistory, useRouteMatch } from "react-router";
import CustomSwitch from "shared/components/CustomSwitch/CustomSwitch";
import AppBreadcrumb from "shared/components/AppBreadcrumb/AppBreadcrumb";
import ConsolidatedPage from "modules/PAC/pages/ConsolidatedPage/ConsolidatedPage";
import HavePermission from "components/HavePermission/HavePermission";
import PACPage from "modules/PAC/pages/PAC/PACPage";
import EvalueateContractorContainer from "./containers/EvaluateContract/EvalueateContractor";
import {
  EvaluateProvider,
  useEvaluateContext,
} from "./context/EvaluateContext";

const useStyles = makeStyles({
  root: {
    marginLeft: 36,
  },
});

const Evaluate: FC = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const classes = useStyles();
  const { routes } = useEvaluateContext();

  return (
    <Container className={classes.root}>
      <AppBreadcrumb routes={routes} />
      <CustomSwitch>
        <Route
          exact
          path={`${path}/:towerId/consolidated`}
          component={() => (
            <HavePermission
              action="canView"
              resourceName="pac.evaluateConsolidated"
              onReject={() => history.push("/unauthorized")}
            >
              <ConsolidatedPage />
            </HavePermission>
          )}
        />
        <Route
          exact
          path={`${path}/:towerId/:contractorId`}
          component={() => (
            <HavePermission
              action="canView"
              resourceName="pac.viewEvaluations"
              onReject={() => history.push("/unauthorized")}
            >
              <EvalueateContractorContainer />
            </HavePermission>
          )}
        />
        <Route exact path={path} component={PACPage} />
      </CustomSwitch>
    </Container>
  );
};

const PACEvaluatePage: FC = () => {
  return (
    <EvaluateProvider>
      <Evaluate />
    </EvaluateProvider>
  );
};

export default PACEvaluatePage;
