import { useEffect, useState } from "react";
import { TaskWithEvaluations } from "lib/contracts/models/Task";
import { TaskEvaluation } from "lib/contracts/models/TaskEvaluation";

interface Params {
  week?: number;
  task: TaskWithEvaluations;
}

const useListTaskEvaluationsItem = ({ week, task }: Params) => {
  const [evaluation, setEvaluation] = useState<TaskEvaluation>();
  useEffect(() => {
    if (task.evaluations?.length && week) {
      setEvaluation(task.evaluations.find((element) => element.week === week));
    }
  }, [task, week]);

  return {
    evaluation,
  };
};

export default useListTaskEvaluationsItem;
