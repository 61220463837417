import moment, { Moment } from "moment";

export type Weeks = Moment[][];

export class DateUtils {
  static setStartDate = (date: Moment) =>
    date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  static setEndDate = (date: Moment) =>
    date.set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 59,
    });

  static getWeekBetweenTowDates = (
    numberOfDayStartWeek: number,
    startDate: Date,
    endDate: Date
  ) => {
    const startWeek = numberOfDayStartWeek;
    const start = DateUtils.setStartDate(moment.utc(startDate));

    const end = DateUtils.setEndDate(moment.utc(endDate));

    const initDayDate = start.clone().day(startWeek);
    const isBefore = initDayDate.isSameOrBefore(start);

    const endDateInit = DateUtils.setStartDate(end);

    const initStartWeekDate = isBefore
      ? initDayDate.clone()
      : initDayDate.clone().subtract(1, "week");

    const initEndWeekDate = isBefore
      ? initDayDate.clone().add(1, "week").subtract(1, "day")
      : initDayDate.clone().subtract(1, "day");

    const weeks = [
      [
        DateUtils.setStartDate(initStartWeekDate),
        DateUtils.setEndDate(initEndWeekDate),
      ],
    ];

    if (+start !== +endDateInit) {
      let currentStartDate = initStartWeekDate.clone();
      let currentEndDate = initEndWeekDate.clone();
      while (!currentEndDate.isAfter(end)) {
        currentStartDate = currentEndDate.clone().add(1, "day");
        currentEndDate = currentEndDate.clone().add(1, "week");
        weeks.push([
          DateUtils.setStartDate(currentStartDate),
          DateUtils.setEndDate(currentEndDate),
        ]);
      }
    }
    return weeks;
  };

  static getNumberWeekOfDate = (weeks: Weeks, day?: Moment) => {
    const currentDate =
      day ||
      moment.utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    let currentWeek = -1;
    weeks.forEach((week, index) => {
      if (currentDate >= week[0] && currentDate <= week[1])
        currentWeek = index + 1;
    });
    return currentWeek;
  };

  static resolveWeeks = (startDay: number, startDate: Date, endDate: Date) => {
    const weeks = DateUtils.getWeekBetweenTowDates(
      startDay,
      startDate,
      endDate
    );
    return {
      currentWeek: DateUtils.getNumberWeekOfDate(weeks),
      weeks,
    };
  };

  static getMomentByStringOrDate = (date: Date | string) => moment(date);
}
