import React, { FC } from "react";
import { Breadcrumb, Breadcrumbs, Icon } from "@blueprintjs/core";
import useAppBreadcrumb, {
  BreadcrumbPropsProgram,
  UseAppBreadcrumbProps,
} from "./hooks/useAppBreadcrumb";

const AppBreadcrumb: FC<UseAppBreadcrumbProps> = ({
  routes: initialRoutes,
}) => {
  const { routes, onSelectRoute } = useAppBreadcrumb({
    routes: initialRoutes,
  });
  return (
    <Breadcrumbs
      breadcrumbRenderer={({
        text,
        href,
        current,
        resolveName,
        ...rest
      }: BreadcrumbPropsProgram) => {
        return (
          <Breadcrumb
            {...rest}
            current={current}
            onClick={() => onSelectRoute(href, current)}
          >
            {resolveName ? resolveName() : text}
            <Icon icon="caret-right" />
          </Breadcrumb>
        );
      }}
      currentBreadcrumbRenderer={({
        text,
        href,
        current,
        resolveName,
        ...rest
      }: BreadcrumbPropsProgram) => {
        return (
          <Breadcrumb
            {...rest}
            current={current}
            onClick={() => onSelectRoute(href, current)}
          >
            {resolveName ? resolveName() : text}
          </Breadcrumb>
        );
      }}
      items={routes}
    />
  );
};

export default AppBreadcrumb;
