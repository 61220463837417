import { TaskReason } from "lib/contracts/models/TaskReason";
import { TaskReasonService } from "lib/contracts/services/TaskReasonService";
import { FormEvent, useState } from "react";
import { IUseFormResult, useForm } from "shared/customHooks/useForm";

export interface UseUpdateTaskReasonFormParams {
  onSave: () => Promise<void>;
  reason: TaskReason;
}

export interface UseUpdateTaskReasonFormResult
  extends IUseFormResult<TaskReason> {
  selected: boolean;
  select: () => void;
  unSelect: () => void;
  submit: () => void;
}

type HookType = (
  param: UseUpdateTaskReasonFormParams
) => UseUpdateTaskReasonFormResult;

const useUpdateTaskReasonForm: HookType = ({ onSave, reason }) => {
  const [selected, setSelected] = useState(false);

  const form = useForm<TaskReason>({
    validations: {
      name: {
        required: {
          value: true,
          message: "Debe asignar un motivo",
        },
      },
    },
    initialValues: {
      name: reason.name,
    },
    async onSubmit(formData, reset) {
      try {
        if (reason.id) {
          const service = new TaskReasonService();
          const { data } = await service.update(reason.id, formData);
          if (data.updated) {
            reset();
            await onSave();
            setSelected(false);
            alert({ message: "Actualizado", severity: "success" });
          }
        }
      } catch (error) {
        alert(error);
      }
    },
  });

  const select = (): void => setSelected(true);
  const unSelect = (): void => {
    setSelected(false);
    form.resetData();
  };

  const submit = (): void => {
    form.handleSubmit({
      preventDefault: (): void => {
        // empty
      },
    } as FormEvent<HTMLFormElement>);
  };

  return {
    ...form,
    selected,
    select,
    unSelect,
    submit,
  };
};

export default useUpdateTaskReasonForm;
