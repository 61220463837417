import { Box } from "@material-ui/core";
import { Contractor } from "lib/contractors/models/Contractor";
import React, { FC } from "react";
// import useStyles from "./styles";
import useContractorUser from "./useContractorUser";

const ContractorUsers: FC<{ contractor: Contractor }> = ({ contractor }) => {
  // const classes = useStyles();
  const { users } = useContractorUser({ contractor });
  return <Box p={2}>{JSON.stringify(users, null, 2)}</Box>;
};

export default ContractorUsers;
