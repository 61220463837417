import React, { FC } from "react";
import { Box } from "@material-ui/core";
import { Alert, Button, Card, H1, H2, H3, Intent } from "@blueprintjs/core";
import { TaskWithEvaluations } from "lib/contracts/models/Task";
import moment from "moment";
import { useHistory } from "react-router";
import { ResolveVirtualWeek } from "lib/projects/utils/ResolveVirtualWeek";
import useListTaskEvaluations from "./hooks/useListTaskEvaluations";
import ListTaskEvaluationsItem from "./components/ListTaskEvaluationsItem/ListTaskEvaluationsItem";
import useStyles from "./styles";
import useApproveEvaluation from "./hooks/useApproveEvaluation";
import DialogReject from "./components/DialogReject/DialogReject";

const ListTaskEvaluationsPage: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const {
    contract,
    rejectedWeek,
    lastPassedWeek,
    approvalWeek,
    towerWeek,
    isOpenApprove,
    virtualWeek,
    isOpenReject,
    week,
    openReject,
    closeReject,
    openApprove,
    closeApprove,
    fetch,
  } = useListTaskEvaluations();

  const { approve, isLoading: isLoadingApprove } = useApproveEvaluation({
    onApproveEvaluation: () => {
      fetch();
      closeApprove();
    },
  });

  return (
    <Box>
      {contract?.id ? (
        <Box>
          <Box>
            <Box
              display="flex"
              alignItems="center"
              style={{ marginBottom: 10 }}
            >
              {contract.activity.tower?.project?.id ? (
                <Box marginRight={2}>
                  <Button
                    icon="arrow-left"
                    onClick={() => {
                      history.push(
                        `/contractor/projects/${contract.activity.tower?.project?.id}`
                      );
                    }}
                  />
                </Box>
              ) : null}
              <H1>{contract.activity.name}</H1>
            </Box>
            <Card className={classes.root}>
              <Box>
                Proyecto:{" "}
                <span className={classes.dark}>
                  {contract.activity.tower?.project?.name}
                </span>
              </Box>
              <Box>
                Nombre etapa:{" "}
                <span className={classes.dark}>
                  {contract.activity.tower?.name}
                </span>
              </Box>
              <br />
              <Box>
                Semana:{" "}
                <span className={classes.dark}>
                  {(towerWeek || 0) + ResolveVirtualWeek(virtualWeek)}
                </span>
                <br />
                <span className={classes.dark}>
                  ({moment(week?.startDate).format("DD MMMM YYYY")}
                  {" - "}
                  {moment(week?.endDate).format("DD MMMM YYYY")})
                </span>
              </Box>
            </Card>
          </Box>
          <Box display="flex" justifyContent="center" marginTop={2}>
            <H2>
              {rejectedWeek ? "Rechazado" : null}
              {!rejectedWeek && !approvalWeek && lastPassedWeek
                ? "Última aprobación"
                : null}
              {approvalWeek ? "Aprobación" : null}
            </H2>
          </Box>
          <Box>
            <H3>Actividades</H3>
            {contract.tasks?.map((task) => {
              return (
                <ListTaskEvaluationsItem
                  task={task as TaskWithEvaluations}
                  key={task.id}
                  week={rejectedWeek || approvalWeek || lastPassedWeek}
                />
              );
            })}
          </Box>
          {approvalWeek ? (
            <Box display="flex" width="100%" marginTop={2}>
              <Alert
                cancelButtonText="Cancelar"
                confirmButtonText="Aprobar"
                icon="endorsed"
                intent={Intent.SUCCESS}
                isOpen={isOpenApprove}
                loading={isLoadingApprove}
                onCancel={closeApprove}
                onConfirm={() => approve(contract.id, approvalWeek)}
              >
                <p>
                  Se realizará la aprobación de la programación para la semana{" "}
                  {(towerWeek || 0) + ResolveVirtualWeek(virtualWeek)}
                </p>
              </Alert>
              <DialogReject
                isOpen={isOpenReject}
                week={approvalWeek}
                contractId={contract.id}
                closeModal={closeReject}
                onClose={closeReject}
                onReject={() => {
                  fetch();
                  closeReject();
                }}
              />
              <Box flexGrow={1} paddingRight={1}>
                <Button fill intent="success" onClick={openApprove}>
                  Aprobar
                </Button>
              </Box>
              <Box flexGrow={1} paddingLeft={1} onClick={openReject}>
                <Button fill intent="danger">
                  Rechazar
                </Button>
              </Box>
            </Box>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
};

export default ListTaskEvaluationsPage;
