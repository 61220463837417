import React, { ChangeEventHandler, FC, useRef } from "react";
import {
  alpha,
  createStyles,
  InputBase,
  InputBaseProps,
  makeStyles,
  Paper,
  Theme,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: (props: { maxWidth?: number | string }) =>
        props?.maxWidth || 160,
      display: "flex",
      paddingRight: 20,
      border: "1px solid",
      borderColor: alpha(theme.palette.detail.main, 0.2),
      position: "relative",
    },
    input: { boxSizing: "border-box" },
    icon: {
      color: theme.palette.detail.main,
      padding: 10,
      top: 1,
      position: "relative",
    },
  })
);

const InputSearch: FC<{
  onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  maxWidth?: number | string;
}> = ({ onChange, maxWidth }) => {
  const classes = useStyles({ maxWidth });
  const ref = useRef<InputBaseProps>();

  return (
    <Paper elevation={0} className={classes.root}>
      <div className={classes.icon}>
        <Search />
      </div>
      <InputBase
        className={classes.input}
        placeholder="Buscar"
        ref={ref}
        fullWidth
        autoFocus
        onChange={onChange}
      />
    </Paper>
  );
};

export default InputSearch;
