import { ContractEval } from "lib/contracts/models/ContractEval";
import { EvaluationState } from "lib/contracts/models/Evaluation";
import { Task } from "lib/contracts/models/Task";
import { TaskEvaluation } from "lib/contracts/models/TaskEvaluation";
import { Tower } from "lib/projects/models/Tower";
import { useEffect, useState } from "react";
import { NumberFormatValues, SourceInfo } from "react-number-format";

export interface EvaluationValue {
  completed: boolean;
  evaluationValue?: string;
  programValue?: number;
  state?: EvaluationState;
  evaluationId?: string;
  changed: boolean;
  compliance: number;
  percentage: number;
  qualification?: number;
}

interface EvaluationValues {
  [key: string]: EvaluationValue;
}

export interface UseEvaluateContractorTableParams {
  contract: ContractEval;
  tower?: Tower;
  fetch: () => Promise<void>;
}

type HandleValueChange = (
  key: string,
  subtask: TaskEvaluation,
  max?: number
) => (values: NumberFormatValues, sourceInfo: SourceInfo) => void;

export interface UseEvaluateContractorTableResult {
  contract: ContractEval;
  evaluationValues: EvaluationValues;
  taskEvaluation: EvaluationValue;
  tower?: Tower;
  isOpen: boolean;
  isOpenModal: boolean;
  isOpenDrawer: boolean;
  taskSelected?: Task;
  toggleOpen: () => void;
  toggleOpenModal: () => void;
  toggleDrawer: () => void;
  handleValueChange: HandleValueChange;
  saveTaskEvaluation: (task?: Task) => void;
  fetch: () => Promise<void>;
  onSaveRow: (task: Task) => void;
}

type HookType = (
  param: UseEvaluateContractorTableParams
) => UseEvaluateContractorTableResult;

const useEvaluateContractorTable: HookType = ({ contract, tower, fetch }) => {
  const [evaluationValues, setEvaluationsValues] = useState<EvaluationValues>(
    {}
  );
  const [saved, setSaved] = useState(false);

  const [taskEvaluation, setTaskEvaluation] = useState<EvaluationValue>({
    evaluationId: "",
    completed: false,
    state: EvaluationState.SCHEDULED_PASSED,
    evaluationValue: "0",
    changed: false,
    compliance: 0,
    percentage: 0,
  });
  const [isOpen, setIsOpen] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [taskSelected, setTaskSelected] = useState<Task>();

  useEffect(() => {
    if (contract.id && contract.tasks.length) {
      const evaluations = contract.tasks.reduce((prev, task) => {
        const currentPrev = prev;
        const compliance =
          ((task.evaluations?.evaluationValue || 0) * 100) /
          (task.evaluations?.programValue || 0 || 1);
        const realCompliance = compliance > 100 ? 100 : compliance;
        currentPrev[`${task.id}`] = {
          completed: !!task.evaluations?.completed,
          programValue: task.evaluations?.programValue,
          state: task.evaluations?.state,
          evaluationValue: `${task.evaluations?.evaluationValue}`,
          evaluationId: task.evaluations?.id,
          changed: task.evaluations?.programValue === 0 || false,
          percentage: task.evaluations?.percentage || 0,
          compliance: Math.trunc(realCompliance),
        };
        return currentPrev;
      }, {} as EvaluationValues);
      setEvaluationsValues(evaluations);
    }
  }, [contract]);

  useEffect(() => {
    if (Object.values(evaluationValues).length) {
      const compliance =
        Object.values(evaluationValues).reduce((prev, value) => {
          return prev + value.compliance * value.percentage;
        }, 0) / 100;
      setTaskEvaluation({
        ...taskEvaluation,
        compliance,
        qualification: compliance === 100 ? 5 : 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluationValues]);

  useEffect(() => {
    if (
      !Object.values(evaluationValues).some(
        (value) => value.state !== EvaluationState.SCHEDULED_EVALUATED
      ) &&
      saved
    ) {
      fetch();
      setSaved(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluationValues, saved]);

  const handleValueChange: HandleValueChange =
    (key, subtask, max) => (values) => {
      const { floatValue } = values;
      let error = false;
      const numericValue = floatValue;
      if (Number.isNaN(numericValue)) return;
      const evaluationValue = evaluationValues[key];
      if (evaluationValue) {
        let correctionValue = 0;
        if (numericValue && !Number.isNaN(numericValue))
          correctionValue = numericValue;
        if (
          numericValue &&
          max &&
          numericValue > max &&
          evaluationValue.evaluationValue !== undefined
        ) {
          correctionValue = +evaluationValue.evaluationValue;
          alert({
            message: `El valor no puede ser mayor a ${max}`,
            severity: "warning",
          });
          error = true;
        }

        if (!error) {
          const compliance =
            (correctionValue * 100) / (subtask.programValue || 1);
          const realCompliance = compliance > 100 ? 100 : compliance;
          setEvaluationsValues({
            ...evaluationValues,
            [key]: {
              ...evaluationValue,
              evaluationValue:
                typeof numericValue === "number"
                  ? `${numericValue}`
                  : numericValue,
              compliance: Math.trunc(realCompliance),
              changed: subtask.evaluationValue !== correctionValue,
            },
          });
        }
      }
    };

  const toggleOpenModal = (): void => {
    if (!isOpen) {
      setTaskSelected(undefined);
    }
    setIsOpenModal(!isOpen);
  };

  const saveTaskEvaluation = (task?: Task): void => {
    setTaskSelected(task);
    setIsOpenModal(true);
  };

  const onSaveRow = (task: Task): void => {
    const key = task.id;
    if (key) {
      const evaluationValue = evaluationValues[key];
      setSaved(true);
      setEvaluationsValues({
        ...evaluationValues,
        [key]: {
          ...evaluationValue,
          state: EvaluationState.SCHEDULED_EVALUATED,
        },
      });
    }
  };

  const toggleOpen = (): void => setIsOpen(!isOpen);
  const toggleDrawer = (): void => setIsOpenDrawer(!isOpenDrawer);

  return {
    contract,
    evaluationValues,
    taskEvaluation,
    tower,
    isOpen,
    isOpenModal,
    isOpenDrawer,
    taskSelected,
    toggleOpen,
    handleValueChange,
    toggleOpenModal,
    saveTaskEvaluation,
    fetch,
    toggleDrawer,
    onSaveRow,
  };
};

export default useEvaluateContractorTable;
