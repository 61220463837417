import React from "react";
import { Icon } from "@blueprintjs/core";
import {
  SettingsOutlined,
  MonetizationOnOutlined,
  Assignment,
} from "@material-ui/icons";

export interface SideElement {
  name: string;
  label: string;
  icon?: React.ElementType;
  path: string;
  childs?: SideElement[];
  matchs?: string[];
  unique?: boolean;
  disabled?: boolean;
}

const Side: SideElement[] = [
  {
    name: "configuration",
    path: "/configuration",
    label: "Configuración",
    icon: SettingsOutlined,
    childs: [
      {
        name: "persons",
        path: "/persons",
        label: "Personas",
      },
      {
        name: "projects",
        path: "/projects",
        label: "Proyectos",
      },
      {
        name: "contractors",
        path: "/contractors",
        label: "Contratistas",
      },
      {
        name: "cpac",
        path: "/cpac",
        label: "PAC",
      },
    ],
  },
  {
    name: "contractor",
    path: "/contractor/projects",
    unique: true,
    label: "Aprobaciones",
    icon: () => <Icon icon="comparison" />,
  },
  {
    name: "contractorReports",
    path: "/contractor/reports",
    unique: true,
    label: "Reportes",
    icon: () => <Icon icon="chart" />,
  },
  {
    name: "pac",
    path: "/pac",
    label: "PAC",
    icon: MonetizationOnOutlined,
    childs: [
      {
        name: "program",
        path: "/program",
        label: "Programación",
      },
      {
        name: "evaluate",
        path: "/evaluate",
        label: "Evaluación",
      },
    ],
  },
  {
    name: "ola",
    path: "/ola",
    label: "OLA",
    icon: Assignment,
    disabled: true,
    childs: [],
  },
];

export default Side;
