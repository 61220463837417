import React, { FC, useCallback } from "react";
import { Box, BoxProps, makeStyles, Theme } from "@material-ui/core";
import { ContractResponse } from "lib/contracts/models/ConsolidatedEvaluationResponse";

interface Props {
  contract: ContractResponse;
}

const useStyles = makeStyles((theme: Theme) => {
  const success = theme.palette.success.main;
  const error = theme.palette.error.main;
  return {
    full: {
      position: "relative",
      "&:before": {
        content: '""',
        display: "block",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        position: "absolute",
        boxShadow: `4px 0px ${success} inset`,
      },
    },
    notFull: {
      position: "relative",
      "&:before": {
        content: '""',
        display: "block",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        position: "absolute",
        boxShadow: `4px 0px ${error} inset`,
      },
    },
  };
});

const ContractorContractQualification: FC<Props> = ({ contract }) => {
  const { qualification } = contract;
  return (
    <Box flexGrow={1} display="flex">
      <Box flexBasis="32%">{contract.activity.name}</Box>
      <Box flexGrow={1} style={{ textAlign: "center" }}>
        {Math.trunc(qualification?.compliance || 0)}%
      </Box>
      <Box flexGrow={1} style={{ textAlign: "center" }}>
        {qualification?.qualification || 0}
      </Box>
    </Box>
  );
};

const useContractorContractQualification = () => {
  const classes = useStyles();
  const elementChildComponent = useCallback(
    (contract: ContractResponse) => (
      <ContractorContractQualification contract={contract} />
    ),
    []
  );

  const stylesProps = useCallback(
    (contract: ContractResponse): BoxProps => {
      const { qualification } = contract;
      if (!qualification) return {};
      const { compliance } = qualification;
      const isFull = (compliance || 0) === 100;
      return {
        className: isFull ? classes.full : classes.notFull,
      };
    },
    [classes]
  );

  return { elementChildComponent, stylesProps };
};

export default useContractorContractQualification;
