import { TaskReason } from "lib/contracts/models/TaskReason";
import { TaskReasonService } from "lib/contracts/services/TaskReasonService";
import { IUseFormResult, useForm } from "shared/customHooks/useForm";

export type UseTaskReasonFormResult = IUseFormResult<TaskReason>;

type HookType = (
  handleCreate: (reason: TaskReason) => void
) => UseTaskReasonFormResult;

const useTaskReasonForm: HookType = (handleCreate) => {
  const form = useForm<TaskReason>({
    validations: {
      name: {
        required: {
          value: true,
          message: "Debe asignar un motivo",
        },
      },
    },
    initialValues: {
      name: "",
    },
    async onSubmit(formData, reset) {
      try {
        const service = new TaskReasonService();
        const { data } = await service.create(formData);
        if (data.reason.id) {
          handleCreate(data.reason);
          reset();
        }
      } catch (error) {
        alert(error);
      }
    },
  });

  return {
    ...form,
  };
};

export default useTaskReasonForm;
