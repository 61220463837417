import React, { FC } from "react";
import { Alert, Intent } from "@blueprintjs/core";
import useRemoveActivity, {
  UseRemoveActivityProps,
} from "./hooks/useRemoveActivity";

const RemoveActivity: FC<UseRemoveActivityProps> = (props) => {
  const { isOpen, isLoading, handleCancel, handleConfirm } =
    useRemoveActivity(props);
  return (
    <Alert
      cancelButtonText="Cancelar"
      confirmButtonText="Borrar"
      icon="trash"
      intent={Intent.DANGER}
      isOpen={isOpen}
      loading={isLoading}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
    >
      <p>¿Esta seguro de borrar la actividad?</p>
    </Alert>
  );
};

export default RemoveActivity;
