import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { RootState } from "store";
import AppLogo from "App/assets/icon-logo";

const LogoComponent = (): JSX.Element => {
  const { platformConfig } = useSelector((state: RootState) => state.app);
  const image = platformConfig?.platformIcon || "";
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {image ? <AppLogo src={image} /> : null}
    </Box>
  );
};

export default LogoComponent;
