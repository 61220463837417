import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Project } from "../models/Project";
import { ProjectService } from "../services/ProjectService";

export interface UseProjectParams {
  id?: string;
  onError?: () => void;
}

export type UseProjectResult = [
  Project | undefined,
  Dispatch<SetStateAction<Project | undefined>>
];

type HookType = (param: UseProjectParams) => UseProjectResult;

const useProject: HookType = ({ id, onError }) => {
  const [project, setProject] = useState<Project>();

  const fetch = useCallback(async () => {
    try {
      if (id) {
        const service = new ProjectService();
        const { data } = await service.getById(id);
        setProject(data?.project);
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message.includes("invalid input synta")) {
          alert({ message: "No existe este proyecto", severity: "warning" });
        } else alert(error.message);
      }
      if (onError) onError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return [project, setProject];
};

export default useProject;
