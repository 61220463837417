export class LogicError extends Error {
  public logic: string;

  constructor(message: string) {
    super(message);
    this.logic = message;
  }
}

export enum SEVERITY {
  WARNING = "warning",
  ERROR = "error",
  SUCCESS = "success",
}

interface ILoginError {
  message: string;
  severity?: SEVERITY;
}

export function CreateError(message: string, severity?: SEVERITY): ILoginError {
  return { message, severity };
}
