import React, { FC } from "react";
import { Button } from "@blueprintjs/core";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import useContractorForm from "./useContractorForm";

const useStyles = makeStyles({
  root: {},
  title: {
    fontSize: "1.1em",
    fontWeight: "bold",
  },
  box: {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    "& > *": {
      margin: 0,
      marginTop: 2,
      marginBottom: 1,
    },
  },
  boxSizing: {
    boxSizing: "border-box",
  },
});

const ContractorForm: FC = () => {
  const classes = useStyles();
  const { data, errors, handleSubmit, handleChange, handleChangeSelect } =
    useContractorForm();
  return (
    <Box className={classes.root} p={2}>
      <form noValidate onSubmit={handleSubmit}>
        <Box display="flex" flexWrap="wrap" alignItems="start">
          <Box width="50%" p={1} className={classes.box}>
            <TextField
              value={data?.name || ""}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Nombre Empresa"
              name="name"
              autoComplete="name"
              autoFocus
              error={!!errors?.name}
              helperText={errors?.name}
              onChange={handleChange("name")}
            />
          </Box>
          <Box width="50%" p={1} className={classes.box}>
            <TextField
              value={data?.email || ""}
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="Correo Electrónico"
              name="email"
              autoComplete="email"
              type="email"
              error={!!errors?.email}
              helperText={errors?.email}
              onChange={handleChange("email")}
            />
          </Box>
          <Box width="50%" p={1} className={classes.box}>
            <FormControl
              variant="outlined"
              fullWidth
              error={!!errors.contractorType}
            >
              <InputLabel htmlFor="outlined-contractorType">
                Tipo Contratista
              </InputLabel>
              <Select
                inputProps={{
                  name: "contractorType",
                  id: "outlined-contractorType",
                }}
                fullWidth
                label="Tipo Contratista"
                value={data.contractorType}
                onChange={handleChangeSelect("contractorType")}
              >
                {["PERMANENTE", "EXTEMPORANEO"].map((type) => (
                  <MenuItem key={`contractorType_${type}`} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
              {errors.contractorType ? (
                <FormHelperText>{errors.contractorType}</FormHelperText>
              ) : null}
            </FormControl>
          </Box>
          <Box width="50%" p={1} className={classes.box}>
            <FormControl
              variant="outlined"
              fullWidth
              error={!!errors.companySize}
            >
              <InputLabel htmlFor="outlined-companySize">
                Tamaño Empresa
              </InputLabel>
              <Select
                inputProps={{
                  name: "companySize",
                  id: "outlined-companySize",
                }}
                fullWidth
                label="Tamaño empresa"
                value={data.companySize}
                onChange={handleChangeSelect("companySize")}
              >
                {["MICRO", "PEQUEÑA", "MEDIANA", "GRANDE"].map((type) => (
                  <MenuItem key={`companySize_${type}`} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
              {errors.documentType ? (
                <FormHelperText>{errors.documentType}</FormHelperText>
              ) : null}
            </FormControl>
          </Box>
          <Box
            width="50%"
            p={1}
            className={classes.box}
            display="flex"
            alignItems="center"
          >
            <FormControl
              variant="outlined"
              fullWidth
              error={!!errors.documentType}
            >
              <InputLabel htmlFor="outlined-documentType">
                Tipo Documento
              </InputLabel>
              <Select
                inputProps={{
                  name: "documentType",
                  id: "outlined-documentType",
                }}
                fullWidth
                label="Tipo Documento"
                value={data.documentType}
                onChange={handleChangeSelect("documentType")}
              >
                {["NIT", "CEDULA", "PASAPORTE", "CEDULA EXTRANJERIA"].map(
                  (type) => (
                    <MenuItem key={`documentType_${type}`} value={type}>
                      {type}
                    </MenuItem>
                  )
                )}
              </Select>
              {errors.documentType ? (
                <FormHelperText>{errors.documentType}</FormHelperText>
              ) : null}
            </FormControl>
          </Box>
          <Box width="50%" p={1} className={classes.box}>
            <TextField
              value={data?.documentNumber || ""}
              variant="outlined"
              margin="normal"
              fullWidth
              id="documentNumber"
              label="Número Documento"
              name="documentNumber"
              autoComplete="documentNumber"
              error={!!errors?.documentNumber}
              helperText={errors?.documentNumber}
              onChange={handleChange("documentNumber")}
            />
          </Box>
          <Box width="50%" p={1} className={classes.box}>
            <TextField
              value={data?.arl || ""}
              variant="outlined"
              margin="normal"
              fullWidth
              id="arl"
              label="ARL"
              name="arl"
              autoComplete="arl"
              error={!!errors?.arl}
              helperText={errors?.arl}
              onChange={handleChange("arl")}
            />
          </Box>
          <Box width="50%" p={1} className={classes.box}>
            <TextField
              value={data?.activity || ""}
              variant="outlined"
              margin="normal"
              fullWidth
              id="activity"
              label="Actividad Económica"
              name="activity"
              autoComplete="activity"
              error={!!errors?.activity}
              helperText={errors?.activity}
              onChange={handleChange("activity")}
            />
          </Box>
          <Box width="50%" p={1} className={classes.box}>
            <TextField
              value={data?.departament || ""}
              variant="outlined"
              margin="normal"
              fullWidth
              id="departament"
              label="Departamento"
              name="departament"
              autoComplete="departament"
              error={!!errors?.departament}
              helperText={errors?.departament}
              onChange={handleChange("departament")}
            />
          </Box>
          <Box width="50%" p={1} className={classes.box}>
            <TextField
              value={data?.city || ""}
              variant="outlined"
              margin="normal"
              fullWidth
              id="city"
              label="Ciudad"
              name="city"
              autoComplete="city"
              error={!!errors?.city}
              helperText={errors?.city}
              onChange={handleChange("city")}
            />
          </Box>
          <Box width="50%" p={1} className={classes.box}>
            <TextField
              value={data?.risk || ""}
              variant="outlined"
              margin="normal"
              fullWidth
              id="risk"
              label="Clase de riesgo"
              name="risk"
              autoComplete="risk"
              error={!!errors?.risk}
              helperText={errors?.risk}
              onChange={handleChange("risk")}
            />
          </Box>
          <Box width="50%" p={1} className={classes.box}>
            <TextField
              value={data?.employeesNumber || 1}
              variant="outlined"
              margin="normal"
              fullWidth
              type="number"
              id="employeesNumber"
              label="N° Empleados directos"
              name="employeesNumber"
              autoComplete="employeesNumber"
              inputProps={{
                min: "1",
              }}
              error={!!errors?.employeesNumber}
              helperText={errors?.employeesNumber}
              onChange={handleChange("employeesNumber")}
            />
          </Box>
          <Box width="100%" marginTop={2}>
            <Button
              icon={data?.id ? "refresh" : "add"}
              intent="primary"
              large
              type="submit"
              fill
            >
              {data?.id ? "Actualizar" : "Nuevo contratista"}
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default ContractorForm;
