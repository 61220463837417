import { ContractService } from "lib/contracts/services/ContractService";
import { useState } from "react";

interface Params {
  onApproveEvaluation: () => void;
}

const useApproveEvaluation = ({ onApproveEvaluation }: Params) => {
  const [isLoading, setIsLoading] = useState(false);

  const approve = async (contractId: string, week: number) => {
    setIsLoading(true);
    try {
      const service = new ContractService();
      await service.approveEvaluation(contractId, week);
      onApproveEvaluation();
      alert({
        message: "La acción se ejecutó con éxito",
        severity: "success",
      });
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  return { approve, isLoading };
};
export default useApproveEvaluation;
