import { makeStyles } from "@material-ui/core";

const PACConfigStyles = makeStyles({
  root: {
    marginLeft: 0,
    paddingLeft: 16,
    paddingRight: 16,
  },
  panel: {
    width: "100%",
  },
});

export default PACConfigStyles;
