import React, { FC } from "react";
import { Button, ButtonProps } from "@blueprintjs/core";
import { Box } from "@material-ui/core";
import useUploadActivities from "./hooks/useUploadActivities";
import UploadActivitiesModal from "./components/modal/UploadActivitiesModal";

interface Props {
  buttonProps?: ButtonProps;
  uploadTasks: (file: File) => Promise<void>;
}

const UploadActivities: FC<Props> = ({ buttonProps, uploadTasks }) => {
  const { isOpen, open, onClose } = useUploadActivities();
  return (
    <>
      <Button
        icon="cloud-upload"
        intent="primary"
        onClick={open}
        {...buttonProps}
      >
        Subir actividades
      </Button>
      <UploadActivitiesModal
        isOpen={isOpen}
        onClose={onClose}
        actions={<Box>1</Box>}
        uploadTasks={uploadTasks}
      >
        <Box>1</Box>
      </UploadActivitiesModal>
    </>
  );
};

export default UploadActivities;
