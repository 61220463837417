import { makeStyles } from "@material-ui/core";

const AssignTowerStyles = makeStyles({
  root: {},
  column: {
    width: "50%",
    boxSizing: "border-box",
  },
});

export default AssignTowerStyles;
