import React, { FC } from "react";
import { Route, useRouteMatch } from "react-router";
import { Divider } from "@blueprintjs/core";
import AppBreadcrumb from "components/AppBreadcrumb/AppBreadcrumb";
import CustomSwitch from "shared/components/CustomSwitch/CustomSwitch";
import ContractorTowerListContainer from "./containers/ContractorTowerListContainer";
import ContractorProjectListContainer from "./containers/ContractorProjectListContainer";
import ContractorTowerWeeksContainer from "./containers/ContractorTowerWeeksContainer";
import ContractorTowerWeekContainer from "./containers/ContractorTowerWeekContainer";

const ContractorReportsPage: FC = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <AppBreadcrumb
        routes={[
          {
            path: "/contractor/reports",
            name: "reports",
            icon: "home",
            text: "",
          },
          {
            path: "/contractor/reports/:projectId",
            name: "ProjectTowers",
            icon: "comparison",
            text: "Etapas",
          },
          {
            path: "/contractor/reports/:projectId/weeks/:towerId",
            name: "TowerWeeks",
            icon: "property",
            text: "Semanas",
          },
          {
            path: "/contractor/reports/:projectId/weeks/:towerId/week/:week",
            name: "TowerWeek",
            icon: "calendar",
            text: "",
          },
        ]}
      />
      <Divider />
      <CustomSwitch>
        <Route
          path={`${path}/:projectId/weeks/:towerId/week/:week`}
          component={ContractorTowerWeekContainer}
        />
        <Route
          path={`${path}/:projectId/weeks/:towerId`}
          component={ContractorTowerWeeksContainer}
        />
        <Route
          path={`${path}/:projectId`}
          component={ContractorTowerListContainer}
        />
        <Route exact path={path} component={ContractorProjectListContainer} />
      </CustomSwitch>
    </>
  );
};

export default ContractorReportsPage;
