import ProjectList from "modules/Contractor/features/ProjectList/ProjectList";
import React, { FC } from "react";
import { useHistory, useLocation } from "react-router";

const ContractorProjectListContainer: FC = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  return (
    <>
      <ProjectList
        onSelectProject={(project) => push(`${pathname}/${project.id}`)}
      />
    </>
  );
};
export default ContractorProjectListContainer;
