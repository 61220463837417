import React, { createContext, FC, useContext } from "react";
import useProgramAssignment, {
  UseProgramAssignmentResult,
} from "../customHooks/useProgramAssignment";

type ProgramAssignmentContextType = UseProgramAssignmentResult;

const ProgramAssignmentContext =
  createContext<ProgramAssignmentContextType | null>(null);

export const useProgramAssignmentContext = (): ProgramAssignmentContextType =>
  useContext(ProgramAssignmentContext) as ProgramAssignmentContextType;

export const ProgramAssignmentProvider: FC = ({ children }) => {
  const value = useProgramAssignment();

  return (
    <ProgramAssignmentContext.Provider value={{ ...value }}>
      {children}
    </ProgramAssignmentContext.Provider>
  );
};
