import React, { FC, useState } from "react";
import { Flex } from "shared/components/Flex/Flex";
import { Card, Collapse, HTMLTable, Icon, Tag } from "@blueprintjs/core";
import { Box, makeStyles, Theme } from "@material-ui/core";
import { EvaluationState } from "lib/contracts/models/Evaluation";
import { Tooltip2 } from "@blueprintjs/popover2";
import NumberFormatString from "components/NumberFormatInputMaterialUI/NumberFormatString";
import { ContractResponse } from "lib/contracts/models/ConsolidatedEvaluationResponse";

const useStyles = makeStyles((theme: Theme) => ({
  clickElement: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: theme.palette.lightBackground.main,
    color: theme.palette.primary.main,
    userSelect: "none",
    cursor: ({ isClick }: { isClick: boolean }) =>
      isClick ? "pointer" : "auto",
    padding: "0.6em",
    paddingLeft: "1em",
    paddingRight: "1em",
  },
  tableElement: {
    border: `1px solid ${theme.palette.detail.light}`,
    "& td, & th": {
      border: `1px solid ${theme.palette.detail.light}`,
      padding: ".5em !important",
    },
  },
}));

const Color: { [key: string]: string } = {
  PENDING: "#7f8c8d",
  SCHEDULED: "#7f8c8d",
  SCHEDULED_SENDING: "#7f8c8d",
  SCHEDULED_REJECTED: "#7f8c8d",
  SCHEDULED_PASSED: "#2980b9",
  SCHEDULED_EVALUATED: "#27ae60",
};

const Name: { [key: string]: string } = {
  PENDING: "En programación",
  SCHEDULED: "En programación",
  SCHEDULED_SENDING: "En programación",
  SCHEDULED_REJECTED: "En programación",
  SCHEDULED_PASSED: "Por evaluar",
  SCHEDULED_EVALUATED: "Evaluado",
};

const ContractCollapse: FC<{ contract: ContractResponse }> = ({ contract }) => {
  const classes = useStyles({
    isClick:
      contract.evaluationState === EvaluationState.SCHEDULED_PASSED ||
      contract.evaluationState === EvaluationState.SCHEDULED_EVALUATED,
  });
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => setIsOpen(!isOpen);

  return (
    <Flex>
      <Card style={{ padding: 0 }}>
        <Box
          onClick={handleClick}
          className={classes.clickElement}
          style={{
            boxShadow: `.5em 0 ${
              // eslint-disable-next-line no-nested-ternary
              contract.qualification
                ? contract.qualification?.compliance === 100
                  ? Color.SCHEDULED_EVALUATED
                  : "#c0392b"
                : Color[contract.evaluationState || "PENDING"]
            } inset`,
            color:
              contract.evaluationState === "SCHEDULED_EVALUATED" ||
              contract.evaluationState === "SCHEDULED_PASSED"
                ? "initial"
                : "darkgray",
          }}
        >
          <Flex>{contract.activity.name}</Flex>

          {contract.qualification ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box display="flex" alignItems="center">
                <span>Cumplimiento: </span>
                <Box paddingX={2}>
                  <Tag
                    intent={
                      (contract.qualification?.compliance || 0) === 100
                        ? "success"
                        : "danger"
                    }
                  >
                    {Math.trunc(contract.qualification?.compliance || 0)}%
                  </Tag>
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <span>Calificación: </span>
                <Box paddingX={2}>
                  <Tag
                    intent={
                      (contract.qualification?.compliance || 0) === 100
                        ? "success"
                        : "danger"
                    }
                  >
                    {contract.qualification?.qualification || 0}
                  </Tag>
                </Box>
              </Box>
            </Box>
          ) : (
            <Flex>
              <Tag
                style={{
                  background: Color[contract.evaluationState || "PENDING"],
                }}
              >
                {Name[contract.evaluationState || "PENDING"]}
              </Tag>
            </Flex>
          )}
        </Box>
      </Card>
      {contract &&
      contract.evaluationState &&
      (contract.evaluationState === EvaluationState.SCHEDULED_PASSED ||
        contract.evaluationState === EvaluationState.SCHEDULED_EVALUATED) ? (
        <Collapse isOpen={isOpen}>
          <Card style={{ padding: 0 }}>
            {contract.tasks && contract.tasks.length ? (
              <Flex>
                <HTMLTable
                  width="100%"
                  className={classes.tableElement}
                  style={{
                    tableLayout: "fixed",
                    borderCollapse: "collapse",
                  }}
                >
                  <thead>
                    <tr>
                      <th>Tarea</th>
                      <th>Can. Programada</th>
                      <th>Can. Ejecutada</th>
                      <th>Unidad de Medida</th>
                      <th>Incidencia</th>
                      <th>Motivo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contract.tasks.map((task) => (
                      <tr key={task.id}>
                        <td>{task.name}</td>
                        <td>
                          {(
                            <NumberFormatString
                              value={task.evaluation?.programValue}
                            />
                          ) ?? "NA"}
                        </td>
                        <td>
                          {(
                            <NumberFormatString
                              value={task.evaluation?.evaluationValue}
                            />
                          ) ?? "NA"}
                        </td>
                        <td>{task.unit.name}</td>
                        <td>
                          {task.evaluation?.percentage ?? "NA"}
                          {task.evaluation?.percentage ? "%" : ""}
                        </td>
                        <td>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <span>{task.evaluation?.reason?.name}</span>
                            {task.evaluation?.comment ? (
                              <Tooltip2
                                content={
                                  <Box maxWidth={200} minWidth={150}>
                                    <p>{task.evaluation?.comment}</p>
                                  </Box>
                                }
                              >
                                <span style={{ cursor: "help", color: "gray" }}>
                                  <Icon icon="comment" /> Comentario
                                </span>
                              </Tooltip2>
                            ) : null}
                          </Box>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </HTMLTable>
              </Flex>
            ) : null}
          </Card>
        </Collapse>
      ) : null}
    </Flex>
  );
};

export default ContractCollapse;
