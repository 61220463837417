import { Contractor } from "lib/contractors/models/Contractor";
import { Contract } from "lib/contracts/models/Contract";
import { Activity } from "lib/projects/models/Activity";
import { useEffect } from "react";
import useBoolean from "shared/customHooks/useBoolean";
import useLoading from "shared/customHooks/useLoading";
import { AssignContractorToActivityService } from "../services/AssignContractorToActivityService";

interface Props {
  activity: Activity;
  handleAssignContractor: (contract?: Contract) => void;
}

const useAssignContractorToActivity = ({
  activity,
  handleAssignContractor,
}: Props) => {
  const { value: isOpen, setFalse, setTrue } = useBoolean();
  const { loading, load, stop } = useLoading();

  const assignContractorToActivity = async (contractor: Contractor) => {
    try {
      load();
      const service = new AssignContractorToActivityService();
      const { data } = await service.assignContractorToActivity(
        contractor.id,
        activity.id
      );
      if (data.contract.id) {
        alert({
          message: "La acción se ejecutó con éxito",
          severity: "success",
        });
        handleAssignContractor(data.contract);
      }
    } catch (error) {
      stop();
      alert(error);
    }
  };

  useEffect(() => {
    if (activity.id) {
      setTrue();
      stop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity]);

  const onClose = () => {
    setFalse();
    setTimeout(handleAssignContractor, 100);
  };

  return {
    loading,
    isOpen,
    onClose,
    assignContractorToActivity,
  };
};

export default useAssignContractorToActivity;
