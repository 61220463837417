import React, { FC } from "react";
import {
  Box,
  Button,
  Chip,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import {
  Grid,
  Table,
  VirtualTable,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";
import { IntegratedSorting, SortingState } from "@devexpress/dx-react-grid";
import moment from "moment";
import useAssignTaskTable from "../customHooks/useAssignTaskTable";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& thead": {
        background: theme.palette.detail.light,
        "& th": {
          paddingTop: 4,
          paddingBottom: 4,
        },
      },
      "& tbody tr": {
        "&:hover": {
          background: theme.palette.highlighter.main,
        },
      },
    },
  })
);

const renderTable =
  (fn: (id?: string) => void) => (props: Table.DataCellProps) => {
    const { column, value, row } = props;
    if (column.name === "")
      return (
        <VirtualTable.Cell {...props}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => fn(row.id)}
          >
            Asignar contratista
          </Button>
        </VirtualTable.Cell>
      );
    if (["startDate", "endDate"].includes(column.name))
      return (
        <VirtualTable.Cell {...props}>
          {moment(value).format("DD-MM-YYYY")}
        </VirtualTable.Cell>
      );
    if (column.name === "name")
      return (
        <VirtualTable.Cell {...props}>
          <Box display="flex" alignItems="center">
            <Box pr={1}>
              <span>{value}</span>
            </Box>
            {!row.numberOfContractors ? (
              <Chip
                label="Sin contratistas"
                color="secondary"
                size="small"
                variant="outlined"
              />
            ) : (
              <Chip
                label={row.numberOfContractors}
                color="default"
                size="small"
              />
            )}
          </Box>
        </VirtualTable.Cell>
      );
    return <VirtualTable.Cell {...props} />;
  };

const compareDate = (a: string, b: string): number => {
  if (moment(a).isSame(b)) return 0;
  return moment(a).isBefore(b) ? -1 : 1;
};

const AssignTaskTable: FC = () => {
  const classes = useStyles();

  const { rows, cols, selectCurrentTask } = useAssignTaskTable();
  return (
    <Box className={classes.root}>
      <Grid rows={rows} columns={cols}>
        <SortingState
          defaultSorting={[{ columnName: "contractor", direction: "desc" }]}
          columnExtensions={[
            { columnName: "numberOfContractors", sortingEnabled: false },
          ]}
        />
        <IntegratedSorting
          columnExtensions={[
            {
              columnName: "startDate",
              compare: compareDate,
            },
            {
              columnName: "endDate",
              compare: compareDate,
            },
          ]}
        />
        <VirtualTable
          cellComponent={renderTable(selectCurrentTask)}
          height="100"
          columnExtensions={[
            {
              columnName: "endDate",
              width: "150px",
            },
            {
              columnName: "startDate",
              width: "150px",
            },
            {
              columnName: "",
              width: "200px",
            },
          ]}
          // columnExtensions={[
          //   {
          //     columnName: "name",
          //     width: "50%",
          //   },
          //   {
          //     columnName: "endDate",
          //     width: "15%",
          //   },
          //   {
          //     columnName: "startDate",
          //     width: "15%",
          //   },
          // ]}
        />
        <TableHeaderRow showSortingControls />
      </Grid>
    </Box>
  );
};

export default AssignTaskTable;
