import { useHistory, useLocation } from "react-router";
import { UserCredentials } from "lib/auth/models/UserCredentials";
import { AuthService } from "lib/auth/services/Auth.Service";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { useEffect } from "react";
import { CookieService } from "shared/services/CookieService";
import { setAuthError, setUserAuth } from "../redux/AuthenticationSlice";
import useAuthContractor from "./useAuthContractor";

const useLogin = () => {
  const history = useHistory();
  const location = useLocation<{ from: { pathname: string } }>();
  const { user, authError } = useSelector((state: RootState) => state.auth);
  const { loginContractor } = useAuthContractor();

  const dispatch = useDispatch();

  const login = async (credentials: UserCredentials): Promise<void> => {
    try {
      const service = new AuthService();
      const cookieService = new CookieService();
      const sessionCookie = cookieService.getSessionId();
      const { data } = await service.login({
        ...credentials,
        did: sessionCookie,
      });
      dispatch(setUserAuth(data.user));
      loginContractor(data.user);
      history.push("/");
    } catch (err) {
      dispatch(setAuthError("Error al intentar iniciar sesión"));
    }
  };

  useEffect(() => {
    if (user && location?.state?.from) {
      history.push(location.state.from.pathname || "/");
    } else if (user) history.push("/");
  }, [user, history, location]);

  return {
    authError,
    login,
  };
};

export default useLogin;
