import React, { FC } from "react";
import { Box, Paper, makeStyles, Theme, Badge } from "@material-ui/core";
import { NonIdealState } from "@blueprintjs/core";
import {
  ProgramProjectsProvider,
  useProgramProjectsContext,
} from "./context/ProgramProjectsContext";

const useStyles = makeStyles((theme: Theme) => {
  return {
    projectElement: {
      cursor: "pointer",
      "&:hover": {
        boxShadow: `0 2px ${theme.palette.secondaryDark.main}`,
      },
    },
  };
});

const ProgramProjects: FC = () => {
  const classes = useStyles();
  const { selectProject, projects } = useProgramProjectsContext();
  return (
    <Box>
      <Box>
        <h2>Seleccione un proyecto</h2>
      </Box>
      <Box display="flex" flexWrap="wrap" alignItems="flex-start">
        {projects.length ? (
          <>
            {projects.map((project) => {
              return (
                <Box
                  m={1}
                  display="flex"
                  key={project.id}
                  className={classes.projectElement}
                  onClick={() =>
                    selectProject(project.id, `${project.numberOfTowers}`)
                  }
                >
                  <Badge badgeContent={project.numberOfTowers} color="primary">
                    <Paper style={{ borderRadius: 0 }}>
                      <Box p={2}>{project.name}</Box>
                    </Paper>
                  </Badge>
                </Box>
              );
            })}
          </>
        ) : (
          <NonIdealState icon="new-grid-item" title="Sin proyectos" />
        )}
      </Box>
    </Box>
  );
};

const ProgramProjectsContainer: FC = () => {
  return (
    <ProgramProjectsProvider>
      <ProgramProjects />
    </ProgramProjectsProvider>
  );
};

export default ProgramProjectsContainer;
