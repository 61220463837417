import { Contractor } from "lib/contractors/models/Contractor";
import { ContractorService } from "lib/contractors/services/ContractorService";
import { IUseFormResult, useForm } from "shared/customHooks/useForm";
import { LogicError } from "shared/services/LogicError";
import { useContractorContext } from "../../../../context/ContractorContext";

export interface UseContractorFormParams {
  name: string;
}

export interface UseContractorFormResult extends IUseFormResult<Contractor> {
  currentContractor?: Contractor;
}

type HookType = (param?: UseContractorFormParams) => UseContractorFormResult;

const useContractorForm: HookType = () => {
  const { currentContractor, handleCreateContractor, handleUpdateContractor } =
    useContractorContext();

  const createContractor = async (
    contractor: Contractor,
    reset: () => void
  ): Promise<void> => {
    const service = new ContractorService();
    const { data } = await service.create(contractor);
    handleCreateContractor(data.contractor);
    reset();
  };

  const updateContractor = async (contractor: Contractor): Promise<void> => {
    if (currentContractor) {
      const updatedData = {} as Record<string, unknown>;

      Object.keys(contractor).forEach((keyString) => {
        const key = keyString as keyof Partial<Contractor>;
        if (contractor[key] !== currentContractor[key])
          updatedData[key] = contractor[key];
      });

      if (Object.keys(updatedData).length) {
        const service = new ContractorService();
        await service.update(contractor.id, updatedData);
        handleUpdateContractor(contractor.id, updatedData);
      }
    }
  };

  const form = useForm<Contractor>(
    {
      validations: {
        name: {
          required: {
            value: true,
            message: "Nombre es requerido",
          },
        },
        email: {
          custom: {
            isValid: (value: unknown): boolean => {
              if (!value) return true;
              const regex = new RegExp(
                "^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$"
              );
              return regex.test(value as string);
            },
            message: "Correo electrónico no válido",
          },
        },
      },
      initialValues: {
        id: currentContractor?.id || undefined,
        name: currentContractor?.name || "",
        email: currentContractor?.email || "",
        companySize: currentContractor?.companySize || "MICRO",
        documentType: currentContractor?.documentType || "NIT",
        documentNumber: currentContractor?.documentNumber || "",
        arl: currentContractor?.arl || "",
        activity: currentContractor?.activity || "",
        departament: currentContractor?.departament || "",
        city: currentContractor?.city || "",
        employeesNumber: currentContractor?.employeesNumber || 1,
        contractorType: currentContractor?.contractorType || "PERMANENTE",
        risk: currentContractor?.risk || "",
        country: currentContractor?.country || "",
      },
      async onSubmit(newData, resetForm) {
        try {
          if (currentContractor?.id) await updateContractor(newData);
          else await createContractor(newData, resetForm);
        } catch (error) {
          if (error instanceof LogicError) alert(error.logic);
          else alert("Error interno servidor");
        }
      },
    },
    [currentContractor]
  );
  return {
    ...form,
    currentContractor,
  };
};

export default useContractorForm;
