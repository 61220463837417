import React, { FC, useState } from "react";
import { Flex } from "shared/components/Flex/Flex";
import { Card, Collapse, Icon, Tag } from "@blueprintjs/core";
import { Box, Theme, makeStyles } from "@material-ui/core";
import { ContractorResponse } from "lib/contracts/models/ConsolidatedEvaluationResponse";
import ContractCollapse from "../ContractCollapse/ContractCollapse";

const useStyles = makeStyles((theme: Theme) => ({
  clickElement: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: theme.palette.detail.light,
    paddingLeft: "1em",
    transition: "color .5s",
    color: theme.palette.primary.main,
    userSelect: "none",
    cursor: "pointer",
    fontWeight: "bold",
  },
}));

const ContractorCollapse: FC<{ contractor: ContractorResponse }> = ({
  contractor,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(true);
  const handleClick = () => setIsOpen(!isOpen);

  return (
    <Flex>
      <Card style={{ padding: 0 }}>
        <Box onClick={handleClick} className={classes.clickElement} p={1}>
          <Flex alignItems="center" container>
            <Icon icon={isOpen ? "chevron-up" : "chevron-down"} />
            <Box paddingLeft={1}>
              {contractor.documentNumber} {contractor.name}
            </Box>
          </Flex>
          {contractor.haveQualifications ? (
            <Box>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box display="flex" alignItems="center">
                  <span>Cumplimiento: </span>
                  <Box paddingX={2}>
                    <Tag
                      intent={
                        (contractor.compliance || 0) === 100
                          ? "success"
                          : "danger"
                      }
                    >
                      {Math.trunc(contractor.compliance || 0)}%
                    </Tag>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  <span>Calificación: </span>
                  <Box paddingX={2}>
                    <Tag
                      intent={
                        (contractor.compliance || 0) === 100
                          ? "success"
                          : "danger"
                      }
                    >
                      {contractor.qualification || 0}
                    </Tag>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : null}
        </Box>
      </Card>
      <Collapse isOpen={isOpen}>
        {contractor.contracts && contractor.contracts.length ? (
          <Flex>
            {contractor.contracts.map((contract) => (
              <ContractCollapse key={contract.id} contract={contract} />
            ))}
          </Flex>
        ) : null}
      </Collapse>
    </Flex>
  );
};

export default ContractorCollapse;
