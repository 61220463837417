import { makeStyles, Theme } from "@material-ui/core";

const TowerFormStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  formItem: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  label: {
    minWidth: "50%",
    userSelect: "none",
  },
  formControl: {
    width: "100%",
  },
  closingDay: {
    padding: "5px",
    border: "1px solid",
    borderColor: theme.palette.detail.main,
    borderRadius: "2px",
    boxSizing: "border-box",
    marginRight: "4px",
    marginTop: "4px",
    cursor: "pointer",
    opacity: "0.6",
    transition: "background 0.2s",
    fontWeight: "bold",
    textTransform: "capitalize",
    "&:hover": {
      opacity: "1",
      background: theme.palette.secondaryDark.main,
      borderColor: theme.palette.secondaryDark.main,
    },
  },
  active: {
    opacity: "1",
    borderColor: theme.palette.secondaryDark.main,
    background: theme.palette.secondaryDark.main,
  },
  dataPicker: {
    padding: 0,
    flexGrow: 1,
    "& .DayPicker-Day.DayPicker-Day--selected": {
      background: theme.palette.secondaryDark.main,
      color: "black",
      fontWeight: "bold",
      "&:hover": {
        background: theme.palette.secondaryDark.main,
      },
    },
  },
}));
export default TowerFormStyles;
