import React, { createContext, FC, useContext } from "react";
import useProgramProjects, {
  UseProgramProjectsResult,
} from "../customHooks/useProgramProjects";

type ProgramProjectsContextType = UseProgramProjectsResult;

const ProgramProjectsContext = createContext<ProgramProjectsContextType | null>(
  null
);

export const useProgramProjectsContext = (): ProgramProjectsContextType =>
  useContext(ProgramProjectsContext) as ProgramProjectsContextType;

export const ProgramProjectsProvider: FC = ({ children }) => {
  const value = useProgramProjects();

  return (
    <ProgramProjectsContext.Provider value={{ ...value }}>
      {children}
    </ProgramProjectsContext.Provider>
  );
};
