import { Role } from "lib/users/models/Role";
import { User } from "lib/users/models/User";
import { PersonsService } from "lib/users/services/PersonsService";
import { IUseFormResult, useForm } from "shared/customHooks/useForm";

type UseSelectRolePersonResult = IUseFormResult<{ roleId: string }>;

interface IUseSelectRolePersonProps {
  person?: User;
  handleChangeRolePerson: (id: string, person: Partial<User>) => void;
  roles: Role[];
}

const useSelectRolePerson = ({
  person,
  handleChangeRolePerson,
  roles,
}: IUseSelectRolePersonProps): UseSelectRolePersonResult => {
  const form = useForm<{
    roleId: string;
  }>({
    initialValues: {
      roleId: person?.roleId || "",
    },
    async onSubmit({ roleId }) {
      const service = new PersonsService();
      if (person?.id) {
        await service.updatePerson(person.id, { roleId });
        handleChangeRolePerson(person.id, {
          roleId,
          role: roles.find((role) => role.id === roleId),
        });
      }
    },
  });

  return {
    ...form,
  };
};
export default useSelectRolePerson;
