import React from "react";
import "moment/locale/es";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import moment from "moment-timezone";
import { ThemeProvider } from "@material-ui/core";
import { theme } from "App/assets/theme";
import App from "App/App";
import { store } from "./store";
import "./App/assets/scss/index.scss";
import reportWebVitals from "./reportWebVitals";

moment.tz.setDefault("America/Bogota");
moment.locale("es");

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
