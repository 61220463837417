import React, { FC } from "react";
import { Button, ButtonGroup, Menu, MenuItem } from "@blueprintjs/core";
import { Table } from "@devexpress/dx-react-grid-material-ui";
import { Box } from "@material-ui/core";
import { Popover2 } from "@blueprintjs/popover2";
import { TaskRow } from "../../models/ActivityContractorListRow";

export interface ActivityContractorListCellActionsProps {
  handleRemoveClick: (activityId: string) => void;
  canDelete?: boolean;
}

const ActivityContractorListCellActions: FC<
  Table.DataCellProps & ActivityContractorListCellActionsProps
> = (props) => {
  const { handleRemoveClick, canDelete, ...componentProps } = props;
  const { row } = props;
  const { id } = row as TaskRow;

  const permissions = [canDelete];

  return (
    <Table.Cell {...componentProps}>
      <Box display="flex" alignItems="center" justifyContent="center">
        {permissions.filter((permission) => permission).length ? (
          <ButtonGroup>
            <Popover2
              usePortal
              autoFocus
              content={
                <Menu key="menu">
                  {canDelete ? (
                    <MenuItem
                      icon="trash"
                      text="Borrar"
                      intent="danger"
                      onClick={() => handleRemoveClick(id)}
                    />
                  ) : null}
                </Menu>
              }
            >
              <Button icon="menu" small minimal />
            </Popover2>
          </ButtonGroup>
        ) : null}
      </Box>
    </Table.Cell>
  );
};
export default ActivityContractorListCellActions;
