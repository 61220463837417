import ContractorPage from "pages/Configuration/containers/Contractors/ContractorPage";
import PACConfigPage from "pages/Configuration/containers/PACConfig/PACConfigPage";
import PersonsPage from "pages/Configuration/containers/Persons/PersonsPage";
import ProjectsPage from "pages/Configuration/containers/Projects/ProjectsPage";
import ListProjectsPage from "modules/Contractor/pages/ListProjects/ListProjectsPage";
import ListTaskEvaluationsPage from "pages/Contractor/ListTaskEvaluations/ListTaskEvaluationsPage";
import ListTowersPage from "pages/Contractor/ListTowers/ListTowersPage";
import GenericChildPage from "pages/GenericChildPage/GenericChildPage";
// eslint-disable-next-line import/no-cycle
import HomePage from "pages/Home/Home";
import InvitePage from "pages/Invite/InvitePage";
import LoginPage from "pages/Login/LoginPage";
import PACEvaluatePage from "pages/PAC/containers/Evaluate/PACEvaluatePage";
import PACProgramPage from "pages/PAC/containers/Program/PACProgramPage";
import SubRoutePage from "shared/pages/SubRoutePage/SubRoutePage";
import ContractorReportsPage from "modules/Contractor/pages/ContractorReportsPage/ContractorReportsPage";
import ForgotPasswordMainView from "modules/ForgotPassword/UI/ForgotPasswordMainView";
import AppRoute from "./models/AppRoute";

export const PublicRoutes: AppRoute[] = [
  {
    path: "/invite/:hash",
    name: "Invite",
    public: true,
    component: InvitePage,
  },
  {
    path: "/login",
    name: "LoginPage",
    public: true,
    component: LoginPage,
  },
  {
    path: "/forgot-password",
    name: "ForgotPasswordPage",
    public: true,
    component: ForgotPasswordMainView,
  },
  {
    path: "/recovery/:hash",
    name: "RecoveryPage",
    public: true,
    component: InvitePage,
  },
];

export const PrivateRoutes: AppRoute[] = [
  {
    path: "/pac",
    name: "pac",
    component: GenericChildPage,
    routes: [
      {
        path: "/program",
        name: "pac.program",
        component: PACProgramPage,
      },
      {
        path: "/evaluate",
        name: "pac.evaluate",
        component: PACEvaluatePage,
      },
    ],
  },
  {
    path: "/configuration",
    name: "configuration",
    component: GenericChildPage,
    routes: [
      {
        path: "/persons",
        name: "configuration.persons",
        component: PersonsPage,
      },
      {
        path: "/projects",
        name: "configuration.projects",
        component: ProjectsPage,
      },
      {
        path: "/contractors",
        name: "configuration.contractors",
        component: ContractorPage,
      },
      {
        path: "/cpac",
        name: "configuration.cpac",
        component: PACConfigPage,
      },
    ],
  },
  {
    path: "/contractor",
    name: "contractor",
    component: SubRoutePage,
    routes: [
      {
        path: "/projects/:projectId/contract/:contractId",
        name: "contractorprojects.child2",
        exac: true,
        component: ListTaskEvaluationsPage,
      },
      {
        path: "/projects/:projectId",
        name: "contractorprojects.child",
        exac: true,
        component: ListTowersPage,
      },
      {
        path: "/projects",
        initial: true,
        exac: true,
        name: "contractorprojects",
        component: ListProjectsPage,
      },
      {
        path: "/reports",
        name: "contractorReports",
        component: ContractorReportsPage,
      },
    ],
  },

  {
    path: "/",
    name: "@home",
    public: true,
    component: HomePage,
  },
];
