import React, { FC } from "react";
import { Box, makeStyles, Theme } from "@material-ui/core";
import { Button, Icon } from "@blueprintjs/core";
import { IEvaluationFilterValue } from "lib/contracts/models/Evaluation";

const useStyles = makeStyles((theme: Theme) => ({
  close: {
    marginRight: 5,
    width: 10,
    cursor: "pointer",
  },
  label: {
    color: theme.palette.detail.main,
  },
}));

const ButtonFilter: FC<{
  value?: IEvaluationFilterValue;
  onClick: () => void;
  emptyLabel: string;
}> = ({ value, onClick, emptyLabel }) => {
  const classes = useStyles();
  return (
    <Button large rightIcon="chevron-down">
      <Box display="flex" alignItems="center">
        {value?.id ? (
          <Box
            className={classes.close}
            onClick={(e) => {
              e.stopPropagation();
              onClick();
            }}
          >
            <Icon icon="delete" />
          </Box>
        ) : null}
        {value?.name || <span className={classes.label}>{emptyLabel}</span>}
      </Box>
    </Button>
  );
};

export default ButtonFilter;
