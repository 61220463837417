import { Contractor } from "lib/contractors/models/Contractor";
import { ContractService } from "lib/contracts/services/ContractService";
import { useCallback, useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router";
import { useSelectContractorContext } from "../../../context/SelectContractorContext";

export interface UseContractsParams {
  a: string;
}

export interface UseContractsResult {
  contracts: Contractor[];
}

type HookType = (param?: UseContractsParams) => UseContractsResult;

const useContracts: HookType = () => {
  const { pathname } = useLocation();
  const { tower } = useSelectContractorContext();
  const [contracts, setContracts] = useState<Contractor[]>([]);

  const fetch = useCallback(async () => {
    try {
      if (
        matchPath(pathname, {
          path: "/pac/program/:projectId/program/:towerId",
          exact: true,
        }) &&
        tower?.id
      ) {
        const service = new ContractService();
        const { data } = await service.getContractByTowerId(tower?.id);
        setContracts(data.contracts);
      }
    } catch (error) {
      alert(error);
    }
  }, [tower, pathname]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    contracts,
  };
};

export default useContracts;
