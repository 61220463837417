import { AxiosResponse } from "axios";
import { HttpService } from "shared/services/HttpService";
import { Role } from "../models/Role";

export class RoleService extends HttpService {
  constructor() {
    super("/roles");
  }

  getAllRoles = (): Promise<AxiosResponse<{ roles: Role[] }>> =>
    this.httpClient.get("/");
}
