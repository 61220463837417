import { ProgrammingConsolidatedResponse } from "lib/contracts/models/ProgrammingConsolidatedResponse";
import { ContractService } from "lib/contracts/services/ContractService";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import useLoading from "shared/customHooks/useLoading";

const useFetchProgrammingConsolidated = () => {
  const { towerId } = useParams<{ towerId: string }>();
  const [response, setResponse] = useState<ProgrammingConsolidatedResponse>();
  const { loading, load, stop } = useLoading();

  const fetch = useCallback(
    async (week = 0) => {
      load();
      try {
        const service = new ContractService();
        const { data } = await service.getProgrammingConsolidated(
          towerId,
          week
        );
        setResponse(data);
      } catch (error) {
        alert(error);
      }
      stop();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [towerId]
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { response, isFetching: loading, fetch };
};

export default useFetchProgrammingConsolidated;
