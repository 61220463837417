import React, { FC } from "react";
import { Box } from "@material-ui/core";
import { Flex } from "shared/components/Flex/Flex";
import { Card, Classes, NonIdealState } from "@blueprintjs/core";
import { ResolveVirtualWeek } from "lib/projects/utils/ResolveVirtualWeek";
import useConsolidatedEvaluation from "../../CORE/hooks/useConsolidatedEvaluation";
import Header from "../Header/Header";
import Filter from "../Filter/Filter";
import ContractorCollapse from "../ContractorCollapse/ContractorCollapse";

const ConsolidatedEvaluation: FC<{ towerId: string }> = ({ towerId }) => {
  const {
    tower,
    week,
    currentEvaluationWeek,
    contractors,
    currentWeek,
    isFetching,
    fetch,
  } = useConsolidatedEvaluation({ towerId });
  return (
    <Box>
      {tower && tower.id && Array.isArray(week) ? (
        <Header
          tower={tower}
          currentWeek={week}
          week={currentEvaluationWeek || 0}
        />
      ) : null}
      <Filter
        week={currentWeek || 1}
        programmingWeek={currentEvaluationWeek || 1}
        virtualWeek={ResolveVirtualWeek(tower?.virtualWeek)}
        onSubmit={fetch}
      />
      {!isFetching ? (
        <div>
          {contractors && contractors.length ? (
            <Flex>
              {contractors.map((contractor) => (
                <ContractorCollapse
                  key={contractor.id}
                  contractor={contractor}
                />
              ))}
            </Flex>
          ) : (
            <Card>
              <NonIdealState
                icon="warning-sign"
                title="Sin resultados"
                description="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año"
              />
            </Card>
          )}
        </div>
      ) : (
        <Box
          height={200}
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={Classes.SKELETON}
        />
      )}
    </Box>
  );
};

export default ConsolidatedEvaluation;
