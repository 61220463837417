import { makeStyles } from "@material-ui/core";

const PersonModalStyles = makeStyles(() => ({
  root: {
    minWidth: 560,
    position: "relative",
  },
  formItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  label: {
    minWidth: "50%",
  },
  formControl: {
    width: "100%",
  },
  progressBox: {
    position: "absolute",
    background: "rgba(0,0,0,0.3)",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default PersonModalStyles;
