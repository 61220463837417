import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { TaskUnit } from "../models/TaskUnit";
import { TaskUnitService } from "../services/TaskUnitService";

export type UseUnitsParams = boolean;

type ReturnSetter = [
  TaskUnit[],
  Dispatch<SetStateAction<TaskUnit[]>>,
  () => Promise<void>
];
type ReturnGetter = [TaskUnit[], () => Promise<void>];

function useUnits(update: boolean): ReturnSetter;
function useUnits(): ReturnGetter;

function useUnits(update?: boolean): ReturnSetter | ReturnGetter {
  const [units, setUnits] = useState<TaskUnit[]>([]);

  const fetch = useCallback(async () => {
    try {
      const service = new TaskUnitService();
      const { data } = await service.getUnits();
      setUnits(data?.units || []);
    } catch (error) {
      // TODO: Use custom class and replace alert
      alert(error);
    }
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  if (update) return [units, setUnits, fetch];
  return [units, fetch];
}

export default useUnits;
