import { Contract } from "lib/contracts/models/Contract";
import { ContractQualification } from "lib/contracts/models/ContractQualification";
import { ContractService } from "lib/contracts/services/ContractService";
import { useCallback, useEffect, useState } from "react";

export interface UseEvaluateContractorHistoricDrawerParams {
  contractId: string;
  onClose: () => void;
  fetch: () => Promise<void>;
}

export interface UseEvaluateContractorHistoricDrawerResult {
  historic: ContractQualification[];
  contract?: Contract;
  lastWeek: number;
  removeQualification: () => Promise<void>;
  loading: boolean;
}

type HookType = (
  param: UseEvaluateContractorHistoricDrawerParams
) => UseEvaluateContractorHistoricDrawerResult;

const useEvaluateContractorHistoricDrawer: HookType = ({
  contractId,
  fetch: reload,
  onClose,
}) => {
  const [historic, setHistoric] = useState<ContractQualification[]>([]);
  const [contract, setContract] = useState<Contract>();
  const [lastWeek, setLastweek] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(async () => {
    try {
      const service = new ContractService();
      const { data } = await service.contractWithQuialificationsHistory(
        contractId
      );
      if (data.historic?.length) {
        setHistoric(data.historic);
        // eslint-disable-next-line prefer-spread
        const max = Math.max.apply(
          Math,
          data.historic.map((o) => o.taskWeek)
        );
        setLastweek(max);
        setContract(data.contract);
      }
    } catch (error) {
      alert(error);
    }
  }, [contractId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const removeQualification = async (): Promise<void> => {
    setLoading(true);
    try {
      const service = new ContractService();
      await service.removeContractQuialification(contractId, lastWeek);
      alert({
        message: "Evaluación borrada",
        severity: "success",
      });
      reload();
      onClose();
    } catch (error) {
      alert(error);
    }
    setLoading(false);
  };

  return {
    historic,
    contract,
    lastWeek,
    removeQualification,
    loading,
  };
};

export default useEvaluateContractorHistoricDrawer;
