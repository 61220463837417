import React, { FC } from "react";
import { Box, TextField } from "@material-ui/core";
import { Button, Classes, Dialog } from "@blueprintjs/core";
import useDialogReject from "./hook/useDialogReject";

interface Params {
  contractId: string;
  week: number;
  onReject: () => void;
  onClose: () => void;
  closeModal: () => void;
  isOpen: boolean;
}

const DialogReject: FC<Params> = ({
  contractId,
  onReject,
  week,
  isOpen,
  closeModal,
}) => {
  const { form } = useDialogReject({
    contractId,
    onReject,
    week,
  });

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        closeModal();
        form.resetData();
      }}
      style={{ maxWidth: "90vw" }}
    >
      <Box className={Classes.DIALOG_BODY}>
        <form onSubmit={form.handleSubmit} id="reject-comment-evaluation" />
        <p>Motivos por los cuales rechaza la programación</p>
        <TextField
          value={form.data?.comment || ""}
          variant="outlined"
          margin="normal"
          fullWidth
          id="comment"
          label="Comentario"
          name="comment"
          multiline
          rows={4}
          required
          error={!!form.errors?.comment}
          helperText={form.errors?.comment}
          onChange={form.handleChange("comment")}
        />
      </Box>
      <Box className={Classes.DIALOG_FOOTER_ACTIONS} paddingX={2}>
        <Button
          onClick={() => {
            closeModal();
            form.resetData();
          }}
          large
        >
          Cerrar
        </Button>
        <Button
          large
          intent="primary"
          type="submit"
          form="reject-comment-evaluation"
        >
          Confirmar
        </Button>
      </Box>
    </Dialog>
  );
};

export default DialogReject;
