import React, { FC } from "react";
import { Box, OutlinedInput, makeStyles, Theme } from "@material-ui/core";
import {
  Button,
  Card,
  ButtonGroup,
  Callout,
  Collapse,
  Colors,
  Tag,
} from "@blueprintjs/core";
import { EvaluationState } from "lib/contracts/models/Evaluation";
import NumberFormatString from "components/NumberFormatInputMaterialUI/NumberFormatString";
import NumberFormat from "react-number-format";
import { ResolveVirtualWeek } from "lib/projects/utils/ResolveVirtualWeek";
import {
  EvaluateContractorTableParams,
  EvaluateContractorTableProvider,
  useEvaluateContractorTableContext,
} from "./context/EvaluateContractorTableContext";
import EvaluateContractorTableModal from "./components/EvaluateContractorTableModal";
import EvaluateContractHistoricDrawer from "./components/EvaluateContractHistoricDrawer";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  table: {
    // borderSpacing: 0,
    width: "100%",
    borderSpacing: "1px",
    paddingBottom: "10px",
    borderColor: "red",
    tableLayout: "fixed",
    "& .numberInput input": {
      padding: "6px 12px 6px 12px !important",
    },
    "& thead": {
      background: theme.palette.detail.light,
      "& tr:first-child": {
        "& th": {
          fontWeight: "initial",
          background: theme.palette.detail.light,
          padding: "0 10px",
          border: "0.5px solid",
          borderColor: theme.palette.detail.light,
        },
      },
    },
    "& tbody tr": {
      "&:hover": {
        "& td": {
          background: theme.palette.highlighter.main,
        },
      },
      "& td": {
        width: "25%",
        border: `1px solid ${theme.palette.detail.light}`,
        paddingLeft: 8,
        paddingRight: 8,
        textAlign: "left",
        verticalAlign: "middle",
      },
    },
  },
  rowTask: {
    "& > *": {},
  },
  noPadding: {
    padding: 0,
  },
  completed: {
    boxShadow: `4px 0 ${Colors.GREEN5} inset`,
  },
  uncompleted: {
    boxShadow: `4px 0 ${Colors.ORANGE3} inset`,
  },
  header: {
    background: theme.palette.detail.light,
  },
}));

const EvaluateContractorTable: FC = () => {
  const classes = useStyles();
  const {
    contract,
    evaluationValues,
    taskEvaluation,
    tower,
    isOpen,
    taskSelected,
    isOpenDrawer,
    handleValueChange,
    toggleOpen,
    saveTaskEvaluation,
    toggleDrawer,
    fetch,
  } = useEvaluateContractorTableContext();
  return (
    <Box className={classes.root}>
      {taskSelected?.id ? <EvaluateContractorTableModal /> : null}
      {isOpenDrawer ? (
        <EvaluateContractHistoricDrawer
          isOpen={isOpenDrawer}
          virtualWeek={ResolveVirtualWeek(tower?.virtualWeek)}
          contractId={contract.id}
          onClose={toggleDrawer}
          fetch={fetch}
        />
      ) : null}
      <Card className={classes.noPadding}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.header}
          padding={2}
          paddingY={1}
        >
          <Box display="flex" alignItems="center">
            <Box marginRight={1}>
              <ButtonGroup>
                <Button
                  icon={isOpen ? "chevron-up" : "chevron-right"}
                  minimal
                  className="button-unoutline"
                  onClick={toggleOpen}
                />
              </ButtonGroup>
            </Box>
            <b>{contract.activity.name}</b>
          </Box>
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
              <span>Cumplimiento: </span>
              <Box paddingX={2}>
                <Tag
                  intent={
                    taskEvaluation.compliance === 100 ? "success" : "warning"
                  }
                >
                  {taskEvaluation.compliance}%
                </Tag>
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <span>Calificación: </span>
              <Box paddingX={2}>
                <Tag
                  intent={
                    taskEvaluation.compliance === 100 ? "success" : "warning"
                  }
                >
                  {taskEvaluation.qualification}
                </Tag>
              </Box>
            </Box>
            <ButtonGroup>
              <Button outlined icon="add-column-left" onClick={toggleDrawer}>
                Histórico
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      </Card>
      <Collapse isOpen={isOpen}>
        <Card
          className={classes.noPadding}
          style={{ borderRadius: "0 0 10px 10px" }}
        >
          <Box marginBottom={3}>
            {contract.tasks.length &&
            tower?.currentWeek &&
            contract.tasks[0].evaluations?.towerWeek &&
            tower.currentWeek > contract.tasks[0].evaluations.towerWeek ? (
              <Callout intent="warning">
                Evaluación atrasada{" "}
                {tower?.currentWeek - contract.tasks[0].evaluations?.towerWeek}{" "}
                {tower?.currentWeek - contract.tasks[0].evaluations?.towerWeek >
                1
                  ? "semanas"
                  : "semana"}
                , evaluando semana:{" "}
                {contract.tasks[0].evaluations?.towerWeek +
                  ResolveVirtualWeek(tower.virtualWeek)}
              </Callout>
            ) : null}
            <table className={classes.table}>
              <thead>
                <tr>
                  <th>
                    <span />
                  </th>
                  <th>Unidad</th>
                  <th>Can. Programada</th>
                  <th>Can. Ejecutada</th>
                  <th>% Incidencia</th>
                  <th>% Cump</th>
                  <th>
                    <span />
                  </th>
                </tr>
              </thead>
              <tbody>
                {contract.tasks.map((task) => (
                  <tr key={task.id}>
                    <td>{task.name}</td>
                    <td>{task.unit.name}</td>
                    <td>
                      <NumberFormatString
                        value={task.evaluations?.programValue}
                      />
                    </td>
                    <td className={classes.noPadding}>
                      {task.evaluations?.programValue !== 0 &&
                      task.evaluations &&
                      evaluationValues[`${task.id}`] &&
                      evaluationValues[`${task.id}`].state !==
                        EvaluationState.SCHEDULED_EVALUATED ? (
                        <Box>
                          <NumberFormat
                            customInput={OutlinedInput}
                            placeholder="0"
                            value={
                              evaluationValues[`${task.id}`]?.evaluationValue
                            }
                            onValueChange={handleValueChange(
                              `${task.id}`,
                              task.evaluations,
                              task.total
                            )}
                            isAllowed={({ floatValue }) =>
                              floatValue ? floatValue <= task.total : true
                            }
                            className="numberInput"
                            allowNegative={false}
                            thousandSeparator
                            allowLeadingZeros
                          />
                        </Box>
                      ) : (
                        <NumberFormatString
                          value={
                            evaluationValues[`${task.id}`]?.evaluationValue
                          }
                        />
                      )}
                    </td>
                    <td>{task.evaluations?.percentage}%</td>
                    <td>{evaluationValues[`${task.id}`]?.compliance}%</td>
                    <td>
                      {evaluationValues[`${task.id}`]?.state !==
                      EvaluationState.SCHEDULED_EVALUATED ? (
                        <Button
                          icon="archive"
                          style={{ background: "white" }}
                          small
                          onClick={() => saveTaskEvaluation(task)}
                        >
                          Guardar
                        </Button>
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        </Card>
      </Collapse>
    </Box>
  );
};

const EvaluateContractorTableContainer: FC<EvaluateContractorTableParams> = (
  props
) => {
  return (
    <EvaluateContractorTableProvider {...props}>
      <EvaluateContractorTable />
    </EvaluateContractorTableProvider>
  );
};

export default EvaluateContractorTableContainer;
