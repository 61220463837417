import { Activity } from "lib/projects/models/Activity";
import { useEffect, useState } from "react";
import { useProgramAssignmentContext } from "../context/ProgramAssignmentContext";

type TaskRow = Omit<Activity, "contractors"> & {
  numberOfContractors: number;
};
type Col = {
  name: string;
  title: string;
  maxWidth?: number;
  width?: number | string;
};
export interface UseAssignTaskTableParams {
  name?: string;
}

export interface UseAssignTaskTableResult {
  rows: TaskRow[];
  cols: Col[];
  selectCurrentTask: (id?: string) => void;
}

type HookType = (param?: UseAssignTaskTableParams) => UseAssignTaskTableResult;

const useAssignTaskTable: HookType = () => {
  const { tasks, selectCurrentTask: selectTask } =
    useProgramAssignmentContext();
  const [rows, setRows] = useState<TaskRow[]>([]);
  const [cols] = useState<Col[]>([
    { name: "name", title: "Nombre", width: "50%" },
    { name: "startDate", title: "Fecha Inicio", maxWidth: 10 },
    { name: "endDate", title: "Fecha Fin" },
    { name: "", title: "" },
  ]);

  useEffect(() => {
    setRows(
      tasks.map((activity) => {
        const { contracts, ...extraTask } = activity;
        return { ...extraTask, numberOfContractors: contracts?.length || 0 };
      })
    );
  }, [tasks]);

  const selectCurrentTask = (id?: string): void =>
    selectTask(id ? tasks.find((cTask) => cTask.id === id) : undefined);

  return {
    rows,
    cols,
    selectCurrentTask,
  };
};

export default useAssignTaskTable;
