import Axios, { AxiosInstance, AxiosResponse } from "axios";
import Config from "config";
import { LogicError } from "./LogicError";

const tokenHeaderName = "x-token";
const refreshTokenHeaderName = "x-refresh-token";

export class HttpService {
  protected httpClient: AxiosInstance;

  protected errors: Record<string, unknown> = {};

  constructor(base: string, errors?: Record<string, unknown>) {
    this.httpClient = Axios.create({
      baseURL: `${Config.server.serverUrl}${base}`,
    });
    if (errors) this.errors = errors;
    this.setInterceptor();
  }

  private setInterceptor = (): void => {
    this.refreshTokens();
    this.httpClient.interceptors.response.use(
      (response: AxiosResponse): AxiosResponse => {
        const headerToken = response.headers[tokenHeaderName];
        const headerRefreshToken = response.headers[refreshTokenHeaderName];
        if (headerToken && headerRefreshToken) {
          localStorage.setItem("contractor_access", headerToken);
          localStorage.setItem("contractor_refresh_access", headerRefreshToken);
          this.refreshTokens();
        }
        return response;
      },
      (error) => {
        if (Axios.isAxiosError(error) && error.response?.data?.logic) {
          const logic: string = error.response?.data?.logic;
          const message = this.errors[logic] || error.response?.data?.message;
          if (
            message === "Refresh token was expired" ||
            message === "Refresh not exists"
          ) {
            alert("LOGOUT");
            return Promise.reject();
          }
          return Promise.reject(new LogicError(message));
        }
        return Promise.reject(
          new LogicError(
            (Axios.isAxiosError(error) && error.response?.data?.message) ||
              "Error interno del servidor"
          )
        );
      }
    );
  };

  private refreshTokens = (): void => {
    const token = localStorage.getItem("contractor_access");
    const refreshToken = localStorage.getItem("contractor_refresh_access");

    this.httpClient.defaults.headers.common[
      tokenHeaderName
    ] = `Bearer ${token}`;
    this.httpClient.defaults.headers.common[refreshTokenHeaderName] =
      refreshToken;
  };
}
