import React, { FC } from "react";
// eslint-disable-next-line import/no-cycle
import { PrivateRoutes } from "config/router/routes";
import { Redirect } from "react-router";
import usePermision from "shared/customHooks/usePermision";

const HomePage: FC = () => {
  const { haveAccess } = usePermision();
  return (
    <Redirect
      to={
        PrivateRoutes.find((route) => haveAccess(route.name, "canView"))
          ?.path || "/notfound"
      }
    />
  );
};

export default HomePage;
