import { BreadcrumbProps } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { useLocation, useRouteMatch, matchPath } from "react-router";

interface BreadcrumbPropsProgram extends BreadcrumbProps {
  name?: string;
  current?: boolean;
}

export interface UseProgramResult {
  routes: BreadcrumbPropsProgram[];
  // removeRoute: (name: string) => void;
  addRoute: (route: BreadcrumbPropsProgram) => void;
}

type HookType = () => UseProgramResult;

const useProgram: HookType = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  const initRoutes = [
    {
      path: "/pac/program",
      name: "Base",
      icon: "projects",
      text: "Projectos",
    },
    {
      path: "/pac/program/:projectId",
      name: "TowerSelected",
      icon: "comparison",
      text: "Etapas",
    },
    {
      path: "/pac/program/:projectId/assignment/:towerId",
      name: "TowerAssign",
      icon: "inherited-group",
      text: "Asignación de contratistas",
    },
    {
      path: "/pac/program/:projectId/program/:towerId",
      name: "TowerProgram",
      icon: "flows",
      text: "Programación",
    },
    {
      path: "/pac/program/:projectId/program/:towerId/contractor/:contractorId",
      name: "TowerProgram",
      icon: "form",
      text: "Contratista",
    },
  ];

  const [routes, setRoutes] = useState<BreadcrumbPropsProgram[]>([
    {
      href: path.replace("/tower/assign/:towerId", ""),
      icon: "folder-close",
      text: "Projectos",
      current: true,
    },
  ]);

  const addRoute = (route: BreadcrumbPropsProgram): void => {
    const indexRoute = routes.findIndex(
      (current) => current.name === route.name
    );
    if (indexRoute > 0) {
      // empty
      setRoutes(
        routes.slice(0, indexRoute).concat({ ...route, current: true })
      );
    } else {
      setRoutes(
        routes
          .map((current) => ({ ...current, current: false }))
          .concat({
            ...route,
            current: true,
          })
      );
    }
  };

  const resolve = (): void => {
    const matchs = initRoutes
      .map((route) => {
        const match = matchPath(pathname, route);
        if (match)
          return {
            ...match,
            name: route.name,
            text: route.text,
            icon: route.icon,
          };
        return false;
      })
      .filter((route) => route);
    setRoutes(
      matchs.map((route) => {
        if (route) {
          return {
            href: route.url,
            text: route.text,
            icon: route.icon,
            current: route.isExact,
          } as BreadcrumbPropsProgram;
        }
        return {};
      })
    );
  };

  useEffect(() => {
    resolve();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return {
    routes,
    // removeRoute,
    addRoute,
  };
};

export default useProgram;
