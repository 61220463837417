import React, { FC } from "react";
import { Box } from "@material-ui/core";
import {
  Breadcrumb,
  BreadcrumbProps,
  Breadcrumbs,
  Icon,
} from "@blueprintjs/core";
import { useHistory } from "react-router";

export interface AppBreadcrumbProps extends BreadcrumbProps {
  name?: string;
  current?: boolean;
}

const AppBreadcrumb: FC<{ routes: AppBreadcrumbProps[] }> = ({ routes }) => {
  const history = useHistory();
  return (
    <Box marginBottom={4}>
      <Breadcrumbs
        breadcrumbRenderer={({
          text,
          href,
          current,
          ...rest
        }: BreadcrumbProps) => {
          return (
            <Breadcrumb
              {...rest}
              current={current}
              onClick={() => {
                if (href && !current) history.push(href);
              }}
            >
              {text}
              <Icon icon="caret-right" />
            </Breadcrumb>
          );
        }}
        currentBreadcrumbRenderer={({
          text,
          href,
          current,
          ...rest
        }: BreadcrumbProps) => {
          return (
            <Breadcrumb
              {...rest}
              current={current}
              onClick={() => {
                if (href && !current) history.push(href);
              }}
            >
              {text}
            </Breadcrumb>
          );
        }}
        items={routes}
      />
    </Box>
  );
};

export default AppBreadcrumb;
