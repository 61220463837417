import { useSelector } from "react-redux";
import { RootState } from "store";
import useProjectsOfContractor from "modules/Contractor/hooks/useProjectsOfContractor";

const useListProjects = () => {
  const { contractor } = useSelector((state: RootState) => state.auth);
  const { projects } = useProjectsOfContractor(contractor?.id);

  return {
    contractor: {},
    projects,
  };
};

export default useListProjects;
