import React, { FC } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import { H2, Button, ButtonGroup, Divider } from "@blueprintjs/core";
import HavePermission from "components/HavePermission/HavePermission";
import UploadActivities from "modules/PAC/features/UploadActivities/UploadActivities";
import {
  ProgramHomeProvider,
  useProgramHomeContext,
} from "./context/ProgramHomeContext";

const useStyles = makeStyles(() => {
  return {
    root: {},
    table: {
      borderSpacing: "10px",
    },
    program: {},
  };
});

const ProgramHome: FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();
  const {
    // uploadTasks,
    towers,
    project,
    downloadExecl,
    uploadTasks,
  } = useProgramHomeContext();
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <H2>{project?.name}</H2>
        </Box>
        <Box>
          <Box>
            <HavePermission action="canAdd" resourceName="pac.activities">
              <Button intent="primary" onClick={downloadExecl} icon="download">
                Plantilla actividades
              </Button>
            </HavePermission>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box>
        {towers.length ? (
          <Box>
            <table className={classes.table}>
              <tbody>
                {towers.map((tower) => (
                  <tr key={tower.id}>
                    <td>
                      <Box pr={2}>
                        <Typography>{tower.name}</Typography>
                      </Box>
                    </td>
                    <td>
                      <ButtonGroup>
                        {tower.tasksCount === "0" ? (
                          <HavePermission
                            action="canAdd"
                            resourceName="pac.activities"
                          >
                            <UploadActivities
                              buttonProps={{ large: true }}
                              uploadTasks={async (file) => {
                                await uploadTasks(file, tower.id);
                              }}
                            />
                          </HavePermission>
                        ) : (
                          <HavePermission
                            action="canView"
                            resourceName="pac.assignActivities"
                          >
                            <Button
                              intent="none"
                              icon="inherited-group"
                              large
                              onClick={() =>
                                history.push(
                                  `${history.location.pathname}/assignment/${tower.id}`
                                )
                              }
                            >
                              Actividades
                            </Button>
                          </HavePermission>
                        )}
                        {tower?.contractorsCount &&
                        +tower.contractorsCount > 0 ? (
                          <HavePermission
                            action="canView"
                            resourceName="pac.scheduleActivities"
                          >
                            <Button
                              large
                              icon="flows"
                              onClick={() =>
                                history.push(
                                  `${history.location.pathname}/program/${tower.id}`
                                )
                              }
                            >
                              Programar actividades
                            </Button>
                          </HavePermission>
                        ) : null}
                        {tower?.contractorsCount &&
                        +tower.contractorsCount > 0 ? (
                          <HavePermission
                            action="canView"
                            resourceName="pac.scheduleConsolidated"
                          >
                            <Button
                              large
                              icon="full-stacked-chart"
                              onClick={() =>
                                history.push(
                                  `${history.location.pathname}/consolidated/${tower.id}`
                                )
                              }
                            >
                              Consolidado de Programación
                            </Button>
                          </HavePermission>
                        ) : null}
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

const ProgramHomeContainer: FC<RouteComponentProps> = (props) => {
  return (
    <ProgramHomeProvider>
      <ProgramHome {...props} />
    </ProgramHomeProvider>
  );
};

export default ProgramHomeContainer;
