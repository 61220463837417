import { useEffect, useState } from "react";
import { TaskReason } from "lib/contracts/models/TaskReason";
import { TaskReasonService } from "lib/contracts/services/TaskReasonService";
import useTaskReasons from "lib/contracts/hooks/useTaskReasons";

type AsyncCallbakcReason = (reason: TaskReason) => Promise<void>;

export interface UsePACConfigResult {
  taskReasons: TaskReason[];
  onCreateReason: () => void;
  removeReason: AsyncCallbakcReason;
  refresh: () => void;
  asyncRefresh: () => Promise<void>;
}

type HookType = () => UsePACConfigResult;

const usePACConfig: HookType = () => {
  const [initialTaskReasons, fetchTaskReason] = useTaskReasons();
  const [taskReasons, setTaskReasons] = useState<TaskReason[]>([]);

  useEffect(() => {
    if (Array.isArray(initialTaskReasons)) setTaskReasons(initialTaskReasons);
  }, [initialTaskReasons]);

  const onCreateReason = (): void => {
    fetchTaskReason();
  };

  const removeReason: AsyncCallbakcReason = async (reason) => {
    try {
      const service = new TaskReasonService();
      await service.remove(reason.id);
      fetchTaskReason();
      alert({ message: "Se ha borrado el motivo", severity: "warning" });
    } catch (error) {
      alert(error);
    }
  };

  const refresh = (): void => {
    fetchTaskReason();
  };

  const asyncRefresh = (): Promise<void> => {
    return fetchTaskReason();
  };

  return {
    taskReasons,
    onCreateReason,
    removeReason,
    refresh,
    asyncRefresh,
  };
};

export default usePACConfig;
