import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { Route, useRouteMatch } from "react-router";
import CustomSwitch from "shared/components/CustomSwitch/CustomSwitch";
import ProgramContractHeader from "./components/ProgramContractHeader/ProgramContractHeader";
import ListContractors from "./containers/ListContractors/ListContractors";
import ProgramContract from "./containers/ProgramContract/ProgramContract";
import { SelectContractorProvider } from "./context/SelectContractorContext";
import SelectContractorStyles from "./styles";

const SelectContractor: FC = () => {
  const classes = SelectContractorStyles();
  const { path } = useRouteMatch();

  return (
    <Box>
      <Box>
        <ProgramContractHeader />
        <Box>
          <Box className={classes.root}>
            <CustomSwitch>
              <Route
                exact
                path={`${path}/contractor/:contractorId`}
                component={ProgramContract}
              />
              <Route exact path={path} component={ListContractors} />
            </CustomSwitch>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const SelectContractorContainer: FC = () => {
  return (
    <SelectContractorProvider>
      <SelectContractor />
    </SelectContractorProvider>
  );
};

export default SelectContractorContainer;
