import useFetchProgrammingConsolidated from "./useFetchProgrammingConsolidated";

const useProgrammingConsolidated = () => {
  const { response, isFetching, fetch } = useFetchProgrammingConsolidated();

  return {
    ...response,
    isFetching,
    fetch,
  };
};

export default useProgrammingConsolidated;
