import React, { useState, forwardRef, useImperativeHandle, FC } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  paper: {
    width: "80%",
    maxHeight: 435,
  },
}));

export interface IAsyncConfirmModal {
  show: (ok: () => void, cancel?: () => void) => Promise<void>;
}

const AsyncConfirmModal: FC<
  { title: string } & { ref: React.Ref<IAsyncConfirmModal> }
> = forwardRef(({ title, children }, ref) => {
  const [open, setOpen] = useState(false);
  const [promise, setPromise] = useState<{
    ok: () => void;
    cancel?: () => void;
  }>({
    ok: (): void => {
      alert("ok");
    },
    cancel: (): void => {
      alert("cancel");
    },
  });

  const classes = useStyles();

  const handleOk = (): void => {
    setOpen(false);
    if (promise.ok) promise.ok();
  };

  const handleCancel = (): void => {
    setOpen(false);
    if (promise.cancel) promise.cancel();
  };

  useImperativeHandle(ref, () => ({
    show: (okFunc: () => void, cancel?: () => void): Promise<void> =>
      new Promise(() => {
        setPromise({
          ok: okFunc,
          cancel,
        });
        setOpen(true);
      }),
  }));

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      open={open}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="secondary">
          Cancelar
        </Button>
        <Button onClick={handleOk}>Confirmar</Button>
      </DialogActions>
    </Dialog>
  );
});

export default AsyncConfirmModal;
