import { makeStyles } from "@material-ui/core";

const ProjectModalStyles = makeStyles(() => ({
  formItem: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  label: {
    minWidth: "50%",
  },
  table: {
    borderSpacing: 0,
    width: "100%",
  },
}));

export default ProjectModalStyles;
