import React, { FC } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  InputBase,
  makeStyles,
} from "@material-ui/core";

import { Button } from "@blueprintjs/core";
import useProgramContractIncidenceForm from "./useProgramContractIncidenceForm";

const useStyles = makeStyles({
  root: {
    borderSpacing: 2,
    "& td, & th": {
      border: "1px solid lightgray",
      width: "20%",
      textAlign: "center",
      padding: "4px",
      "& input": {
        borderBottom: "1px solid gray",
      },
    },
  },
  green: {
    color: "green",
  },
  error: {
    color: "red",
  },
});

const ProgramContractIncidenceForm: FC = () => {
  const compClasses = useStyles();
  const {
    open,
    handleClick,
    handleClose,
    handleSubmit,
    handleInputChange,
    data,
    value,
    contract,
    tasks,
  } = useProgramContractIncidenceForm();
  return (
    <span>
      <Button
        intent="primary"
        // className={classes.button}
        small
        onClick={handleClick}
      >
        Guardar
      </Button>
      {open ? (
        <Dialog open={open} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Guardar</DialogTitle>
          <DialogContent>
            <table style={{ borderSpacing: 5 }}>
              <tbody>
                <tr>
                  <td>Nombre actividad</td>
                  <td>
                    <b>{contract.activity.name}</b>
                  </td>
                </tr>
                <tr>
                  <td>Contratista</td>
                  <td>
                    <b>{contract.contractor.name}</b>
                  </td>
                </tr>
              </tbody>
            </table>
            <form id="form-incidence" onSubmit={handleSubmit} />
            <Divider />
            <Box paddingY={2}>
              <table className={compClasses.root}>
                <thead>
                  <tr>
                    <th>Actividad</th>
                    <th>Unidad</th>
                    <th>Total</th>
                    <th>Programado</th>
                    <th>Prioridad</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span />
                    </td>
                    <td>
                      <span />
                    </td>
                    <td>
                      <span />
                    </td>
                    <td>
                      <span />
                    </td>
                    <td
                      className={
                        `${value()}` === `${100}`
                          ? compClasses.green
                          : compClasses.error
                      }
                    >
                      <Box>
                        <b>{value()}%</b>
                      </Box>
                    </td>
                  </tr>
                  {tasks.map((activity) => {
                    return (
                      <tr key={`incidence-${activity.id}`}>
                        <td>
                          <Box>{activity.name}</Box>
                        </td>
                        <td>
                          <Box>{activity.unit.name}</Box>
                        </td>
                        <td>
                          <Box>{activity.total}</Box>
                        </td>
                        <td>
                          <Box>
                            {activity?.evaluations
                              ? activity.evaluations
                                  .find(
                                    (evaluation) =>
                                      evaluation.currentProgramation
                                  )
                                  ?.value.toFixed(2)
                              : "0"}
                          </Box>
                        </td>
                        <td>
                          <Box>
                            {activity.id ? (
                              <InputBase
                                type="number"
                                value={data[activity.id] || 0}
                                onChange={handleInputChange(activity.id)}
                              />
                            ) : null}
                          </Box>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button intent="primary" onClick={handleClose}>
              Cerrar
            </Button>
            <Button intent="primary" type="submit" form="form-incidence" fill>
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </span>
  );
};

export default ProgramContractIncidenceForm;
