import { initializeApp, setAppLoading, setPlatformConfig } from "App/store";
import logging from "config/logger/logging";
import useAuth from "features/Authentication/core/hooks/useAuth";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import usePermision from "shared/customHooks/usePermision";
import { RootState } from "store";
import usePlatformConfig from "./usePlatformConfig";

const useApp = () => {
  const dispatch = useDispatch();
  const { initialized, loading } = useSelector((state: RootState) => state.app);
  const { user, userInitialized, minSide } = useSelector(
    (state: RootState) => state.auth
  );
  const { initializeAuth, initializeUser, initializePermissions } = useAuth();
  const { permissions } = usePermision();
  const { getPublicConfig } = usePlatformConfig();

  useEffect(() => {
    if (user) initializeUser(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, userInitialized]);

  useEffect(() => {
    if (permissions) initializePermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInitialized, permissions]);

  const initialize = useCallback(async () => {
    if (!initialized) {
      dispatch(setAppLoading(true));
      logging.info("App Loading");
      await initializeAuth();
      const platfornConfig = await getPublicConfig();
      dispatch(setPlatformConfig(platfornConfig));
      dispatch(setAppLoading(false));
      dispatch(initializeApp());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return {
    initialized,
    loading,
    minSide,
  };
};

export default useApp;
