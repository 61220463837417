import { makeStyles, Theme } from "@material-ui/core";

interface Params {
  minSide: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flexGrow: 1,
    background: theme.palette.lightBackground.main,
  },
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: ({ minSide }: Params) => (minSide ? "auto" : "100vh"),
    overflowX: "hidden",
  },
  containerRoutes: {
    paddingTop: ({ minSide }: Params) => theme.spacing(minSide ? 2 : 4),
    paddingBottom: ({ minSide }: Params) => theme.spacing(minSide ? 2 : 4),
    paddingLeft: ({ minSide }: Params) => theme.spacing(minSide ? 2 : 0),
    paddingRight: ({ minSide }: Params) => theme.spacing(minSide ? 2 : 0),
    minHeight: ({ minSide }: Params) => (minSide ? "auto" : "100vh"),
  },
}));

export default useStyles;
