import { createRef } from "react";

import { PersonsService } from "lib/users/services/PersonsService";
import { IAsyncConfirmModal } from "shared/components/AsyncModal/AsyncConfirmModal";

const useRemovePerson = ({
  onRemovePerson,
}: {
  onRemovePerson: (id: string) => void;
}): {
  modalRef: React.RefObject<IAsyncConfirmModal>;
  handleClickDelete: (id: string) => Promise<void>;
} => {
  const modalRef = createRef<IAsyncConfirmModal>();

  const removePerson = async (id: string): Promise<void> => {
    const service = new PersonsService();
    await service.updatePerson(id, { disabled: true });
    onRemovePerson(id);
  };

  const handleClickDelete = async (id: string): Promise<void> => {
    await modalRef.current?.show(() => removePerson(id));
  };

  return {
    modalRef,
    handleClickDelete,
  };
};

export default useRemovePerson;
