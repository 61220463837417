import React from "react";
import {
  Box,
  BoxProps,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { cs } from "shared/helpers/CssHelpers";
import { Icon as BIcon } from "@blueprintjs/core";

interface MenuItemComponentProps extends BoxProps {
  icon?: React.ElementType;
  active?: boolean;
  title: string;
  light?: boolean;
  disabled?: boolean;
  minSide?: boolean;
}

interface StylePropType {
  light: MenuItemComponentProps["light"];
  minSide: MenuItemComponentProps["minSide"];
}

const useStyles = makeStyles((theme: Theme) => {
  const { primary, white, secondaryDark, tertiaryText } = theme.palette;
  return createStyles({
    activeBar: {
      height: "100%",
      width: 6,
      backgroundColor: ({ light }: StylePropType) =>
        light ? primary.main : secondaryDark.main,
      position: "absolute",
      borderRadius: "10px 0 0 10px",
      right: 0,
    },
    activeContainer: {
      "&:hover": {
        cursor: "initial",
      },
    },
    activeTitle: {
      color: ({ light }: StylePropType) =>
        light
          ? `${primary.main} !important`
          : `${secondaryDark.main} !important`,
    },
    container: {
      position: "relative",
      height: ({ minSide }: StylePropType) => (minSide ? 40 : 60),
      cursor: "pointer",
      display: "flex",
      flexGrow: ({ minSide }: StylePropType) => (minSide ? 1 : 0),
      alignItems: "center",
      "&:hover": {
        "& $title, & $icon": {
          color: ({ light }: StylePropType) =>
            light ? primary.main : secondaryDark.main,
        },
      },
    },
    title: {
      userSelect: "none",
      fontSize: 16,
      fontWeight: 600,
      textTransform: "capitalize",
      lineHeight: "20px",
      letterSpacing: "0.2px",
      color: ({ light }: StylePropType) =>
        light ? tertiaryText.main : white.main,
      marginLeft: ({ minSide }: StylePropType) => (minSide ? 10 : 18),
      marginRight: ({ minSide }: StylePropType) => (minSide ? 10 : 0),
    },
    icon: {
      marginLeft: ({ minSide }: StylePropType) => (minSide ? 10 : 26),
      marginTop: 2,
      color: ({ light }: StylePropType) =>
        light ? tertiaryText.main : white.main,
    },
    disabled: {
      cursor: "default",
      color: "gray !important",
      "&:hover": {
        "& $title, & $icon": {
          color: "gray !important",
        },
      },
    },
  });
});

const MenuItemComponent: React.FC<MenuItemComponentProps> = ({
  icon: Icon,
  active,
  title,
  light,
  disabled,
  minSide,
  ...extraProps
}) => {
  const classes = useStyles({ light, minSide });
  return (
    <Box
      display="flex"
      alignItems="center"
      className={cs(
        classes.container,
        !!active && classes.activeContainer,
        !!disabled && classes.disabled
      )}
      {...extraProps}
    >
      {!minSide && active && <div className={classes.activeBar} />}
      {Icon ? (
        <span
          className={cs(
            classes.icon,
            !!active && classes.activeTitle,
            !!disabled && classes.disabled
          )}
        >
          <Icon />
        </span>
      ) : null}
      <span
        className={cs(
          classes.title,
          !!active && classes.activeTitle,
          !!disabled && classes.disabled
        )}
        style={{
          textAlign: "center",
          verticalAlign: "middle",
        }}
      >
        {title}
      </span>
      {disabled ? (
        <BIcon
          icon="build"
          size={14}
          style={{ marginLeft: "10px", color: "darkkhaki" }}
        />
      ) : null}
    </Box>
  );
};
export default MenuItemComponent;
