import React, { FC } from "react";
import styled from "styled-components";

const IconStyled = styled.img`
  max-width: 180px;
  max-height: 60px;
`;

const AppLogo: FC<{ src: string }> = ({ src }): JSX.Element => (
  <IconStyled src={src} />
);

export default AppLogo;
