import { ChangeEventHandler, useCallback, useEffect, useState } from "react";

import { User } from "lib/users/models/User";
import { PersonsService } from "lib/users/services/PersonsService";

interface UsePersonsResult {
  persons: User[];
  isOpenModalRolePerson: boolean;
  closeModalRolePerson: () => void;
  isOpenModalPerson: boolean;
  openModalPerson: () => void;
  closeModalPerson: () => void;
  handleCreateUser: (person: User) => void;
  handleClickEdit: (person: User) => void;
  handleClickSelectRole: (person: User) => void;
  handleUserUpdated: (person: User) => void;
  handleUserRemove: (id: string) => void;
  handleChangeRolePerson: (id: string, person: Partial<User>) => void;
  handleChangeSearch: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  currentPerson?: User;
}

const usePersons = (): UsePersonsResult => {
  const [persons, setPersons] = useState<User[]>([]);
  const [originalPersons, setOriginalPersons] = useState<User[]>([]);
  const [currentPerson, setCurrentPerson] = useState<User>();
  const [isOpenModalPerson, setIsOpenModalPerson] = useState(false);
  const [isOpenModalRolePerson, setIsOpenModalRolePerson] = useState(false);

  const fetch = useCallback(async () => {
    const service = new PersonsService();
    const { data } = await service.getAllPersons();
    setOriginalPersons(data.users);
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    setPersons(originalPersons);
  }, [originalPersons]);

  const openModalPerson = (): void => setIsOpenModalPerson(true);
  const closeModalPerson = (): void => {
    setIsOpenModalPerson(false);
    setCurrentPerson(undefined);
  };

  const openModalRolePerson = (): void => setIsOpenModalRolePerson(true);
  const closeModalRolePerson = (): void => {
    setIsOpenModalRolePerson(false);
    setCurrentPerson(undefined);
  };

  const handleCreateUser = (person: User): void => {
    setIsOpenModalPerson(false);
    setOriginalPersons(originalPersons.concat(person));
  };

  const handleUserUpdated = (personUpdated: User): void => {
    setIsOpenModalPerson(false);
    setOriginalPersons(
      originalPersons.map((person) => {
        if (person.id === personUpdated.id) return personUpdated;
        return person;
      })
    );
  };

  const handleUserRemove = (id: string): void =>
    setOriginalPersons(originalPersons.filter((person) => person.id !== id));

  const handleClickEdit = (person: User): void => {
    setCurrentPerson(person);
    openModalPerson();
  };

  const handleClickSelectRole = (person: User): void => {
    setCurrentPerson(person);
    openModalRolePerson();
  };

  const handleChangeRolePerson = (
    id: string,
    personUpdated: Partial<User>
  ): void => {
    closeModalRolePerson();
    setOriginalPersons(
      originalPersons.map((person) => {
        if (person.id === id) return { ...person, ...personUpdated };
        return person;
      })
    );
  };

  const handleChangeSearch: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    e.preventDefault();
    setPersons(
      originalPersons.filter((person) =>
        person.name
          .concat(` ${person.lastName || ""}`)
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      )
    );
  };

  return {
    persons,
    isOpenModalPerson,
    openModalPerson,
    handleCreateUser,
    handleUserUpdated,
    closeModalPerson,
    handleClickEdit,
    currentPerson,
    handleUserRemove,
    closeModalRolePerson,
    isOpenModalRolePerson,
    handleChangeRolePerson,
    handleClickSelectRole,
    handleChangeSearch,
  };
};
export default usePersons;
