const AuthErrors = {
  REQUIRE_EMAIL_PASSWORD:
    "El correo electrónico y la contraseña son requeridos",
  NOT_FOUND: "El usuario no existe",
  NOT_CONFIRMED: "El no ha confirmado el usuario",
  INVALID_PASSWORD: "Contraseña incorrecta",
  LINK_ALREADY_USED: "Invitación no válida",
  INVALID_HASH: "Invitación no válida",
};
export default AuthErrors;
