import { Button, Card, H5 } from "@blueprintjs/core";
import { Classes, Popover2 } from "@blueprintjs/popover2";
import { Box } from "@material-ui/core";
import React, { FC } from "react";

const ProgramCellRemoveRow: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <Popover2
        usePortal
        autoFocus
        position="top"
        content={
          <Card>
            <div key="text">
              <H5>Borrar programación</H5>
              <p style={{ width: "250px" }}>
                ¿Está seguro de que desea borrar esta programación?
                <br />
                Se eliminaran las programaciones posteriores y no podrá
                recuperarlas.
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 15,
                }}
              >
                <Button
                  className={Classes.POPOVER2_DISMISS}
                  style={{ marginRight: 10 }}
                >
                  Cancelar
                </Button>
                <Button
                  intent="danger"
                  className={Classes.POPOVER2_DISMISS}
                  onClick={onClick}
                >
                  Borrar
                </Button>
              </div>
            </div>
          </Card>
        }
      >
        <Button icon="trash" small />
      </Popover2>
    </Box>
  );
};

export default ProgramCellRemoveRow;
