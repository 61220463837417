import React, { FC } from "react";
import { Button, Grid, Link, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import useLogin from "features/Authentication/core/hooks/useLogin";
import useLoginForm from "features/Authentication/core/hooks/useLoginForm";
import LoginFormStyles from "./LoginFormStyles";

const LoginForm: FC = () => {
  const classes = LoginFormStyles();
  const { login, authError } = useLogin();
  const { data, errors, handleSubmit, handleChange } = useLoginForm(login);

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit}>
      <TextField
        value={data?.email || ""}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Correo Electrónico"
        name="email"
        autoComplete="email"
        autoFocus
        error={!!errors?.email}
        helperText={errors?.email}
        onChange={handleChange("email")}
      />
      <TextField
        value={data.password || ""}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Contraseña"
        type="password"
        id="password"
        autoComplete="current-password"
        error={!!errors?.password}
        helperText={errors?.password}
        onChange={handleChange("password")}
      />
      {authError ? <Alert severity="warning">{authError}</Alert> : ""}

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
      >
        Iniciar sesión
      </Button>
      <Grid container>
        <Grid item xs>
          <Link href="/forgot-password" variant="body2">
            ¿Olvidaste tu contraseña?
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginForm;
