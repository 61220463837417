import { ContractorService } from "lib/contractors/services/ContractorService";
import { Tower } from "lib/projects/models/Tower";
import { TowerWeek } from "../types/TowerWeek";

export class TowerWeeksService extends ContractorService {
  constructor() {
    super();
    this.errors = {};
  }

  getTowerWeeks = (contractorId: string, towerId: string) =>
    this.httpClient.get<{
      weeks: TowerWeek[];
      tower: Tower;
      compliance: number;
      qualification: number;
    }>(`/${contractorId}/tower/${towerId}/weeks`);
}
