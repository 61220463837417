import { DateRange } from "@blueprintjs/datetime";
import { Activity } from "lib/projects/models/Activity";
import { Tower } from "lib/projects/models/Tower";
import useBoolean from "shared/customHooks/useBoolean";
import { useForm } from "shared/customHooks/useForm";
import { CreationActivityModel } from "../models/CreationActivityModel";
import { CreateActivityService } from "../services/CreateActivityService";

interface Form {
  name: string;
  dates: DateRange;
}

interface Props {
  tower: Tower;
  onActivityCreated: (activity: Activity) => void;
}

const useCreateFormDialog = ({ tower, onActivityCreated }: Props) => {
  const { value: isOpen, setTrue, setFalse } = useBoolean();

  const handleSubmit = async (values: Form, reset: () => void) => {
    try {
      const { name, dates } = values;
      const [startDate, endDate] = dates;
      if (!!startDate && !!endDate) {
        const creationData: CreationActivityModel = {
          name,
          startDate,
          endDate,
        };
        const service = new CreateActivityService();
        const { data } = await service.create(tower.id, creationData);
        const { activity } = data;
        onActivityCreated(activity);
        alert({
          message: "La acción se ejecutó con éxito",
          severity: "success",
        });
        setFalse();
      }
      reset();
    } catch (error) {
      alert(error);
    }
  };

  const form = useForm<Form>({
    validations: {
      name: {
        required: {
          value: true,
          message: "Nombre requerido",
        },
      },
      dates: {
        custom: {
          isValid: (value) => !!value[0] && !!value[1],
          message: "Se require agregar una fecha de inicio y de fin",
        },
      },
    },
    initialValues: {
      name: "",
      dates: [null, null],
    },
    onSubmit: handleSubmit,
  });

  const handleDatePickerChange = (dates: DateRange) => {
    form.handleChangeValueType("dates", dates);
  };

  const onClose = () => {
    form.resetData();
    setFalse();
  };

  return {
    ...form,
    isOpen,
    handleDatePickerChange,
    handleClickDialog: setTrue,
    onClose,
  };
};

export default useCreateFormDialog;
