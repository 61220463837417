import { HttpService } from "shared/services/HttpService";
import { PlatformConfig } from "../models/PlatformConfig";

export class PlatformConfigService extends HttpService {
  constructor() {
    super("/platform");
  }

  getPublicConfig = () =>
    this.httpClient.get<{ config: PlatformConfig }>("/public");
}
