import { setContractorTower } from "modules/Contractor/store";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { ContractorService } from "lib/contractors/services/ContractorService";

const useTowerOfContractor = (towerId: string) => {
  const dispatch = useDispatch();
  const contractor = useSelector((state: RootState) => state.auth.contractor);
  const tower = useSelector((state: RootState) => state.Contractor.tower);

  const fetch = useCallback(async () => {
    if (contractor && contractor.id && !tower?.id) {
      try {
        const service = new ContractorService();
        const { data } = await service.getTower(contractor.id, towerId);
        dispatch(setContractorTower(data.tower));
      } catch (error) {
        alert(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractor, towerId]);

  useEffect(() => {
    fetch();
  }, [contractor, towerId, fetch]);

  return tower;
};

export default useTowerOfContractor;
