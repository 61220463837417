import React, { FC } from "react";
import {
  Box,
  CircularProgress,
  makeStyles,
  SwipeableDrawer,
  Theme,
  Typography,
  alpha,
  Divider,
  Paper,
  IconButton,
} from "@material-ui/core";
import moment from "moment";
import { DeleteOutline } from "@material-ui/icons";
import ContractorSearch from "lib/contractors/components/ContractorSearch";
import ContractorList from "lib/contractors/components/ContractorList";
import useDrawerAssignContractor from "../customHooks/useDrawerAssignContractor";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100vh",
    maxHeight: "100vh",
    width: "800px",
    "& *": {
      boxSizing: "border-box",
    },
    position: "relative",
  },
  spinnerContent: {
    display: "flex",
    height: "100%",
    width: "100%",
    background: alpha(theme.palette.highlighter.main, 0.6),
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 2,
  },
  spinner: {
    color: theme.palette.primary.main,
    width: "60px !important",
    height: "60px !important",
  },
  info: {
    background: theme.palette.highlighter.main,
    padding: "18px 36px",
    borderRadius: 10,
  },
  table: {
    borderSpacing: "10px",
  },
}));

const DrawerAssignContractor: FC = () => {
  const classes = useStyles();

  const {
    currentTask,
    contractors,
    loading,
    handleClose,
    handleChangeSearch,
    assignContractor,
    removeContract,
  } = useDrawerAssignContractor();
  return (
    <SwipeableDrawer
      anchor="right"
      open={!!currentTask}
      onClose={handleClose}
      // eslint-disable-next-line no-console
      onOpen={() => {
        //  open
      }}
    >
      <Box className={classes.root} display="flex" flexDirection="column">
        {loading ? (
          <Box className={classes.spinnerContent}>
            <CircularProgress className={classes.spinner} />
          </Box>
        ) : null}
        <Box p={2}>
          <Typography variant="h1">Lista de contratistas</Typography>
        </Box>
        <Box display="flex" flex="1 1 auto">
          <Box display="flex" flexDirection="column">
            <Box p={2}>
              <ContractorSearch handleChangeSearch={handleChangeSearch} />
            </Box>

            <ContractorList
              contractors={contractors}
              onClick={(contractor) => {
                assignContractor(contractor);
              }}
            />
          </Box>
          <Box p={2} width="60%">
            <Box className={classes.info} mb={2}>
              <b>{currentTask?.name}</b>
              <table>
                <tbody>
                  <tr>
                    <td>Fecha Inicio:</td>
                    <td>
                      {moment(currentTask?.startDate).format("DD/MM/YYYY")}
                    </td>
                  </tr>
                  <tr>
                    <td>Fecha Fin:</td>
                    <td>{moment(currentTask?.endDate).format("DD/MM/YYYY")}</td>
                  </tr>
                </tbody>
              </table>
            </Box>
            <Box p={2}>
              <span>
                <b>Contratistas asignados</b>
              </span>
            </Box>
            <Divider />
            <Box>
              {currentTask?.contracts?.map((contract) => (
                <Box key={contract.id} p={1}>
                  <Paper>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box p={2}>
                        <Box>{contract.contractor.name}</Box>
                        <Box>
                          {contract.contractor.documentNumber ||
                            "No hay número de documento"}
                        </Box>
                      </Box>
                      <Box>
                        <IconButton onClick={() => removeContract(contract)}>
                          <DeleteOutline color="secondary" />
                        </IconButton>
                      </Box>
                    </Box>
                  </Paper>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

export default DrawerAssignContractor;
