import { useEffect, useState } from "react";
import usePermision, {
  AccessPermission,
} from "shared/customHooks/usePermision";

export interface HavePermissionProps {
  resourceName: string;
  action: AccessPermission;
  onReject?: () => void;
}

const useHavePermission = ({
  resourceName,
  action,
  onReject,
}: HavePermissionProps) => {
  const { haveAccess } = usePermision();
  const [valid, setValid] = useState(false);

  useEffect(() => {
    const isValid = haveAccess(resourceName, action);
    if (!isValid && onReject) onReject();
    setValid(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return valid;
};

export default useHavePermission;
