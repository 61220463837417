import {
  EvaluateContractorsFilterValues,
  IEvaluationContractorFilter,
  IEvaluationFilters,
  IEvaluationProjectFilter,
  IEvaluationTowerFilter,
} from "lib/contracts/models/Evaluation";
import { ContractService } from "lib/contracts/services/ContractService";
import { useCallback, useEffect, useState } from "react";

interface EvaluateContractorsFilterParams {
  onFilter: (filters: EvaluateContractorsFilterValues) => void;
}

const useEvaluateContractorsFilter = ({
  onFilter,
}: EvaluateContractorsFilterParams) => {
  const [originalFilters, setOriginalFilters] = useState<IEvaluationFilters>({
    contractors: [],
    projects: [],
    towers: [],
  });

  const [filters, setFilters] = useState<IEvaluationFilters>({
    contractors: [],
    projects: [],
    towers: [],
  });

  const [contractorFilter, setContractorFilter] =
    useState<IEvaluationContractorFilter>();
  const [projectFilter, setProjectFilter] =
    useState<IEvaluationProjectFilter>();
  const [towerFilter, setTowerFilter] = useState<IEvaluationTowerFilter>();
  const [filtersActives, setFiltersActives] =
    useState<EvaluateContractorsFilterValues>({
      contractorId: undefined,
      projectId: undefined,
      towerId: undefined,
    });

  const fetch = useCallback(async () => {
    try {
      const service = new ContractService();
      const { data } = await service.getFilterForEvalHistory();
      setOriginalFilters(data?.filters);
    } catch (error) {
      if (error instanceof Error) {
        if (error.message.includes("invalid input synta")) {
          alert({ message: "No existe este proyecto", severity: "warning" });
        } else alert(error.message);
      }
    }
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    setFilters(originalFilters);
  }, [originalFilters]);

  const selectContractor = (value?: IEvaluationContractorFilter) => {
    setFiltersActives({
      ...filtersActives,
      contractorId: value?.id,
    });
    setContractorFilter(value);
  };

  const selectProject = (value?: IEvaluationProjectFilter) => {
    setFiltersActives({
      ...filtersActives,
      projectId: value?.id,
    });
    setProjectFilter(value);
  };

  const selectTower = (value?: IEvaluationTowerFilter) => {
    setFiltersActives({
      ...filtersActives,
      towerId: value?.id,
    });
    setTowerFilter(value);
  };

  useEffect(() => {
    if (filtersActives.projectId) {
      const project = originalFilters.projects.find(
        (element) => element.id === filtersActives.projectId
      );
      setFilters({
        ...filters,
        contractors: project?.contractors || [],
        towers: project?.towers || [],
      });
    } else if (filtersActives.towerId) {
      const tower = originalFilters.towers.find(
        (element) => element.id === filtersActives.towerId
      );
      setFilters({
        ...filters,
        contractors: tower?.contractors || [],
        projects: tower?.project ? [tower.project] : [],
      });
    } else if (filtersActives.contractorId) {
      const contractor = originalFilters.contractors.find(
        (element) => element.id === filtersActives.contractorId
      );
      setFilters({
        ...filters,
        projects: contractor?.projects || [],
        towers: contractor?.towers || [],
      });
    } else {
      setFilters(originalFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersActives]);

  const onClick = () => onFilter(filtersActives);

  return {
    filters,
    projectFilter,
    towerFilter,
    contractorFilter,
    selectProject,
    selectTower,
    selectContractor,
    onClick,
  };
};

export default useEvaluateContractorsFilter;
