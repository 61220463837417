import React, { FC } from "react";
import { Contractor } from "lib/contractors/models/Contractor";
import { Box } from "@material-ui/core";
import {
  Classes,
  Drawer,
  DrawerSize,
  Spinner,
  SpinnerSize,
} from "@blueprintjs/core";
import ContractorSearch from "lib/contractors/components/ContractorSearch";
import ContractorList from "lib/contractors/components/ContractorList";
import useContractorList from "lib/contractors/customHooks/useContractorList";
import { Contract } from "lib/contracts/models/Contract";
import useAssignContractorToActivity from "./hooks/useAssignContractorToActivity";
import { TaskRow } from "../ActivityContractorList/models/ActivityContractorListRow";

interface Props {
  activity: TaskRow;
  contractors: Contractor[];
  handleAssignContractor: (contract?: Contract) => void;
}

const AssignContractorToActivity: FC<Props> = ({
  activity,
  contractors,
  handleAssignContractor,
}) => {
  const {
    isOpen,
    onClose: close,
    loading,
    assignContractorToActivity,
  } = useAssignContractorToActivity({
    activity,
    handleAssignContractor,
  });

  const { handleChangeSearch, contractors: list } =
    useContractorList(contractors);
  return (
    <>
      <Drawer
        isOpen={isOpen}
        onClose={close}
        position="right"
        size={DrawerSize.SMALL}
        title="Contratistas"
      >
        <Box padding={1}>
          <ContractorSearch handleChangeSearch={handleChangeSearch} />
        </Box>
        {loading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{
              position: "absolute",
              background: "rgba(0,0,0,.3)",
              height: "100%",
              width: "100%",
            }}
          >
            <Spinner size={SpinnerSize.LARGE} />
          </Box>
        ) : null}
        <Box className={Classes.DRAWER_BODY}>
          <ContractorList
            contractors={list}
            onClick={(contractor) => {
              if (activity?.contractor?.id !== contractor.id)
                assignContractorToActivity(contractor);
            }}
            isSelect={(contractor) =>
              activity?.contractor?.id === contractor.id
            }
          />
        </Box>
        <Box className={Classes.DRAWER_FOOTER} />
      </Drawer>
    </>
  );
};

export default AssignContractorToActivity;
