import { ChangeEvent, useState } from "react";

interface Props {
  onClose: () => void;
  uploadTasks: (file: File) => Promise<void>;
}
const useUploadActivitiesForm = ({ uploadTasks, onClose }: Props) => {
  const [file, setFile] = useState<File>();
  const [initialWeek, setInitialWeek] = useState(1);

  const handleUploadFile = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (e.target.files?.length) {
      const eventFile = e.target.files[0];
      const types = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ];
      if (eventFile.size > 16000000) {
        alert("El tamaño del archivo es superior a 16MB");
      } else if (types.includes(eventFile.type)) {
        setFile(eventFile);
      } else {
        alert("El tipo de archivo no es valido");
      }
    }
  };

  const onSubmit = async () => {
    if (file?.name) {
      await uploadTasks(file);
      onClose();
    }
  };

  const handleNumericInputChange = (valueAsNumber: number) => {
    if (/^[0-9]*$/.test(`${valueAsNumber}`))
      setInitialWeek(valueAsNumber <= 0 ? 1 : valueAsNumber);
  };

  return {
    file,
    initialWeek,
    onSubmit,
    handleUploadFile,
    handleNumericInputChange,
  };
};

export default useUploadActivitiesForm;
