import React, { FC } from "react";
import { Box, Chip, Paper } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Grid,
  Table,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";
import { IntegratedSorting, SortingState } from "@devexpress/dx-react-grid";
import { NonIdealState } from "@blueprintjs/core";
import BorderLinearProgress from "shared/components/BorderLinearProgress/BorderLinearProgress";
import Tag from "shared/components/Tag/Tag";
import { ContractorEvalHistory } from "lib/contracts/models/TaskEvaluation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& thead": {
        background: theme.palette.detail.light,
        "& th": {
          paddingTop: 4,
          paddingBottom: 4,
        },
      },
      "& tbody tr": {
        cursor: "pointer",
        "&:hover": {
          background: theme.palette.highlighter.main,
        },
      },
    },
  })
);

const HighlightedCell: FC<Table.DataCellProps> = (props) => {
  const { row } = props;
  const { completed, total } = row;
  const percentage = total !== 0 ? (completed * 100) / total : total;
  return (
    <Table.Cell {...props} value={percentage}>
      {percentage.toFixed(0)} % Calificado
      <BorderLinearProgress variant="determinate" value={percentage} />
    </Table.Cell>
  );
};

const QualificationCell: FC<Table.DataCellProps> = ({ value, ...props }) => {
  return (
    <Table.Cell {...props} value={value}>
      <Chip label={value?.toFixed(1) || 0} />
    </Table.Cell>
  );
};

const ComplianceCell: FC<Table.DataCellProps> = ({ value, ...props }) => {
  return (
    <Table.Cell {...props} value={value}>
      {value.toFixed(2)}%
    </Table.Cell>
  );
};

const Cell: FC<Table.DataCellProps> = (props) => {
  const { column, value } = props;

  if (column.name === "pacEvaluate") return <HighlightedCell {...props} />;

  if (column.name === "qualification") return <QualificationCell {...props} />;

  if (column.name === "lastQualification")
    return <QualificationCell {...props} />;

  if (column.name === "compliance") return <ComplianceCell {...props} />;

  if (column.name === "evaluation")
    return (
      <Table.Cell {...props}>
        <div>
          <Tag text={value} />
        </div>
      </Table.Cell>
    );

  return <Table.Cell {...props} />;
};

const EvaluateContractorsTable: FC<{
  rows: ContractorEvalHistory[];
  cols: {
    name: string;
    title: string;
  }[];
  selectContractor: (data: ContractorEvalHistory | undefined) => void;
}> = ({ rows, cols, selectContractor }) => {
  const classes = useStyles();
  return (
    <Paper elevation={0}>
      <Box className={classes.root}>
        {rows.length ? (
          <Grid rows={rows} columns={cols}>
            <SortingState
              columnExtensions={[
                { columnName: "pacEvaluate", sortingEnabled: false },
              ]}
            />
            <IntegratedSorting />
            <Table
              cellComponent={Cell}
              columnExtensions={[
                {
                  columnName: "qualifications",
                  width: "10%",
                },
              ]}
              rowComponent={(props) => (
                <Table.Row
                  onClick={() => selectContractor(props.row)}
                  {...props}
                />
              )}
            />
            <TableHeaderRow showSortingControls />
          </Grid>
        ) : (
          <Box p={4}>
            <NonIdealState
              icon="clipboard"
              title="Sin evaluaciones programas"
              description="Aún no se han programado evaluaciones para esta semana"
            />
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default EvaluateContractorsTable;
