import useBoolean from "shared/customHooks/useBoolean";
import useLoading from "shared/customHooks/useLoading";
import { SuccessAction } from "shared/utils/Alert/SuccessAction";
import { RemoveActivityService } from "../services/RemoveActivityService";

export interface UseRemoveActivityProps {
  handleRemoveActivity: (id?: string) => void;
  activityId: string;
}

const useRemoveActivity = ({
  handleRemoveActivity,
  activityId,
}: UseRemoveActivityProps) => {
  const { value: isOpen, setFalse } = useBoolean(true);
  const { loading: isLoading, load, stop } = useLoading();

  const handleConfirm = async () => {
    load();
    try {
      const service = new RemoveActivityService();
      await service.remove(activityId);
      SuccessAction();
      setFalse();
      setTimeout(() => handleRemoveActivity(activityId), 100);
    } catch (error) {
      setFalse();
      setTimeout(handleRemoveActivity, 100);
      alert(error);
    }
    stop();
  };

  const handleCancel = () => {
    setFalse();
    setTimeout(handleRemoveActivity, 100);
  };

  return { isOpen, isLoading, handleConfirm, handleCancel };
};

export default useRemoveActivity;
