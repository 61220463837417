import React, { FC } from "react";
import { Box, makeStyles, Theme } from "@material-ui/core";
import { TaskWithEvaluations } from "lib/contracts/models/Task";
import { Card } from "@blueprintjs/core";
import NumberFormatString from "components/NumberFormatInputMaterialUI/NumberFormatString";
import useListTaskEvaluationsItem from "./hooks/useListTaskEvaluationsItem";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  element: {
    color: theme.palette.detail.main,
  },
}));

const ListTaskEvaluationsItem: FC<{
  week?: number;
  task: TaskWithEvaluations;
}> = ({ week, task }) => {
  const classes = useStyles();
  const { evaluation } = useListTaskEvaluationsItem({
    week,
    task,
  });
  return (
    <Box className={classes.root}>
      <Box marginY={1}>
        <Card>
          <Box className={classes.element}>
            <i>{task.name}</i>
          </Box>
          <Box className={classes.element}>
            <i>Unidad: {task.unit.name}</i>
          </Box>
          <br />
          <Box>
            Cantidad programada:{" "}
            <b>
              <NumberFormatString value={evaluation?.programValue} />
            </b>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default ListTaskEvaluationsItem;
