import useContractByContractorAndProject from "lib/contracts/hooks/useContractByContractorAndProject";
import { Contract } from "lib/contracts/models/Contract";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { RootState } from "store";

const useListTowersPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { projectId } = useParams<{ projectId: string }>();
  const contractor = useSelector((state: RootState) => state.auth.contractor);
  const { contracts } = useContractByContractorAndProject({
    contractorId: contractor?.id,
    projectId,
  });

  const selectContract = (contract: Contract) => {
    history.push(`${pathname}/contract/${contract.id}`);
  };

  return {
    contractor,
    projectId,
    contracts,
    selectContract,
  };
};

export default useListTowersPage;
