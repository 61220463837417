import { useHistory, useParams, useRouteMatch } from "react-router";
import { useCallback, useEffect, useState } from "react";
import { ActivityService } from "lib/projects/services/ActivityService";
import { Activity } from "lib/projects/models/Activity";
import { Contractor } from "lib/contractors/models/Contractor";
import { Tower } from "lib/projects/models/Tower";
import { Project } from "lib/projects/models/Project";
import useContractors from "lib/contractors/customHooks/useContractors";
import { ProjectService } from "lib/projects/services/ProjectService";
import { TowerService } from "lib/projects/services/TowerService";

export interface UseProgramAssignmentParams {
  a: string;
}

export interface UseProgramAssignmentResult {
  project?: Project;
  projectId: string;
  tasks: Activity[];
  tower?: Tower;
  back: () => void;
  currentTask?: Activity;
  selectCurrentTask: (activity?: Activity) => void;
  contractors: Contractor[];
  changeCurrentTasks: (activity: Activity) => void;
}

type HookType = (
  param?: UseProgramAssignmentParams
) => UseProgramAssignmentResult;

const useProgramAssignment: HookType = () => {
  const history = useHistory();
  const { projectId, towerId } =
    useParams<{ projectId: string; towerId: string }>();
  const { path } = useRouteMatch();
  const [project, setProject] = useState<Project>();
  const [tower, setTower] = useState<Tower>();
  const [tasks, setTasks] = useState<Activity[]>([]);
  const [currentTask, setCurrentTask] = useState<Activity>();
  const [contractors] = useContractors();

  const back = useCallback(() => {
    history.push(
      path
        .replace("/assignment/:towerId", "")
        .replace("/:projectId", `/${projectId}`)
    );
  }, [history, path, projectId]);

  const fetch = useCallback(async () => {
    const service = new ProjectService();
    const { data } = await service.getById(projectId);
    if (!data.project) back();
    else {
      setProject(data.project);
      const towerService = new TowerService();
      const { data: dataTower } = await towerService.find(towerId);
      const taskService = new ActivityService();
      const { data: dataTasks } = await taskService.all(towerId);
      setTasks(dataTasks.tasks);
      setTower(dataTower.tower);
    }
  }, [back, projectId, towerId]);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch]);

  const selectCurrentTask = (activity?: Activity): void => {
    setCurrentTask(activity);
  };

  const changeCurrentTasks = (activity: Activity): void => {
    setTasks(tasks.map((el) => (el.id === activity.id ? activity : el)));
  };

  return {
    projectId,
    project,
    tasks,
    tower,
    back,
    currentTask,
    selectCurrentTask,
    contractors,
    changeCurrentTasks,
  };
};

export default useProgramAssignment;
