import moment, { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { DateUtils } from "shared/helpers/DateHelper";

import { Contract } from "lib/contracts/models/Contract";
import { Project } from "lib/projects/models/Project";
import { Tower } from "lib/projects/models/Tower";
import { ProjectService } from "lib/projects/services/ProjectService";
import { TowerService } from "lib/projects/services/TowerService";

export interface UseSelectContractorParams {
  a: string;
}

export interface UseSelectContractorResult {
  project?: Project;
  tower?: Tower;
  currentContract?: Contract;
  currentWeek: Moment[];
  currentWeekNumber: number;
  selectContract: (contract?: Contract) => void;
  back: () => void;
}

type HookType = (
  param?: UseSelectContractorParams
) => UseSelectContractorResult;

const useSelectContractor: HookType = () => {
  const history = useHistory();
  const { projectId, towerId } =
    useParams<{ projectId: string; towerId: string; contractorId: string }>();
  const [project, setProject] = useState<Project>();
  const [tower, setTower] = useState<Tower>();
  const [currentContract, setCurrentContract] = useState<Contract>();
  const [currentWeekNumber, setCurrentWeekNumber] = useState(0);
  const [currentWeek, setCurrentWeek] = useState<Moment[]>([
    moment(),
    moment(),
  ]);

  const back = useCallback(() => {
    history.push(`/pac/program/${projectId}`);
  }, [history, projectId]);

  const fetch = useCallback(async () => {
    const service = new ProjectService();
    const { data } = await service.getById(projectId);
    if (!data.project) back();
    else {
      setProject(data.project);
      const towerService = new TowerService();
      const { data: dataTower } = await towerService.find(towerId);
      setTower(dataTower.tower);
      const { tower: currentTower } = dataTower;
      const weeks = DateUtils.getWeekBetweenTowDates(
        currentTower?.closingDay || 1,
        typeof currentTower?.startDate === "string"
          ? new Date(currentTower.startDate)
          : new Date(),
        typeof currentTower?.endDate === "string"
          ? new Date(currentTower.endDate)
          : new Date()
      );
      const week =
        currentTower?.currentWeek && currentTower?.currentWeek < 0
          ? 0
          : currentTower?.currentWeek;
      setCurrentWeekNumber(week || 0);
      setCurrentWeek(weeks[week || 0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [back, projectId, towerId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const selectContract = (contract?: Contract): void => {
    setCurrentContract(contract);
  };

  return {
    project,
    tower,
    currentContract,
    currentWeek,
    currentWeekNumber,
    selectContract,
    back,
  };
};

export default useSelectContractor;
