import React, { FC } from "react";
import { Box } from "@material-ui/core";
import { QueryClient, QueryClientProvider } from "react-query";
import ForgotPasswordForm from "./forms/ForgotPasswordForm";

const client = new QueryClient();

const ForgotPasswordMainView: FC = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <QueryClientProvider client={client}>
        <ForgotPasswordForm />
      </QueryClientProvider>
    </Box>
  );
};

export default ForgotPasswordMainView;
