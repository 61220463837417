import { AxiosResponse } from "axios";
import { Contractor } from "lib/contractors/models/Contractor";
import { Tower } from "lib/projects/models/Tower";
import { HttpService } from "shared/services/HttpService";
import { Contract } from "../models/Contract";
import { ContractEval } from "../models/ContractEval";
import { ContractQualification } from "../models/ContractQualification";
import {
  EvaluateContractorsFilterValues,
  Evaluation,
  IEvaluationFilters,
} from "../models/Evaluation";
import { ProgrammingConsolidatedResponse } from "../models/ProgrammingConsolidatedResponse";
import { RowEvaluation } from "../models/RowEvaluation";
import { Task } from "../models/Task";
import { ContractorEvalHistory } from "../models/TaskEvaluation";
import { TaskEvaluationsDTO } from "../models/TaskEvaluationsDTO";

export class ContractService extends HttpService {
  constructor() {
    super("/contracts");
  }

  getContractsByContractorAndTower = (
    contractorId: string,
    towerId: string
  ): Promise<
    AxiosResponse<{
      contractor: Contractor;
      contracts: ContractEval[];
      tower: Tower;
    }>
  > =>
    this.httpClient.get(
      `/contractor/${contractorId}/tower/${towerId}/with-tasks`
    );

  getEvalHistory = (
    params?: EvaluateContractorsFilterValues
  ): Promise<
    AxiosResponse<{ contracts: ContractorEvalHistory[]; contractors: number[] }>
  > => this.httpClient.get("/all", { params });

  getFilterForEvalHistory = (): Promise<
    AxiosResponse<{ filters: IEvaluationFilters }>
  > => this.httpClient.get("/all/filters");

  listContractsByTowerIdAndContractorId = (
    towerId: string,
    contractorId: string
  ): Promise<AxiosResponse<{ contracts: Contract[] }>> =>
    this.httpClient.get(`/contractor/${contractorId}/tower/${towerId}/list`);

  getContractByTowerId = (
    towerId: string
  ): Promise<AxiosResponse<{ contracts: Contractor[] }>> =>
    this.httpClient.get(`/tower/${towerId}`);

  assignActivity = (
    contractorId: string,
    taskId: string
  ): Promise<AxiosResponse<{ contract: Contract }>> =>
    this.httpClient.post(`/${contractorId}/activity/${taskId}/assign`);

  removeActivity = (contractId: string): Promise<AxiosResponse<void>> =>
    this.httpClient.delete(`/${contractId}`);

  getAllTaksAndEvaluations = (
    contractId: string
  ): Promise<
    AxiosResponse<{
      totalTowerWeeks: number;
      currentTowerWeek: number;
      requireProgramation: boolean;
      tasksEvaluations: TaskEvaluationsDTO[];
      evaluations: Evaluation[];
    }>
  > => this.httpClient.get(`/${contractId}/tasks`);

  contratorCreateSubtask = (
    contractId: string,
    partial: Partial<Task>
  ): Promise<AxiosResponse<{ activity: Task }>> =>
    this.httpClient.post(`/${contractId}/task`, partial);

  savePercentageTasks = (
    contractId: string,
    partial: Partial<{ [key: string]: unknown }[]>
  ): Promise<AxiosResponse<{ activity: Task }>> =>
    this.httpClient.patch(`/${contractId}/task`, partial);

  removeEvaluationOfWeek = (
    contractId: string,
    taskWeek: number
  ): Promise<
    AxiosResponse<{
      success: boolean;
    }>
  > => this.httpClient.delete(`/${contractId}/week/${taskWeek}`);

  sedEvaluationOfWeek = (
    contractId: string,
    taskWeek: number
  ): Promise<
    AxiosResponse<{
      success: boolean;
    }>
  > => this.httpClient.patch(`/${contractId}/week/${taskWeek}`);

  updateTaskEvaluation = (
    taskEvaluationId: string,
    partial: Partial<RowEvaluation>
  ): Promise<
    AxiosResponse<{
      contractor: Contractor;
      contracts: ContractEval[];
      tower: Tower;
    }>
  > => this.httpClient.put(`/taskevaluation/${taskEvaluationId}`, partial);

  contractWithQuialificationsHistory = (
    contractId: string
  ): Promise<
    AxiosResponse<{
      contract: Contract;
      historic: ContractQualification[];
    }>
  > => this.httpClient.get(`/${contractId}/historic-quialifications`);

  removeContractQuialification = (
    contractId: string,
    taskWeek: number
  ): Promise<
    AxiosResponse<{
      success: boolean;
    }>
  > =>
    this.httpClient.delete(`/${contractId}/quialifications/week/${taskWeek}`);

  getContractsByApproval = (
    contractorId: string,
    projectId: string
  ): Promise<
    AxiosResponse<{
      contracts: Contract[];
    }>
  > =>
    this.httpClient.get(
      `/contractor/${contractorId}/project/${projectId}/approval`
    );

  getContractsApproval = (
    contractId: string
  ): Promise<
    AxiosResponse<{
      contract: Contract;
      rejectedWeek?: number;
      approvalWeek?: number;
      lastPassedWeek?: number;
      towerWeek?: number;
      virtualWeek?: number;
      week: {
        startDate: string;
        endDate: string;
      };
    }>
  > => this.httpClient.get(`/${contractId}/approval`);

  approveEvaluation = (
    contractId: string,
    week: number
  ): Promise<
    AxiosResponse<{
      succes: boolean;
    }>
  > => this.httpClient.post(`/${contractId}/week/${week}/approve`);

  rejectEvaluation = (
    contractId: string,
    week: number,
    comment: string
  ): Promise<
    AxiosResponse<{
      succes: boolean;
    }>
  > => this.httpClient.post(`/${contractId}/week/${week}/reject`, { comment });

  getProgrammingConsolidated = (
    towerId: string,
    week: number
  ): Promise<AxiosResponse<ProgrammingConsolidatedResponse>> =>
    this.httpClient.get(`/tower/${towerId}/week/${week}/consolidated`);
}
