import React, { createContext, FC, useContext } from "react";
import useEvalueateContractor, {
  UseEvalueateContractorResult,
} from "../customHooks/useEvalueateContractor";

type EvalueateContractorContextType = UseEvalueateContractorResult;

const EvalueateContractorContext =
  createContext<EvalueateContractorContextType | null>(null);

export const useEvalueateContractorContext =
  (): EvalueateContractorContextType =>
    useContext(EvalueateContractorContext) as EvalueateContractorContextType;

export const EvalueateContractorProvider: FC = ({ children }) => {
  const value = useEvalueateContractor({ a: "1" });

  return (
    <EvalueateContractorContext.Provider value={{ ...value }}>
      {children}
    </EvalueateContractorContext.Provider>
  );
};
