import React, { createContext, FC, useContext } from "react";
import useEvaluate, { UseEvaluateResult } from "../customHooks/useEvaluate";

type EvaluateContextType = UseEvaluateResult;

const EvaluateContext = createContext<EvaluateContextType | null>(null);

export const useEvaluateContext = (): EvaluateContextType =>
  useContext(EvaluateContext) as EvaluateContextType;

export const EvaluateProvider: FC = ({ children }) => {
  const value = useEvaluate();

  return (
    <EvaluateContext.Provider value={{ ...value }}>
      {children}
    </EvaluateContext.Provider>
  );
};
