import React, { FC, ReactNode } from "react";
import { Card } from "@blueprintjs/core";
import { Box, BoxProps } from "@material-ui/core";

interface Props<T> {
  elements: T[];
  onSelectElement: (element: T) => void;
  elementChildComponent: (element: T) => ReactNode;
  elementComponentStyles?: (element: T) => BoxProps;
}

const AppList =
  <T extends { id?: string }>(): FC<Props<T>> =>
  ({
    elements,
    onSelectElement,
    elementChildComponent,
    elementComponentStyles,
  }) => {
    return (
      <>
        {elements && elements.length ? (
          <>
            {elements.map((element, key) => (
              <Box marginY={1} key={element?.id || key}>
                <Card
                  interactive
                  onClick={() => onSelectElement(element)}
                  {...(elementComponentStyles
                    ? elementComponentStyles(element)
                    : null)}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {elementChildComponent(element)}
                  </Box>
                </Card>
              </Box>
            ))}
          </>
        ) : null}
      </>
    );
  };

export default AppList;
