import { TaskUnit } from "lib/contracts/models/TaskUnit";
import { TaskUnitService } from "lib/contracts/services/TaskUnitService";
import { FormEvent, useState } from "react";
import { IUseFormResult, useForm } from "shared/customHooks/useForm";

export interface UseUpdateSubTaskUnitFormParams {
  onSave: () => Promise<void>;
  unit: TaskUnit;
}

export interface UseUpdateSubTaskUnitFormResult
  extends IUseFormResult<TaskUnit> {
  selected: boolean;
  select: () => void;
  unSelect: () => void;
  submit: () => void;
}

type HookType = (
  param: UseUpdateSubTaskUnitFormParams
) => UseUpdateSubTaskUnitFormResult;

const useUpdateSubTaskUnitForm: HookType = ({ onSave, unit }) => {
  const [selected, setSelected] = useState(false);

  const form = useForm<TaskUnit>({
    validations: {
      name: {
        required: {
          value: true,
          message: "Debe asignar un unidad",
        },
      },
    },
    initialValues: {
      name: unit.name,
    },
    async onSubmit(formData, reset) {
      try {
        if (unit.id) {
          const service = new TaskUnitService();
          const { data } = await service.updateUnit(unit.id, formData);
          if (data.updated) {
            reset();
            await onSave();
            setSelected(false);
            alert({ message: "Actualizado", severity: "success" });
          }
        }
      } catch (error) {
        alert(error);
      }
    },
  });

  const select = (): void => setSelected(true);
  const unSelect = (): void => {
    setSelected(false);
    form.resetData();
  };

  const submit = (): void => {
    form.handleSubmit({
      preventDefault: (): void => {
        // empty
      },
    } as FormEvent<HTMLFormElement>);
  };

  return {
    ...form,
    selected,
    select,
    unSelect,
    submit,
  };
};

export default useUpdateSubTaskUnitForm;
