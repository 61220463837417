import dotenv from "dotenv";
import SERVER from "./backend/server";
import logging from "./logger/logging";

export type Environment = "staging" | "development" | "production";

const env: Environment = (process.env.NODE_ENV as Environment) || "local";
if (process.env.NODE_ENV !== "production") {
  logging.info(env);
  dotenv.config();
}

logging.info(SERVER[env]);

const Config = {
  server: SERVER[env],
};

export default Config;
