import React, { FC, useState } from "react";
import { ContractResponse } from "lib/contracts/models/ProgrammingConsolidatedResponse";
import { Flex } from "shared/components/Flex/Flex";
import { Card, Collapse, HTMLTable, Tag } from "@blueprintjs/core";
import { Box, makeStyles, Theme } from "@material-ui/core";
import { EvaluationState } from "lib/contracts/models/Evaluation";

const useStyles = makeStyles((theme: Theme) => ({
  clickElement: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: theme.palette.lightBackground.main,
    color: theme.palette.primary.main,
    userSelect: "none",
    cursor: "pointer",
    padding: "0.6em",
    paddingLeft: "1em",
    paddingRight: "1em",
  },
  tableElement: {
    border: `1px solid ${theme.palette.detail.light}`,
    "& td, & th": {
      border: `1px solid ${theme.palette.detail.light}`,
      padding: ".5em !important",
    },
  },
}));

const Color: { [key: string]: string } = {
  PENDING: "#bdc3c7",
  SCHEDULED: "#f39c12",
  SCHEDULED_SENDING: "#9b59b6",
  SCHEDULED_PASSED: "#2ecc71",
  SCHEDULED_REJECTED: "#e74c3c",
  SCHEDULED_EVALUATED: "#16a085",
};

const Name: { [key: string]: string } = {
  PENDING: "Sin programación",
  SCHEDULED: "Programado",
  SCHEDULED_SENDING: "Enviado",
  SCHEDULED_PASSED: "Aprobado",
  SCHEDULED_REJECTED: "Rechazado",
  SCHEDULED_EVALUATED: "Evaluado",
};

const ContractCollapse: FC<{ contract: ContractResponse }> = ({ contract }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => setIsOpen(!isOpen);

  return (
    <Flex>
      <Card style={{ padding: 0 }}>
        <Box
          onClick={handleClick}
          className={classes.clickElement}
          style={{
            boxShadow: `.5em 0 ${
              Color[contract.evaluationState || EvaluationState.PENDING]
            } inset`,
          }}
        >
          <Flex
            style={{
              color:
                contract.evaluationState === EvaluationState.SCHEDULED_EVALUATED
                  ? "#16a085"
                  : "initial",
            }}
          >
            {contract.activity.name}
          </Flex>
          <Flex>
            <Tag
              style={{
                background:
                  Color[contract.evaluationState || EvaluationState.PENDING],
              }}
              large
            >
              {Name[contract.evaluationState || EvaluationState.PENDING]}
            </Tag>
          </Flex>
        </Box>
      </Card>
      <Collapse isOpen={isOpen}>
        <Card style={{ padding: 0 }}>
          {contract.tasks && contract.tasks.length ? (
            <Flex>
              <HTMLTable
                width="100%"
                className={classes.tableElement}
                style={{
                  tableLayout: "fixed",
                  borderCollapse: "collapse",
                }}
              >
                <thead>
                  <tr>
                    <th>Tarea</th>
                    <th>Cantidad programada</th>
                    <th>Unidad de Medida</th>
                    <th>Incidencia</th>
                  </tr>
                </thead>
                <tbody>
                  {contract.tasks.map((task) => (
                    <tr>
                      <td>{task.name}</td>
                      <td>{task.evaluation?.programValue ?? "NA"}</td>
                      <td>{task.unit.name}</td>
                      <td>
                        {task.evaluation?.percentage ?? "NA"}
                        {task.evaluation?.percentage ? "%" : ""}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </HTMLTable>
            </Flex>
          ) : null}
        </Card>
      </Collapse>
    </Flex>
  );
};

export default ContractCollapse;
