import React, { FC } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import { LockOpenOutlined } from "@material-ui/icons";
import useForgotPassword from "modules/ForgotPassword/core/customHooks/useForgotPassword";
import styles from "./ForgotPasswordForm.module.scss";

const ForgotPasswordForm: FC = () => {
  const { data, errors, isLoading, isSuccess, handleChange, handleSubmit } =
    useForgotPassword();
  return (
    <Box className={styles.ForgotPasswordContainer}>
      {isLoading && (
        <div className={styles.Loading}>
          <CircularProgress />
        </div>
      )}
      <Avatar className={styles.Icon}>
        <LockOpenOutlined />
      </Avatar>
      <Typography variant="h1">¿Has olvidado tu contraseña?</Typography>
      <Typography variant="body1">
        Restablecer la contraseña en dos pasos rápidos
      </Typography>
      <form onSubmit={handleSubmit} id="forgotPassword" />
      <TextField
        value={data.email}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Correo Electrónico"
        onChange={handleChange("email")}
        name="email"
        autoComplete="email"
        autoFocus
        error={!!errors?.email}
        helperText={errors?.email}
      />
      {isSuccess && (
        <Box className={styles.SuccessMessage}>
          Se ha enviado un correo con las instrucciones para restablecer la
          contraseña
        </Box>
      )}
      <Box className={styles.submit}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          form="forgotPassword"
        >
          Restablecer la contraseña
        </Button>
      </Box>
      <Box p={2}>
        <Link href="/" variant="body2">
          Volver
        </Link>
      </Box>
    </Box>
  );
};

export default ForgotPasswordForm;
