import React, { FC } from "react";
import { Box } from "@material-ui/core";
import { Select } from "@blueprintjs/select";
import { Button, MenuItem } from "@blueprintjs/core";
import {
  EvaluateContractorsFilterValues,
  IEvaluationContractorFilter,
  IEvaluationProjectFilter,
  IEvaluationTowerFilter,
} from "lib/contracts/models/Evaluation";
import EvaluateContractorsFilterStyles from "./styles";
import useEvaluateContractorsFilter from "./hook/useEvaluateContractorsFilter";
import ButtonFilter from "./components/ButtonFilter";

const ContractorSelect = Select.ofType<IEvaluationContractorFilter>();
const ProjectSelect = Select.ofType<IEvaluationProjectFilter>();
const TowerSelect = Select.ofType<IEvaluationTowerFilter>();

const Element: FC<{ className?: string }> = ({ children, className }) => (
  <Box className={className} display="flex" paddingRight={1} paddingY={1}>
    {children}
  </Box>
);

const EvaluateContractorsFilter: FC<{
  onFilter: (params: EvaluateContractorsFilterValues) => void;
  omitContracts?: boolean;
}> = ({ onFilter, omitContracts }) => {
  const {
    filters,
    projectFilter,
    towerFilter,
    contractorFilter,
    selectProject,
    selectTower,
    selectContractor,
    onClick,
  } = useEvaluateContractorsFilter({ onFilter });
  const classes = EvaluateContractorsFilterStyles();

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap">
      <Element className={classes.filterLabel}>Filtrar </Element>
      <Element>
        <ProjectSelect
          items={filters.projects}
          itemRenderer={(item, { handleClick }) => {
            return (
              <MenuItem key={item.id} text={item.name} onClick={handleClick} />
            );
          }}
          onItemSelect={selectProject}
          fill
        >
          <ButtonFilter
            value={projectFilter}
            onClick={() => selectProject(undefined)}
            emptyLabel="Proyecto"
          />
        </ProjectSelect>
      </Element>
      <Element>
        <TowerSelect
          items={filters.towers}
          itemRenderer={(item, { handleClick }) => {
            return (
              <MenuItem key={item.id} text={item.name} onClick={handleClick} />
            );
          }}
          onItemSelect={selectTower}
          fill
        >
          <ButtonFilter
            value={towerFilter}
            onClick={() => selectTower(undefined)}
            emptyLabel="Etapa"
          />
        </TowerSelect>
      </Element>
      {!omitContracts ? (
        <Element>
          <ContractorSelect
            items={filters.contractors}
            itemRenderer={(item, { handleClick }) => {
              return (
                <MenuItem
                  key={item.id}
                  text={item.name}
                  onClick={handleClick}
                />
              );
            }}
            onItemSelect={(item) => selectContractor(item)}
            fill
          >
            <ButtonFilter
              value={contractorFilter}
              onClick={() => selectContractor(undefined)}
              emptyLabel="Contratista"
            />
          </ContractorSelect>
        </Element>
      ) : null}
      <Element>
        <Button large icon="search" intent="primary" onClick={onClick}>
          Buscar
        </Button>
      </Element>
    </Box>
  );
};

export default EvaluateContractorsFilter;
