import React, { FC } from "react";
// import { Box, makeStyles } from "@material-ui/core";
import { Button, Classes, Dialog } from "@blueprintjs/core";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import { useEvaluateContractorTableContext } from "../context/EvaluateContractorTableContext";
import useEvaluateContractorModal from "../customHooks/useEvaluateContractorModal";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const EvaluateContractorTableModal: FC = () => {
  const classes = useStyles();
  const { isOpenModal, taskSelected, evaluationValues } =
    useEvaluateContractorTableContext();
  const {
    handleSubmit,
    evaluation,
    data,
    errors,
    handleChangeSelect,
    reasons,
    handleChange,
    handleClose,
  } = useEvaluateContractorModal();
  return (
    <Dialog isOpen={isOpenModal} onClose={handleClose} usePortal>
      <Box className={Classes.DIALOG_HEADER}>Evaluar actividad</Box>
      <Box className={Classes.DIALOG_BODY}>
        {reasons.length ? (
          <Box minWidth="400px">
            <table style={{ borderSpacing: 10 }}>
              <tbody>
                <tr>
                  <td>Nombre actividad</td>
                  <td>
                    <Box paddingX={2}>
                      <b>{taskSelected?.name}</b>
                    </Box>
                  </td>
                </tr>
                <tr>
                  <td>Programado</td>
                  <td>
                    <Box paddingX={2}>
                      <b>
                        {taskSelected?.evaluations?.programValue}{" "}
                        {taskSelected?.unit.name}
                      </b>
                    </Box>
                  </td>
                </tr>
                <tr>
                  <td>Evaluado</td>
                  <td>
                    <Box paddingX={2}>
                      <b>
                        {evaluationValues[`${taskSelected?.id}`]
                          ?.evaluationValue || 0}{" "}
                        {taskSelected?.unit.name}
                      </b>
                    </Box>
                  </td>
                </tr>
              </tbody>
            </table>
            <form id="form-evaluate-2" onSubmit={handleSubmit} />
            {evaluation &&
            typeof evaluation?.compliance === "number" &&
            evaluation?.compliance < 100 &&
            !(
              typeof evaluation?.programValue === "number" &&
              evaluation.programValue === 0
            ) ? (
              <Box>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  fullWidth
                  required
                  error={!!errors.reasonId}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Motivo
                  </InputLabel>
                  <Select
                    inputProps={{
                      name: "age",
                      id: "outlined-age-native-simple",
                    }}
                    required
                    fullWidth
                    label="Motivo"
                    value={data.reasonId}
                    onChange={handleChangeSelect("reasonId")}
                  >
                    {reasons.map((reason) => (
                      <MenuItem key={`reason_${reason.id}`} value={reason.id}>
                        {reason.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.reasonId ? (
                    <FormHelperText>{errors.reasonId}</FormHelperText>
                  ) : null}
                </FormControl>
                <TextField
                  value={data?.comment || ""}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="comment"
                  label="Comentario"
                  name="comment"
                  multiline
                  required
                  rows={4}
                  error={!!errors?.comment}
                  helperText={errors?.comment}
                  onChange={handleChange("comment")}
                />
              </Box>
            ) : null}
          </Box>
        ) : (
          <span>
            Se debén configurar los motivos de evaluación, antes de guardar
          </span>
        )}
      </Box>
      <Box className={Classes.DIALOG_FOOTER}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box flexGrow="1">
            <Button intent="primary" onClick={handleClose} large fill>
              Cerrar
            </Button>
          </Box>
          {reasons.length ? (
            <Box flexGrow="1" paddingLeft={2}>
              <Button type="submit" form="form-evaluate-2" large fill>
                Guardar
              </Button>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Dialog>
  );
};

export default EvaluateContractorTableModal;
