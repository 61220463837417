import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserAuth } from "lib/auth/models/UserAuth";
import { Contractor } from "lib/contractors/models/Contractor";
import { Permissions } from "lib/users/models/Permission";

export interface AuthenticationState {
  user: UserAuth | null;
  userInitialized: boolean;
  contractor: Contractor | null;
  minSide: boolean;
  loading: boolean;
  authError: string;
  authLoading: boolean;
  initialized: boolean;
  initializedPermissions: boolean;
  permissions: Permissions | null;
}

const initialState: AuthenticationState = {
  user: null,
  userInitialized: false,
  permissions: null,
  contractor: null,
  authError: "",
  loading: false,
  minSide: false,
  authLoading: false,
  initialized: false,
  initializedPermissions: false,
};

const AuthenticationSlice = createSlice({
  name: "@Authentication",
  initialState,
  reducers: {
    setUserAuth: (state, action: PayloadAction<UserAuth | null>) => {
      state.user = action.payload;
      state.userInitialized = true;
    },
    setAuthError: (state, action: PayloadAction<string>) => {
      state.authError = action.payload;
    },
    setAuthPermissions(state, action: PayloadAction<Permissions>) {
      state.permissions = action.payload;
    },
    initializePermissions(state) {
      state.initializedPermissions = true;
    },
    initializeAuth(state) {
      state.initialized = true;
    },
    authDismouunt: (state) => {
      state.user = initialState.user;
      state.userInitialized = initialState.userInitialized;
      state.permissions = initialState.permissions;
      state.contractor = initialState.contractor;
      state.authError = initialState.authError;
      state.loading = initialState.loading;
      state.minSide = initialState.minSide;
      state.authLoading = initialState.authLoading;
      state.initializedPermissions = initialState.initializedPermissions;
    },
    setAuthMinSide: (state, action: PayloadAction<boolean>) => {
      state.minSide = action.payload;
    },
    setAuthContractor: (state, action: PayloadAction<Contractor | null>) => {
      state.contractor = action.payload;
    },
  },
});

export const {
  setUserAuth,
  setAuthError,
  initializeAuth,
  initializePermissions,
  setAuthPermissions,
  authDismouunt,
  setAuthMinSide,
  setAuthContractor,
} = AuthenticationSlice.actions;
export const AuthenticationReducer = AuthenticationSlice.reducer;
