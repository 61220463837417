import React, { FC, useEffect, useState } from "react";
import { Button, ButtonGroup, H5, MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    // maxHeight: "500px",
    // overflow: "auto",
    '& [class*="-menu"]': {
      maxHeight: "300px",
      overflowY: "auto",
    },
  },
});

const FilterSelect = Select.ofType<number>();

const Filter: FC<{
  week: number;
  onSubmit: (week: number) => void;
  programmingWeek: number;
  virtualWeek: number;
}> = ({ week, programmingWeek, virtualWeek, onSubmit }) => {
  const classes = useStyles();
  const [weeks, setWeeks] = useState<number[]>([]);
  const [weekSelected, setWeekSelected] = useState(0);

  useEffect(() => {
    setWeeks(new Array(programmingWeek).fill(0).map((el, index) => index + 1));
  }, [programmingWeek]);

  useEffect(() => {
    setWeekSelected(week);
  }, [week]);

  const selectTower = async (weekSelect: number) => {
    await onSubmit(weekSelect);
    setWeekSelected(weekSelect);
  };

  return (
    <Box
      m={2}
      display="flex"
      alignItems="center"
      style={{ position: "relative" }}
    >
      <Box p={1}>
        <H5 style={{ margin: 0 }}>Filtrar:</H5>
      </Box>
      <ButtonGroup>
        <FilterSelect
          items={weeks}
          itemRenderer={(item, { handleClick }) => (
            <MenuItem
              key={item}
              text={`Semana ${item + virtualWeek}`}
              onClick={handleClick}
            />
          )}
          filterable={false}
          onItemSelect={selectTower}
          popoverProps={{
            className: classes.root,
            minimal: true,
            usePortal: false,
          }}
        >
          <Button icon="calendar">Semana {weekSelected + virtualWeek}</Button>
        </FilterSelect>
      </ButtonGroup>
    </Box>
  );
};
export default Filter;
