import React, { Dispatch, FC } from "react";
import { Box } from "@material-ui/core";
import { TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import { Column } from "@devexpress/dx-react-grid";
import {
  // Classes,
  Popover2,
} from "@blueprintjs/popover2";
import {
  Button,
  // Card,
  // H5,
  Menu,
  MenuDivider,
  MenuItem,
} from "@blueprintjs/core";
import { Evaluation } from "lib/contracts/models/Evaluation";
import ProgramContractTableCellHeaderStyles from "./styles";
import {
  ScheduleAction,
  ScheduleActions,
} from "../../customHooks/useTableCellHeaderActions";

type ColumnHeader = Evaluation & Column;
type Props = TableHeaderRow.CellProps & {
  actionsDispatch: Dispatch<ScheduleAction>;
  column: Column & { week?: number };
};

const ProgramContractTableCellHeader: FC<Props> = (props) => {
  const classes = ProgramContractTableCellHeaderStyles();
  const { column, actionsDispatch } = props;
  const {
    isProgramation,
    isEvaluation,
    isFuture,
    state,
    comment,
    requireSend,
  } = column as ColumnHeader;

  if (isEvaluation)
    return <TableHeaderRow.Cell {...props} className={classes.pass} />;

  if (isProgramation) {
    return (
      <TableHeaderRow.Cell {...props} className={classes.eval}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <span>{column.title}</span>
          <Popover2
            usePortal
            position="right"
            autoFocus
            content={
              <Menu key="menu">
                {requireSend ? (
                  <MenuItem
                    icon="send-to-graph"
                    text="Enviar programación"
                    onClick={() =>
                      actionsDispatch({
                        type: ScheduleActions.SEND_SCHEDULE,
                        payload: {
                          week: column?.week || 0,
                        },
                      })
                    }
                  />
                ) : null}
                <MenuItem
                  icon="edit"
                  text="Editar"
                  onClick={() =>
                    actionsDispatch({
                      type: ScheduleActions.REMOVE_SCHEDULE,
                      payload: {
                        week: column?.week || 0,
                      },
                    })
                  }
                />
                {/* <MenuDivider />
                <MenuItem icon="trash" text="Borrar" intent="danger" /> */}
              </Menu>
            }
          >
            <Button icon="menu-open" intent="none" small minimal />
          </Popover2>
        </Box>
      </TableHeaderRow.Cell>
    );
  }
  if (state === "SCHEDULED_SENDING")
    return <TableHeaderRow.Cell {...props} className={classes.send} />;

  if (state === "SCHEDULED_PASSED")
    return <TableHeaderRow.Cell {...props} className={classes.passed} />;

  if (state === "SCHEDULED_REJECTED") {
    return (
      <TableHeaderRow.Cell {...props} className={classes.reject}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <span>{column.title}</span>
          <Popover2
            usePortal
            position="right"
            autoFocus
            content={
              <Menu key="menu">
                <MenuItem text="Programación Rechazada" disabled />
                <MenuDivider />
                <MenuItem
                  icon="label"
                  text="Ver comentario"
                  onClick={() =>
                    actionsDispatch({
                      type: ScheduleActions.VIEW_COMMENT,
                      payload: {
                        week: column?.week || 0,
                        comment,
                      },
                    })
                  }
                />
                <MenuItem
                  icon="send-to-graph"
                  text="Enviar programación"
                  onClick={() =>
                    actionsDispatch({
                      type: ScheduleActions.SEND_SCHEDULE,
                      payload: {
                        week: column?.week || 0,
                      },
                    })
                  }
                />
                <MenuItem
                  icon="edit"
                  text="Editar"
                  onClick={() =>
                    actionsDispatch({
                      type: ScheduleActions.REMOVE_SCHEDULE,
                      payload: {
                        week: column?.week || 0,
                      },
                    })
                  }
                />
              </Menu>
            }
          >
            <Button icon="menu-open" intent="none" small minimal />
          </Popover2>
        </Box>
      </TableHeaderRow.Cell>
    );
  }
  if (
    typeof isFuture === "boolean" &&
    typeof isProgramation === "boolean" &&
    !isFuture &&
    !isProgramation
  )
    return <TableHeaderRow.Cell {...props} className={classes.current} />;

  if (isFuture)
    return <TableHeaderRow.Cell {...props} className={classes.next} />;

  return <TableHeaderRow.Cell {...props} />;
};

export default ProgramContractTableCellHeader;
