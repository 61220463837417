import { alpha, makeStyles, Theme } from "@material-ui/core";

const useProgramCellStyles = makeStyles((theme: Theme) => ({
  root: {
    borderBottom: "1px solid",
  },
  hight: {
    boxShadow: `4px 0 ${alpha(theme.palette.secondaryDark.main, 0.3)} inset`,
    padding: 0,
    paddingLeft: "8px",
  },
}));

export default useProgramCellStyles;
