import React, { FC } from "react";
import { Card, Classes, Blockquote, Dialog, H4 } from "@blueprintjs/core";
import { Box, makeStyles, Theme } from "@material-ui/core";
import { ContractResponse } from "lib/contracts/models/ConsolidatedEvaluationResponse";
import useContractorContractDialog from "../../hooks/useContractorContractDialog";

interface Props {
  contract: ContractResponse;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    "& tr:nth-child(even)": {
      background: theme.palette.detail.light,
    },
    "&, td": {
      borderBottom: `1px solid ${theme.palette.detail.light}`,
      //   borderCollapse: "collapse",
    },
    "& td": {
      width: "50%",
      padding: 4,
    },
  },
}));

const ContractorContractDialog: FC<Props> = ({
  contract,
  onClose: onParentClose,
}) => {
  const classes = useStyles();
  const { isOpen, close } = useContractorContractDialog({
    contract,
    onParentClose,
  });
  return (
    <Dialog title={contract.activity.name} isOpen={isOpen} onClose={close}>
      <Box className={Classes.DIALOG_BODY} style={{ padding: 0, margin: 0 }}>
        <Box>
          {contract.tasks && contract.tasks.length
            ? contract.tasks.map((task) => (
                <Box key={task.id}>
                  <Card>
                    {/* <pre>{JSON.stringify(task, null, 2)}</pre> */}
                    <Box>
                      <H4>{task.name}</H4>
                    </Box>
                    <table className={classes.root}>
                      <tbody>
                        <tr>
                          <td>Cantidad programada</td>
                          <td>{task.evaluation?.programValue}</td>
                        </tr>
                        <tr>
                          <td>Cantidad ejecutada</td>
                          <td>{task.evaluation?.evaluationValue}</td>
                        </tr>
                        <tr>
                          <td>Unidad</td>
                          <td>{task.unit.name}</td>
                        </tr>
                        <tr>
                          <td>Incidencia</td>
                          <td>{task.evaluation?.percentage}%</td>
                        </tr>
                        {task.evaluation?.comment ? (
                          <>
                            <tr>
                              <td>Motivo</td>
                              <td>{task.evaluation?.reason?.name}</td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                <Card>
                                  <Blockquote style={{ marginTop: 1 }}>
                                    {task.evaluation?.comment}
                                  </Blockquote>
                                </Card>
                              </td>
                            </tr>
                          </>
                        ) : null}
                      </tbody>
                    </table>
                  </Card>
                </Box>
              ))
            : null}
        </Box>
      </Box>
    </Dialog>
  );
};

export default ContractorContractDialog;
