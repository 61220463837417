import React, { FC } from "react";
import { Box, lighten, makeStyles, Theme } from "@material-ui/core";
import { Button, Card, Classes, Drawer, H5 } from "@blueprintjs/core";
import moment from "moment";
import { Popover2, Classes as PoperClasses } from "@blueprintjs/popover2";
import useEvaluateContractorHistoricDrawer from "../customHooks/useEvaluateContractorHistoricDrawer";

const useStyles = makeStyles((theme: Theme) => {
  const color = lighten(theme.palette.detail.main, 0.5);
  return {
    root: {},
    hight: {
      color: theme.palette.highlighter.dark,
    },
    contentItems: {
      '& [class*="element"]:first-child > [class*="time"]:first-child': {
        height: "calc(100% - 5px)",
        top: 5,
      },
      '& [class*="element"]:last-child': {
        '& > [class*="time"]:first-child': {
          height: "5px",
          top: 0,
        },
        '& [class*="elementContent"]': {
          boxShadow: "none",
        },
      },
    },
    noPadding: {
      padding: 0,
    },
    element: {
      position: "relative",
      paddingLeft: "40px",
    },
    elementContent: {
      paddingBottom: "10px",
      boxShadow: `0 1px ${color}`,
    },
    time: {
      position: "absolute",
      background: color,
      width: "4px",
      height: "100%",
      top: 0,
      left: 18,
    },
    timeCircle: {
      position: "absolute",
      background: color,
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      top: 5,
      left: 15,
    },
  };
});

const EvaluateContractHistoricDrawer: FC<{
  isOpen: boolean;
  contractId: string;
  virtualWeek: number;
  onClose: () => void;
  fetch: () => Promise<void>;
}> = ({ isOpen, contractId, virtualWeek, onClose, fetch }) => {
  const classes = useStyles();
  const { historic, contract, lastWeek, removeQualification, loading } =
    useEvaluateContractorHistoricDrawer({
      contractId,
      fetch,
      onClose,
    });
  return (
    <Drawer
      className={classes.root}
      icon="info-sign"
      title="Histórico"
      isOpen={isOpen}
      size="500px"
      usePortal
      onClose={onClose}
      isCloseButtonShown={false}
    >
      <Box
        className={Classes.DRAWER_HEADER}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
      >
        <div>
          Tarea: <b>{contract?.activity.name}</b>
        </div>
        <div>
          Desde:
          <b> {moment(contract?.activity.startDate).format("DD-MM-YYYY")} </b>
          Hasta:{" "}
          <b>{moment(contract?.activity.endDate).format("DD-MM-YYYY")}</b>
        </div>
      </Box>
      <div className={Classes.DRAWER_BODY}>
        <Box className={classes.contentItems} paddingY={2}>
          {historic.map((record) => {
            return (
              <Box
                key={record.id}
                className={classes.element}
                paddingBottom={2}
                paddingRight={2}
              >
                {historic.length > 1 ? <div className={classes.time} /> : null}
                {historic.length > 1 ? (
                  <div className={classes.timeCircle} />
                ) : null}
                <Box>
                  <Box
                    display="flex"
                    paddingRight={2}
                    className={classes.elementContent}
                  >
                    <Box flexGrow="1">
                      <div className={classes.hight}>
                        {moment(record.createdAt).format("DD-MM-YYYY")}
                        <br />
                        <small>
                          {lastWeek === record.towerWeek
                            ? "Última calificación"
                            : null}
                        </small>
                      </div>
                      <div>Semana: {record.towerWeek + virtualWeek}</div>
                      <div>Cumplimiento: {record.compliance.toFixed(2)}%</div>
                      <div>Calificación: {record.qualification}</div>
                    </Box>
                    <Box>
                      {lastWeek === record.taskWeek ? (
                        <Popover2
                          usePortal
                          autoFocus
                          position="top"
                          content={
                            <Card>
                              <div key="text">
                                <H5>Corregir evaluación</H5>
                                <p style={{ width: "250px" }}>
                                  ¿Está seguro de que desea corregir esta
                                  evaluación?
                                  <br />
                                  Se perdera el registro
                                </p>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginTop: 15,
                                  }}
                                >
                                  <Button
                                    className={PoperClasses.POPOVER2_DISMISS}
                                    style={{ marginRight: 10 }}
                                  >
                                    Cancelar
                                  </Button>
                                  <Button
                                    intent="danger"
                                    className={PoperClasses.POPOVER2_DISMISS}
                                    onClick={removeQualification}
                                  >
                                    Corregir
                                  </Button>
                                </div>
                              </div>
                            </Card>
                          }
                        >
                          <Button icon="trash" loading={loading}>
                            Corregir
                          </Button>
                        </Popover2>
                      ) : null}
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      </div>
    </Drawer>
  );
};

export default EvaluateContractHistoricDrawer;
