import { IUseFormResult, useForm } from "shared/customHooks/useForm";

export interface UseProgramCellResult
  extends IUseFormResult<{ value: number }> {
  b: string;
}

type HookType = (id: number, initialValue: number) => UseProgramCellResult;

const useProgramCell: HookType = (id, initialValue) => {
  const form = useForm<{ value: number }>({
    initialValues: {
      value: initialValue,
    },
  });
  return {
    ...form,
    b: "",
  };
};

export default useProgramCell;
