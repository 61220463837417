import { useEffect, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router";
import { BreadcrumbProps } from "@blueprintjs/core";
import { AppBreadcrumbRoute } from "../types/AppBreadcrumbRoute";

export interface BreadcrumbPropsProgram extends BreadcrumbProps {
  name?: string;
  current?: boolean;
  resolveName?: () => string;
}

export interface UseAppBreadcrumbProps {
  routes: AppBreadcrumbRoute[];
}

const useAppBreadcrumb = ({ routes: initialRoutes }: UseAppBreadcrumbProps) => {
  const { pathname } = useLocation();
  const { push } = useHistory();

  const [routes, setRoutes] = useState<BreadcrumbPropsProgram[]>([]);

  const addRoute = (route: BreadcrumbPropsProgram): void => {
    const indexRoute = routes.findIndex(
      (current) => current.name === route.name
    );
    if (indexRoute > 0) {
      // empty
      setRoutes(
        routes.slice(0, indexRoute).concat({ ...route, current: true })
      );
    } else {
      setRoutes(
        routes
          .map((current) => ({ ...current, current: false }))
          .concat({
            ...route,
            current: true,
          })
      );
    }
  };

  const resolve = (): void => {
    const matchs = initialRoutes
      .map((route) => {
        const match = matchPath(pathname, route);
        if (match)
          return {
            ...match,
            name: route.name,
            text: route.text,
            icon: route.icon,
            resolveName: route.getName,
          };
        return false;
      })
      .filter((route) => route);
    setRoutes(
      matchs.map((route) => {
        if (route) {
          return {
            href: route.url,
            text: route.text,
            icon: route.icon,
            current: route.isExact,
            resolveName: route.resolveName,
          } as BreadcrumbPropsProgram;
        }
        return {};
      })
    );
  };

  useEffect(() => {
    resolve();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const onSelectRoute = (href?: string, current?: boolean) => {
    if (href && !current) push(href);
  };

  return {
    routes,
    onSelectRoute,
    addRoute,
  };
};

export default useAppBreadcrumb;
