/* eslint-disable no-console */
import config from "../common/config";

const DEFAULT_NAMESPACE = config.defaults.namespace;

const getDate = (): string => new Date().toISOString();

type Message = string | Record<string, unknown>;
const info = (message: Message, namespace?: string): void => {
  if (typeof message === "string")
    console.log(
      `[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] [INFO] ${message}`
    );
  else
    console.log(
      `[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] [INFO]`,
      message
    );
};

const warn = (message: Message, namespace?: string): void => {
  if (typeof message === "string")
    console.log(
      `[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] [WARN] ${message}`
    );
  else
    console.log(
      `[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] [WARN]`,
      message
    );
};

const error = (message: Message, namespace?: string): void => {
  if (typeof message === "string")
    console.log(
      `[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] [ERROR] ${message}`
    );
  else
    console.log(
      `[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] [ERROR]`,
      message
    );
};

const logging = {
  info,
  warn,
  error,
};

export default logging;
