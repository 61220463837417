import React, { FC } from "react";
import { Box, Typography } from "@material-ui/core";
import { Card } from "@blueprintjs/core";
import { ResolveVirtualWeek } from "lib/projects/utils/ResolveVirtualWeek";
import ProgramContractHeaderStyles from "./styles";
import { useSelectContractorContext } from "../../context/SelectContractorContext";

const ProgramContractHeader: FC = () => {
  const classes = ProgramContractHeaderStyles();
  const { project, tower, currentContract, currentWeek, currentWeekNumber } =
    useSelectContractorContext();
  return (
    <Card className={classes.root}>
      <Box display="flex">
        <Box flexBasis="1" flexGrow="1" className={classes.column}>
          <Box>
            <Typography variant="h2">Proyecto</Typography>
            <Typography>
              <b>{project?.name}</b>
            </Typography>
          </Box>
          <Box>
            <Typography variant="h2">Número de semanas</Typography>
            <Typography className={classes.dark}>
              {tower?.totalWeeks ? (
                <b>
                  {tower?.totalWeeks + +ResolveVirtualWeek(tower.virtualWeek)}
                </b>
              ) : null}
            </Typography>
          </Box>
        </Box>
        <Box flexBasis="1" flexGrow="1" className={classes.column}>
          <Box>
            <Typography variant="h2">Etapa</Typography>
            <Typography>
              <b>{tower?.name}</b>
            </Typography>
          </Box>

          {currentWeek.length ? (
            <Box>
              Semana a programar
              <br />
              <Typography className={classes.dark}>
                <b>
                  Semana{" "}
                  {tower?.virtualWeek
                    ? currentWeekNumber +
                      ResolveVirtualWeek(tower.virtualWeek) +
                      1
                    : 0}
                </b>
              </Typography>
              <Typography className={classes.dark}>
                {currentWeek[0].format("DD MMM")} -
                {currentWeek[1].format("DD MMM")}
              </Typography>
            </Box>
          ) : null}
        </Box>
        <Box flexBasis="1" flexGrow="1" className={classes.column}>
          {currentContract?.id ? (
            <Box>
              <Typography variant="h2">Contratista</Typography>
              <Typography>
                <b>{currentContract.contractor?.name}</b>
              </Typography>
            </Box>
          ) : null}

          <Box>
            <Typography variant="h2">Semana en evaluación</Typography>
            <Typography className={classes.dark}>
              <b>
                Semana{" "}
                {currentWeekNumber && tower && tower.virtualWeek
                  ? currentWeekNumber + ResolveVirtualWeek(tower.virtualWeek)
                  : 0}
              </b>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default ProgramContractHeader;
