import React, { FC } from "react";
import { Table } from "@devexpress/dx-react-grid-material-ui";
import { Evaluation } from "lib/contracts/models/Evaluation";
import { Button, ButtonGroup } from "@blueprintjs/core";
import { TaskEvaluationsDTO } from "lib/contracts/models/TaskEvaluationsDTO";
import NumberFormatString from "components/NumberFormatInputMaterialUI/NumberFormatString";
import ProgramCell from "../ProgramCell/ProgramCell";
import ProgramCellRemoveRow from "./ProgramCellRemoveRow";

interface ProgramCell extends Table.DataCellProps {
  value: Evaluation;
  onChangeValue: (row: string, value: string) => void;
  removeRow: (row: string) => void;
  selectRow: (task: TaskEvaluationsDTO) => void;
}

const ProgramContractTableCell: FC<ProgramCell> = (props) => {
  const { row, value, column, onChangeValue, removeRow, selectRow } = props;

  let tablevalue = 1;
  if (typeof value === "object") tablevalue = value.value;
  else tablevalue = value;

  if (value.currentProgramation)
    return <ProgramCell {...props} onChangeValue={onChangeValue} />;

  if (column.name === "inUse")
    return (
      <Table.Cell
        {...props}
        style={{ textAlign: "end", padding: "0" }}
        value={
          <ButtonGroup minimal>
            {!value ? (
              <ProgramCellRemoveRow onClick={() => removeRow(row.id)} />
            ) : null}
            <Button icon="edit" small onClick={() => selectRow(row)} />
          </ButtonGroup>
        }
      />
    );

  if (column.name === "unit" && value?.name)
    return <Table.Cell {...props} value={value?.name} />;

  return (
    <Table.Cell {...props} value={<NumberFormatString value={tablevalue} />} />
  );
};

export default ProgramContractTableCell;
