import { ChangeEventHandler, useEffect, useState } from "react";

import useProjects from "lib/projects/cutomHooks/useProjects";
import { Project } from "lib/projects/models/Project";

export interface UseProjectHomeResult {
  projects: Project[];
  handleChangeSearch: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  open: boolean;
  openModal: () => void;
  closeModal: () => void;
  handleCreateProject: (project: Project, updated?: boolean) => void;
  selectProject: (project: Project) => void;
  currentProject: Project | undefined;
}

const useProjectHome = (): UseProjectHomeResult => {
  const [originalProjects, setOriginalProjects] = useProjects();
  const [projects, setProjects] = useState<Project[]>([]);
  const [open, setOpen] = useState(false);
  const [currentProject, setCurrentProject] = useState<Project>();

  useEffect(() => {
    setProjects(originalProjects);
  }, [originalProjects]);

  const selectProject = (project: Project): void => {
    setCurrentProject(project);
  };

  useEffect(() => {
    if (currentProject?.id) setOpen(true);
  }, [currentProject]);

  const handleChangeSearch: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    e.preventDefault();
    setProjects(
      originalProjects.filter((project) =>
        project.name.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const openModal = (): void => setOpen(true);
  const closeModal = (): void => {
    setOpen(false);
    setCurrentProject(undefined);
  };

  const handleCreateProject = (project: Project, updated?: boolean): void => {
    if (updated) {
      setOriginalProjects(
        originalProjects.map((el) => (el.id === project.id ? project : el))
      );
    } else setOriginalProjects(originalProjects.concat(project));
    closeModal();
  };

  return {
    projects,
    handleChangeSearch,
    open,
    openModal,
    closeModal,
    handleCreateProject,
    selectProject,
    currentProject,
  };
};

export default useProjectHome;
