import { AxiosResponse } from "axios";
import { HttpService } from "shared/services/HttpService";
import { Task } from "../models/Task";

export class TaskService extends HttpService {
  constructor() {
    super("/tasks");
  }

  removeTask = (
    taskId: string
  ): Promise<
    AxiosResponse<{
      removed: boolean;
    }>
  > => this.httpClient.delete(`/${taskId}`);

  update = (
    taskId: string,
    task: Partial<Task>
  ): Promise<AxiosResponse<{ updated: boolean }>> =>
    this.httpClient.put(`/${taskId}`, task);
}
