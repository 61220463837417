import useTaskReasons from "lib/contracts/hooks/useTaskReasons";
import { RowEvaluation } from "lib/contracts/models/RowEvaluation";
import { TaskReason } from "lib/contracts/models/TaskReason";
import { ContractService } from "lib/contracts/services/ContractService";
import { IUseFormResult, useForm } from "shared/customHooks/useForm";
import { useEvaluateContractorTableContext } from "../context/EvaluateContractorTableContext";
import { EvaluationValue } from "./useEvaluateContractorTable";

interface Validation {
  required: {
    value: true;
    message: string;
  };
}

export interface UseEvaluateContractorModalParams {
  a: string;
}

export interface UseEvaluateContractorModalResult
  extends IUseFormResult<RowEvaluation> {
  isOpenModal: boolean;
  reasons: TaskReason[];
  handleClose: () => void;
  evaluation?: EvaluationValue;
}

type HookType = (
  param?: UseEvaluateContractorModalParams
) => UseEvaluateContractorModalResult;

const useEvaluateContractorModal: HookType = () => {
  const {
    isOpenModal,
    taskSelected,
    toggleOpenModal,
    evaluationValues,
    onSaveRow,
  } = useEvaluateContractorTableContext();

  const [reasons] = useTaskReasons();

  const evaluation = evaluationValues[`${taskSelected?.id}`];

  const initialValues: Partial<RowEvaluation> = {
    comment: taskSelected?.evaluations?.comment || "",
  };

  const validations: { [key: string]: Validation | undefined } = {
    reasonId: {
      required: {
        value: true,
        message: "Se requiere agregar un motivo",
      },
    },
    comment: {
      required: {
        value: true,
        message: "Se requiere agregar un comentario",
      },
    },
  };

  if (evaluation.compliance && evaluation.compliance < 100) {
    initialValues.reasonId = 0;
    initialValues.comment = "";
  }

  if (
    typeof evaluation.programValue === "number" &&
    evaluation.programValue === 0 &&
    evaluation.compliance === 0
  ) {
    validations.reasonId = undefined;
    validations.comment = undefined;
  } else if (evaluation.compliance && evaluation.compliance >= 100) {
    validations.reasonId = undefined;
    validations.comment = undefined;
  }

  const form = useForm<RowEvaluation>(
    {
      validations,
      initialValues,
      async onSubmit(newData) {
        try {
          const id = taskSelected?.evaluations?.id;
          if (id) {
            const service = new ContractService();
            await service.updateTaskEvaluation(id, {
              ...newData,
              evaluationValue: evaluation.evaluationValue
                ? +evaluation.evaluationValue
                : 0,
            });
            if (taskSelected && taskSelected.id) onSaveRow(taskSelected);
            toggleOpenModal();
            alert({
              message: `Se ha guardado la calificación para la semana ${taskSelected?.evaluations?.towerWeek}`,
              severity: "success",
            });
          }
        } catch (error) {
          alert(error);
        }
      },
    },
    [taskSelected?.evaluations]
  );

  const handleClose = (): void => {
    form.resetData();
    toggleOpenModal();
  };

  return {
    isOpenModal,
    reasons,
    handleClose,
    evaluation,
    ...form,
  };
};

export default useEvaluateContractorModal;
