import React, { FC } from "react";
import { Box, makeStyles } from "@material-ui/core";
import {
  Button,
  Card,
  ControlGroup,
  FormGroup,
  InputGroup,
} from "@blueprintjs/core";
import BoxTitle from "shared/components/BoxTitle/BoxTitle";
import useTaskUnits from "../customHooks/useTaskUnits";
import UnitItem from "./UnitItem";
import useSubTaskUnitForm from "../customHooks/useTaskUnitForm";

const useStyles = makeStyles({
  root: {},
});

const Units: FC = () => {
  const classes = useStyles();
  const { units, handleCreate, removeUnit, fetchUnits } = useTaskUnits();

  const { handleSubmit, errors, data, formLoading, handleChange } =
    useSubTaskUnitForm(handleCreate);

  return (
    <Box className={classes.root}>
      <BoxTitle>Unidades de medida</BoxTitle>
      <Card>
        <BoxTitle>Nueva unidad</BoxTitle>
        <form onSubmit={handleSubmit}>
          <Box display="flex" alignItems="center">
            <ControlGroup>
              <InputGroup
                id="unit-input"
                placeholder="Nueva unidad"
                value={data.name}
                onChange={handleChange("name")}
                intent={errors.name ? "danger" : "none"}
              />
              <Button
                icon="plus"
                type="submit"
                loading={formLoading}
                intent={errors.name ? "danger" : "none"}
                outlined
              >
                Agregar
              </Button>
            </ControlGroup>
            <FormGroup
              helperText={!!errors?.name && errors?.name}
              labelFor="unit-input"
              intent={errors.name ? "danger" : "none"}
            />
          </Box>
        </form>
      </Card>
      <Box marginY={1} />
      <Card>
        <Box display="flex" alignItems="baseline">
          <Box flexBasis="1" flexGrow="1" width="60%">
            {units.map((unit) => (
              <UnitItem
                key={unit.id}
                unit={unit}
                onConfirm={() => removeUnit(unit)}
                onUpdateUnit={fetchUnits}
              />
            ))}
          </Box>
          <Box flexBasis="1" flexGrow="1" width="40%" />
        </Box>
      </Card>
    </Box>
  );
};

export default Units;
