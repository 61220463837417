import { RootState } from "store";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setContractorProjectList } from "modules/Contractor/store";
import { ContractorService } from "lib/contractors/services/ContractorService";

const useProjectsOfContractor = (contractorId?: string) => {
  const dispatch = useDispatch();
  const projects = useSelector(
    (state: RootState) => state.Contractor.listProjects
  );

  const fetch = useCallback(async () => {
    if (contractorId && !projects?.length) {
      try {
        const service = new ContractorService();
        const { data } = await service.getProjects(contractorId);
        dispatch(setContractorProjectList(data.projects));
      } catch (error) {
        alert(error);
      }
    }
  }, [contractorId, dispatch, projects]);

  useEffect(() => {
    fetch();
  }, [contractorId, fetch]);

  return {
    projects,
  };
};

export default useProjectsOfContractor;
