import { AxiosResponse } from "axios";
import { Contract } from "lib/contracts/models/Contract";
import { ContractService } from "lib/contracts/services/ContractService";
import { Activity } from "lib/projects/models/Activity";

export class ContractActivityDateService extends ContractService {
  getContractActivityDate = (
    activityId: string
  ): Promise<
    AxiosResponse<{
      contract?: Contract;
      activity: Activity;
      minDate: string;
      disabledDays?: { before: string };
    }>
  > => this.httpClient.get(`/activity/${activityId}`);
}
