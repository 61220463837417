import { useCallback, useEffect, useState } from "react";
import { Contractor } from "lib/contractors/models/Contractor";
import { ContractorService } from "lib/contractors/services/ContractorService";
import { User } from "lib/users/models/User";

interface Params {
  contractor: Contractor;
}
const useContractorUser = ({ contractor }: Params) => {
  const [users, setUsers] = useState<User[]>([]);

  const fetch = useCallback(async () => {
    try {
      const service = new ContractorService();
      const { data } = await service.getUsers(contractor.id);
      setUsers(data?.users);
    } catch (error) {
      alert(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractor.id]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    users,
  };
};

export default useContractorUser;
