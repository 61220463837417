import React from "react";
import { H1, Icon, NonIdealState } from "@blueprintjs/core";
import { Box } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";

const Unauthorized: React.FunctionComponent<RouteComponentProps> = () => {
  return (
    <Box
      minHeight="50vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <NonIdealState
        icon={<Icon icon="lock" size={80} />}
        title={<H1>Sin permiso</H1>}
        description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur nam modi harum odio accusamus eius quo rerum quasi dignissimos nisi voluptatum, veniam obcaecati illum eum inventore similique excepturi laboriosam adipisci."
      />
    </Box>
  );
};

export default Unauthorized;
