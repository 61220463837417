import React, { createContext, FC, useContext } from "react";
import useEvaluateContractorTable, {
  UseEvaluateContractorTableResult,
  UseEvaluateContractorTableParams,
} from "../customHooks/useEvaluateContractorTable";

type EvaluateContractorTableContextType = UseEvaluateContractorTableResult;

const EvaluateContractorTableContext =
  createContext<EvaluateContractorTableContextType | null>(null);

export const useEvaluateContractorTableContext =
  (): EvaluateContractorTableContextType =>
    useContext(
      EvaluateContractorTableContext
    ) as EvaluateContractorTableContextType;

export type EvaluateContractorTableParams = UseEvaluateContractorTableParams;

export const EvaluateContractorTableProvider: FC<UseEvaluateContractorTableParams> =
  ({ children, ...props }) => {
    const value = useEvaluateContractorTable(props);

    return (
      <EvaluateContractorTableContext.Provider value={{ ...value }}>
        {children}
      </EvaluateContractorTableContext.Provider>
    );
  };
