/* eslint-disable no-param-reassign */
import { ChangeEvent, useState } from "react";
import { Borders, Column, Fill } from "exceljs";
import { XLSXUtils } from "shared/utils/XSXL/XLSX";
import { ContractorService } from "lib/contractors/services/ContractorService";

export interface UseXLSXContractorsResult {
  makeDemoXLSX: () => Promise<void>;
  uploadContracts: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  loading: boolean;
}

type HookType = (params: {
  onBulkCreate: () => void;
}) => UseXLSXContractorsResult;

const useXLSXContractors: HookType = ({ onBulkCreate }) => {
  const [loading, setLoading] = useState(false);

  const makeDemoXLSX = async (): Promise<void> => {
    const xlsx = new XLSXUtils();
    const book = xlsx.createBook();
    const SheetName = "Contratistas";
    const worksheet = xlsx.createSheet(book, SheetName);

    const fill: Fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "EAEAEA" },
    };

    const border: Partial<Borders> = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };

    const columns: Partial<Column>[] = [
      {
        header: "TAMAÑO EMPRESA",
        key: "companySize",
        width: 20,
      },
      {
        header: "TIPO DOCUMENTO",
        key: "documentType",
        width: 20,
        fill,
      },
      {
        header: "N DOCUMENTO",
        key: "documentNumber",
        width: 20,
        fill,
      },
      {
        header: "NOMBRE",
        key: "name",
        width: 20,
        fill,
      },
      {
        header: "ARL",
        key: "arl",
        width: 5,
        fill,
      },
      {
        header: "ACTIVIDAD ECONÓMICA",
        key: "activity",
        width: 25,
        fill,
      },
      {
        header: "DEPARTAMENTO",
        key: "departament",
        width: 20,
        fill,
      },
      {
        header: "CIUDAD",
        key: "city",
        width: 10,
        fill,
      },
      {
        header: "N EMPLEADOS DIRECTOS",
        key: "nempleados",
        width: 25,
        fill,
      },
      {
        header: "TIPO CONTRATISTA",
        key: "contractorType",
        width: 20,
        fill,
      },
      {
        header: "CLASE DE RIESGO",
        key: "risk",
        width: 20,
        fill,
      },
      {
        header: "EMAIL",
        key: "email",
        width: 20,
        fill,
      },
    ];

    worksheet.columns = columns;

    worksheet.addRow([
      "MICRO",
      "NIT",
      "000000",
      "Contratista 1",
      "",
      "",
      "",
      "",
      "1",
      "PERMANENTE",
      "",
      "contratista1@contractor.com",
    ]);

    worksheet.getCell("A1").fill = fill;
    worksheet.getCell("A1").border = border;
    worksheet.getCell("B1").fill = fill;
    worksheet.getCell("B1").border = border;
    worksheet.getCell("C1").fill = fill;
    worksheet.getCell("C1").border = border;
    worksheet.getCell("D1").fill = fill;
    worksheet.getCell("D1").border = border;
    worksheet.getCell("E1").fill = fill;
    worksheet.getCell("E1").border = border;
    worksheet.getCell("F1").fill = fill;
    worksheet.getCell("F1").border = border;
    worksheet.getCell("G1").fill = fill;
    worksheet.getCell("G1").border = border;
    worksheet.getCell("H1").fill = fill;
    worksheet.getCell("H1").border = border;
    worksheet.getCell("I1").fill = fill;
    worksheet.getCell("I1").border = border;
    worksheet.getCell("J1").fill = fill;
    worksheet.getCell("J1").border = border;
    worksheet.getCell("K1").fill = fill;
    worksheet.getCell("K1").border = border;
    worksheet.getCell("L1").fill = fill;
    worksheet.getCell("L1").border = border;

    worksheet.getCell("A2").dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: ['"MICRO,PEQUEÑA,MEDIANA,GRANDE"'],
    };

    worksheet.getCell("B2").dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: ['"NIT,CEDULA,PASAPORTE,CEDULA EXTRANJERIA"'],
    };

    worksheet.getCell("J2").dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: ['"PERMANENTE,EXTEMPORANEO"'],
    };

    await xlsx.downloadXLSX(book, `Plantilla${SheetName}`);
  };

  const uploadContracts = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setLoading(true);
    if (e.target.files?.length) {
      try {
        const formData = new FormData();
        const file = e.target.files[0];
        const types = [
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
        ];
        if (types.includes(file.type)) {
          formData.append("contracts", file, file.name);
          const service = new ContractorService();
          const { data } = await service.createBulk(formData);

          if (data.created) onBulkCreate();
          e.target.value = "";
          e.target.files = null;
        } else {
          alert("El tipo de archivo no es valido");
        }
      } catch (error) {
        alert(error);
        e.target.value = "";
        e.target.files = null;
      }
    }
    setLoading(false);
  };

  return {
    makeDemoXLSX,
    uploadContracts,
    loading,
  };
};

export default useXLSXContractors;
