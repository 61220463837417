import { ChangeEventHandler, useEffect, useState } from "react";
import { Contractor } from "lib/contractors/models/Contractor";

const useContractorList = (originalContractors: Contractor[]) => {
  const [contractors, setContractors] = useState<Contractor[]>([]);

  useEffect(() => {
    setContractors(originalContractors);
  }, [originalContractors]);

  const handleChangeSearch: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    e.preventDefault();
    setContractors(
      originalContractors.filter((contractor) =>
        contractor.name.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const reset = () => {
    setContractors(originalContractors);
  };

  return {
    contractors,
    handleChangeSearch,
    reset,
  };
};

export default useContractorList;
