import { AxiosResponse } from "axios";
import { HttpService } from "shared/services/HttpService";
import { Tower } from "../models/Tower";

export class TowerService extends HttpService {
  constructor() {
    super("/towers");
  }

  all = (projectId: string): Promise<AxiosResponse<{ towers: [] }>> =>
    this.httpClient.get(`/${projectId}`);

  find = (towerId: string): Promise<AxiosResponse<{ tower: Tower }>> =>
    this.httpClient.get(`/find/${towerId}`);

  allWithProject = (
    projectId: string
  ): Promise<AxiosResponse<{ towers: [] }>> =>
    this.httpClient.get(`/project/${projectId}`);

  update = (
    id: string,
    tower: Partial<Tower>
  ): Promise<AxiosResponse<{ updated: boolean }>> =>
    this.httpClient.put(`/${id}`, tower);
}
