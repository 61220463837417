import React, { FC } from "react";
import HavePermission from "components/HavePermission/HavePermission";
import ConsolidatedEvaluationSelectProject from "modules/PAC/features/ConsolidatedEvaluation/UI/ConsolidatedEvaluationSelectProject/ConsolidatedEvaluationSelectProject";
import EvaluateContractorsContainer from "pages/PAC/containers/Evaluate/containers/EvaluateContractors/EvaluateContractors";

const PACPage: FC = () => {
  return (
    <div>
      <div>
        <HavePermission
          action="canView"
          resourceName="pac.evaluateConsolidated"
        >
          <ConsolidatedEvaluationSelectProject />
        </HavePermission>
        <HavePermission action="canView" resourceName="pac.viewEvaluations">
          <EvaluateContractorsContainer />
        </HavePermission>
      </div>
    </div>
  );
};
export default PACPage;
