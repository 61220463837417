import { useHistory, useParams, useRouteMatch } from "react-router";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { Borders, Fill } from "exceljs";
import { ActivityService } from "lib/projects/services/ActivityService";
import { ProjectService } from "lib/projects/services/ProjectService";
import { Tower } from "lib/projects/models/Tower";
import { Project } from "lib/projects/models/Project";
import { TowerService } from "lib/projects/services/TowerService";
import { XLSXUtils } from "shared/utils/XSXL/XLSX";

export interface UseProgramHomeParams {
  a: string;
}

export interface UseProgramHomeResult {
  project?: Project;
  towers: Tower[];
  uploadTasks: (file: File, towerId: string) => void;
  downloadExecl: () => Promise<void>;
}

type HookType = (param?: UseProgramHomeParams) => UseProgramHomeResult;

const useProgramHome: HookType = () => {
  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();
  const { path } = useRouteMatch();
  const [project, setProject] = useState<Project>();
  const [towers, setTowers] = useState<Tower[]>([]);

  const fetch = useCallback(async () => {
    const service = new ProjectService();
    const { data } = await service.getById(projectId);
    if (!data.project) history.push(path.replace("/:projectId", ""));
    else {
      setProject(data.project);
      const towerService = new TowerService();
      const { data: dataTowers } = await towerService.allWithProject(projectId);
      setTowers(dataTowers.towers);
    }
  }, [history, path, projectId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const uploadTasks = async (file: File, towerId: string): Promise<void> => {
    if (file?.name) {
      const formData = new FormData();
      const types = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ];
      if (types.includes(file.type)) {
        formData.append("tasks", file, file.name);
        const service = new ActivityService();
        const { data } = await service.createBulk(towerId, formData);
        if (data.created)
          setTowers(
            towers.map((tower) =>
              tower.id === towerId ? { ...tower, tasksCount: "1" } : tower
            )
          );
      } else {
        alert("El tipo de archivo no es valido");
      }
    }
  };

  const downloadExecl = async (): Promise<void> => {
    try {
      const xlsx = new XLSXUtils();
      const book = xlsx.createBook();
      const sheet = xlsx.createSheet(book, "Tareas");

      const fill: Fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "EAEAEA" },
      };

      const border: Partial<Borders> = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      sheet.columns = [
        {
          key: "activity",
          header: "ACTIVIDAD",
          width: 50,
        },
        {
          key: "startDate",
          header: "FECHA INICIO",
          width: 20,
        },
        {
          key: "endDate",
          header: "FECHA FINAL",
          width: 20,
        },
      ];

      sheet.getCell("A1").fill = fill;
      sheet.getCell("A1").border = border;
      sheet.getCell("B1").fill = fill;
      sheet.getCell("B1").border = border;
      sheet.getCell("C1").fill = fill;
      sheet.getCell("C1").border = border;

      const date = moment(new Date()).format("DD/MM/YYYY");
      sheet.addRow(["Actividad de ejemplo", date, date]);
      await xlsx.downloadXLSX(book, "PlantillaActividades");
    } catch (error) {
      alert(error);
    }
  };

  return {
    project,
    uploadTasks,
    towers,
    downloadExecl,
  };
};

export default useProgramHome;
