import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PlatformConfig } from "lib/platform/models/PlatformConfig";

export interface AppState {
  error: string;
  loading: boolean;
  initialized: boolean;
  platformConfig?: PlatformConfig;
}

const initialState: AppState = {
  error: "",
  loading: false,
  initialized: false,
};

const AppSlice = createSlice({
  name: "@Authentication",
  initialState,
  reducers: {
    setAppLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setAppError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    initializeApp(state) {
      state.initialized = true;
    },
    setPlatformConfig(state, action: PayloadAction<PlatformConfig>) {
      state.platformConfig = action.payload;
    },
  },
});

export const { setAppLoading, setAppError, initializeApp, setPlatformConfig } =
  AppSlice.actions;
export const APPReducer = AppSlice.reducer;
