import logging from "config/logger/logging";
import { Permissions } from "lib/users/models/Permission";
import { User } from "lib/users/models/User";
import { UserService } from "lib/users/services/UserService";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { RootState } from "store";
import {
  initializeAuth,
  setAuthPermissions,
  setUserAuth,
  initializePermissions,
  authDismouunt,
} from "../redux/AuthenticationSlice";
import useAuthContractor from "./useAuthContractor";

const useAuth = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const { initialized, user, authLoading, initializedPermissions, minSide } =
    useSelector((state: RootState) => state.auth);
  const { loginContractor } = useAuthContractor();

  const handleError = () => {
    if (pathname.includes("/invite/")) history.push(pathname);
    else if (pathname.includes("/forgot-password")) history.push(pathname);
    else if (pathname.includes("/recovery/")) history.push(pathname);
    else history.push("/login");
  };

  const initializeUser = (currentUser: User | null) => {
    const permissions: Permissions = {};
    if (currentUser?.resources) {
      const resources = currentUser?.resources || [];
      resources.forEach((resource) => {
        permissions[resource.name] = resource;
      });
    }
    dispatch(setAuthPermissions(permissions));
  };

  const initialize = async () => {
    if (!initialized) {
      logging.info("Auth Loading");
      try {
        const userService = new UserService();
        const { data } = await userService.getCurrentUser();
        dispatch(setUserAuth(data.user));

        loginContractor(data.user);
        dispatch(initializeAuth());
        logging.info("Auth Loaded: Success");
      } catch (err) {
        handleError();
        logging.info("Auth Required Login");
      }
    }
  };

  const dismount = async () => {
    dispatch(authDismouunt());
  };

  const initializeResources = async () => {
    dispatch(initializePermissions());
  };

  const logout = async (): Promise<void> => {
    localStorage.removeItem("contractor_access");
    localStorage.removeItem("contractor_refresh_access");
    localStorage.removeItem("jwt");
    dismount();
    history.push("/login");
  };

  return {
    initialized,
    initializeAuth: initialize,
    initializeUser,
    user,
    logout,
    authLoading,
    initializedPermissions,
    initializePermissions: initializeResources,
    minSide,
  };
};

export default useAuth;
