import React, { createContext, FC, useContext } from "react";
import useEvaluateContractors, {
  UseEvaluateContractorsResult,
} from "../customHooks/useEvaluateContractors";

type EvaluateContractorsContextType = UseEvaluateContractorsResult;

const EvaluateContractorsContext =
  createContext<EvaluateContractorsContextType | null>(null);

export const useEvaluateContractorsContext =
  (): EvaluateContractorsContextType =>
    useContext(EvaluateContractorsContext) as EvaluateContractorsContextType;

export const EvaluateContractorsProvider: FC = ({ children }) => {
  const pac = useEvaluateContractors();

  return (
    <EvaluateContractorsContext.Provider value={{ ...pac }}>
      {children}
    </EvaluateContractorsContext.Provider>
  );
};
