import React, { FC } from "react";
import { Box, Button, Divider, TextField, Typography } from "@material-ui/core";
import moment from "moment";
import { Add } from "@material-ui/icons";
import {
  Classes,
  Dialog,
  Menu,
  MenuDivider,
  MenuItem,
  Button as BButton,
  NonIdealState,
  Card,
  HTMLTable,
} from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { useHistory, useRouteMatch } from "react-router";
import ProjectModalStyles from "./styles";
import { useProjectHomeContext } from "../../context/ProjectHomeContext";
import useProjectForm from "../../customHooks/useProjectForm";
import TowerForm from "../TowerForm/TowerForm";

const ProjectModal: FC = () => {
  const classes = ProjectModalStyles();
  const { open, currentProject } = useProjectHomeContext();
  const { path } = useRouteMatch();
  const history = useHistory();

  const {
    handleSubmit,
    data,
    errors,
    handleChange,
    toggleFormIndex,
    closeFormModal,
    formIndex,
    addTower,
    towers,
    currentTower,
    selectTower,
  } = useProjectForm();

  return (
    <Dialog
      isOpen={open}
      title={
        currentProject && !!currentProject.id
          ? "Actualizar proyecto"
          : "Nuevo Proyecto"
      }
      onClose={!formIndex ? closeFormModal : () => null}
    >
      {formIndex === 0 ? (
        <>
          <div className={Classes.DIALOG_BODY}>
            <Box paddingY={2}>
              <form id="project_form" noValidate onSubmit={handleSubmit}>
                <div className={classes.formItem}>
                  <div className={classes.label}>
                    <Typography>Nombre</Typography>
                  </div>
                  <TextField
                    value={data.name}
                    fullWidth
                    required
                    label="Ingresa"
                    name="name"
                    autoFocus
                    error={!!errors.name}
                    helperText={errors.name}
                    onChange={handleChange("name")}
                  />
                </div>
              </form>
            </Box>
            <Divider />
            <Box
              paddingY={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <b className="bp3-text-large">Etapas</b>
              </Box>
              <Box>
                <BButton icon="insert" onClick={toggleFormIndex}>
                  Agregar etapa
                </BButton>
              </Box>
            </Box>
            {towers.length ? (
              <Box>
                <HTMLTable condensed striped bordered className={classes.table}>
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Código ERP</th>
                      <th>Fecha Inicio</th>
                      <th>Fecha Fin</th>
                      <th>
                        <span />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {towers.map((tower) => (
                      <tr key={Math.random()}>
                        <td>
                          <Box display="flex" alignItems="center">
                            <Typography>{tower.name}</Typography>
                          </Box>
                        </td>
                        <td>
                          <Box display="flex" alignItems="center">
                            <Typography>{tower.erp}</Typography>
                          </Box>
                        </td>
                        <td>
                          {tower.startDate
                            ? moment(tower.startDate).format("DD-MM-yyyy")
                            : "Sin configurar"}
                        </td>
                        <td>
                          {tower.endDate
                            ? moment(tower.endDate).format("DD-MM-yyyy")
                            : "Sin configurar"}
                        </td>
                        <td>
                          <Popover2
                            usePortal
                            autoFocus
                            content={
                              <Menu key="menu">
                                <MenuItem
                                  icon="inherited-group"
                                  text="Asignar Usuarios"
                                  onClick={() => {
                                    history.push(
                                      `${path}/tower/assign/${tower.id}`
                                    );
                                  }}
                                />
                                <MenuItem
                                  icon="edit"
                                  text="Editar"
                                  onClick={() => selectTower(tower)}
                                />
                                <MenuDivider />
                                <MenuItem
                                  icon="trash"
                                  text="Borrar"
                                  intent="danger"
                                />
                              </Menu>
                            }
                          >
                            <BButton icon="menu-open" />
                          </Popover2>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </HTMLTable>
              </Box>
            ) : (
              <Card>
                <NonIdealState
                  title="Proyecto sin etapas"
                  description={
                    <Button
                      onClick={toggleFormIndex}
                      startIcon={<Add />}
                      variant="outlined"
                    >
                      Agregar etapa
                    </Button>
                  }
                />
              </Card>
            )}
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            {formIndex === 0 ? (
              <Box display="flex" alignItems="center">
                <Box>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={closeFormModal}
                  >
                    Cancelar
                  </Button>
                </Box>
                <Box flexGrow="1" marginLeft={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={currentProject?.name === data.name}
                    form="project_form"
                  >
                    {currentProject?.id ? "Actualizar" : "Crear"}
                  </Button>
                </Box>
              </Box>
            ) : null}
          </div>
        </>
      ) : (
        <TowerForm
          back={toggleFormIndex}
          handleSave={addTower}
          tower={currentTower}
        />
      )}
    </Dialog>
  );
};

export default ProjectModal;
