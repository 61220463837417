import React from "react";
import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";

// import { useLocation } from "react-router";
import { useHistory, useLocation } from "react-router";
import { Icon } from "@blueprintjs/core";
import useAuth from "features/Authentication/core/hooks/useAuth";
import LogoComponent from "./LogoComponent";
import MenuItemComponent from "./MenuItemComponent";
import useSidebar from "../core/hooks/useSidebar";

interface StyleProps {
  minSide: boolean;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      boxSizing: "border-box",
      backgroundColor: theme.palette.primary.main,
      width: ({ minSide }: StyleProps) => (minSide ? "100vw" : 250),
      minHeight: ({ minSide }: StyleProps) => (minSide ? "40px" : "100vh"),
      paddingTop: ({ minSide }: StyleProps) => (minSide ? 10 : 32),
    },
    containerChild: {
      boxSizing: "border-box",
      backgroundColor: theme.palette.tertiary.main,
      width: 200,
      height: ({ minSide }: StyleProps) => (minSide ? "40px" : "100vh"),
      paddingTop: ({ minSide }: StyleProps) => (minSide ? 0 : 32),
    },
    menuItemList: {
      backgroundColor: ({ minSide }: StyleProps) =>
        minSide ? theme.palette.primary.main : "transparent",
      marginTop: ({ minSide }: StyleProps) => (minSide ? 0 : 30),
    },
    separator: {
      borderTop: "1px solid #DFE0EB",
      marginTop: 16,
      marginBottom: 16,
      opacity: 0.06,
    },
    subMenuHeader: {
      display: "flex",
      maxHeight: 30,
      color: theme.palette.detail.main,
      alignItems: "center",
      position: "relative",
    },
    subMenuHeaderIcon: {
      padding: 10,
      position: "relative",
      top: 2,
    },
  })
);

const SidebarComponent: React.FC<React.ReactNode> = () => {
  const location = useLocation();
  const history = useHistory();
  const { logout, minSide } = useAuth();
  const { permissions, currentSide } = useSidebar();
  // const { permissions, currentSide } = useSidebarContext();

  // const currentSide: SideElement | null = null;
  const classes = useStyles({ minSide });

  return (
    <Box display="flex" justifyContent="start">
      <Box className={classes.container} display="flex" flexDirection="column">
        <LogoComponent />
        <Box
          display="flex"
          flexDirection={minSide ? "row" : "column"}
          justifyContent="space-between"
          className={classes.menuItemList}
        >
          {permissions?.map((permission) => (
            <MenuItemComponent
              key={`menuitem_${permission.label}`}
              title={permission.label || ""}
              minSide={minSide}
              active={currentSide?.name === permission.name}
              icon={permission.icon}
              disabled={!!permission.disabled}
              onClick={() => {
                if (!permission.disabled) {
                  if (
                    !permission.unique &&
                    location.pathname.includes(permission.path)
                  )
                    return;
                  history.push(permission.path);
                }
              }}
            />
          ))}
          <MenuItemComponent
            title={minSide ? "" : "Cerrar sesión"}
            minSide={minSide}
            onClick={logout}
            icon={() => <Icon icon="log-out" />}
            style={{ flexGrow: 0 }}
          />
        </Box>
      </Box>
      {currentSide?.childs && !!currentSide?.childs?.length ? (
        <Box
          display="flex"
          flexDirection="column"
          className={classes.containerChild}
        >
          <div className={classes.subMenuHeader}>
            <div className={classes.subMenuHeaderIcon}>
              {currentSide && currentSide.icon ? <currentSide.icon /> : null}
            </div>
            {currentSide && currentSide.label}
          </div>
          <Box className={classes.menuItemList}>
            {currentSide?.childs?.length
              ? currentSide?.childs.map((permission) => (
                  <MenuItemComponent
                    key={`menuitem_${permission.label}`}
                    title={permission.label || ""}
                    active={location.pathname.includes(
                      currentSide.path + permission.path
                    )}
                    icon={permission.icon}
                    light
                    onClick={() => {
                      if (
                        location.pathname.includes(
                          currentSide.path + permission.path
                        )
                      )
                        return;
                      history.push(currentSide.path + permission.path);
                    }}
                  />
                ))
              : null}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default SidebarComponent;
