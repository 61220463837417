import React from "react";
import { Redirect, Route, RouteProps } from "react-router";
import { HaveAccessType } from "shared/customHooks/usePermision";
import AppRoute from "config/router/models/AppRoute";

const PrivateRoute: React.FC<
  RouteProps & { haveAccess: HaveAccessType; route: AppRoute }
> = ({ children, haveAccess, route, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (route.public || haveAccess(route.name, "canView")) return children;
        return (
          <Redirect
            to={{
              pathname: "/unauthorized",
              state: {
                from: location,
              },
            }}
          />
        );
      }}
    />
  );
};
export default PrivateRoute;
