import useUnits from "lib/contracts/hooks/useUnits";
import { TaskUnit } from "lib/contracts/models/TaskUnit";
import { TaskUnitService } from "lib/contracts/services/TaskUnitService";

type AsyncCallbakcUnit = (unit: TaskUnit) => Promise<void>;

export interface UseTaskUnitsParams {
  a: string;
}

export interface UseTaskUnitsResult {
  units: TaskUnit[];
  handleCreate: (unit: TaskUnit) => void;
  removeUnit: AsyncCallbakcUnit;
  fetchUnits: () => Promise<void>;
}

type HookType = (param?: UseTaskUnitsParams) => UseTaskUnitsResult;

const useTaskUnits: HookType = () => {
  const [units, fetchUnits] = useUnits();

  const handleCreate = (): void => {
    fetchUnits();
  };

  const removeUnit: AsyncCallbakcUnit = async (unit) => {
    try {
      const service = new TaskUnitService();
      await service.removeUnit(unit.id);
      fetchUnits();
      alert({
        message: "Se ha borrado la unidad de medida",
        severity: "warning",
      });
    } catch (error) {
      alert(error);
    }
  };

  return {
    units,
    handleCreate,
    removeUnit,
    fetchUnits,
  };
};

export default useTaskUnits;
