import React, { FC } from "react";
import { Container, makeStyles } from "@material-ui/core";
import { Route, useRouteMatch } from "react-router";
import CustomSwitch from "shared/components/CustomSwitch/CustomSwitch";
import { ProjectProvider } from "./context/ProjectContext";
import ProjectHomeContainer from "./containers/Home/HomeContainer";
import AssignTowerContainer from "./containers/Assign/AssignTowerContainer";

const useStyles = makeStyles({
  root: {
    marginLeft: 36,
  },
});

const Projects: FC = () => {
  const classes = useStyles();
  const { path } = useRouteMatch();
  return (
    <Container maxWidth="md" className={classes.root}>
      <CustomSwitch>
        <Route
          path={`${path}/tower/assign/:towerId`}
          component={AssignTowerContainer}
        />
        <Route exact path={path} component={ProjectHomeContainer} />
      </CustomSwitch>
    </Container>
  );
};

const ProjectsPage: FC = () => {
  return (
    <ProjectProvider>
      <Projects />
    </ProjectProvider>
  );
};

export default ProjectsPage;
