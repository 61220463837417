import { ContractService } from "lib/contracts/services/ContractService";
import { useForm } from "shared/customHooks/useForm";

interface Params {
  contractId: string;
  week: number;
  onReject: () => void;
}

const useDialogReject = ({ week, contractId, onReject }: Params) => {
  const onSubmit = async (comment: string, reset: () => void) => {
    try {
      const service = new ContractService();
      await service.rejectEvaluation(contractId, week, comment);
      onReject();
      alert({
        message: "La acción se ejecutó con éxito",
        severity: "success",
      });
      reset();
    } catch (error) {
      alert(error);
    }
  };

  const form = useForm<{ comment: string }>({
    validations: {
      comment: {
        required: {
          value: true,
          message: "Debe agregar un comentario",
        },
      },
    },
    initialValues: {
      comment: "",
    },
    async onSubmit(newData, reset) {
      onSubmit(newData.comment, reset);
    },
  });
  return {
    form,
  };
};
export default useDialogReject;
