import React, { FC } from "react";
import { Box, Typography } from "@material-ui/core";
import { Card, Divider } from "@blueprintjs/core";
import EvaluateContractorsTable from "./components/EvaluateContractorsTable";
import {
  EvaluateContractorsProvider,
  useEvaluateContractorsContext,
} from "./context/EvaluateContractorsContext";
import EvaluateContractorsFilter from "./components/EvaluateContractorsFilter/EvaluateContractorsFilter";

const EvaluateContractors: FC = () => {
  const { rows, cols, selectContractor, onFilter } =
    useEvaluateContractorsContext();

  return (
    <Box>
      <Box>
        <Box mt={4}>
          <h1>
            <Typography variant="h1">Evaluaciones</Typography>
          </h1>
        </Box>
        <Divider />
        <Box mt={2}>
          <Box>
            <EvaluateContractorsFilter onFilter={onFilter} />
          </Box>
          <Box mt={2}>
            <Card style={{ padding: 0 }}>
              <EvaluateContractorsTable
                rows={rows}
                cols={cols}
                selectContractor={selectContractor}
              />
            </Card>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const EvaluateContractorsContainer: FC = () => {
  return (
    <EvaluateContractorsProvider>
      <EvaluateContractors />
    </EvaluateContractorsProvider>
  );
};

export default EvaluateContractorsContainer;
