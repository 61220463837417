import React, { FC } from "react";
import { Box, Container, makeStyles } from "@material-ui/core";
import {
  Route,
  RouteComponentProps,
  useHistory,
  useRouteMatch,
} from "react-router";

import {
  Breadcrumb,
  BreadcrumbProps,
  Breadcrumbs,
  Icon,
} from "@blueprintjs/core";
import CustomSwitch from "shared/components/CustomSwitch/CustomSwitch";
import AssignContractorPage from "modules/PAC/pages/AssignContractorPage/AssignContractorPage";
import { ProgramProvider, useProgramContext } from "./context/ProgramContext";
import ProgramProjectsContainer from "./containers/ProgramProject/ProgramProject";
import ProgramHomeContainer from "./containers/ProgramHome/ProgramHome";
import ProgramAssignmentContainer from "./containers/ProgramAssignment/ProgramAssignment";
import SelectContractorContainer from "./containers/SelectContractor/SelectContractor";
import ProgrammingConsolidated from "./containers/ProgrammingConsolidated/ProgrammingConsolidated";

const useStyles = makeStyles({
  root: {
    marginLeft: 36,
  },
});

const Program: FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const history = useHistory();

  const { routes } = useProgramContext();
  return (
    <Container className={classes.root}>
      <Box marginBottom={4}>
        <Breadcrumbs
          breadcrumbRenderer={({
            text,
            href,
            current,
            ...rest
          }: BreadcrumbProps) => {
            return (
              <Breadcrumb
                {...rest}
                current={current}
                onClick={() => {
                  if (href && !current) history.push(href);
                }}
              >
                {text}
                <Icon icon="caret-right" />
              </Breadcrumb>
            );
          }}
          currentBreadcrumbRenderer={({
            text,
            href,
            current,
            ...rest
          }: BreadcrumbProps) => {
            return (
              <Breadcrumb
                {...rest}
                current={current}
                onClick={() => {
                  if (href && !current) history.push(href);
                }}
              >
                {text}
              </Breadcrumb>
            );
          }}
          items={routes}
        />
      </Box>
      <CustomSwitch>
        <Route
          path={`${path}/:projectId/consolidated/:towerId`}
          component={ProgrammingConsolidated}
        />
        <Route
          path={`${path}/:projectId/program/:towerId`}
          component={SelectContractorContainer}
        />
        <Route
          exact
          path={`${path}/:projectId/assignment/:towerId/1`}
          component={ProgramAssignmentContainer}
        />
        <Route
          exact
          path={`${path}/:projectId/assignment/:towerId`}
          component={AssignContractorPage}
        />
        <Route
          exact
          path={`${path}/:projectId`}
          component={ProgramHomeContainer}
        />
        <Route exact path={path} component={ProgramProjectsContainer} />
      </CustomSwitch>
    </Container>
  );
};

const PACProgramPage: FC<RouteComponentProps> = (props) => {
  return (
    <ProgramProvider>
      <Program {...props} />
    </ProgramProvider>
  );
};

export default PACProgramPage;
