import { useCallback, useState } from "react";
import useLoading from "shared/customHooks/useLoading";
import { ConsolidatedEvaluationResponse } from "lib/contracts/models/ConsolidatedEvaluationResponse";
import { ConsolidatedEvaluationService } from "lib/contracts/services/ConsolidatedEvaluationService";

const useConsolidatedEvaluationAPI = ({ towerId }: { towerId: string }) => {
  const [response, setResponse] = useState<ConsolidatedEvaluationResponse>();
  const { loading, load, stop } = useLoading();

  const fetch = useCallback(
    async (week = 0) => {
      load();
      try {
        const service = new ConsolidatedEvaluationService();
        const { data } = await service.getConsolidatedEvaluation(
          towerId,
          week,
          {}
        );
        setResponse(data);
      } catch (error) {
        alert(error);
      }
      stop();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [towerId]
  );

  return { response, isFetching: loading, fetch };
};

export default useConsolidatedEvaluationAPI;
