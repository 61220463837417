import { Tower } from "lib/projects/models/Tower";
import { TowerService } from "lib/projects/services/TowerService";
import moment from "moment";
import { useForm } from "shared/customHooks/useForm";
import { ISODate } from "utils/Validators";

interface IUseTowerFormProps {
  tower: Partial<Tower> | undefined;
  handleSave: (tower: Partial<Tower>, updated?: boolean) => void;
}

const useTowerForm = ({ tower, handleSave }: IUseTowerFormProps) => {
  const form = useForm<Tower>({
    validations: {
      startDate: {
        custom: {
          isValid: (value) => !!value && !ISODate.validate(value)?.error,
          message: "Debe seleccionar una fecha de inicio",
        },
      },
      virtualWeek: {
        custom: {
          isValid: (virtualWeek) => virtualWeek > 0,
          message: "Semana de inicio debe ser superior a 0",
        },
      },
      name: {
        required: {
          value: true,
          message: "Se requiere el nombre de la etapa",
        },
      },
      erp: {
        required: {
          message: "El código ERP es requerido",
          value: true,
        },
      },
      closingDay: {
        custom: {
          isValid: (value) => !!value,
          message: "Debe seleccionar un día de corte PAC",
        },
      },
    },
    initialValues: {
      name: tower?.name || "",
      erp: tower?.erp || "",
      closingDay: tower?.closingDay || 0,
      virtualWeek: tower?.virtualWeek || 0,
      startDate: tower?.startDate
        ? moment(tower.startDate).toDate()
        : moment().toDate(),
    },
    async onSubmit(towerData, reset) {
      try {
        if (!!tower?.id?.length && !tower?.id?.includes("temp_")) {
          const service = new TowerService();
          const updatedData = {} as Record<string, unknown>;

          Object.keys(towerData).forEach((keyString) => {
            const key = keyString as keyof Partial<Tower>;
            if (
              key === "startDate" &&
              !moment(towerData.startDate).isSame(tower.startDate)
            )
              updatedData.startDate = towerData.startDate;
            else if (key !== "startDate" && towerData[key] !== tower[key])
              updatedData[key] = towerData[key];
          });
          if (Object.values(towerData).length)
            await service.update(tower.id, updatedData);
        }
        handleSave(
          {
            ...tower,
            ...towerData,
          },
          !!tower?.id
        );
        alert({
          message: "La acción se ejecutó con éxito",
          severity: "success",
        });
        reset();
      } catch (error) {
        alert(error);
      }
    },
  });

  const handleChangeStartDate = (date: Date) => {
    if (!tower?.haveTasksInProgess && date)
      form.handleChangeValue<Date>("startDate", date);
  };

  return {
    ...form,
    handleChangeStartDate,
  };
};

export default useTowerForm;
