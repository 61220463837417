import { AxiosResponse } from "axios";
import { HttpService } from "shared/services/HttpService";
import TaskReasonErrors from "../errors/TaskReason.Errors";
import { TaskReason } from "../models/TaskReason";

export class TaskReasonService extends HttpService {
  constructor() {
    super("/taskreasons", TaskReasonErrors);
  }

  getReasons = (): Promise<AxiosResponse<{ reasons: TaskReason[] }>> =>
    this.httpClient.get(`/`);

  create = (
    reason: Partial<TaskReason>
  ): Promise<AxiosResponse<{ reason: TaskReason }>> =>
    this.httpClient.post("/", reason);

  update = (
    id: string,
    reason: Partial<TaskReason>
  ): Promise<AxiosResponse<{ updated: boolean }>> =>
    this.httpClient.put(`/${id}`, reason);

  remove = (
    reasonId: TaskReason["id"]
  ): Promise<AxiosResponse<{ success: boolean }>> =>
    this.httpClient.delete(`/${reasonId}`);
}
