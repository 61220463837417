import React, { FC, ReactNode } from "react";
import { Box } from "@material-ui/core";
import { Project } from "lib/projects/models/Project";
import AppList from "components/List/List";
import useListProjects from "./hooks/useListProjects";

interface Props {
  renderProject?: (project: Project) => ReactNode;
  onSelectProject: (project: Project) => void;
}

const List = AppList<Project>();

const ProjectList: FC<Props> = ({ renderProject, onSelectProject }) => {
  const { projects } = useListProjects();
  return (
    <>
      <List
        elementChildComponent={(project) => (
          <>
            <Box>{project.name}</Box>
            {renderProject ? renderProject(project) : null}
          </>
        )}
        elements={projects}
        onSelectElement={onSelectProject}
      />
    </>
  );
};

export default ProjectList;
