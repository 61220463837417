import React, { createContext, FC, useContext } from "react";
import useAssignTower, {
  UseAssignTowerResult,
} from "../customHooks/useAssignTower";

type AssignTowerContextType = UseAssignTowerResult;

const AssignTowerContext = createContext<AssignTowerContextType | null>(null);

export const useAssignTowerContext = (): AssignTowerContextType =>
  useContext(AssignTowerContext) as AssignTowerContextType;

export const AssignTowerProvider: FC = ({ children }) => {
  const value = useAssignTower({ a: "1" });

  return (
    <AssignTowerContext.Provider value={{ ...value }}>
      {children}
    </AssignTowerContext.Provider>
  );
};
