import React, { FC } from "react";
import clsx from "clsx";
import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioProps,
  Typography,
} from "@material-ui/core";
import { Role } from "lib/users/models/Role";
import { User } from "lib/users/models/User";
import SelectRolePersonModalStyles from "./styles";
import useSelectRolePerson from "../../customHooks/useSelectRolePerson";

interface ISelectRolePersonModalProps {
  open: boolean;
  roles: Role[];
  person?: User;
  handleChangeRolePerson: (id: string, person: Partial<User>) => void;
  handleClose: () => void;
}

const StyledRadio: FC<RadioProps> = (props) => {
  const classes = SelectRolePersonModalStyles();

  return (
    <Radio
      className={classes.rootCheck}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};

const SelectRolePersonModal: FC<ISelectRolePersonModalProps> = ({
  open,
  handleChangeRolePerson,
  person,
  handleClose,
  roles,
}) => {
  const classes = SelectRolePersonModalStyles();
  const { handleChangeRadio, handleSubmit, data } = useSelectRolePerson({
    person,
    handleChangeRolePerson,
    roles,
  });

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box className={classes.root}>
        <DialogTitle>
          {person?.name
            ? `${person.name} ${person?.lastName || ""}`
            : "Nuevo Usuario"}
          <Typography variant="h2">
            Selecciona el tipo de acceso según la información que necesita
            visualizar.
          </Typography>
        </DialogTitle>
        <form noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="quiz"
                name="quiz"
                value={data.roleId}
                onChange={handleChangeRadio("roleId")}
              >
                {roles.map((role) => (
                  <FormControlLabel
                    key={role.id}
                    value={role.id}
                    checked={
                      typeof data.roleId !== "undefined" &&
                      role.id === data.roleId
                    }
                    className={classes.radioRoot}
                    control={<StyledRadio />}
                    label={
                      <div>
                        <Typography>{role.name}</Typography>
                        <span className={classes.labelSubtitle}>
                          <Typography>{role.desc}</Typography>
                        </span>
                      </div>
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="primary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              {person?.id ? "Actualizar" : "Crear"}
            </Button>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
};

export default SelectRolePersonModal;
