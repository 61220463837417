import React, { FC, ComponentType } from "react";
import { Contractor } from "lib/contractors/models/Contractor";
import useContractors from "lib/contractors/customHooks/useContractors";
import { Contract } from "lib/contracts/models/Contract";
import { ActivityDate } from "lib/projects/models/ActivityDate";
import { Activity } from "lib/projects/models/Activity";
import HavePermission from "components/HavePermission/HavePermission";
import ActivityContractorListTable from "./components/ActivityContractorListTable/ActivityContractorListTable";
import useActivityContractorList, {
  ActivityContractorListProps,
} from "./hooks/useActivityContractorList";
import { TaskRow } from "./models/ActivityContractorListRow";

interface AssignChildNode {
  activity: TaskRow;
  contractors: Contractor[];
  handleAssignContractor: (contract?: Contract) => void;
}

interface ChangeDateChildNode {
  activityDate: ActivityDate;
  handleDateChange: (activityDate?: ActivityDate) => void;
}

interface TableHeaderProps {
  handleAddActivity: (activity: Activity) => void;
}

interface RemoveActivity {
  handleRemoveActivity: (id?: string) => void;
  activityId: string;
}

interface Props {
  assignContractorComponent: ComponentType<AssignChildNode>;
  changeActivityDateComponent: ComponentType<ChangeDateChildNode>;
  tableHeader: ComponentType<TableHeaderProps>;
  removeActivityComponent: ComponentType<RemoveActivity>;
}

const ActivityContractorList: FC<ActivityContractorListProps & Props> = ({
  tower,
  assignContractorComponent: AssignContractorComponent,
  changeActivityDateComponent: ChangeActivityDateComponent,
  tableHeader: TableHeader,
  removeActivityComponent: RemoveActivityComponent,
}) => {
  const {
    activities,
    activityDate,
    activity,
    activityAction,
    selectActivity,
    selectActivityDate,
    handleAssignContractor,
    handleDateChange,
    addActivity,
    handleRemoveClick,
    handleRemoveActivity,
  } = useActivityContractorList({
    tower,
  });
  const [contractors] = useContractors();
  return (
    <>
      {activityAction?.id && activityAction.action === "remove" ? (
        <HavePermission resourceName="pac.assignActivities" action="canDelete">
          <RemoveActivityComponent
            activityId={activityAction?.id}
            handleRemoveActivity={handleRemoveActivity}
          />
        </HavePermission>
      ) : null}
      <TableHeader handleAddActivity={addActivity} />
      {activityDate?.id ? (
        <ChangeActivityDateComponent
          activityDate={activityDate}
          handleDateChange={handleDateChange}
        />
      ) : null}
      {activity?.id ? (
        <AssignContractorComponent
          activity={activity}
          handleAssignContractor={handleAssignContractor}
          contractors={contractors}
        />
      ) : null}
      <ActivityContractorListTable
        activities={activities}
        onDateSelect={selectActivityDate}
        onClickAssignContractor={selectActivity}
        handleRemoveClick={handleRemoveClick}
      />
    </>
  );
};

export default ActivityContractorList;
