import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router";

import { AuthService } from "lib/auth/services/Auth.Service";
import { User } from "lib/users/models/User";
import { IUseFormResult, useForm } from "shared/customHooks/useForm";
import { LogicError } from "shared/services/LogicError";
import useAuth from "features/Authentication/core/hooks/useAuth";

interface IUseInviteResult {
  loading: boolean;
  isRecovery: boolean;
  user?: User;
}

interface FormData {
  password: string;
  confirmPassword: string;
}

type result = IUseFormResult<FormData> & IUseInviteResult;
const useInvite = (): result => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<User>();
  const { hash } = useParams<{ hash: string }>();

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const service = new AuthService();
      if (path.includes("/recovery/")) {
        const { data: recoveryData } = await service.verifyRecovery(hash);
        setUser(recoveryData?.user || undefined);
      } else {
        const { data } = await service.verify(hash);
        setUser(data.user);
      }
    } catch (error) {
      if (error instanceof LogicError) {
        if (error.logic === "RECOVERY_NOT_FOUND") {
          history.push("/login");
        } else alert({ message: error.message, severity: "warning" });
      } else alert("Error interno servidor");
    }
    setLoading(false);
  }, [history, hash, path]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const form = useForm<FormData>({
    validations: {
      password: {
        pattern: {
          value: "^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$",
          message:
            "Mínimo ocho caracteres, al menos una letra mayúscula y un número:",
        },
      },
      confirmPassword: {
        custom: {
          isValid(value, data) {
            return (
              typeof data !== "undefined" &&
              !!data.password &&
              value === data.password
            );
          },
          message: "La contraseñas no coincide",
        },
      },
    },
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    async onSubmit({ password }) {
      const service = new AuthService();
      if (user?.id) {
        await service.changePassword(user?.id, password);
        alert({ message: "Ya puedes iniciar sesión 🙌", severity: "success" });
      }
      logout();
    },
  });

  return {
    loading,
    user,
    isRecovery: path.includes("/recovery/"),
    ...form,
  };
};

export default useInvite;
