import { makeStyles, Theme } from "@material-ui/core";

const LoginFormStyles = makeStyles((theme: Theme) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default LoginFormStyles;
