import { Tower } from "lib/projects/models/Tower";
import { setContractorTower } from "modules/Contractor/store";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { TowerListService } from "../services/TowerListService";

interface Props {
  projectId: string;
}

const useTowerList = ({ projectId }: Props) => {
  const dispatch = useDispatch();
  const { contractor } = useSelector((state: RootState) => state.auth);
  const [towers, setTowers] = useState<Tower[]>([]);

  const fetch = useCallback(async () => {
    if (contractor?.id) {
      try {
        const service = new TowerListService();
        const { data } = await service.getTowerListByContractorIdAndProjectId(
          contractor.id,
          projectId
        );
        setTowers(data.towers);
      } catch (error) {
        alert(error);
      }
    }
  }, [projectId, contractor]);

  useEffect(() => {
    fetch();
  }, [contractor, fetch]);

  useEffect(() => {
    dispatch(setContractorTower(null));
  }, [dispatch]);

  return {
    towers,
  };
};

export default useTowerList;
