import React, { FC } from "react";
// import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  Button,
  // DialogActions,
  // DialogContent,
  // DialogTitle,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  Classes,
  Dialog,
  Intent,
  Spinner,
  SpinnerSize,
} from "@blueprintjs/core";
import { User } from "lib/users/models/User";
import { Role } from "lib/users/models/Role";
import SelectContractor from "lib/contractors/components/SelectContractor/SelectContractor";
import useAuth from "features/Authentication/core/hooks/useAuth";
import usePersonModal from "../../customHooks/usePersonModal";
import PersonModalStyles from "./styles";

interface IPersonModalProps {
  open: boolean;
  person?: User;
  handleUserCreated: (person: User) => void;
  handleUserUpdated: (person: User) => void;
  handleClose: () => void;
  roles: Role[];
}

const PersonModal: FC<IPersonModalProps> = ({
  open,
  handleUserCreated,
  handleUserUpdated,
  person,
  handleClose: onClose,
  roles,
}) => {
  const classes = PersonModalStyles();
  const { user } = useAuth();
  const {
    data,
    errors,
    formLoading,
    requireContractor,
    error,
    contractor,
    handleChange,
    handleSubmit,
    handleChangeSelect,
    handleClose,
    selectContractor,
  } = usePersonModal({
    handleUserCreated,
    handleUserUpdated,
    onClose,
    person,
    roles,
  });

  return (
    <Dialog
      isOpen={open}
      onClose={requireContractor ? () => null : handleClose}
    >
      <div className={Classes.DIALOG_HEADER}>
        {person?.name
          ? `${person.name} ${person?.lastName || ""}`
          : "Nuevo Usuario"}
      </div>
      <div className={Classes.DIALOG_BODY}>
        <form noValidate onSubmit={handleSubmit} id="create-person-form" />
        <Typography variant="h2">
          Se enviará una invitación al correo ingresado
        </Typography>
        <div className={classes.formItem}>
          <div className={classes.label}>
            <Typography>Nombre</Typography>
          </div>
          <TextField
            value={data.name}
            fullWidth
            required
            label="Ingresa"
            name="name"
            autoFocus
            error={!!errors.name}
            helperText={errors.name}
            onChange={handleChange("name")}
          />
        </div>
        <div className={classes.formItem}>
          <div className={classes.label}>
            <Typography>Apellidos</Typography>
          </div>
          <TextField
            fullWidth
            value={data.lastName}
            required
            label="Ingresa"
            name="lastName"
            error={!!errors.lastName}
            helperText={errors.lastName}
            onChange={handleChange("lastName")}
          />
        </div>
        <div className={classes.formItem}>
          <div className={classes.label}>
            <Typography>Correo electrónico</Typography>
          </div>
          <TextField
            fullWidth
            value={data.email}
            required
            label="Ingresa"
            name="email"
            error={!!errors.email}
            helperText={errors.email}
            onChange={handleChange("email")}
          />
        </div>
        <div className={classes.formItem}>
          <div className={classes.label}>
            <Typography>Cargo</Typography>
          </div>
          <TextField
            fullWidth
            value={data.position}
            required
            label="Ingresa"
            name="position"
            error={!!errors.position}
            helperText={errors.position}
            onChange={handleChange("position")}
          />
        </div>
        <div className={classes.formItem}>
          <div className={classes.label}>
            <Typography>Tipo de acceso</Typography>
          </div>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            error={!!errors.roleId}
            disabled={user?.id === person?.id}
          >
            <Select
              fullWidth
              value={data.roleId}
              onChange={handleChangeSelect("roleId")}
            >
              {roles.map((role) => (
                <MenuItem key={`role_${role.id}`} value={role.id}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
            {data.roleId ? (
              <FormHelperText>{errors.roleId}</FormHelperText>
            ) : null}
          </FormControl>
        </div>
        {requireContractor ? (
          <div className={classes.formItem}>
            <div className={classes.label}>
              <Typography>Contratista</Typography>
            </div>
            <FormControl error={!!errors.contractorId} style={{ flexGrow: 1 }}>
              <SelectContractor
                onSelectContractor={selectContractor}
                contractorName={contractor?.name}
              />
              {errors.contractorId ? (
                <FormHelperText>{errors.contractorId}</FormHelperText>
              ) : null}
            </FormControl>
          </div>
        ) : null}
        {error ? <Alert severity="warning">{error}</Alert> : null}
      </div>
      <Box className={Classes.DIALOG_FOOTER_ACTIONS} paddingX={2}>
        <Box marginRight={1}>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            Cancelar
          </Button>
        </Box>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          form="create-person-form"
        >
          {person?.id ? "Actualizar" : "Crear"}
        </Button>
      </Box>
      {formLoading && (
        <Box className={classes.progressBox}>
          <Spinner intent={Intent.PRIMARY} size={SpinnerSize.LARGE} />
        </Box>
      )}
    </Dialog>
  );
};

export default PersonModal;
