import { Contract } from "lib/contracts/models/Contract";
import { ContractService } from "lib/contracts/services/ContractService";
import { useReducer } from "react";

export enum ScheduleActions {
  NONE = "NONE",
  REMOVE_SCHEDULE = "REMOVE_SCHEDULE",
  SEND_SCHEDULE = "SEND_SCHEDULE",
  VIEW_COMMENT = "VIEW_COMMENT",
  SEND = "SEND",
}

export interface ScheduleAction {
  type: ScheduleActions;
  payload?: {
    week: number;
    comment?: string;
  };
}

interface ActionsState {
  isOpen: boolean;
  isLoading: boolean;
  week?: number;
  comment: string;
  action: ScheduleActions;
}

function counterReducer(
  state: ActionsState,
  action: ScheduleAction
): ActionsState {
  const { type, payload } = action;
  switch (type) {
    case ScheduleActions.REMOVE_SCHEDULE:
      return {
        ...state,
        isOpen: true,
        week: payload?.week,
        action: ScheduleActions.REMOVE_SCHEDULE,
      };
    case ScheduleActions.SEND_SCHEDULE:
      return {
        ...state,
        isOpen: true,
        week: payload?.week,
        action: ScheduleActions.SEND_SCHEDULE,
      };
    case ScheduleActions.VIEW_COMMENT:
      return {
        ...state,
        isOpen: true,
        week: payload?.week,
        comment: payload?.comment || "",
        action: ScheduleActions.VIEW_COMMENT,
      };
    case ScheduleActions.NONE:
      return {
        ...state,
        isOpen: false,
        isLoading: false,
        week: undefined,
        action: ScheduleActions.NONE,
      };
    case ScheduleActions.SEND:
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
}
interface Params {
  contract: Contract;
  fetch: () => void;
}

const useTableCellHeaderActions = ({ contract, fetch }: Params) => {
  const service = new ContractService();
  const [state, dispatch] = useReducer(counterReducer, {
    isOpen: false,
    isLoading: false,
    action: ScheduleActions.NONE,
    comment: "",
  });

  const send = () =>
    dispatch({
      type: ScheduleActions.SEND,
    });

  const end = () =>
    dispatch({
      type: ScheduleActions.NONE,
    });

  const removeScheduleWeek = async (): Promise<void> => {
    try {
      if (typeof state.week === "number") {
        const { data } = await service.removeEvaluationOfWeek(
          contract.id,
          state.week
        );
        if (data.success) {
          await fetch();
          alert({
            message: "Programación borrada",
            severity: "success",
          });
        }
      }
    } catch (error) {
      alert(error);
    }
  };

  const sendSchedule = async (): Promise<void> => {
    try {
      if (typeof state.week === "number") {
        await service.sedEvaluationOfWeek(contract.id, state.week);
        await fetch();
        alert({
          message: "Programación enviada",
          severity: "success",
        });
      }
    } catch (error) {
      alert(error);
    }
  };

  const onCancel = () =>
    dispatch({
      type: ScheduleActions.NONE,
    });

  const onConfirm = async () => {
    send();
    if (state.action === ScheduleActions.REMOVE_SCHEDULE)
      await removeScheduleWeek();
    if (state.action === ScheduleActions.SEND_SCHEDULE) await sendSchedule();
    end();
  };

  return {
    state,
    actionsDispatch: dispatch,
    onCancel,
    onConfirm,
  };
};

export default useTableCellHeaderActions;
