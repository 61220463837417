import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Project } from "../models/Project";
import { ProjectService } from "../services/ProjectService";

export interface UseProjectsParams {
  a: string;
}

type HookType = (
  param?: UseProjectsParams
) => [Project[], Dispatch<SetStateAction<Project[]>>];

const useProjects: HookType = () => {
  const [projects, setProjects] = useState<Project[]>([]);

  const fetch = useCallback(async () => {
    try {
      const service = new ProjectService();
      const { data } = await service.getAll();
      setProjects(data?.projects || []);
    } catch (error) {
      alert(error);
    }
  }, [setProjects]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return [projects, setProjects];
};

export default useProjects;
