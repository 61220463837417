import { useCallback, useEffect, useState } from "react";
import { Activity } from "lib/projects/models/Activity";
import { ActivityService } from "lib/projects/services/ActivityService";
import { Tower } from "lib/projects/models/Tower";
import { Contract } from "lib/contracts/models/Contract";
import { ActivityDate } from "lib/projects/models/ActivityDate";
import moment from "moment";
import { TaskRow } from "../models/ActivityContractorListRow";

export interface ActivityContractorListProps {
  tower: Tower;
}

type Action = "remove";

const useActivityContractorList = ({ tower }: ActivityContractorListProps) => {
  const [activities, setActivities] = useState<Activity[]>([]);
  const [activity, setActivity] = useState<TaskRow>();
  const [activityDate, setActivityDate] = useState<ActivityDate>();
  const [activityAction, setActivityAction] =
    useState<{ id: string; action: Action }>();

  const fetch = useCallback(async () => {
    const taskService = new ActivityService();
    const { data: dataTasks } = await taskService.all(tower.id);
    setActivities(dataTasks.tasks);
  }, [tower]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const selectActivity = useCallback(
    (activitySelected?: TaskRow): void => {
      setActivity(activitySelected);
    },
    [setActivity]
  );

  const selectActivityDate = useCallback(
    (activityDateSelected?: ActivityDate): void => {
      setActivityDate(activityDateSelected);
    },
    [setActivityDate]
  );

  const handleAssignContractor = (contract?: Contract) => {
    if (contract?.id && activity?.id) {
      setActivities(
        activities.map((activityElement) => {
          if (activityElement.id === activity.id) {
            return {
              ...activityElement,
              contracts: [contract],
            };
          }
          return activityElement;
        })
      );
      selectActivity({
        ...activity,
        contractor: contract.contractor,
      } as TaskRow);
    } else {
      selectActivity();
    }
  };

  const handleDateChange = (activityDateUpdated?: ActivityDate) => {
    if (activityDateUpdated?.id && activityDate?.id) {
      setActivities(
        activities.map((activityElement) => {
          if (activityElement.id === activityDateUpdated.id) {
            return {
              ...activityElement,
              startDate: moment(activityDateUpdated.startDate).toDate(),
              endDate: moment(activityDateUpdated.endDate).toDate(),
            };
          }
          return activityElement;
        })
      );
    } else setActivityDate(undefined);
  };

  const addActivity = (newActivity: Activity) => {
    setActivities(activities.concat(newActivity));
  };

  const handleRemoveClick = useCallback((id: string) => {
    setActivityAction({ id, action: "remove" });
  }, []);

  const handleRemoveActivity = (id?: string) => {
    if (id) {
      setActivities(
        activities.filter((activityElement) => activityElement.id !== id)
      );
    }
    setActivityAction(undefined);
  };

  return {
    activityAction,
    activities,
    activity,
    activityDate,
    selectActivity,
    selectActivityDate,
    handleAssignContractor,
    handleDateChange,
    addActivity,
    handleRemoveClick,
    handleRemoveActivity,
  };
};

export default useActivityContractorList;
