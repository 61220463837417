import { Contractor } from "lib/contractors/models/Contractor";
import { Role } from "lib/users/models/Role";
import { User } from "lib/users/models/User";
import { PersonsService } from "lib/users/services/PersonsService";
import { useEffect, useState } from "react";
import { useForm } from "shared/customHooks/useForm";
import { LogicError } from "shared/services/LogicError";

interface IUseNewPersonProps {
  handleUserCreated: (person: User) => void;
  handleUserUpdated: (person: User) => void;
  onClose: () => void;
  person?: User;
  roles: Role[];
}

const usePersonModal = ({
  handleUserCreated,
  handleUserUpdated,
  onClose,
  person,
  roles,
}: IUseNewPersonProps) => {
  const [error, setError] = useState("");
  const [requireContractor, setRequireContractor] = useState(false);
  const [contractor, setContractor] = useState<Contractor>();
  const [init, setInit] = useState(false);

  const checkRoleRequireContractor = (roleId: string) =>
    roles.some((elment) => elment.id === roleId && !!elment?.requireContractor);

  const form = useForm<User>({
    validations: {
      roleId: {
        required: {
          value: true,
          message: "Tipo de acceso es requerido",
        },
      },
      name: {
        required: {
          value: true,
          message: "Nombre es requerido",
        },
      },
      lastName: {
        required: {
          value: true,
          message: "Apellido es requerido",
        },
      },
      email: {
        pattern: {
          value: "^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$",
          message: "Correo electrónico no válido",
        },
        required: {
          value: true,
          message: "Correo electrónico es requerido",
        },
      },
      position: {
        required: {
          value: true,
          message: "Cargo es requerido",
        },
      },
      contractorId: {
        custom: {
          isValid: (value, data): boolean => {
            const required = data?.roleId
              ? checkRoleRequireContractor(data?.roleId as string)
              : false;
            return data?.roleId && required ? !!value : true;
          },
          message: "Debe asignarle un contratista a este usuario",
        },
      },
    },
    initialValues: {
      name: person?.name || "",
      lastName: person?.lastName || "",
      email: person?.email || "",
      position: person?.position || "",
      roleId: person?.roleId || "",
      contractorId: person?.contractorId || "",
    },
    async onSubmit(newData, reset) {
      const service = new PersonsService();
      try {
        if (person?.id) {
          const updatedData = {} as Record<string, unknown>;

          Object.keys(newData).forEach((keyString) => {
            const key = keyString as keyof Partial<User>;
            if (newData[key] !== person[key]) updatedData[key] = newData[key];
          });

          if (Object.keys(updatedData).length) {
            await service.updatePerson(person.id, updatedData);
            handleUserUpdated({
              ...person,
              ...updatedData,
              contractors: contractor?.id ? [contractor] : [],
            });
          } else {
            handleUserUpdated({
              ...person,
              contractors: contractor?.id ? [contractor] : [],
            });
          }
        } else {
          const { roleId, ...createData } = newData;
          const { data: response } = await service.createPerson(
            roleId,
            createData
          );

          handleUserCreated(response.user);
        }
        reset();
        alert({
          message: "Se ha creado el usuario con éxito",
          severity: "success",
          icon: "endorsed",
        });
        if (!person?.id)
          setTimeout(
            () =>
              alert({
                message:
                  "Se ha enviado un correo electrónico para su activación",
                severity: "success",
                icon: "send-message",
              }),
            2000
          );
      } catch (err) {
        if (err instanceof LogicError && err.logic === "EMAIL_EXISTS") {
          setError("Correo electrónico, ya  se encuentra en uso");
        } else alert(err);
      }
    },
  });

  useEffect(() => {
    if (
      person &&
      Array.isArray(person?.contractors) &&
      person?.contractors.length
    ) {
      setContractor(person?.contractors[0]);
      setInit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person?.id]);

  useEffect(() => {
    form.handleChangeValue("contractorId", contractor?.id || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractor]);

  useEffect(() => {
    if (form.data.roleId) {
      setRequireContractor(!!checkRoleRequireContractor(form.data.roleId));
    } else setRequireContractor(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.data.roleId]);

  const selectContractor = (_contractor?: Contractor) => {
    setContractor(_contractor);
  };

  useEffect(() => {
    if (!requireContractor && init) setContractor(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requireContractor, init]);

  const handleClose = (): void => {
    form.resetData();
    onClose();
    setError("");
  };

  return {
    ...form,
    error,
    requireContractor,
    contractor,
    handleClose,
    selectContractor,
  };
};

export default usePersonModal;
