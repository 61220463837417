import useContractorList from "lib/contractors/customHooks/useContractorList";
import { Contractor } from "lib/contractors/models/Contractor";
import { Contract } from "lib/contracts/models/Contract";
import { ContractService } from "lib/contracts/services/ContractService";

import { useEffect, useState } from "react";
import { useProgramAssignmentContext } from "../context/ProgramAssignmentContext";

const useDrawerAssignContractor = () => {
  const {
    currentTask,
    selectCurrentTask,
    contractors: originalContractors,
    changeCurrentTasks,
  } = useProgramAssignmentContext();
  const [loading, setLoading] = useState(false);
  const [haveChanges, setHaveChanges] = useState(false);

  const contractorList = useContractorList(originalContractors);
  const [contractors, setContractors] = useState<Contractor[]>([]);

  useEffect(() => {
    const contracts =
      currentTask?.contracts?.map((contract) => contract.contractorId) || [];
    setContractors(
      contractorList.contractors.filter(
        (contractor) =>
          !contracts.some((contract) => contract === contractor.id)
      )
    );
  }, [contractorList.contractors, currentTask?.contracts]);

  const assignContractor = async (
    contractorSelected: Contractor
  ): Promise<void> => {
    setLoading(true);
    setHaveChanges(true);
    try {
      const contractorService = new ContractService();
      if (currentTask?.id) {
        const { data } = await contractorService.assignActivity(
          contractorSelected.id,
          currentTask?.id
        );
        setContractors(
          contractors.filter(
            (contractor) => contractor.id !== data.contract.contractorId
          )
        );
        const currentContractors = currentTask.contracts;
        selectCurrentTask({
          ...currentTask,
          contracts: currentContractors?.concat(data.contract),
        });
        alert({
          message: "Se ha asignado el contratista a la actividad",
          severity: "success",
        });
      }
    } catch (error) {
      alert(error);
    }
    setLoading(false);
  };

  const handleClose = (): void => {
    if (currentTask && haveChanges) {
      changeCurrentTasks(currentTask);
    }
    selectCurrentTask(undefined);
  };

  const removeContract = async (contract: Contract): Promise<void> => {
    setLoading(true);
    setHaveChanges(true);
    if (contract.tasksCount) alert("Este contratista ya esta programado");
    else {
      const service = new ContractService();
      await service.removeActivity(contract.id);
      setContractors(contractors.concat(contract.contractor));
      if (currentTask) {
        const currentContractors = currentTask.contracts;
        selectCurrentTask({
          ...currentTask,
          contracts: currentContractors?.filter((el) => el.id !== contract.id),
        });
        alert({
          message: "Se ha elminado el contratista de la actividad",
          severity: "success",
        });
      }
    }
    setLoading(false);
  };

  return {
    ...contractorList,
    contractors,
    currentTask,
    handleClose,
    assignContractor,
    loading,
    removeContract,
  };
};

export default useDrawerAssignContractor;
