import { Role } from "lib/users/models/Role";
import { RoleService } from "lib/users/services/RoleService";
import { useCallback, useEffect, useState } from "react";

interface IUseRoleList {
  roles: Role[];
}

const useRoleList = (): IUseRoleList => {
  const [roles, setRoles] = useState<Role[]>([]);

  const fetch = useCallback(async () => {
    const service = new RoleService();
    const { data } = await service.getAllRoles();
    setRoles(data.roles);
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    roles,
  };
};
export default useRoleList;
