import { Contract } from "lib/contracts/models/Contract";
import useBoolean from "shared/customHooks/useBoolean";

interface Props {
  contract: Contract;
  onParentClose: () => void;
}

const useContractorContractDialog = ({ contract, onParentClose }: Props) => {
  const { value: isOpen, setFalse, setTrue: open } = useBoolean(!!contract);

  const close = () => {
    setFalse();
    setTimeout(onParentClose, 100);
  };

  return {
    isOpen,
    close,
    open,
  };
};
export default useContractorContractDialog;
