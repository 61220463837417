import React, { FC } from "react";
import { Box, makeStyles } from "@material-ui/core";

import {
  Button,
  ButtonGroup,
  Card,
  FormGroup,
  H5,
  InputGroup,
} from "@blueprintjs/core";
import { Classes, Popover2 } from "@blueprintjs/popover2";
import { TaskUnit } from "lib/contracts/models/TaskUnit";
import useUpdateSubTaskUnitForm from "../customHooks/useUpdateSubTaskUnitForm";

const useStyles = makeStyles({
  root: {},
  card: {
    padding: "10px",
  },
});

const UnitItem: FC<{
  unit: TaskUnit;
  onConfirm: () => void;
  onUpdateUnit: () => Promise<void>;
}> = ({ unit, onConfirm, onUpdateUnit }) => {
  const classes = useStyles();
  const {
    data,
    handleChange,
    errors,
    formLoading,
    selected,
    select,
    unSelect,
    submit,
  } = useUpdateSubTaskUnitForm({
    onSave: onUpdateUnit,
    unit,
  });
  return (
    <Box key={unit?.id} paddingY={0.5}>
      <Card className={classes.card}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.root}
        >
          <Box>
            {selected ? (
              <Box display="flex" alignItems="center">
                <FormGroup
                  style={{ margin: 0 }}
                  helperText={!!errors?.name && errors?.name}
                  labelFor="unit-input"
                  intent={errors.name ? "danger" : "none"}
                >
                  <InputGroup
                    id="unit-input"
                    placeholder="Nuevo motivo"
                    value={data.name}
                    onChange={handleChange("name")}
                    intent={errors.name ? "danger" : "none"}
                  />
                </FormGroup>
              </Box>
            ) : (
              unit.name
            )}
          </Box>
          <Box>
            <ButtonGroup>
              <Button
                icon={selected ? "floppy-disk" : "edit"}
                onClick={selected ? submit : select}
                loading={formLoading}
              >
                {selected ? "Guardar" : "Editar"}
              </Button>
              {selected ? (
                <Button
                  icon="small-cross"
                  loading={formLoading}
                  onClick={unSelect}
                >
                  Cancelar
                </Button>
              ) : null}
              {!selected && !unit.inUsed ? (
                <Popover2
                  usePortal
                  autoFocus
                  position="top"
                  content={
                    <Card>
                      <div key="text">
                        <H5>Confirmar la eliminación</H5>
                        <p style={{ width: "250px" }}>
                          ¿Está seguro de que desea eliminar estos elementos?
                          <br />
                          No podrás recuperarlos.
                        </p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: 15,
                          }}
                        >
                          <Button
                            className={Classes.POPOVER2_DISMISS}
                            style={{ marginRight: 10 }}
                          >
                            Cancelar
                          </Button>
                          <Button
                            intent="danger"
                            className={Classes.POPOVER2_DISMISS}
                            onClick={onConfirm}
                          >
                            Borrar
                          </Button>
                        </div>
                      </div>
                    </Card>
                  }
                >
                  <Button icon="trash" intent="none">
                    Borrar
                  </Button>
                </Popover2>
              ) : null}
            </ButtonGroup>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default UnitItem;
