import {
  createStyles,
  LinearProgress,
  Theme,
  withStyles,
} from "@material-ui/core";

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {},
    colorPrimary: {
      backgroundColor: "white",
    },
    bar: {
      borderRadius: 5,
      backgroundColor: theme.palette.secondaryDark.main,
    },
  })
)(LinearProgress);

export default BorderLinearProgress;
