import useBoolean from "shared/customHooks/useBoolean";

const useUploadActivities = () => {
  const loadingState = useBoolean();
  const openState = useBoolean();

  const onClose = () => {
    openState.setFalse();
  };

  return {
    isOpen: openState.value,
    isLoading: loadingState.value,
    open: openState.setTrue,
    onClose,
  };
};

export default useUploadActivities;
