import { Column } from "@devexpress/dx-react-grid";
import { Contract } from "lib/contracts/models/Contract";
import { Evaluation } from "lib/contracts/models/Evaluation";
import { useCallback, useEffect, useState } from "react";
// import moment from "moment";
import { TaskEvaluationsDTO } from "lib/contracts/models/TaskEvaluationsDTO";
import { TaskUnit } from "lib/contracts/models/TaskUnit";
import { ContractService } from "lib/contracts/services/ContractService";
import { TaskService } from "lib/contracts/services/TaskService";
import { ResolveVirtualWeek } from "lib/projects/utils/ResolveVirtualWeek";

type Row = {
  [key: string]: unknown;
};

export interface UseProgramContractTableParams {
  contract: Contract;
  units: TaskUnit[];
  haveUser: boolean;
}

export interface UseProgramContractTableResult {
  contract: Contract;
  units: TaskUnit[];
  rows: Row[];
  cols: Column[];
  requireProgramation: boolean;
  isTaskInProgress: boolean;
  lastEvaluationHaveState: boolean;
  taskSelected?: TaskEvaluationsDTO;
  taskEvaluations: TaskEvaluationsDTO[];
  haveUser: boolean;
  fetch: () => Promise<void>;
  onChangeValue: (row: string, value: string) => void;
  removeRow: (row: string) => void;
  selectTask: (task?: TaskEvaluationsDTO) => void;
}

type HookType = (
  param: UseProgramContractTableParams
) => UseProgramContractTableResult;

const useProgramContractTable: HookType = ({ contract, units, haveUser }) => {
  const [evaluations, setEvaluations] = useState<Evaluation[]>([]);
  const [taskEvaluations, setTaskEvaluations] = useState<TaskEvaluationsDTO[]>(
    []
  );
  const [requireProgramation, setRequireProgramation] = useState(false);
  const [rows, setRows] = useState<Row[]>([]);
  const [cols, setCols] = useState<Column[]>([]);
  const [isTaskInProgress, setTaskInProgress] = useState(false);
  const [lastEvaluationHaveState, setLastEvaluationHaveState] = useState(false);
  const [taskSelected, setTaskSelected] = useState<TaskEvaluationsDTO>();

  const fetch = useCallback(async () => {
    try {
      // if (
      //   moment(contract.activity.startDate)
      //     .subtract(1, "week")
      //     .isBefore(new Date())
      // ) {
      setTaskInProgress(true);
      const service = new ContractService();
      const { data } = await service.getAllTaksAndEvaluations(contract.id);
      setTaskEvaluations(data.tasksEvaluations);
      setEvaluations(data.evaluations);
      setRequireProgramation(data.requireProgramation);
      // }
    } catch (error) {
      alert(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract.id]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    if (evaluations.length) {
      const lastEvaluation = evaluations[evaluations.length - 1];
      const haveLastEvaluationState = !!lastEvaluation.state;
      setLastEvaluationHaveState(haveLastEvaluationState);
      const data = evaluations.map((evaluation) => {
        const virtualWeek = ResolveVirtualWeek(evaluation?.virtualWeek);
        return {
          name: `week_${evaluation.towerWeek}`,
          title: `Semana ${evaluation.towerWeek + virtualWeek}`,
          ...evaluation,
        };
      });
      const initialColumns = [];
      if (!haveLastEvaluationState)
        initialColumns.push({ name: "inUse", title: " " });

      initialColumns.push(
        ...[
          { name: "name", title: "Actividades" },
          { name: "unit", title: "Unidad" },
          { name: "total", title: "Total" },
        ]
      );
      setCols([...initialColumns, ...data]);
    }
  }, [evaluations]);

  const getRowData = () => {
    const rowsData = taskEvaluations
      .filter(
        (task) => Array.isArray(task.evaluations) && task.evaluations.length
      )
      .map((task) => {
        const { evaluations: currentEvaluations, ...extra } = task;
        const evaluationsData = currentEvaluations.reduce(
          (prev, evaluation) => {
            return {
              ...prev,
              [`week_${evaluation.towerWeek}`]: {
                ...evaluation,
                value: evaluation.value.toFixed(2),
              },
            };
          },
          {}
        );
        return {
          ...extra,
          ...evaluationsData,
        };
      });
    return rowsData;
  };

  useEffect(() => {
    if (taskEvaluations.length) {
      const rowData = getRowData();
      setRows(rowData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskEvaluations]);

  const getTask = (
    task: TaskEvaluationsDTO,
    newValue: number
  ): TaskEvaluationsDTO => {
    const currentTask = task;
    if (
      Array.isArray(currentTask.evaluations) &&
      currentTask.evaluations.length
    ) {
      try {
        const weeksData = currentTask.evaluations;
        const weeksPrediction = weeksData.filter((week) => week.isPrediction);
        const prevTotal = currentTask.prevTotal || 0;

        const value = +newValue;
        const currentTotal = currentTask.total - prevTotal - value;
        const predictionLength = weeksPrediction.length - 1;
        const prediction =
          currentTotal / (predictionLength > 0 ? predictionLength : 1);
        currentTask.evaluations = weeksData.map((week) => {
          if (week.currentProgramation) return { ...week, value };
          if (week.isPrediction)
            return { ...week, value: +prediction.toFixed(2) };
          return week;
        });
      } catch (err) {
        alert(err);
      }
    }
    return task;
  };

  const onChangeValue = (row: string, stringValue: string) => {
    const value = stringValue.replaceAll(",", "");
    if (Number.isNaN(+value)) alert("No valido");
    else {
      const numValue = Number(value) < 0 ? 0 : Number(value);
      setTaskEvaluations(
        taskEvaluations.map((task) =>
          task.id === row ? getTask(task, numValue) : task
        )
      );
    }
  };

  const removeRow = async (row: string) => {
    try {
      const service = new TaskService();
      const { data } = await service.removeTask(row);
      if (data.removed) {
        await fetch();
        alert({
          message: "La acción se ejecutó con éxito",
          severity: "success",
        });
      }
    } catch (error) {
      alert(error);
    }
  };

  const selectTask = (task?: TaskEvaluationsDTO) => {
    setTaskSelected(task);
  };

  return {
    contract,
    cols,
    rows,
    isTaskInProgress,
    lastEvaluationHaveState,
    taskEvaluations,
    units,
    requireProgramation,
    taskSelected,
    haveUser,
    fetch,
    onChangeValue,
    removeRow,
    selectTask,
  };
};

export default useProgramContractTable;
