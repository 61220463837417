import React, { FC } from "react";
import useHavePermission, { HavePermissionProps } from "./useHavePermission";

const HavePermission: FC<HavePermissionProps> = ({
  children,
  resourceName,
  action,
  onReject,
}) => {
  const isValid = useHavePermission({
    resourceName,
    action,
    onReject,
  });

  return <>{isValid ? children : null}</>;
};
export default HavePermission;
