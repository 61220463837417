import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router";

import useProject from "lib/projects/cutomHooks/useProject";
import useTower from "lib/projects/cutomHooks/useTower";
import { Project } from "lib/projects/models/Project";
import { Tower } from "lib/projects/models/Tower";
import { User, UserTower } from "lib/users/models/User";
import { PersonsService } from "lib/users/services/PersonsService";

export interface UseAssignTowerParams {
  a: string;
}

type AssignUser = (user: User) => Promise<void>;
type RemoveUser = (userTower: UserTower) => Promise<void>;

export interface UseAssignTowerResult {
  tower?: Tower;
  project?: Project;
  usersInTower: UserTower[];
  usersOutTower: User[];
  assignUser: AssignUser;
  removeUser: RemoveUser;
}

type HookType = (param?: UseAssignTowerParams) => UseAssignTowerResult;

const useAssignTower: HookType = () => {
  const { towerId } = useParams<{ towerId: string }>();
  const [usersInTower, setUsersInTower] = useState<UserTower[]>([]);
  const [usersOutTower, setUsersOutTower] = useState<User[]>([]);

  const history = useHistory();
  const { path } = useRouteMatch();

  const onError = (): void => {
    history.push(path.replace("/tower/assign/:towerId", ""));
  };

  const { tower } = useTower({
    id: towerId,
    onError,
  });

  const [project] = useProject({ id: tower?.projectId, onError });

  const fetch = useCallback(async () => {
    try {
      if (tower?.id) {
        const service = new PersonsService();
        const { data } = await service.getAllPersonsInTower(tower.id);
        setUsersInTower(data?.in);
        setUsersOutTower(data?.out);
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message.includes("invalid input synta")) {
          alert({ message: "No existe este proyecto", severity: "warning" });
        } else alert(error.message);
      }
      if (onError) onError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tower]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const assignUser: AssignUser = async (user: User) => {
    try {
      if (user.id && tower && tower.id) {
        const service = new PersonsService();
        const { data } = await service.assignUser(tower.id, user.id);
        if (data.success) {
          await fetch();
        }
      }
    } catch (error) {
      alert(error);
    }
  };

  const removeUser: RemoveUser = async (userTower: UserTower) => {
    try {
      if (userTower.userId && tower && tower.id) {
        const service = new PersonsService();
        const { data } = await service.removeUser(tower.id, userTower.userId);
        if (data.success) {
          await fetch();
        }
      }
    } catch (error) {
      alert(error);
    }
  };

  return {
    tower,
    project,
    usersInTower,
    usersOutTower,
    assignUser,
    removeUser,
  };
};

export default useAssignTower;
