import React, { createContext, FC, useContext } from "react";
import usePACConfig, { UsePACConfigResult } from "../customHooks/usePACConfig";

type PACConfigContextType = UsePACConfigResult;

const PACConfigContext = createContext<PACConfigContextType | null>(null);

export const usePACConfigContext = (): PACConfigContextType =>
  useContext(PACConfigContext) as PACConfigContextType;

export const PACConfigContextProvider: FC = ({ children }) => {
  const value = usePACConfig();

  return (
    <PACConfigContext.Provider value={{ ...value }}>
      {children}
    </PACConfigContext.Provider>
  );
};
