import { AxiosResponse } from "axios";
import { Project } from "lib/projects/models/Project";
import { Tower } from "lib/projects/models/Tower";
import { User } from "lib/users/models/User";
import { HttpService } from "shared/services/HttpService";
import { Contractor } from "../models/Contractor";

export class ContractorService extends HttpService {
  constructor() {
    super("/contractors");
  }

  getAll = (): Promise<AxiosResponse<{ contractors: Contractor[] }>> =>
    this.httpClient.get("/");

  getById = (id: string): Promise<AxiosResponse<{ contractor: Contractor }>> =>
    this.httpClient.get(`/${id}`);

  haveUser = (id: string): Promise<AxiosResponse<{ exists: boolean }>> =>
    this.httpClient.get(`/${id}/user/exists`);

  getUsers = (id: string): Promise<AxiosResponse<{ users: User[] }>> =>
    this.httpClient.get(`/${id}/users`);

  getProjects = (id: string): Promise<AxiosResponse<{ projects: Project[] }>> =>
    this.httpClient.get(`/${id}/projects`);

  getTower = (id: string, towerId: string) =>
    this.httpClient.get<{ tower: Tower }>(`/${id}/tower/${towerId}`);

  create = (
    contractor: Partial<Contractor>
  ): Promise<AxiosResponse<{ contractor: Contractor }>> =>
    this.httpClient.post("/", contractor);

  update = (
    id: string,
    contractor: Partial<Contractor>
  ): Promise<AxiosResponse<{ updated: boolean }>> =>
    this.httpClient.put(`/${id}`, contractor);

  createBulk = (
    // towerId: string,
    contracts: FormData
  ): Promise<AxiosResponse<{ created: boolean }>> =>
    this.httpClient.post(`/bulk`, contracts, {
      headers: { "Content-Type": "multipart/form-data" },
    });
}
