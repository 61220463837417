import React, { FC } from "react";
import { Card } from "@blueprintjs/core";
import { Box, Typography } from "@material-ui/core";
import { Tower } from "lib/projects/models/Tower";
import moment from "moment";
import { ResolveVirtualWeek } from "lib/projects/utils/ResolveVirtualWeek";
import useStyles from "./styles";

const Header: FC<{ week: number; currentWeek: Date[]; tower: Tower }> = ({
  tower,
  currentWeek,
  week,
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <Box display="flex">
        <Box flexBasis="1" flexGrow="1" className={classes.column}>
          <Box>
            <Typography variant="h2">Etapa</Typography>
            <Typography>
              <b>{tower?.name}</b>
            </Typography>
          </Box>
        </Box>
        <Box flexBasis="1" flexGrow="1" className={classes.column}>
          {currentWeek.length ? (
            <Box>
              Semana en evaluación
              <br />
              <Typography className={classes.dark}>
                <b>Semana {week + ResolveVirtualWeek(tower.virtualWeek)}</b>
              </Typography>
              <Typography className={classes.dark}>
                {moment(currentWeek[0]).format("DD MMM")} -
                {moment(currentWeek[1]).format("DD MMM")}
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Card>
  );
};
export default Header;
