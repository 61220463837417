import React, { createContext, FC, useContext } from "react";
import useProgramHome, {
  UseProgramHomeResult,
} from "../customHooks/useProgramHome";

type ProgramHomeContextType = UseProgramHomeResult;

const ProgramHomeContext = createContext<ProgramHomeContextType | null>(null);

export const useProgramHomeContext = (): ProgramHomeContextType =>
  useContext(ProgramHomeContext) as ProgramHomeContextType;

export const ProgramHomeProvider: FC = ({ children }) => {
  const value = useProgramHome();

  return (
    <ProgramHomeContext.Provider value={{ ...value }}>
      {children}
    </ProgramHomeContext.Provider>
  );
};
