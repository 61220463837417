import { useState } from "react";

const useContractorInformation = () => {
  const [tab, setTab] = useState("Home");
  return {
    tab,
    setTab,
  };
};

export default useContractorInformation;
