import React, { createContext, FC, useContext } from "react";
import useProgramContractTable, {
  UseProgramContractTableResult,
} from "./customHooks/useProgramContractTable";
import { ProgramContractTableProps } from "./interfaces/ProgramContractTableProps";

type ProgramContractTableContextType = UseProgramContractTableResult;

const ProgramContractTableContext =
  createContext<ProgramContractTableContextType | null>(null);

export const useProgramContractTableContext =
  (): ProgramContractTableContextType =>
    useContext(ProgramContractTableContext) as ProgramContractTableContextType;

export const ProgramContractTableProvider: FC<ProgramContractTableProps> = ({
  children,
  contract,
  units,
  haveUser,
}) => {
  const value = useProgramContractTable({ contract, units, haveUser });

  return (
    <ProgramContractTableContext.Provider value={{ ...value }}>
      {children}
    </ProgramContractTableContext.Provider>
  );
};
