import { UserCredentials } from "lib/auth/models/UserCredentials";
import { useForm } from "shared/customHooks/useForm";
import { EMAIL } from "utils/Validators";

type OnSubmit = (credentials: UserCredentials) => Promise<void>;

const useLoginForm = (onSubmit: OnSubmit) => {
  const form = useForm<UserCredentials>({
    validations: {
      email: {
        pattern: {
          value: EMAIL,
          message: "Correo electrónico no válido",
        },
        required: {
          value: true,
          message: "El correo electrónico es requerido",
        },
      },
      password: {
        required: {
          value: true,
          message: "Se requiere de una contraseña",
        },
      },
    },
    onSubmit,
    initialValues: {
      email: "",
      password: "",
    },
  });

  return form;
};

export default useLoginForm;
