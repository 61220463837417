import { useSelector } from "react-redux";
import { RootState } from "store";

export type AccessPermission = "canAdd" | "canDelete" | "canEdit" | "canView";

export type HaveAccessType = (
  resourceName: string,
  action: AccessPermission
) => boolean;

const usePermision = () => {
  const { permissions, initializedPermissions } = useSelector(
    (state: RootState) => state.auth
  );

  const haveAccess: HaveAccessType = (resourceName, action) => {
    if (!permissions || !Object.keys(permissions).length) return false;
    const permission = permissions[resourceName];
    return !!permission && permission[action];
  };

  return {
    haveAccess,
    initializedPermissions,
    permissions,
  };
};
export default usePermision;
