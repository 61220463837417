import React, { FC } from "react";
import { Theme, makeStyles } from "@material-ui/core";
import { Card } from "@blueprintjs/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.highlighter.main,
  },
}));

const Summary: FC = ({ children }) => {
  const classes = useStyles();
  return <Card className={classes.root}>{children}</Card>;
};

export default Summary;
