import { useState } from "react";

const useLoading = () => {
  const [loading, setLoading] = useState(false);
  const stop = () => setLoading(false);
  const load = () => setLoading(true);

  return {
    loading,
    load,
    stop,
  };
};

export default useLoading;
