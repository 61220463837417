import AppRoute from "config/router/models/AppRoute";
import React, { FC } from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  useLocation,
} from "react-router-dom";
import CustomSwitch from "shared/components/CustomSwitch/CustomSwitch";
import usePermision from "shared/customHooks/usePermision";

const GenericChildPage: FC<RouteComponentProps & AppRoute> = ({
  name,
  routes,
  path,
}) => {
  const location = useLocation();
  const { haveAccess } = usePermision();

  return (
    <CustomSwitch>
      {Array.isArray(routes) && routes.length ? (
        <>
          {routes?.map((route) => {
            return (
              <Route
                key={`${name}/${route.name}`}
                path={`${path}${route.path}`}
                render={(props) => {
                  if (haveAccess(route.name, "canView"))
                    return <route.component {...props} {...route} />;
                  return (
                    <Redirect
                      to={{
                        pathname: "/unauthorized",
                        state: {
                          from: location,
                        },
                      }}
                    />
                  );
                }}
              />
            );
          })}
          <Redirect
            exact
            from={path}
            to={
              location.pathname !== path
                ? location.pathname
                : `${path}${
                    routes.find((route) => haveAccess(route.name, "canView"))
                      ?.path || "/"
                  }`
            }
          />
        </>
      ) : null}
    </CustomSwitch>
  );
};

export default GenericChildPage;
