import React from "react";
import { Box } from "@material-ui/core";
import { Route } from "react-router-dom";

import GlobalAlert from "shared/components/PrivateRoute/UI/GlobalAlert";
import CustomSwitch from "shared/components/CustomSwitch/CustomSwitch";
import { PublicRoutes, PrivateRoutes } from "config/router/routes";
import PrivateRoute from "shared/components/PrivateRoute/UI/PrivateRoute";
import useAuth from "features/Authentication/core/hooks/useAuth";
import { Spinner } from "@blueprintjs/core";
import usePermision from "shared/customHooks/usePermision";
import NotFoundPage from "pages/NotFound/NotFoundPage";
// import Unauthorized from "pages/Unauthorized/unauthorized";
import Unauthorized from "pages/Unauthorized/Unauthorized";
import SidebarComponent from "shared/sidebar/UI/SidebarComponent";
import useStyles from "./styles";
import useApp from "./customHooks/useApp";

const App = (): JSX.Element => {
  const { loading, initialized, minSide } = useApp();
  const classes = useStyles({ minSide });
  const { user, initializedPermissions } = useAuth();
  const { haveAccess } = usePermision();
  return (
    <Box>
      <GlobalAlert />
      {loading ? (
        <Box
          height="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner intent="primary" size={100} />
        </Box>
      ) : null}
      {initialized ? (
        <Box
          className={classes.root}
          flexDirection={minSide ? "column" : "row"}
        >
          {user ? (
            // && !history.location.pathname.includes("/invite/")
            <SidebarComponent />
          ) : null}

          <Box className={classes.container}>
            <div className={classes.root}>
              <main className={classes.content}>
                {initializedPermissions ? (
                  <div className={classes.containerRoutes}>
                    <CustomSwitch>
                      <Route path="/unauthorized" component={Unauthorized} />
                      <Route path="/notfound" component={NotFoundPage} />
                      {PrivateRoutes.map((route) => (
                        <PrivateRoute
                          haveAccess={haveAccess}
                          path={route.path}
                          key={`${route.name}`}
                          route={route}
                        >
                          <route.component {...route} />
                        </PrivateRoute>
                      ))}
                    </CustomSwitch>
                  </div>
                ) : (
                  <div className={classes.containerRoutes}>
                    <CustomSwitch>
                      <Route path="/unauthorized" component={Unauthorized} />
                      <Route path="/notfound" component={NotFoundPage} />
                      {PublicRoutes.map((route) => (
                        <Route path={route.path}>
                          <route.component />
                        </Route>
                      ))}
                    </CustomSwitch>
                  </div>
                )}
              </main>
            </div>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default App;
