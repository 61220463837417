import React, { createContext, FC, useContext } from "react";
import useProjectHome, {
  UseProjectHomeResult,
} from "../customHooks/useProjectHome";

type ProjectHomeContextType = UseProjectHomeResult;

const ProjectHomeContext = createContext<ProjectHomeContextType | null>(null);

export const useProjectHomeContext = (): ProjectHomeContextType =>
  useContext(ProjectHomeContext) as ProjectHomeContextType;

export const ProjectHomeProvider: FC = ({ children }) => {
  const value = useProjectHome();

  return (
    <ProjectHomeContext.Provider value={{ ...value }}>
      {children}
    </ProjectHomeContext.Provider>
  );
};
