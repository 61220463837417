import React, { FC } from "react";
import { H1, Icon } from "@blueprintjs/core";
import { Box } from "@material-ui/core";
import ProjectList from "modules/Contractor/features/ProjectList/ProjectList";
import { useHistory, useLocation } from "react-router";

const ListProjectsPage: FC = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  return (
    <>
      <Box>
        <Box display="flex" justifyContent="flex-start">
          <H1>Proyectos</H1>
        </Box>
        <ProjectList
          renderProject={(project) => (
            <Box>
              {!project.isRequiredApprove ? (
                <Icon icon="issue-closed" intent="success" />
              ) : (
                <Box>
                  <Icon icon="issue" intent="warning" /> Acción requerida
                </Box>
              )}
            </Box>
          )}
          onSelectProject={(project) => push(`${pathname}/${project.id}`)}
        />
      </Box>
    </>
  );
};

export default ListProjectsPage;
