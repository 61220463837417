import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Project } from "lib/projects/models/Project";
import { Tower } from "lib/projects/models/Tower";

interface ContractorState {
  error: string;
  isLoading: boolean;
  initialized: boolean;
  listProjects: Project[];
  listTowers: Tower[];
  project: Project | null;
  tower: Tower | null;
}

const initialState: ContractorState = {
  error: "",
  initialized: false,
  isLoading: false,
  project: null,
  tower: null,
  listProjects: [],
  listTowers: [],
};

const ContractorSlice = createSlice({
  name: "@Contractor",
  initialState,
  reducers: {
    setContractorProjectList(state, action: PayloadAction<Project[]>) {
      state.listProjects = action.payload;
    },
    setContractorTowerList(state, action: PayloadAction<Tower[]>) {
      state.listTowers = action.payload;
    },
    setContractorProject(state, action: PayloadAction<Project | null>) {
      state.project = action.payload;
      if (!action.payload) {
        state.listTowers = [];
        state.tower = null;
      }
    },
    setContractorTower(state, action: PayloadAction<Tower | null>) {
      state.tower = action.payload;
    },
  },
});

export const {
  setContractorProject,
  setContractorProjectList,
  setContractorTower,
  setContractorTowerList,
} = ContractorSlice.actions;

export const ContractorReducer = ContractorSlice.reducer;
