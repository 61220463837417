import { AxiosResponse } from "axios";
import { HttpService } from "shared/services/HttpService";
import { TaskUnit } from "../models/TaskUnit";

export class TaskUnitService extends HttpService {
  constructor() {
    super("/tasksunits");
  }

  getUnits = (): Promise<AxiosResponse<{ units: TaskUnit[] }>> =>
    this.httpClient.get(`/`);

  createUnit = (
    unit: Partial<TaskUnit>
  ): Promise<AxiosResponse<{ unit: TaskUnit }>> =>
    this.httpClient.post("/", unit);

  updateUnit = (
    id: string,
    unit: Partial<TaskUnit>
  ): Promise<AxiosResponse<{ updated: boolean }>> =>
    this.httpClient.put(`/${id}`, unit);

  removeUnit = (
    unitId: TaskUnit["id"]
  ): Promise<AxiosResponse<{ success: boolean }>> =>
    this.httpClient.delete(`/${unitId}`);
}
