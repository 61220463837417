import React, { FC, useCallback, useEffect, useState } from "react";
import { IToastProps, Toaster } from "@blueprintjs/core";
import useAuth from "features/Authentication/core/hooks/useAuth";

type Severity = "none" | "primary" | "success" | "warning" | "danger" | "info";

interface Message extends IToastProps {
  message: string;
  severity: Severity;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function instanceOfMessage(object: any): object is Message {
  if (typeof object === "object") return "message" in object;
  return false;
}

const GlobalAlert: FC = () => {
  const { logout } = useAuth();
  const [ref, setRef] = useState<Toaster>();

  const addMessage = useCallback(
    (message: string | Message | typeof Error): void => {
      if (typeof message === "string") {
        if (message === "LOGOUT") {
          logout();
        } else {
          ref?.show({
            message,
            intent: "primary",
          });
        }
      } else if (message instanceof Error) {
        ref?.show({
          message: message.message,
          intent: "warning",
        });
      } else if (instanceOfMessage(message)) {
        if (ref?.state) {
          ref.show({
            ...message,
            message: message.message,
            intent:
              message.severity === "info"
                ? "primary"
                : message.severity || "danger",
          });
        }
      } else if (ref?.state) {
        ref.show({
          message,
          intent: "danger",
        });
      }
    },
    [logout, ref]
  );

  useEffect(() => {
    window.alert = (message: string) => addMessage(message);
    window.onerror = (msg) => {
      alert(msg);
    };
  }, [addMessage]);

  return (
    <>
      <Toaster
        position="top"
        ref={(currentRef: Toaster) => setRef(currentRef)}
        usePortal
        autoFocus={false}
      />
    </>
  );
};
export default GlobalAlert;
