import React, { FC, ReactChild } from "react";
import {
  alpha,
  createStyles,
  makeStyles,
  Paper,
  Theme,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 5,
      display: "flex",
      padding: `5px 0`,
      paddingRight: 10,
      minHeight: 48,
      justifyContent: "space-between",
      alignItems: "center",
      "&:hover": {
        boxShadow: `0 2px 4px ${alpha(theme.palette.black.main, 0.3)}`,
      },
    },
    desc: {
      padding: "0 20px",
    },
    title: {
      color: theme.palette.black.main,
    },
    subTitle: {
      color: theme.palette.detail.main,
    },
    left: {
      padding: "0 20px",
      display: "flex",
      alignItems: "center",
    },
  })
);

const ListItemElement: FC<{
  title: string;
  subTitle?: string;
  letfComponent?: ReactChild;
}> = ({ children, title, subTitle, letfComponent }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root} square elevation={0}>
      <div className={classes.left}>
        {letfComponent}
        <div className={classes.desc}>
          <div className={classes.title}>{title}</div>
          <div className={classes.subTitle}>{subTitle}</div>
        </div>
      </div>
      {children}
    </Paper>
  );
};

export default ListItemElement;
