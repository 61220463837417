import { useState } from "react";
import useContractors from "lib/contractors/customHooks/useContractors";

const useSelectContractor = () => {
  const [contractors] = useContractors();
  const [isOpen, setIsOpen] = useState(false);

  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  return {
    contractors,
    isOpen,
    close,
    open,
  };
};

export default useSelectContractor;
