import { AxiosResponse } from "axios";
import { Contract } from "lib/contracts/models/Contract";
import { Activity } from "lib/projects/models/Activity";
import { ActivityService } from "lib/projects/services/ActivityService";

export class ChangeActivityDateService extends ActivityService {
  constructor() {
    super();
    this.errors = {
      START_DATE_GREATER_END_DATE:
        "La fecha de inicio no puede ser mayor a la fecha de fin",
      END_DATE_LESS_END_DATE:
        "La fecha de fin no puede ser menor a la fecha de inicio",
    };
  }

  changeActivityDates = (
    activityId: string,
    dateRange: (Date | null)[]
  ): Promise<
    AxiosResponse<{
      contract?: Contract;
      activity: Activity;
      minDate: string;
      disabledDays?: { before: string };
    }>
  > => this.httpClient.patch(`/${activityId}/dates`, { dateRange });
}
