import { useCallback, useEffect, useState } from "react";
import { Contractor } from "lib/contractors/models/Contractor";
import { ContractorService } from "lib/contractors/services/ContractorService";

type HookType = () => [
  contractors: Contractor[],
  updateContractors: (contractos: Contractor[]) => void,
  fetch: () => Promise<void>
];

const useContractors: HookType = () => {
  const [originalContractors, setOriginalContractors] = useState<Contractor[]>(
    []
  );

  const fetch = useCallback(async () => {
    const service = new ContractorService();
    const { data } = await service.getAll();
    setOriginalContractors(data.contractors || []);
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const updateContractors = (contractos: Contractor[]): void =>
    setOriginalContractors(contractos);

  return [originalContractors, updateContractors, fetch];
};

export default useContractors;
