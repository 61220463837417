import { useCallback, useEffect, useState } from "react";
import useConsolidatedEvaluationAPI from "lib/contracts/hooks/useConsolidatedEvaluationAPI";
import useTowerOfContractor from "modules/Contractor/hooks/useTowerOfContractor";
import {
  ContractorResponse,
  ContractResponse,
} from "lib/contracts/models/ConsolidatedEvaluationResponse";

interface Props {
  towerId: string;
  week: number;
}

const useTowerWeek = ({ towerId, week }: Props) => {
  const tower = useTowerOfContractor(towerId);
  const { fetch, isFetching, response } = useConsolidatedEvaluationAPI({
    towerId,
  });
  const [contractor, setContractor] = useState<ContractorResponse>();
  const [contract, setContract] = useState<ContractResponse>();

  useEffect(() => {
    fetch(week);
  }, [fetch, week]);

  useEffect(() => {
    if (response && response.contractors.length >= 1) {
      setContractor(response.contractors[0]);
    }
  }, [response]);

  const onSelectContract = useCallback(
    (contractSelected?: ContractResponse) => {
      setContract(contractSelected);
    },
    []
  );

  const onDialogClose = () => setContract(undefined);

  return {
    tower,
    contractor,
    contract,
    isLoading: isFetching,
    onDialogClose,
    onSelectContract,
  };
};

export default useTowerWeek;
