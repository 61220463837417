import { useCallback, useEffect, useState } from "react";
import { Contract } from "../models/Contract";
import { ContractService } from "../services/ContractService";

interface Params {
  contractorId?: string;
  projectId: string;
}

const useContractByContractorAndProject = ({
  contractorId,
  projectId,
}: Params) => {
  const [contracts, setContracts] = useState<Contract[]>([]);

  const fetch = useCallback(async () => {
    if (contractorId && projectId) {
      try {
        const service = new ContractService();
        const { data } = await service.getContractsByApproval(
          contractorId,
          projectId
        );
        setContracts(data.contracts);
      } catch (error) {
        alert(error);
      }
    }
  }, [contractorId, projectId]);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    contracts,
  };
};

export default useContractByContractorAndProject;
