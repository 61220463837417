import React, { FC } from "react";
import { H1 } from "@blueprintjs/core";
import { Box } from "@material-ui/core";
import ConsolidatedEvaluation from "modules/PAC/features/ConsolidatedEvaluation/UI/ConsolidatedEvaluation/ConsolidatedEvaluation";
import { Flex } from "shared/components/Flex/Flex";
import { useParams } from "react-router";

const ConsolidatedPage: FC = () => {
  const { towerId } = useParams<{ towerId: string }>();
  return (
    <Box>
      <Flex justifyContent="space-between" container alignItems="center">
        <Flex>
          <H1>Informe Consolidado de Evaluación</H1>
        </Flex>
      </Flex>
      <ConsolidatedEvaluation towerId={towerId} />
    </Box>
  );
};

export default ConsolidatedPage;
