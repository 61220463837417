import React, { FC } from "react";
import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  InputGroup,
} from "@blueprintjs/core";
import { Tower } from "lib/projects/models/Tower";
import { Activity } from "lib/projects/models/Activity";
import { Box } from "@material-ui/core";
import { DateRangePicker } from "@blueprintjs/datetime";
import moment from "moment";
import MomentLocaleUtils from "react-day-picker/moment";
import useCreateFormDialog from "./hooks/useCreateFormDialog";

interface Props {
  tower: Tower;
  onActivityCreated: (activity: Activity) => void;
}

const CreateActivity: FC<Props> = ({ tower, onActivityCreated }) => {
  const {
    isOpen,
    data,
    errors,
    onClose,
    handleClickDialog,
    handleChange,
    handleSubmit,
    handleDatePickerChange,
  } = useCreateFormDialog({ tower, onActivityCreated });
  return (
    <>
      <Button intent="primary" icon="add-row-top" onClick={handleClickDialog}>
        Agregar Actividad
      </Button>
      <Dialog
        isOpen={isOpen}
        title="Agregar Actividad"
        icon="add-row-top"
        onClose={onClose}
      >
        <Box className={Classes.DIALOG_BODY}>
          <form id="create-activity__form" onSubmit={handleSubmit}>
            <Box paddingY={1}>
              <FormGroup
                labelFor="create-activity__name-input"
                label="Nombre Actividad"
                labelInfo="*"
                helperText={errors.name ? errors.name : ""}
                intent={errors.name ? "danger" : "none"}
              >
                <InputGroup
                  id="create-activity__name-input"
                  placeholder="Nombre Actividad"
                  value={data.name}
                  intent={errors.name ? "danger" : "none"}
                  onChange={handleChange("name")}
                />
              </FormGroup>
            </Box>
            <Box paddingY={1}>
              <FormGroup
                label="Fecha de inicio y fin"
                labelInfo="*"
                helperText={errors.dates ? errors.dates : ""}
                intent={errors.dates ? "danger" : "none"}
              >
                <DateRangePicker
                  shortcuts={false}
                  locale="es"
                  value={data.dates}
                  localeUtils={MomentLocaleUtils}
                  minDate={moment(tower.startDate).toDate()}
                  contiguousCalendarMonths={false}
                  onChange={handleDatePickerChange}
                />
              </FormGroup>
            </Box>
          </form>
        </Box>
        <Box className={Classes.DIALOG_FOOTER}>
          <Box className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              intent="primary"
              icon="add"
              form="create-activity__form"
              type="submit"
            >
              Guardar
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default CreateActivity;
